import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate';
import messagesEs from 'vee-validate/dist/locale/es';

import attributes from './attributes';
import RequiredIf from './rules/required_if';
import ConfirmPassword from './rules/confirm_password';

export function init() {
    Validator.localize('es', messagesEs);
    Vue.use(VeeValidate, {
        locale: 'es',
        dictionary: {
            es: {
                attributes: attributes
            }
        }
    });

    VeeValidate.Validator.extend('required_if', RequiredIf, {
        hasTarget: true,
        paramNames: ['targetValue']
    });
    VeeValidate.Validator.extend('confirm_password', ConfirmPassword, {
        hasTarget: true,
        paramNames: ['targetValue']
    });
}

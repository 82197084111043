import { roundPrice } from '../events/utils'

export const dataItemToRequest = (items_in_cart, list_to_request) => {
    for(let item in items_in_cart) {
        let original_item = items_in_cart[item].item ? items_in_cart[item].item : '';
        let obj = {
            price: items_in_cart[item].price,
            quota: items_in_cart[item].quota,
            item_id: original_item ? original_item.id : '',
            is_donation_gift: original_item ? original_item.is_donation_gift : false
        };

        list_to_request.push(obj);
    }
};

export const payment_method = {
    DEPOSIT: 1,
    CARD: 2,
    PERUVIAN_CARD: 3
};

export const assume_commission = {
    COUPLE: 0,
    GUEST: 1
};

export const currency = {
    SOLES: 1,
    DOLAR: 2
};

export const countryIssuance = {
    DEFAULT: 'Perú'
};
<template>
    <div>
        <div v-if="show_first_step">
            <div class="content-fields-form">
                <p></p>
                <p class="info-form">
                    Para ayudarte a restablecer tu contraseña,
                    por favor escribe el correo electrónico
                    vinculado a tu cuenta en The Gift List.
                </p>
                <p></p>
                <form method="post" @submit.prevent="submitFirstStep">
                    <div v-html="csrf_token"></div>

                    <div class="content-field">
                        <label class="label-field">CORREO ELECTRÓNICO</label>
                        <input type="text" name="email" v-model="user.email" class="input-text-form-tgl"
                               v-validate="'required|email|min:5|max:50'" placeholder="Ingresa tu correo electrónico"
                               v-bind:class="{'error-input-text': errors.has('email')}">
                        <p class="text-message-error" v-if="errors.has('email')">{{ errors.first('email') }}</p>
                    </div>

                    <div class="content-submit content-bnt-recover-pass">
                        <a v-bind:href="link_login" class="link-cancel-form">CANCELAR</a>
                        <button type="submit" class="btn-submit-tgl">
                            SIGUIENTE &nbsp;&nbsp;&nbsp;
                            <span class="arrow-btn-form"></span>
                        </button>
                    </div>

                    <div v-if="sent_form">
                        <p v-html="response_form_message" class="error-submit-form-message"></p>
                    </div>

                </form>
            </div>
        </div>

        <recovery-p-enter-code v-if="show_second_step" :data_second_step="data_second_step">
        </recovery-p-enter-code>

    </div>
</template>

<script>

    import * as usersAPI from '../../api/users'
    import RecoveryPEnterCode from './RecoveryPEnterCode'

    export default {
        name: "recovery-password",
        props: ['csrf_token', 'link_login', 'link_home'],
        data: () =>  {
            return {
                user: {
                    email: null,
                },
                sent_form: null,
                response_form_message: '',
                show_first_step: true,
                show_second_step: false,
                data_second_step: null
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            submitFirstStep: function () {

                this.$validator.validateAll().finally(() => {
                    if (this.isFormValid) {

                        usersAPI.sendCode(this.user.email).then((response) => {

                            this.response_form_message = response.data.message;

                            if (response.data.success) {
                                this.show_first_step = false;
                                this.show_second_step = true;
                                this.data_second_step = {
                                    'csrf_token': this.csrf_token,
                                    'user_email': this.user.email,
                                    'link_login': this.link_login
                                }
                            }

                        }).catch((error) => {
                            this.response_form_message = 'Ocurrió un error, intente de nuevo.';
                        }).finally(() => {
                            this.hideMessagesResponse();
                        });

                        this.sent_form = true;
                    }
                });

            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_form_message = '';
                }.bind(this), 2000)
            }
        },
        components: { RecoveryPEnterCode }
    }

</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hide_form_edit_account
        ? _c("form", { attrs: { method: "post" } }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.csrf_token) } }),
            _vm._v(" "),
            _c("div", [
              _c("h4", { staticClass: "common-title-section" }, [
                _vm._v("EDITA TU USUARIO")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("NOMBRE")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:2|max:50|alpha_spaces",
                      expression: "'required|min:2|max:50|alpha_spaces'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.first_name,
                      expression: "user.first_name"
                    }
                  ],
                  staticClass: "common-input-text",
                  class: { "error-input-text": _vm.errors.has("first_name") },
                  attrs: {
                    type: "text",
                    name: "first_name",
                    placeholder: "Ingresa tu nombre",
                    autocomplete: "given-name"
                  },
                  domProps: { value: _vm.user.first_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "first_name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("first_name")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("first_name")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("APELLIDOS")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:2|max:50|alpha_spaces",
                      expression: "'required|min:2|max:50|alpha_spaces'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.last_name,
                      expression: "user.last_name"
                    }
                  ],
                  staticClass: "common-input-text",
                  class: { "error-input-text": _vm.errors.has("last_name") },
                  attrs: {
                    type: "text",
                    name: "last_name",
                    placeholder: "Ingresa tu apellido",
                    autocomplete: "family-name"
                  },
                  domProps: { value: _vm.user.last_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "last_name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("last_name")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("last_name")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("CORREO ELECTRÓNICO")
                ]),
                _vm._v(" "),
                !_vm.edit_email
                  ? _c("div", { staticClass: "static-field" }, [
                      _c("span", {
                        staticClass: "text-static-field",
                        domProps: { innerHTML: _vm._s(_vm.user.email.new) }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "btn-edit-mail",
                          on: {
                            click: function($event) {
                              _vm.edit_email = true
                            }
                          }
                        },
                        [_vm._v("EDITAR")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.edit_email
                  ? _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email|min:5|max:50",
                          expression: "'required|email|min:5|max:50'"
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.email.new,
                          expression: "user.email.new"
                        }
                      ],
                      staticClass: "common-input-text",
                      class: { "error-input-text": _vm.errors.has("email") },
                      attrs: {
                        type: "email",
                        name: "email",
                        placeholder: "Ingresa tu correo electrónico"
                      },
                      domProps: { value: _vm.user.email.new },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.user.email, "new", $event.target.value)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.errors.has("email")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("email")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("TELÉFONO")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "numeric|min:5|max:15",
                      expression: "'numeric|min:5|max:15'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.telephone,
                      expression: "user.telephone"
                    }
                  ],
                  staticClass: "common-input-text",
                  class: { "error-input-text": _vm.errors.has("telephone") },
                  attrs: {
                    type: "text",
                    name: "telephone",
                    placeholder: "Ingresa tu teléfono de contacto"
                  },
                  domProps: { value: _vm.user.telephone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "telephone", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("telephone")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("telephone")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("DIRECCIÓN")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "min:5|max:100",
                      expression: "'min:5|max:100'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.address,
                      expression: "user.address"
                    }
                  ],
                  staticClass: "common-input-text",
                  class: { "error-input-text": _vm.errors.has("address") },
                  attrs: {
                    type: "text",
                    name: "address",
                    placeholder: "Ingresa tu dirección",
                    autocomplete: "street-address"
                  },
                  domProps: { value: _vm.user.address },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "address", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("address")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("address")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("CIUDAD")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "min:2|max:30",
                      expression: "'min:2|max:30'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.city,
                      expression: "user.city"
                    }
                  ],
                  staticClass: "common-input-text",
                  class: { "error-input-text": _vm.errors.has("city") },
                  attrs: {
                    type: "text",
                    name: "city",
                    placeholder: "Ingresa tu ciudad",
                    autocomplete: "address-level2"
                  },
                  domProps: { value: _vm.user.city },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "city", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("city")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("city")))
                    ])
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hide_form_edit_account
        ? _c("form", { attrs: { method: "post" } }, [
            _c("div", [
              _c("h4", { staticClass: "common-title-section after-content" }, [
                _vm._v("CAMBIA TU CONTRASEÑA")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("CONTRASEÑA ACTUAL")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password.current,
                      expression: "password.current"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate.continues",
                      value: "required_if:new_password",
                      expression: "'required_if:new_password'",
                      modifiers: { continues: true }
                    }
                  ],
                  staticClass: "common-input-text",
                  class: {
                    "error-input-text": _vm.errors.has("current_password")
                  },
                  attrs: {
                    type: "password",
                    name: "current_password",
                    autocomplete: "current-password",
                    placeholder: "Ingresa tu contraseña"
                  },
                  domProps: { value: _vm.password.current },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.password, "current", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("current_password")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("current_password")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("NUEVA CONTRASEÑA")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "min:8",
                      expression: "'min:8'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password.new,
                      expression: "password.new"
                    }
                  ],
                  ref: "new_password",
                  staticClass: "common-input-text",
                  class: { "error-input-text": _vm.errors.has("new_password") },
                  attrs: {
                    type: "password",
                    name: "new_password",
                    placeholder: "Ingresa tu nueva contraseña",
                    autocomplete: "new-password"
                  },
                  domProps: { value: _vm.password.new },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.password, "new", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("new_password")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("new_password")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "common-content-field" }, [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("CONFIRMA TU NUEVA CONTRASEÑA")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.continues",
                      value: "confirm_password:new_password",
                      expression: "'confirm_password:new_password'",
                      modifiers: { continues: true }
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password.confirm,
                      expression: "password.confirm"
                    }
                  ],
                  staticClass: "common-input-text",
                  class: {
                    "error-input-text": _vm.errors.has("confirm_password")
                  },
                  attrs: {
                    type: "password",
                    name: "confirm_password",
                    placeholder: "Reingresa tu nueva contraseña",
                    autocomplete: "new-password"
                  },
                  domProps: { value: _vm.password.confirm },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.password, "confirm", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("confirm_password")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(_vm._s(_vm.errors.first("confirm_password")))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "common-container-btn content-bnt-and-link" },
              [
                _c(
                  "a",
                  {
                    staticClass: "link-cancel-form",
                    attrs: { href: "/users/edit-account/" }
                  },
                  [_vm._v("CANCELAR")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "common-green-btn",
                    attrs: { type: "button" },
                    on: { click: _vm.clickBtnSubmit }
                  },
                  [_vm._v("GUARDAR CAMBIOS")]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _vm.response_error_message
                ? _c("p", {
                    staticClass: "error-submit-form-message",
                    domProps: { innerHTML: _vm._s(_vm.response_error_message) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.response_success_message
                ? _c("p", {
                    staticClass: "success-submit-form-message",
                    domProps: {
                      innerHTML: _vm._s(_vm.response_success_message)
                    }
                  })
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hide_form_edit_account
        ? _c("edit-account-confirm", {
            attrs: { data: _vm.data_form_verify_code }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
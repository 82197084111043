import Vue from 'vue'
import CreateEvent from './events/CreateEvent'
import DataCouple from './events/DataCouple'
import CurrencyAndCommission from './events/CurrencyAndCommission'
import CoverDesign from './events/CoverDesign'
import BuildList from './events/BuildList'
import Password from './events/event/Password'
import EventList from './events/event/EventList'
import PreviewList from './events/PreviewList'
import BankAccount from './events/BankAccount'
import PublishEvent from './events/Publish'
import ReceivedGiftsTable from './events/ReceivedGiftsTable'
import GiftsInListTable from './events/GiftsInListTable'
import EventInformation from './events/EventInformation'
import DataOwnerEvent from './events/DataOwnerEvent'


Vue.component('create-event', CreateEvent);
Vue.component('data-couple', DataCouple);
Vue.component('currency-and-commission', CurrencyAndCommission);
Vue.component('cover-design', CoverDesign);
Vue.component('build-list', BuildList);
Vue.component('preview-list', PreviewList);
Vue.component('bank-account', BankAccount);
Vue.component('publish-event', PublishEvent);
Vue.component('received-gifts-table', ReceivedGiftsTable);
Vue.component('gifts-in-list-table', GiftsInListTable);
Vue.component('event-information', EventInformation);

Vue.component('event-password', Password);
Vue.component('event-list', EventList);

Vue.component('data-owner-event', DataOwnerEvent);

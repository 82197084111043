import { render, staticRenderFns } from "./AccountContrib.vue?vue&type=template&id=b349b80c&"
import script from "./AccountContrib.vue?vue&type=script&lang=js&"
export * from "./AccountContrib.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/giftlist-stg/the-gift-list/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b349b80c')) {
      api.createRecord('b349b80c', component.options)
    } else {
      api.reload('b349b80c', component.options)
    }
    module.hot.accept("./AccountContrib.vue?vue&type=template&id=b349b80c&", function () {
      api.rerender('b349b80c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "static_src/js/app-vue/events/event/AccountContrib.vue"
export default component.exports
<template>
    <div>
        <div class="have-account-question">
            ¿Aún no tienes una cuenta con nosotros?
            <a v-bind:href="link_register_user" class="log-in-link">REGÍSTRATE</a>
        </div>
        <div class="content-fields-form">
            <form method="post" @submit.prevent="submitForm">
                <div v-html="csrf_token"></div>

                <div class="content-field">
                    <label class="label-field">CORREO ELECTRÓNICO</label>
                    <input type="text" name="email" v-model="user.email" class="input-text-form-tgl" autofocus
                           autocomplete="username" v-validate="'required|email'"
                           placeholder="Ingresa tu correo electrónico"
                           v-bind:class="{'error-input-text': errors.has('email')}">
                    <p class="text-message-error" v-if="errors.has('email')">{{ errors.first('email') }}</p>
                </div>

                <div class="content-field">
                    <label class="label-field">CONTRASEÑA</label>
                    <input type="password" name="password" v-model="user.password"
                           autocomplete="current-password" v-validate="'required'"
                           class="input-text-form-tgl" placeholder="Ingresa tu contraseña"
                           v-bind:class="{'error-input-text': errors.has('password')}">
                    <p class="text-message-error" v-if="errors.has('password')">{{ errors.first('password') }}</p>
                </div>

                <div class="content-field">
                    <input type="checkbox" name="accept_terms" v-model="user.remember_me" class="checkbox-form-tgl"
                           id="remember_checkbox">
                    <label class="label-field-checkbox" for="remember_checkbox">
                            Recuérdame
                    </label>

                    <a v-bind:href="recover_pass_link" class="forgot-pass-link">¿Olvidaste tu contraseña?</a>

                    <p  v-html="user.remember_me.message" class="text-message-error"></p>
                </div>

                <div class="content-submit">
                    <button type="submit" :disabled="!isFormValid" class="btn-submit-tgl">
                            INICIAR SESIÓN
                    </button>
                </div>

                <div v-if="sent_form">
                    <p v-html="response_form_message" class="error-submit-form-message"></p>
                </div>

            </form>
        </div>
    </div>
</template>

<script>

    import * as securityAPI from '../../api/security'

    export default {
        name: "login-user",
        props: ['csrf_token', 'link_register_user', 'link_home', 'recover_pass_link', 'redirect_to'],
        data: () =>  {
            return {
                user: {
                    email: null,
                    password: null,
                    remember_me: false
                },
                sent_form: null,
                response_form_message: '',
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].pristine) ||
                    Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            submitForm: function () {
                // because of chrome autofill bug Vue watch is not triggered
                // we need to validate here always
                if(this.isFormValid){

                    securityAPI.login(
                        this.user.email, this.user.password, this.user.remember_me, this.redirect_to
                    ).then( (response) => {

                        this.response_form_message = response.data.message;

                        if(response.data.success){
                            window.location = response.data.redirect_to;
                        }

                    }).catch( (error) => {
                        this.response_form_message = 'Ocurrió un error, intente de nuevo.';
                    }).finally(() => {
                        this.hideMessagesResponse();
                    });

                    this.sent_form = true;
                }

            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_form_message = '';
                }.bind(this), 2000)
            }
        }
    }

</script>
<template>
    <div>
        <div v-if="show_second_step">
            <div class="content-fields-form">
                <p></p>
                <p class="info-form">
                    Por tu seguridad, necesitamos verificar tu
                    identidad. Introduce a continuación el código
                    de 6 dígitos que hemos enviado a {{ data_second_step.user_email }}.
                </p>
                <p></p>
                <form method="post" @submit.prevent="submitForm">
                    <div v-html="data_second_step.csrf_token"></div>

                    <div class="content-field">
                        <label class="label-field">INTRODUCE EL CÓDIGO</label>
                        <input type="text" name="code" v-model="code" class="input-text-form-tgl"
                               v-validate="'required|numeric|length:6'" placeholder="Introduce el código"
                               v-bind:class="{'error-input-text': errors.has('code')}">
                        <p class="text-message-error" v-if="errors.has('code')">{{ errors.first('code') }}</p>
                    </div>

                    <div class="content-field">
                        <a v-on:click="resendCode" href="javascript:void(0)" class="resend-code" v-if="!show_sending_message">Reenviar código</a>
                        <a href="javascript:void(0)" class="resend-code" v-if="show_sending_message">Enviando...</a>
                    </div>

                    <div class="content-submit content-bnt-recover-pass">
                        <a v-bind:href="data_second_step.link_login" class="link-cancel-form">CANCELAR</a>
                        <button type="submit" class="btn-submit-tgl">
                            SIGUIENTE &nbsp;&nbsp;&nbsp;
                            <span class="arrow-btn-form"></span>
                        </button>
                    </div>

                    <div v-if="sent_form">
                        <p v-html="response_error_form_message" class="error-submit-form-message"></p>
                        <p v-html="response_success_form_message" class="success-submit-form-message"></p>
                    </div>

                </form>
            </div>
        </div>


        <recovery-p-new-password v-if="show_third_step" :data_third_step="data_third_step">
        </recovery-p-new-password>

    </div>
</template>

<script>

    import * as usersAPI from '../../api/users'
    import RecoveryPNewPassword from './RecoveryPNewPassword'

    export default {
        name: "recovery-p-enter-code",
        props: ['data_second_step'],
        data: () =>  {
            return {
                code: null,
                sent_form: null,
                response_error_form_message: '',
                response_success_form_message: '',
                show_sending_message: false,
                show_second_step: true,
                show_third_step: false,
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            submitForm: function () {

                this.$validator.validateAll().finally(() => {
                    if (this.isFormValid) {

                        usersAPI.checkCode(this.code, this.data_second_step.user_email).then((response) => {

                            if (response.data.success) {
                                this.response_error_form_message = '';
                                this.response_success_form_message = response.data.message;

                                this.show_second_step = false;
                                this.show_third_step = true;
                                this.data_third_step = {
                                    'csrf_token': this.data_second_step.csrf_token,
                                    'user_email': this.data_second_step.user_email,
                                    'security_code': this.code,
                                    'link_login': this.data_second_step.link_login
                                }
                            } else {
                                this.response_error_form_message = response.data.message;
                                this.response_success_form_message = '';
                            }

                        }).catch((error) => {
                            this.response_error_form_message = 'Ocurrió un error, intente de nuevo.';
                            this.response_success_form_message = '';
                        }).finally(() => {
                            this.hideMessagesResponse();
                        });

                        this.sent_form = true;
                    }
                });

            },
            resendCode: function () {

                this.code = null;

                this.show_sending_message =  true;

                usersAPI.sendCode(this.data_second_step.user_email).then( (response) => {

                    if(response.data.success){
                        this.response_error_form_message = '';
                        this.response_success_form_message = 'Un código nuevo ha sido enviado a tu correo electrónico.';

                    }else{
                        this.response_error_form_message = response.data.message;
                        this.response_success_form_message = '';
                    }

                }).catch( (error) => {
                    this.response_error_form_message = 'Ocurrió un error, intente de nuevo.';
                    this.response_success_form_message = '';
                }).finally(() => {
                    this.show_sending_message =  false;
                    this.hideMessagesResponse();
                });

                this.sent_form = true;

            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_form_message = '';
                    this.response_success_form_message = '';
                }.bind(this), 2000)
            }
        },
        components: { RecoveryPNewPassword }
    }


</script>
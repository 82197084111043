var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "dropdown",
        "tgl-dropdown",
        _vm.dropdownClass ? _vm.dropdownClass : ""
      ]
    },
    [
      _c(
        "button",
        {
          class: ["common-select", _vm.buttonClass ? _vm.buttonClass : ""],
          attrs: {
            type: "button",
            disabled: _vm.disabled,
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.selectedValue) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "dropdownMenuButton" }
        },
        _vm._l(_vm.options, function(option) {
          return _c(
            "a",
            {
              class: [
                "dropdown-item",
                _vm.value ===
                (_vm.valuePropName ? option[_vm.valuePropName] : option)
                  ? "active"
                  : ""
              ],
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.updateInput(option)
                }
              }
            },
            [
              _vm._v(
                _vm._s(_vm.labelPropName ? option[_vm.labelPropName] : option)
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <form method="post">
            <div hidden v-html="csrf_token"></div>

            <div class="common-container-content-page container-height-auto">

                <div class="wrapper-info-amount-event">
                    <div class="info-amount info-money-available ">
                        <div>
                            <label class="common-label-field">TOTAL DISPONIBLE</label>
                            <span class="icon-help-tool"
                                  v-tooltip="{ placement: 'top', title: 'Luego de 7 días de recibir un regalo podrás ver el monto sumado aquí y podrás solicitar la transferencia a tu cuenta.' }"></span>
                        </div>
                        <div class="event-amount">{{ event.currency.symbol }} {{ event.money_available }}</div>
                    </div>
                    <div class="info-amount info-money-total hide-in-mobile">
                        <div>
                            <label class="common-label-field">TOTAL REGALADO</label>
                            <span class="icon-help-tool"
                                  v-tooltip="{ placement: 'top', title: 'Al recibir un regalo podrás ver el monto sumado aquí.' }">
                            </span>
                        </div>
                        <div class="event-amount">{{ event.currency.symbol }} {{ event.total }}</div>
                    </div>
                    <div class="info-amount hide-in-mobile">
                        <div>
                            <label class="common-label-field">TOTAL TRANSFERIDO</label>
                        </div>
                        <div class="event-amount">{{ event.currency.symbol }} {{ event.money_transferred }}</div>
                    </div>
                    <div class="wrapper-event-amount-mobile">
                        <div class="info-amount info-money-total">
                            <div>
                                <label class="common-label-field">TOTAL REGALADO</label>
                                <span class="icon-help-tool"
                                      v-tooltip="{ placement: 'top', title: 'Al recibir un regalo podrás ver el monto sumado aquí.' }">
                                </span>
                            </div>
                            <div class="event-amount">{{ event.currency.symbol }} {{ event.total }}</div>
                        </div>
                        <div class="info-amount info-money-transfer">
                            <div>
                                <label class="common-label-field">TOTAL TRANSFERIDO</label>
                            </div>
                            <div class="event-amount">{{ event.currency.symbol }} {{ event.money_transferred }}</div>
                        </div>
                    </div>
                </div>

                <h4 class="common-title-section">TRANSFERIR MONTO</h4>

                <div class="common-content-field">
                    <label class="common-label-field">MONTO A TRANSFERIR</label>

                    <input type="text" name="amount_transfer"
                           v-bind:readonly="event.money_available == 0 ? true : false"
                           class="common-input-text"
                           :placeholder="(windowSize > 768) ? 'Ingresa un monto igual o menor al total disponible' : 'Igual o menor al total disponible'"
                           v-model="amount_to_transfer"
                           v-validate="{required: true, decimal: 2, min_value: 1, max_value: event.money_available}"
                           v-bind:class="{'error-input-text': errors.has('amount_transfer')}">
                    <p class="message-error-field" v-if="errors.has('amount_transfer')">
                        {{ errors.first('amount_transfer') }}
                    </p>

                </div>

                <div class="common-container-btn text-center container-btn-transfer">
                    <button class="common-green-btn" @click.prevent="transferRequestSubmit">
                        SOLICITAR TRANSFERENCIA
                    </button>
                </div>

                <div>
                    <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                    <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
                </div>
            </div>

            <div class="section-history-transfers">
                <h2 class="common-title title-no-padding history-title">HISTORIAL DE TRANSFERENCIAS</h2>
                <div class="wrapper-table-tgl">
                    <div class="table-responsive">
                        <table class="table table-tgl-transfers">
                            <thead>
                                <tr>
                                    <th scope="col" class="title-head-table">ID #</th>
                                    <th scope="col" class="title-head-table">ESTADO</th>
                                    <th scope="col" class="title-head-table">F. PROCESO</th>
                                    <th scope="col" class="title-head-table">F. OPERACIÓN</th>
                                    <th scope="col" class="title-head-table">CARGO</th>
                                    <th scope="col" class="title-head-table">ABONO</th>
                                    <th scope="col" class="title-head-table">ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody v-if="list_transfers.length">
                                <tr v-for="(transfer, index) in list_transfers">
                                    <td scope="row" class="simple-text">{{ transfer.id }}</td>
                                    <td class="simple-text">{{ transfer.transfer_request_status__label }}</td>
                                    <td class="simple-text">{{ transfer.created_at }}</td>
                                    <td class="simple-text" v-if="transfer.transfer_request_status_id != transfer_status.SOLICITADA">
                                        {{ transfer.updated_at }}
                                    </td>
                                    <td class="simple-text" v-else></td>
                                    <td class="simple-text">
                                        {{ event.currency.symbol }} {{ transfer.commission }}
                                    </td>
                                    <td class="simple-text">
                                        {{ event.currency.symbol }} {{ transfer.total }}
                                    </td>
                                    <td>
                                        <a v-if="transfer.transfer_request_status_id == transfer_status.SOLICITADA"
                                           href="javascript:void(0)" @click.prevent="deleteTransferRequest(transfer.id, index)"
                                           class="btn-action-transfer simple-link">
                                            CANCELAR
                                        </a>

                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="7">Lo sentimos. No se encontraron transferencias.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="wrapper-history-mobile">
                    <div v-if="list_transfers.length">
                        <div class="wrapper-row-transfer" v-for="(transfer, index) in list_transfers">
                            <div class="row-transfer">
                                <span class="label-row">ID#</span>
                                <span class="info-row simple-text">{{ transfer.id }}</span>
                            </div>
                            <div class="row-transfer">
                                <span class="label-row">Estado</span>
                                <span class="info-row simple-text">{{ transfer.transfer_request_status__label }}</span>
                            </div>
                            <div class="row-transfer">
                                <span class="label-row">F. Proceso</span>
                                <span class="info-row simple-text">{{ transfer.created_at }}</span>
                            </div>
                            <div class="row-transfer">
                                <span class="label-row">F. Operación</span>
                                <span class="info-row simple-text" v-if="transfer.transfer_request_status_id != transfer_status.SOLICITADA">
                                    {{ transfer.updated_at }}
                                </span>
                                <span class="info-row simple-text" v-else></span>
                            </div>
                            <div class="row-transfer">
                                <span class="label-row">Cargo</span>
                                <span class="info-row simple-text">{{ event.currency.symbol }} {{ transfer.commission }}</span>
                            </div>
                            <div class="row-transfer">
                                <span class="label-row">Abono</span>
                                <span class="info-row simple-text">{{ event.currency.symbol }} {{ transfer.total }}</span>
                            </div>
                            <div class="row-transfer">
                                <span class="label-row">Acciones</span>
                                <a v-if="transfer.transfer_request_status_id == transfer_status.SOLICITADA"
                                   href="javascript:void(0)" @click.prevent="deleteTransferRequest(transfer.id, index)"
                                   class="btn-action-transfer simple-link">
                                    CANCELAR
                                </a>
                                <span class="info-row simple-text" v-else>-</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="simple-text history-title">Lo sentimos. No se encontraron transferencias.</div>
                </div>
            </div>
        </form>
    </div>

</template>

<script>

    import * as TransferUtils from './utils'
    import * as TransferAPI from '../../api/transfers'

    export default {
        name: "transfers",
        props: ['csrf_token', 'data_vue'],
        data: () =>  {
            return {
                response_error_message: null,
                response_success_message: null,
                event: {},
                list_transfers: [],
                amount_to_transfer: null,
                transfer_status: {},
                windowSize: 0
            }
        },
        methods:{
            transferRequestSubmit: function (){

                this.$validator.validate().then(result => {
                    if (result) {
                        TransferAPI.createTransferRequest(this.event.id, this.amount_to_transfer).then((response) => {

                            let is_success = response.data.success;

                            this.response_error_message = is_success ? '' : response.data.message;
                            this.response_success_message = is_success ? response.data.message: '';

                            let transfer_dict = response.data.transfer_dict;
                            if(is_success && transfer_dict){
                                transfer_dict = TransferUtils.updateTransfer(transfer_dict);
                                this.list_transfers.push(transfer_dict);

                                this.event.money_available = response.data.money_available;

                                this.amount_to_transfer = null;

                                this.$validator.reset();
                            }

                            this.hideMessagesResponse();

                        }).catch((error) => {
                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();
                        });
                    }
                });
            },
            deleteTransferRequest: function (transfer_id, index){

                TransferAPI.deleteTransferRequest(this.event.id, transfer_id).then((response) => {

                    let is_success = response.data.success;

                    this.response_error_message = is_success ? '' : response.data.message;
                    this.response_success_message = is_success ? response.data.message: '';

                    if(is_success){
                        this.list_transfers.splice(index, index);
                        this.event.money_available = response.data.money_available;
                    }

                    this.hideMessagesResponse();

                }).catch((error) => {
                    this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                    this.hideMessagesResponse();
                });
            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            },
            handleResize: function() {
                this.windowSize = window.innerWidth;
            }
        },

        created() {

            this.event = this.data_vue.event;
            this.transfer_status = this.data_vue.transfer_status;

            if(this.data_vue.list_transfer_request) {
                this.list_transfers = TransferUtils.loadListTransfers(this.data_vue.list_transfer_request)
            }

            window.addEventListener('resize', this.handleResize);
            this.handleResize();

        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-table-dashboard table-gift-in-list" },
    [
      _c("div", { staticClass: "table-header-dashboard" }, [
        _c("div", { staticClass: "box-of-table col-name-gift" }, [
          _c(
            "a",
            {
              class: [
                "label-header-dashboard",
                _vm.is_dashboard_page ? "" : "no-dashboard"
              ],
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.changeOrderBy("name")
                }
              }
            },
            [_vm._v("NOMBRE")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-of-table col-parent-cat" }, [
          _c(
            "a",
            {
              class: [
                "label-header-dashboard",
                _vm.is_dashboard_page ? "" : "no-dashboard"
              ],
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.changeOrderBy("category__parent__label")
                }
              }
            },
            [_vm._v("CATEGORÍA")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-of-table col-cat-name" }, [
          _c(
            "a",
            {
              class: [
                "label-header-dashboard",
                _vm.is_dashboard_page ? "" : "no-dashboard"
              ],
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.changeOrderBy("category__label")
                }
              }
            },
            [_vm._v("SUBCATEGORÍA")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-of-table col-num-quota" }, [
          _c(
            "a",
            {
              class: [
                "label-header-dashboard",
                _vm.is_dashboard_page ? "" : "no-dashboard"
              ],
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.changeOrderBy("num_quota")
                }
              }
            },
            [_vm._v("CUOTAS")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-of-table col-price" }, [
          _c(
            "a",
            {
              class: [
                "label-header-dashboard",
                _vm.is_dashboard_page ? "" : "no-dashboard"
              ],
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.changeOrderBy("price")
                }
              }
            },
            [_vm._v("PRECIO TOTAL")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-of-table col-state" }, [
          _c(
            "a",
            {
              class: [
                "label-header-dashboard",
                _vm.is_dashboard_page ? "" : "no-dashboard"
              ],
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.changeOrderBy("status")
                }
              }
            },
            [_vm._v("ESTADO")]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "body-header-dashboard" },
        _vm._l(_vm.giftListSorted, function(gift) {
          return _c("div", { staticClass: "row-body-dashboard" }, [
            _c(
              "div",
              { staticClass: "box-of-table col-name-gift col-with-sub-title" },
              [
                _c("div", { staticClass: "sub-title-col" }, [
                  _vm._v("Nombre: ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-body-dashboard is-strong" }, [
                  _vm._v(_vm._s(gift.name))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-of-table col-parent-cat col-with-sub-title" },
              [
                _c("div", { staticClass: "sub-title-col" }, [
                  _vm._v("Categoría: ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-body-dashboard" }, [
                  _vm._v(_vm._s(gift.category__parent__label))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-of-table col-cat-name col-with-sub-title" },
              [
                _c("div", { staticClass: "sub-title-col" }, [
                  _vm._v("Subcategoría: ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-body-dashboard" }, [
                  _vm._v(_vm._s(gift.category__label))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-of-table col-num-quota col-with-sub-title" },
              [
                _c("div", { staticClass: "sub-title-col" }, [
                  _vm._v("Cuotas: ")
                ]),
                _vm._v(" "),
                gift.num_quota > 1
                  ? _c("div", { staticClass: "info-body-dashboard" }, [
                      _vm._v(_vm._s(gift.num_quota))
                    ])
                  : _c("div", { staticClass: "info-body-dashboard" }, [
                      _vm._v("Cuota única")
                    ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-of-table col-price col-with-sub-title" },
              [
                _c("div", { staticClass: "sub-title-col" }, [
                  _vm._v("Precio total: ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-body-dashboard" }, [
                  _vm._v(
                    _vm._s(_vm.event.currency.symbol) + " " + _vm._s(gift.price)
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-of-table col-state col-with-sub-title" },
              [
                _c("div", { staticClass: "sub-title-col" }, [
                  _vm._v("Estado: ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-body-dashboard" }, [
                  _vm._v(_vm._s(gift.status))
                ])
              ]
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
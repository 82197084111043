import * as UtilsJS from '../../utils'

const format_date = 'DD/MM/YYYY';

export const loadListTransfers = (list_transfers) => {
    for(let index in list_transfers) {

        let transfer = list_transfers[index];

        transfer.created_at = UtilsJS.changeFormatDate(transfer.created_at, format_date);
        transfer.updated_at = UtilsJS.changeFormatDate(transfer.updated_at, format_date);
    }

    return list_transfers;
};

export const updateTransfer = (transfer_dict) => {

    transfer_dict.created_at = UtilsJS.changeFormatDate(transfer_dict.created_at, format_date);
    transfer_dict.updated_at = UtilsJS.changeFormatDate(transfer_dict.updated_at, format_date);

    return transfer_dict;
};

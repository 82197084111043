var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper-fixed-bottom-bar",
      attrs: { id: "fixedBottomBar" }
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "content-elements" }, [
            _c(
              "a",
              {
                staticClass: "link-cancel-form link-desktop",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR AL PASO ANTERIOR")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-cancel-form link-mobile",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-items-added" }, [
              _vm._v("\n                    Número de regalos agregados: "),
              _c("span", { staticClass: "count-item-added" }, [
                _vm._v("(" + _vm._s(_vm.items_added_count) + ")")
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("strong", [
                _vm._v(
                  "Total: " +
                    _vm._s(_vm.event.currency.symbol) +
                    " " +
                    _vm._s(_vm.total_amount_items_added)
                )
              ])
            ]),
            _vm._v(" "),
            _vm.event.status_step === 4
              ? _c(
                  "button",
                  {
                    staticClass: "common-green-btn",
                    on: { click: _vm.activateNextStep }
                  },
                  [
                    _vm._v(
                      "\n                    SIGUIENTE\n                    "
                    ),
                    _c("span", { staticClass: "arrow-into-btn" })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.event.status_step > 4
              ? _c(
                  "a",
                  {
                    staticClass: "common-green-btn",
                    attrs: { href: _vm.next_link }
                  },
                  [
                    _vm._v(
                      "\n                    SIGUIENTE\n                    "
                    ),
                    _c("span", { staticClass: "arrow-into-btn" })
                  ]
                )
              : _vm._e()
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="wrapper-view-items">
        <div class="wrapper-options-filter">
            <div class="content-item-filter">
                <input type="text" class="input-search-menu" placeholder="Buscar item" v-model="search_item">
            </div>

            <div class="show-device content-preview">
                <div class="content-item-filter">
                    <a v-bind:href="preview_link" class="text-uppercase simple-link">VISTA PREVIA</a>
                </div>
                <div class="content-item-filter">
                    <a href="javascript:void(0)" v-if="!show_only_added" @click="show_only_added = true"
                       class="text-uppercase simple-link">VER REGALOS AGREGADOS ({{ items_added_count }})</a>
                    <a href="javascript:void(0)" v-if="show_only_added" @click="show_only_added = false"
                       class="text-uppercase simple-link">VER TODOS LOS REGALOS</a>
                </div>
            </div>

            <hr class="hr-tgl show-device">
            <div class="content-item-filter show-device"></div>

            <div class="container-checkbox">
                <input type="checkbox" id="isContributionAccount" class="checkbox-form-tgl"
                        v-model="event.is_contribution_account">
                <label class="label-field-checkbox label-in-list-item" for="isContributionAccount" @click="changeAccountContribution">
                    <span class="text-label-checkbox w-auto">
                        Aporte libre
                    </span>
                </label>

                <span class="icon-help-tool"
                    v-tooltip="{ placement: 'right', title: 'Al incluir esta opción, tus invitados tendrán la posibilidad de aportar el monto de dinero que ellos elijan.' }">
                </span>

                <div>
                    <p v-if="response_error_contribution" v-html="response_error_contribution" class="error-submit-form-message"></p>
                    <p v-if="response_success_contribution" v-html="response_success_contribution" class="success-submit-form-message"></p>
                </div>
            </div>

            <div class="show-device mobile content-preview">
                <div class="content-item-filter">
                    <a v-bind:href="preview_link" class="text-uppercase simple-link">VISTA PREVIA</a>
                </div>
                <div class="content-item-filter">
                    <a href="javascript:void(0)" v-if="!show_only_added" @click="show_only_added = true"
                       class="text-uppercase simple-link">VER REGALOS AGREGADOS ({{ items_added_count }})</a>
                    <a href="javascript:void(0)" v-if="show_only_added" @click="show_only_added = false"
                       class="text-uppercase simple-link">VER TODOS LOS REGALOS</a>
                </div>
            </div>

            <hr class="hr-tgl show-device">
            <div class="content-item-filter"></div>
            <div class="content-item-filter">
                <category-sidebar :data_list_category="data_vue.list_category"></category-sidebar>
            </div>
            <div class="content-item-filter show-device"></div>
            <hr class="hr-tgl show-device">

            <div class="content-item-filter show-device"></div>

            <div class="content-item-filter content-change-currency" v-if="event.status == event_status.NOT_PUBLISHED">
                <div class="wrapper-categories">
                    <div class="content-item-category">
                        <span class="label-category">
                            TIPO DE MONEDA
                        </span>
                        <span class="icon-help-tool"
                              v-tooltip="{ placement: 'right', title: 'Aquí puedes modificar el tipo de moneda de tu lista de regalos. Recuerda que deberás proporcionar una cuenta en la misma moneda y que esta información no podrá ser modificada una vez que tu lista haya sido publicada.' }"
                              >
                        </span>
                    </div>

                    <select class="common-select" v-model.number="event.currency.id" v-if="show_drop_currency">
                        <option v-for="currency in list_currencies" :value="currency.id" >
                        {{ currency.symbol }} {{ currency.name }}
                        </option>
                    </select>

                    <div class="content-item-category content-edit-currency" v-else>
                        <span class="label-category sub-label-category">
                            {{ event.currency.symbol }} - {{ event.currency.name }}
                        </span>
                        <a href="javascript:void(0)" class="simple-link" @click="show_drop_currency = true">EDITAR</a>
                    </div>

                    <div>
                        <p v-if="response_error_currency" v-html="response_error_currency" class="error-submit-form-message"></p>
                        <p v-if="response_success_currency" v-html="response_success_currency" class="success-submit-form-message"></p>
                    </div>

                </div>
            </div>
            <hr class="hr-tgl hr-tgl-mobile show-device" v-if="event.status == event_status.NOT_PUBLISHED">

        </div>
        <div class="wrapper-content-list-item in-build-list">
            <div class="content-open-filter">
                <h2 class="common-title">{{ name_category_selected }}</h2>
                <span class="tab-open-filter show-device mobile" id="openSideFilter">
                    FILTROS
                    <span class="arrow-open-filter"></span>
                </span>
            </div>
            <div class="wrapper-list-item">
                <div class="wrapper-item wrapper-add-item-first"
                     data-toggle="modal" data-target="#storeItemModal" @click="loadCreateDataModal()">
                    <div class="wrapper-add-item">
                        <div class="content-icon-add-item">
                            <div class="img-add-card"></div>
                            <span class="text-add-card">CREAR UN PRODUCTO</span>
                        </div>
                    </div>
                </div>
                <div v-for="item in listItemsFiltered" :key="generateKey(item)"
                    :class="['wrapper-item', item.is_item_added ? 'wrapper-item-added' : 'wrapper-item-normal']">

                    <div class="wrapper-image-card" :style="{ backgroundImage: 'url('+item.image+')'}">
                        <div class="wrapper-bg-added add-to-list" v-if="item.is_item_added"></div>
                        <div class="wrapper-buttons-card text-center" v-if="item.is_item_added">
                            <a href="javascript:void(0)" class="delete-item-link"
                               v-if="!isItemPurchased(item)" @click="deleteItem(item.id)">
                                ELIMINAR
                            </a>
                            <button class="common-green-btn" data-toggle="modal"
                                    data-target="#storeItemModal" @click="loadUpdateDataModal(item)">
                                EDITAR
                            </button>
                        </div>
                        <div class="wrapper-buttons-card text-center" v-else>
                            <button class="common-green-btn" data-toggle="modal"
                                    data-target="#storeItemModal" @click="loadCreateDataModal(item)">
                                AGREGAR
                            </button>
                        </div>
                    </div>

                    <div :class="['wrapper-info-card', item.is_item_added ? '' : 'text-center']">
                        <h5 class="title-card">{{ item.name }}</h5>

                        <div class="description-item" v-if="!item.is_item_added && item.is_donation_gift && item.description">
                                {{ item.description }}
                        </div>

                        <div v-if="item.is_item_added">
                            <div class="description-item" v-if="item.description">
                                {{ item.description }}
                            </div>

                            <div class="price-item" v-if="item.num_quota > 1">
                                {{ event.currency.symbol }}{{ item.price_quota }} por cuota
                            </div>

                            <div class="total-price" v-if="item.num_quota > 1">PRECIO TOTAL: {{ event.currency.symbol }}{{ item.price }}</div>
                            <div class="price-item" v-else>{{ event.currency.symbol }}{{ item.price }}</div>

                            <div class="wrapper-add-another-one" v-if="item.original_item">
                                <div class="text-center">
                                    <a href="javascript:void(0)" class="simple-link add-new-card-link"
                                        data-toggle="modal" data-target="#storeItemModal"
                                        @click="loadCreateDataModal(item.original_item)">
                                        AGREGAR OTRO
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <fixed-bottom-bar
                :before_step="before_step"
                :next_link="next_link"
                :total_amount_items_added="total_amount_items_added"
                :items_added_count="items_added_count"
                :event="event">
            </fixed-bottom-bar>
        </div>
        <store-item-modal
            ref="store-item-modal"
            :data_list_category="data_vue.list_category"
            :currency="event.currency"
            :csrf_token="csrf_token"
            :event_id="event.id"
            @item-added="onItemNew"
            @item-updated="onItemUpdate"
        >
        </store-item-modal>
    </div>
</template>

<script>

    import 'bootstrap/js/dist/modal'
    import * as eventsAPI from '../../api/events'
    import CategorySidebar from './CategorySidebar'
    import StoreItemModal from './StoreItemModal'
    import FixedBottomBar from './FixedBottomBar'
    import * as EventUtils from './utils'

    export default {
        name: "build-list",
        props: ['csrf_token', 'before_step', 'data_vue', 'preview_link', 'next_link'],
        data: () =>  {
            return {
                list_items: [],
                event: {
                    id: null,
                    is_contribution_account: null,
                    status: null,
                    currency: {}
                },
                list_currencies: [],
                items_added_count: 0,
                total_amount_items_added: 0,
                category_selected: 0,
                response_success_contribution: null,
                response_error_contribution: null,
                response_success_currency: null,
                response_error_currency: null,
                name_category_selected: EventUtils.ALL_CATEGORY_NAME,
                show_drop_currency: false,
                search_item: '',
                order_by: EventUtils.ORDER_BY_INITIAL,
                orders: EventUtils.ORDER_BY_OPTIONS,
                show_only_group: false,
                show_only_added: false,
                event_status: EventUtils.event_status
            }
        },

        methods:{
            loadCreateDataModal: function (obj_item) {
                this.$refs['store-item-modal'].loadCreateDataModal(obj_item);
            },
            loadUpdateDataModal: function (obj_item) {
                this.$refs['store-item-modal'].loadUpdateDataModal(obj_item);
            },
            onItemNew: function(obj_item) {
                obj_item.image = this.data_vue.MEDIA_URL + obj_item.image;
                this.list_items.push(obj_item);
                this.total_amount_items_added += obj_item.price;
                ++this.items_added_count;

                if(obj_item.is_donation_gift && !obj_item.item_id){
                    EventUtils.removeItemFromList(this.list_items, obj_item.company_item_id, true);
                }else if (obj_item.item_id) {
                    EventUtils.removeItemFromList(this.list_items, obj_item.item_id, false);
                }
            },
            onItemUpdate: function(obj_item) {
                EventUtils.updateEventItemInList(this.list_items, obj_item, this.data_vue.MEDIA_URL);
            },
            updateCategoryFromCategory: function (category_id, name_category) {
                this.category_selected = category_id;
                this.name_category_selected = name_category;
            },
            updateOrderByFromCategory: function(order_by){
                this.order_by = order_by;
            },
            isItemPurchased: (item) => EventUtils.isItemPurchased(item),
            hideMessagesResponse: function(is_currency_change){
                setTimeout(function () {
                    if(is_currency_change){
                        this.response_error_currency = '';
                        this.response_success_currency = '';
                    }else{
                        this.response_error_contribution = '';
                        this.response_success_contribution = '';
                    }

                }.bind(this), 2000)
            },
            changeAccountContribution: function(){

                eventsAPI.changeAccountContribution(
                    this.event.id, !this.event.is_contribution_account
                ).then( (response) => {

                    let response_success = response.data.success;

                    this.response_error_contribution = response_success ? '' : response.data.message;
                    this.response_success_contribution = response_success ? response.data.message : '';


                    this.hideMessagesResponse(false);

                }).catch( (error) => {
                    this.response_error_contribution = 'Ocurrió un error, intente de nuevo.';
                    this.hideMessagesResponse(false);
                });
            },
            changeCurrency: function(){

                eventsAPI.changeCurrency(
                    this.event.id, this.event.currency.id

                ).then( (response) => {

                    let response_success = response.data.success;

                    this.response_error_currency = response_success ? '' : response.data.message;
                    this.response_success_currency = response_success ? response.data.message : '';

                    const data_currency = response.data.currency;
                    if(response_success && data_currency){
                        this.event.currency.name = data_currency.name;
                        this.event.currency.symbol = data_currency.symbol;
                    }

                    window.location.reload();
                    this.hideMessagesResponse(true);

                }).catch( (error) => {
                    this.response_error_currency = 'Ocurrió un error, intente de nuevo.';
                    this.hideMessagesResponse(true);
                });
            },
            deleteItem: function(item_id){

                eventsAPI.deleteEventItem({
                    id: item_id,
                    event_id: this.event.id
                }).then( (response) => {

                    if(response.data.success){
                        const removed_item = EventUtils.removeEventItemFromList(this.list_items, item_id);
                        if (removed_item) {
                            this.total_amount_items_added -= removed_item.price;
                            --this.items_added_count;
                        }
                    }

                });

            },
            generateKey: function(item){

                let new_key = '';

                if (item.is_donation){
                    new_key = 'company_donation_' + item.id
                }else if(item.is_donation_gift){
                    new_key = 'event_item_' + item.id
                }else{
                    new_key = 'item_' + item.id
                }
                return new_key;
            }
        },
        created() {
            const data_vue = this.data_vue;
            if(data_vue){

                this.list_items = EventUtils.loadEventItems(data_vue);
                this.event = data_vue.event;

                this.list_currencies = data_vue.list_currencies;
                this.items_added_count = data_vue.items_added_count;
                this.total_amount_items_added = parseFloat(data_vue.total_amount_items_added);

            }

        },
        computed: {
            listItemsFiltered() {
                const list_items = EventUtils.filterEventItems(
                    this.list_items, this.search_item, this.category_selected, this.show_only_group,
                    this.show_only_added
                );
                return EventUtils.sortEventItems(list_items, this.order_by);
            }
        },
        components: { CategorySidebar, StoreItemModal, FixedBottomBar },
        watch: {
            'event.currency.id': function(){
                if(this.show_drop_currency){
                    this.changeCurrency();
                }
            }
        }
    }
</script>

<template>
<div>
    <form method="post" @submit.prevent="onSubmit">
        <div v-html="csrf_token"></div>

        <EventInformationLocation
            v-for="(location, index) in locations"
            :key="index"
            :index="index"
            :location="location"
            :googleMapsApiKey="googleMapsApiKey"
            :show="show_locations"
            :icon_list="icon_list"
            @delete-location="deleteLocation"
        />

        <div v-if="locations.length<max_locations_number" class="page-event-info-section"
             v-bind:class="{ deactivated: !show_locations }">
            <div class="row tgl-row event-info-add-location">
                <div class="col-12 tgl-col">
                    <button type="button" class="common-green-btn add-location-btn"
                            :disabled="!show_locations" v-on:click="addLocation">
                        + AGREGAR OTRA LOCACIÓN (ej. Ceremonia, Recepción)
                    </button>
                </div>
            </div>
        </div>
        <div class="page-event-info-section">
            <div class="row tgl-row event-info-title">
                <div class="col-md-12 tgl-col">
                    <h4 class="common-title-section">INFORMACIÓN ADICIONAL</h4>
                </div>
            </div>
            <div class="common-content-field">
                <label class="common-label-field">HASHTAG</label>
                <input type="text" name="hashtag" class="common-input-text"
                       placeholder="#"
                       v-bind:class="{'error-input-text': errors.has('hashtag')}"
                       v-validate="'alpha_num|max:140'"
                       v-model="hashtag">

                <p class="message-error-field" v-if="errors.has('hashtag')">
                    {{ errors.first('hashtag') }}
                </p>
            </div>
            <div class="common-content-field">
                <label class="common-label-field">DRESS CODE</label>
                <textarea type="text" id="dress_code" name="dress_code" class="common-input-text"
                       v-bind:class="{'error-input-text': errors.has('dress_code')}"
                       v-validate="'max:400'"
                       v-model="dress_code"
                       rows="3">
                </textarea>

                <p class="message-error-field" v-if="errors.has('dress_code')">
                    {{ errors.first('dress_code') }}
                </p>
            </div>
            <div class="common-content-field">
                <editor :init="{
                            menubar: false,
                            plugins: [
                                'lists',
                            ],
                            toolbar: 'formatselect | bold italic strikethrough forecolor | alignleft aligncenter alignright alignjustify | numlist bullist',
                        }"
                        v-model="additional_info">
                </editor>
            </div>
        </div>

        <div class="page-event-info-section">
            <div class="common-container-btn text-right">
                <button type="submit" class="common-green-btn">
                    GUARDAR
                </button>
            </div>
            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>
        </div>
    </form>
</div>

</template>

<script>

    import * as eventsAPI from '../../api/events'
    import * as moment from 'moment';
    import Editor from "@tinymce/tinymce-vue";
    import EventInformationLocation from "./EventInformationLocation";
    import { EventBus } from '../event-bus';

    export default {
        name: 'event-information',
        components: {
            Editor,
            EventInformationLocation
        },
        props: ['csrf_token', 'data_vue', 'googleMapsApiKey', 'event_id', 'dashboard_link'],
        data() {
            return {
                locations: [{
                    id: null,
                    title: null,
                    icon: null,
                    date: null,
                    hour: null,
                    minutes: null,
                    am: null,
                    name: null,
                    address: null,
                    latitude: null,
                    longitude: null,
                    order: 1,
                    show: true,
                    changed: false,
                    valid: false,
                }],
                num_initial_locations: 1,
                max_locations_number: 2,
                icon_list: [],
                hashtag: null,
                dress_code: null,
                additional_info: null,
                initial_additional_info: null,
                response_error_message: '',
                response_success_message: '',
            }
        },
        mounted() {
        },
        methods: {
            onSubmit: function () {
                EventBus.$emit('validate-all');
                this.$validator.validate().then(result => {
                    if (result && this.isFormChanged && this.isFormValid) {
                        const data = {
                            event_id: this.event_id,
                            locations: this.locations,
                            hashtag: this.hashtag,
                            dress_code: this.dress_code,
                            additional_info: this.additional_info
                        };
                        const data_json = {
                            data: JSON.stringify(data)
                        };

                        eventsAPI.saveEventInformation(data_json).then( (response) => {

                            let redirect = false;
                            if(response.data.success){
                                this.response_error_message = '';
                                this.response_success_message = response.data.message;
                                redirect = true;
                            }else{
                                this.response_error_message = response.data.message;
                                this.response_success_message = '';
                            }

                            this.hideMessagesResponse(redirect);

                        }).catch( (error) => {
                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();
                        });
                    } else if(this.isFormChanged && !this.isFormValid) {
                        this.response_error_message = 'No se ha podido grabar. Revisa los errores en formulario.';
                        this.response_success_message = '';
                        this.hideMessagesResponse();
                    } else if(!this.isFormChanged) {
                        this.response_error_message = '';
                        this.response_success_message = 'No se detectaron cambios por guardar en el formulario.';
                        this.hideMessagesResponse(true);
                    }
                });
            },
            hideMessagesResponse: function(redirect){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                    if (redirect) {
                        window.location = this.dashboard_link;
                    }
                }.bind(this), 2000)
            },
            addLocation: function (){
                this.locations.push({
                    id: null,
                    title: null,
                    icon: null,
                    date: null,
                    hour: null,
                    minutes: null,
                    am: null,
                    name: null,
                    address: null,
                    latitude: null,
                    longitude: null,
                    order: this.locations[this.locations.length - 1].order + 1,
                    show: true,
                    changed: false,
                    valid: false,
                })
            },
            deleteLocation: function (index) {
                this.locations.splice(index, 1);
            }
        },
        computed: {
            show_locations: function() {
                return this.locations[0].show;
            },
            isFormChanged: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed) ||
                    this.locations.some(item => item.changed) || (this.locations.length !== this.num_initial_locations) ||
                    (this.additional_info !== this.initial_additional_info);
            },
            isFormValid: function() {
                if (this.show_locations) {
                    return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid) &&
                        this.locations.every(item => item.valid);
                }
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            },
        },
        created() {
            if(this.data_vue){
                const data_vue = JSON.parse(this.data_vue);

                if(typeof data_vue == "object"){
                    if (data_vue.locations && data_vue.locations.length>0){
                        this.locations = data_vue.locations.map(function(obj){
                            let valid_date = null;
                            if(obj.date && obj.date !== "None" && obj.date !== "1900-01-01"){
                                valid_date = moment(obj.date).toDate();
                            }
                            obj.date = valid_date;
                            if(obj.hour && obj.hour !== "None"){
                                obj.hour = parseInt(obj.hour);
                            }
                            if(obj.minutes && obj.minutes !== "None"){
                                obj.minutes = parseInt(obj.minutes);
                            }
                            if(obj.latitude && obj.latitude !== "None"){
                                obj.latitude = parseFloat(obj.latitude);
                            }
                            if(obj.longitude && obj.longitude !== "None"){
                                obj.longitude = parseFloat(obj.longitude);
                            }
                            obj.changed = false;
                            obj.valid = false;
                            return obj;
                        });
                        this.num_initial_locations = data_vue.locations.length
                    }

                    this.hashtag = data_vue.hashtag ? data_vue.hashtag : null;
                    this.dress_code = data_vue.dress_code ? data_vue.dress_code : null;
                    this.additional_info = data_vue.additional_information ? data_vue.additional_information : null;
                    this.initial_additional_info = data_vue.additional_information ? data_vue.additional_information : null;

                    const static_url = data_vue.STATIC_URL;

                    this.icon_list = data_vue.icon_list.map((icon) => {
                        return {
                            value: icon,
                            url: static_url + icon
                        };
                    });
                }
            }
        },
    };

</script>

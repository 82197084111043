<template>
    <div class="content-item-in-cart">
        <div v-if="item_cart.item" class="wrapper-image-item" :style="{ backgroundImage: 'url('+item_cart.item.image+')'}"></div>
        <div v-else class="wrapper-image-item is-contrib"></div>

        <div class="wrapper-info-item">
            <div class="header-item-in-cart">
                <div class="container-half half-left">
                    <h4 class="title-item" v-if="item_cart.item">{{ item_cart.item.name }}</h4>
                    <h4 class="title-item" v-else>Aporte libre</h4>
                </div>
                <div class="container-half half-right no-show-mobile">
                    <h6 class="title-total">Total</h6>
                </div>
            </div>
            <div class="body-item-in-cart">
                <div class="container-half half-left">
                    <div class="description-item" v-html="item_cart.item ? item_cart.item.description : ''"></div>
                    <div class="common-content-field">
                        <label class="common-label-field" v-if="item_cart.item && item_cart.item.num_quota > 1">CANTIDAD O CUOTAS</label>

                        <div v-if="item_cart.item && item_cart.item.num_quota > 1" >

                            <dropdown v-if="item_cart.item.available_quota > 0"
                                      button-class="select-quota-in-cart"
                                      :value="item_cart.quota"
                                      @change="updateItemInCart"
                                      :options="getQuotaArray()">
                            </dropdown>

                            <select v-else class="common-select select-quota-in-cart"
                                    :value="item_cart.quota"
                                    disabled>
                                <option :value="item_cart.quota">{{ item_cart.quota }}</option>
                            </select>
                        </div>

                    </div>
                    <div class="wrapper-action-item-cart">
                        <a href="javascript:void(0)" class="delete-item-in-cart" @click.prevent="deleteItemClick">ELIMINAR</a>
                        <div class="show-in-mobile content-price-mobile">
                            <h6 class="title-total">Total</h6>
                            <div class="price-item">{{ event.currency.symbol }}{{ item_cart.price }}</div>
                        </div>
                    </div>
                </div>
                <div class="container-half half-right no-show-mobile">
                    <div class="price-item">{{ event.currency.symbol }}{{ item_cart.price }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as EventUtils from '../events/utils'
    import * as shoppingCartAPI from '../../api/shopping-cart'

    export default {
        name: "item-cart",
        props: ['item_cart', 'cart', 'event', 'is_checkout_page'],

        data: () =>  {
            return {
                is_account_contrib: false
            }
        },

        methods:{
            deleteItemClick(){

                let value = this.is_account_contrib ? null : -1;

                this.deleteItemFromCart(value);
            },
            updateItemInCart: function(quota) {

                const price = EventUtils.roundPrice(quota * this.item_cart.item.price_quota);

                shoppingCartAPI.addOrUpdateItem(
                    this.event.id, this.item_cart.item.id, quota, price
                ).then((response) => {

                    if (response.data.success) {
                        EventUtils.addorUpdateCartItem(this.cart, this.item_cart.item, quota, price);

                        if(this.is_checkout_page){
                            this.$emit('update_checkout_component', '');
                        }else{
                            this.updateQuotaAmount(quota);
                        }

                    } else {
                        this.$root.show_error_event_item = true;
                    }

                    this.hideMessagesResponse();

                }).catch((error) => {
                    this.$root.show_error_event_item = true;
                    this.hideMessagesResponse();
                });

            },
            deleteItemFromCart: function(value) {

                shoppingCartAPI.deleteItem(
                    this.event.id, this.is_account_contrib ? 0 :  this.item_cart.item.id
                ).then( (response) => {

                    if (response.data.success) {
                        EventUtils.deleteCartItem(this.cart, this.is_account_contrib ?  null : this.item_cart.item);

                        if(this.is_checkout_page){
                            this.$emit('update_checkout_component', '');
                        }else{

                            if(this.is_account_contrib){
                                this.$emit('update_contrib', false);
                            }

                            this.updateQuotaAmount(value);
                        }

                        this.$emit('update_count_items', Object.keys(this.cart.items).length);

                    } else {
                        this.$root.show_error_event_item = true;
                    }

                    this.hideMessagesResponse();

                }).catch( (error) => {
                    this.$root.show_error_event_item = true;
                    this.hideMessagesResponse();
                });
            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.$root.show_error_event_item = false;
                }.bind(this), 2000)
            },
            updateQuotaAmount: function (value){
                this.$emit('update_quota_or_amount', value, this.$vnode.key, this.is_account_contrib);
            },
            getQuotaArray: function (){
                let quota_list = Array.from(Array(this.item_cart.item.available_quota + 1).keys());
                if (quota_list.length > 0) {
                    quota_list.splice(0, 1);
                }
                return quota_list;
            }
        },

        created() {
            if(this.$vnode.key === 'account_contrib'){
                this.is_account_contrib = true;
            }
        }
    }
</script>

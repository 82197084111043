<template>
    <div class="wrapper-item wrapper-item-added" @mouseleave="onMouseLeave">

        <div class="wrapper-image-card" :style="{ backgroundImage: 'url('+item.image+')'}">
            <div class="wrapper-item-is-donation" v-if="item.is_donation_gift">
                <div class="img-solidary"></div>
            </div>
            <div class="wrapper-bg-added added-to-cart" v-if="item.is_in_cart"></div>
            <div class="wrapper-bg-bought" v-if="!isItemAvailable"></div>
            <div class="wrapper-buttons-card text-center" v-else>
                <form method="post" @submit.prevent="submitItem">
                    <dropdown v-if="item.num_quota > 1"
                              dropdown-class="select-quota-dropdown"
                              button-class="select-quota"
                              name="quota"
                              v-model.number="quota"
                              v-validate="'min_value:0'"
                              :options="itemQuotaOptions"
                              value-prop-name="id" label-prop-name="label"
                    >
                    </dropdown>
                    <button type="submit"
                            :class="['common-green-btn', (item.is_in_cart && item.num_quota > 1) ? 'btn-update-quota' : '']"
                            :disabled="item.num_quota > 1 && !(fields['quota'] && fields['quota'].valid)">
                        <span v-if="item.is_in_cart && item.num_quota > 1">ACTUALIZAR</span>
                        <span v-if="item.is_in_cart && item.num_quota === 1">ELIMINAR</span>
                        <span v-if="!item.is_in_cart">{{ event.gift_word ? event.gift_word : 'REGALAR' }}</span>
                    </button>
                </form>
            </div>
        </div>

        <div :class="['wrapper-info-card', 'wrapper-info-height', !isItemAvailable ? 'wrapper-info-card-bought' : '']">
            <h5 v-if="item.is_donation_gift" class="title-card with-tooltip">
                <span class="title-span-card">{{ item.name }}</span>
                <div class="icon-help-tool"
                      v-tooltip="{placement: 'right', title: 'El valor de este regalo se donará a esta organizacion.' }">
                </div>
            </h5>
            <h5 v-else class="title-card">{{ item.name }}</h5>

            <div class="description-item" v-if="item.description">
                {{ item.description }}
            </div>

            <div class="price-item" v-if="item.num_quota > 1">
                {{ event.currency.symbol }}{{ item.price_quota }} por {{ event.quota_word ? event.quota_word : 'cuota' }}
            </div>

            <div class="total-price" v-if="item.num_quota > 1">PRECIO TOTAL: {{ event.currency.symbol }}{{ item.price }}</div>
            <div class="price-item" v-else>{{ event.currency.symbol }}{{ item.price }}</div>

            <div class="wrapper-progress-bar" v-if="item.num_quota > 1">
                <div class="container-progress-bar">
                    <div class="progress-bar-count" :style="{ width: progressBarWidth+'%'}"></div>
                </div>
                <span class="info-progress-bar">{{ availableQuotaString }}</span>
            </div>

        </div>

    </div>
</template>

<script>

    import * as EventUtils from '../utils'
    import * as shoppingCartAPI from '../../../api/shopping-cart'

    export default {
        name: "event-item",
        props: ['item', 'cart', 'event'],

        data: () =>  {
            return {
                quota: -1
            }
        },

        computed: {
            isItemAvailable: function() {
                return EventUtils.isItemAvailable(this.item);
            },
            availableQuotaString: function() {
                return EventUtils.availableQuotaString(this.item, this.event.quota_word);
            },
            progressBarWidth: function() {
                return EventUtils.progressBarWidth(this.item);
            },
            itemQuotaOptions: function(){
                let options = [];
                if (this.item.is_in_cart) {
                    options.push({id: 0, label: '0'});
                } else {
                    const quota_text = this.event.quota_word ? this.event.quota_word.charAt(0).toUpperCase() + this.event.quota_word.slice(1) + 's' : 'Cuotas'
                    options.push({id: -1, label: quota_text});
                }
                for(let i = 0; i < this.item.available_quota && i < 10; i++) {
                    options.push({id: i + 1, label: '' + (i + 1)});
                }
                return options;
            }
        },

        methods:{
            submitItem: function() {
                if ((this.item.is_in_cart && this.item.num_quota === 1) || this.quota === 0) {
                    this.deleteItemFromCart();
                } else {
                    this.addItemToCart();
                }
            },
            addItemToCart: function() {

                let quota = this.item.num_quota > 1 ? this.quota : 1;
                let price = this.item.num_quota > 1 ? quota * this.item.price_quota : this.item.price;

                price = EventUtils.roundPrice(price);

                shoppingCartAPI.addOrUpdateItem(
                    this.event.id, this.item.id, quota, price
                ).then((response) => {

                    if (response.data.success) {
                        EventUtils.addorUpdateCartItem(this.cart, this.item, quota, price);
                        this.$emit('update_count_items_cart', Object.keys(this.cart.items).length);
                    } else {
                        this.$root.show_error_event_item = true;
                    }

                    this.hideMessagesResponse();

                }).catch((error) => {
                    this.$root.show_error_event_item = true;
                    this.hideMessagesResponse();
                });

            },
            deleteItemFromCart: function() {

                shoppingCartAPI.deleteItem(
                    this.event.id, this.item.id
                ).then( (response) => {

                    if (response.data.success) {
                        EventUtils.deleteCartItem(this.cart, this.item);
                        this.quota = -1;
                        this.$emit('update_count_items_cart', Object.keys(this.cart.items).length);
                    } else {
                        this.$root.show_error_event_item = true;
                    }

                    this.hideMessagesResponse();

                }).catch( (error) => {
                    this.$root.show_error_event_item = true;
                    this.hideMessagesResponse();
                });
            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.$root.show_error_event_item = false;
                }.bind(this), 2000)
            },
            onMouseLeave: function() {
                $('.dropdown.show').dropdown('toggle');
            }
        },

        created() {
            this.quota = this.item.is_in_cart ? this.cart.items[this.item.id].quota : -1;
        }
    }
</script>

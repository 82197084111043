<template>
    <div>

        <form method="post" @submit.prevent="createComplaintSubmit">
            <div v-html="csrf_token" hidden></div>

            <h4 class="common-title-section">IDENTIFICACIÓN DEL CONSUMIDOR RECLAMANTE</h4>

            <div class="common-content-field">
                <label class="common-label-field">NOMBRE *</label>
                <input type="text" name="first_name"
                       :class="['common-input-text', errors.has('first_name') ? 'error-input-text' : '' ]"
                       placeholder="Ingresa tu nombre completo"
                       v-validate="'required|min:2|max:200'"
                       v-model="name" >

                <p class="message-error-field" v-if="errors.has('first_name')">
                    {{ errors.first('first_name') }}
                </p>

            </div>

            <div class="common-content-field">
                <label class="common-label-field">DOMICILIO *</label>
                <input type="text" name="address"
                       :class="['common-input-text', errors.has('address') ? 'error-input-text' : '' ]"
                       placeholder="Ingresa tu dirección incluyendo distrito, provincia y departamento"
                       v-validate="'required|min:2|max:200'"
                       v-model="address">

                <p class="message-error-field" v-if="errors.has('address')">
                    {{ errors.first('address') }}
                </p>
            </div>

            <div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">TIPO DE DOCUMENTO</label>

                    <select name="document_type" class="common-select" v-model.number="document_type_id"
                            v-validate="'required'">
                        <option v-for="doc_type in data_vue.list_document_type"
                                :value="doc_type.id">{{ doc_type.label }}
                        </option>
                    </select>

                    <p class="message-error-field" v-if="errors.has('document_type')">
                        {{ errors.first('document_type') }}
                    </p>
                </div>

                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">NRO. DE DOCUMENTO *</label>

                    <input type="text" name="dni_number"
                           :class="['common-input-text', errors.has('dni_number') ? 'error-input-text' : '' ]"
                           placeholder="ej. 12345678"
                           v-validate="'required|numeric|min:8|max:9'" v-model.number="document_number">

                    <p class="message-error-field" v-if="errors.has('dni_number')">
                        {{ errors.first('dni_number') }}
                    </p>
                </div>
            </div>

            <div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">TELÉFONO *</label>

                    <input type="text" name="telephone"
                           :class="['common-input-text', errors.has('telephone') ? 'error-input-text' : '' ]"
                           placeholder="Ingresa tu teléfono"
                           v-validate="'required|numeric|min:5|max:15'" v-model.number="telephone">

                    <p class="message-error-field" v-if="errors.has('telephone')">
                        {{ errors.first('telephone') }}
                    </p>
                </div>

                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">EMAIL *</label>

                    <input type="text" name="email"
                           :class="['common-input-text', errors.has('email') ? 'error-input-text' : '' ]"
                           placeholder="Ingresa tu correo electrónico"
                           v-validate="'required|email|min:5|max:50'" v-model="email">

                    <p class="message-error-field" v-if="errors.has('email')">
                        {{ errors.first('email') }}
                    </p>
                </div>
            </div>

            <div class="next-content"></div>

            <h4 class="common-title-section">IDENTIFICACIÓN DEL BIEN CONTRATADO</h4>

            <div>
                <div class="wrapper-check-radio">
                    <label for="contracted_service_1" class="label-check-radio mr-0">
                        <input type="radio" hidden :value="data_vue.contracted_service_type.PRODUCT"
                               name="contracted_service" id="contracted_service_1"
                               class="custom-check-radio" v-model.number="contracted_service_type_id"
                               v-validate="'required'">
                        <span class="label-text">Producto</span>
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label for="contracted_service_2" class="label-check-radio mr-0">
                        <input type="radio" hidden :value="data_vue.contracted_service_type.SERVICE"
                               name="contracted_service" id="contracted_service_2"
                               class="custom-check-radio" v-model.number="contracted_service_type_id"
                               v-validate="'required'">
                        <span class="label-text">Servicio</span>
                    </label>
                </div>
                <p class="message-error-field" v-if="errors.has('contracted_service')">
                    {{ errors.first('contracted_service') }}
                </p>
            </div>

            <div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">MONTO RECLAMADO</label>

                    <input type="text" name="reclaimed_amount"
                           :class="['common-input-text', 'currency-symbol', 'soles', errors.has('reclaimed_amount') ? 'error-input-text' : '' ]"
                           v-validate="'numeric'" v-model.number="reclaimed_amount">

                    <p class="message-error-field" v-if="errors.has('reclaimed_amount')">
                        {{ errors.first('reclaimed_amount') }}
                    </p>
                </div>
            </div>

            <div class="common-content-field">
                <label class="common-label-field">DESCRIPCIÓN *</label>

                <input type="text" name="claim_description"
                       :class="['common-input-text', errors.has('claim_description') ? 'error-input-text' : '' ]"
                       v-validate="'required|min:2|max:200'" v-model="claim_description">

                <p class="message-error-field" v-if="errors.has('claim_description')">
                    {{ errors.first('claim_description') }}
                </p>
            </div>

            <div class="next-content"></div>

            <h4 class="common-title-section">DETALLE DE RECLAMACIÓN Y PEDIDO DEL CONSUMIDOR</h4>

            <div>
                <div class="wrapper-check-radio">
                    <label for="claim_type_1" class="label-check-radio mr-0">
                        <input type="radio" hidden :value="data_vue.claim_type.CLAIM"
                               name="claim_type" id="claim_type_1"
                               class="custom-check-radio" v-model.number="claim_type_id"
                               v-validate="'required'">
                        <span class="label-text">Reclamo</span>
                    </label>
                    <span class="icon-help-tool"
                          v-tooltip="{ placement: 'right',
                          title: 'Disconformidad relacionada a los productos o servicios.' }"
                    ></span>
                    &nbsp;&nbsp;&nbsp;
                    <label for="claim_type_2" class="label-check-radio mr-0">
                        <input type="radio" hidden :value="data_vue.claim_type.COMPLAINT"
                               name="claim_type" id="claim_type_2"
                               class="custom-check-radio" v-model.number="claim_type_id"
                               v-validate="'required'">
                        <span class="label-text">Queja</span>
                    </label>
                    <span class="icon-help-tool"
                          v-tooltip="{ placement: 'right',
                          title: 'Disconformidad no relacionada a los productos o servicios; o, malestar o descontento respecto a la atención al público.' }"
                    ></span>
                </div>
                <p class="message-error-field" v-if="errors.has('claim_type')">
                    {{ errors.first('claim_type') }}
                </p>
            </div>

            <div class="common-content-field">
                <label class="common-label-field">DETALLE</label>
                <textarea type="text" name="claim_detail"
                          :class="['common-input-text', errors.has('claim_detail') ? 'error-input-text' : '']"
                          v-validate="'min:2|max:400'" v-model="claim_detail"
                          rows="5">
                </textarea>

                <p class="message-error-field" v-if="errors.has('claim_detail')">
                    {{ errors.first('claim_detail') }}
                </p>
            </div>

            <div class="common-content-field">
                <label class="common-label-field">PEDIDO</label>
                <textarea type="text" name="claim_order"
                          :class="['common-input-text', errors.has('claim_order') ? 'error-input-text' : '']"
                          v-validate="'min:2|max:400'" v-model="claim_order"
                          rows="5">
                </textarea>

                <p class="message-error-field" v-if="errors.has('claim_order')">
                    {{ errors.first('claim_order') }}
                </p>
            </div>


            <div class="common-container-btn text-right">
                <button type="submit" class="common-green-btn">ENVIAR</button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>

        </form>

    </div>
</template>

<script>

    import * as complaintsBookAPI from '../../api/complaints-book'

    export default {
        name: "complaints-book",
        props: ['csrf_token', 'data_vue', 'next_url'],
        data: () => {
            return {
                response_error_message: null,
                response_success_message: null,
                list_document_type: [],
                name: null,
                address: null,
                document_type_id: null,
                document_number: null,
                telephone: null,
                email: null,
                contracted_service_type_id: null,
                reclaimed_amount: null,
                claim_description: null,
                claim_type_id: null,
                claim_detail: null,
                claim_order: null
            }
        },
        created() {

            const data = this.data_vue;

            this.document_type_id = data.list_document_type ? data.list_document_type[0].id : 1;

        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            createComplaintSubmit: function() {

                this.$validator.validateAll().finally(() => {
                    if (this.isFormValid) {

                        let data = {
                            name: this.name ? this.name : '',
                            address: this.address ? this.address : '',
                            document_type_id: this.document_type_id ? this.document_type_id : '',
                            document_number: this.document_number ? this.document_number : '',
                            telephone: this.telephone ? this.telephone : '',
                            email: this.email ? this.email : '',
                            contracted_service_type_id: this.contracted_service_type_id ? this.contracted_service_type_id : '',
                            reclaimed_amount: this.reclaimed_amount ? this.reclaimed_amount : '',
                            claim_description: this.claim_description ? this.claim_description : '',
                            claim_type_id: this.claim_type_id ? this.claim_type_id : '',
                            claim_detail: this.claim_detail ? this.claim_detail : '',
                            claim_order: this.claim_order ? this.claim_order : ''
                        };

                        let data_json = {data: JSON.stringify(data)};


                        complaintsBookAPI.createComplaint(data_json).then((response) => {

                            let is_success = response.data.success;

                            this.response_error_message = is_success ? '' : response.data.message;
                            this.response_success_message = is_success ? response.data.message : '';

                            this.hideMessagesResponse();

                            setTimeout(() => {
                                window.location = this.next_url
                            }, 1000);

                        }).catch((error) => {

                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();

                        });
                    }
                });

            },
            hideMessagesResponse: function(){
                setTimeout(() => {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }, 2000);
            },
        }
    }
</script>

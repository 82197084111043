<template>
    <div>
        <form  method="post" @submit.prevent="publishSubmit">

            <div v-html="csrf_token" hidden></div>

            <h4 class="common-title-section">URL PERSONALIZADA</h4>
            <p></p>
            <p class="description-page not-padding">
                Personaliza el URL de tu página. Con esta información será más fácil que tus
                invitados encuentren tu lista.
                <br><br>
                La URL personalizada solo permite letras minúsculas (sin tildes) y números, con
                excepción de la letra ñ.
            </p>
            <p></p>

            <div>
                <div class="common-content-field">
                    <div class="content-label-left">
                        <span class="label-custom-url">www.thegiftlist.pe/</span>
                        <input type="text" name="custom_url" placeholder="ej. michiycarlos"
                               :class="['common-input-text', 'input-custom-url',
                               (errors.has('custom_url') || is_invalid_url) ? 'error-input-text invalid' : '', is_valid_url ? 'valid' : '']"

                               v-validate="'required|min:2|max:50'" v-model="event.url" v-on:keyup="validateURL">
                    </div>
                    <p class="message-error-field" v-if="errors.has('custom_url')">
                        {{ errors.first('custom_url') }}
                    </p>
                    <p class="message-error-field" v-else-if="is_invalid_url">
                        <span v-if="is_taken_url">La URL personalizada que has ingresado ya está en uso. Prueba con otra opción.</span>
                        <span v-else>No se permiten espacios, mayúculas, caracteres especiales ni la letra ñ en la URL personalizada.</span>
                    </p>
                </div>
                <div>
                    <div class="common-content-field">
                        <div class="container-checkbox">
                            <input type="checkbox" id="isPrivateEvent" class="checkbox-form-tgl"
                                   v-model="event.is_private" name="is_private" v-validate="">
                            <label class="label-field-checkbox" for="isPrivateEvent">
                            <span class="text-label-checkbox w-auto">
                                Es una lista privada.
                            </span>
                            </label>

                        <span class="icon-help-tool"
                              v-tooltip="{ placement: 'right', title: 'Elige esta opción si quieres que tus invitados necesiten una clave para ingresar a tu lista.' }">
                        </span>
                        </div>
                    </div>
                </div>

                <div v-if="event.is_private">
                    <div class="common-content-field half-content half-left">
                        <label class="common-label-field">CONTRASEÑA</label>

                        <input type="text" name="password" placeholder="Ingresa una contraseña"
                               :class="['common-input-text', errors.has('password') ? 'error-input-text' : '']"
                               v-validate="`${event.is_private ? 'required|min:2' : 'min:2'}`" v-model="event.token">

                        <p class="message-error-field" v-if="errors.has('password')">
                            {{ errors.first('password') }}
                        </p>
                    </div>
                </div>
                <div>
                    <div class="common-content-field">
                        <div class="container-checkbox">
                            <input type="checkbox" id="isPermissionShare" class="checkbox-form-tgl"
                                   v-model="event.is_permission_share" name="is_permission_share" v-validate="">
                            <label class="label-field-checkbox " for="isPermissionShare">
                                <span class="text-label-checkbox w-auto">
                                    Autorizo a The Gift List a publicar mi foto en redes sociales.
                                     <span class="icon-help-tool"
                                          v-tooltip="{ placement: 'right', title: 'Elige esta opción si nos autorizas a compartir tu lista y tu foto de pefil en las redes sociales de The Gift List.' }">
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-container-btn content-bnt-and-link flex-mobile">
                <a :href="before_step" class="link-cancel-form desktop">REGRESAR AL PASO ANTERIOR</a>
                <a :href="before_step" class="link-cancel-form mobile">REGRESAR</a>
                <button type="submit" class="common-green-btn" :disabled="(isEventPublished && !isFormChanged)">
                    {{ isEventPublished ? 'ACTUALIZAR' : 'PUBLICAR' }}
                </button>
            </div>
        </form>
        <div>
            <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
            <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
        </div>
    </div>
</template>

<script>

    import * as eventsAPI from '../../api/events'
    import { urls_not_allowed, event_status } from './utils'

    export default {
        name: "publish-event",
        props:['csrf_token', 'before_step', 'data_vue', 'next_link' ],
        data: () =>  {
            return {
                event: {
                    id: null ,
                    url: null,
                    is_private: false,
                    token: null,
                    is_permission_share: true,
                    status: null
                },
                is_valid_url: false,
                is_invalid_url: false,
                is_taken_url: false,
                timer: 0,
                response_error_message: '',
                response_success_message: ''
            }
        },
        created(){
            let event = this.data_vue.event;
            this.event = {
                    id: event.id ,
                    url: event.url ? event.url : '',
                    is_private: event.is_private,
                    token: event.token ? event.token : '',
                    is_permission_share: event.is_permission_share,
                    status: event.status
                };

            if (event.url){
                this.is_valid_url = true;
            }

        },
        computed: {
            isFormChanged: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed);
            },
            isFormValid: function() {
                return Object.keys(this.fields).every(key => {
                    if (key === 'custom_url') {
                        return this.fields[key].valid && this.is_valid_url && !this.is_invalid_url;
                    }

                    return (this.fields[key] && this.fields[key].valid);
                });
            },
            isEventPublished: function() {
                return parseInt(this.event.status) >= event_status.PUBLISHED;
            },
        },

        methods:{

            publishSubmit: function () {

                this.$validator.validate().then(result => {
                    if (result && this.isFormChanged && this.isFormValid) {

                        let data = {
                            event_id: this.event.id,
                            url: this.event.url,
                            is_private: this.event.is_private,
                            token: this.event.is_private ? this.event.token : '',
                            is_permission_share: this.event.is_permission_share,
                            status: this.event.status
                        };

                        eventsAPI.storeDataPublish(data).then((response) => {

                            let is_success = response.data.success;

                            this.response_error_message = is_success ? '' : response.data.message;
                            this.response_success_message = is_success ? response.data.message : '';

                            if (is_success) setTimeout(() => {
                                if (this.isEventPublished) {
                                    window.location.reload(false);
                                } else {
                                    this.gotoNextStep();
                                }
                            }, 1000);

                            this.hideMessagesResponse();

                        }).catch((error) => {

                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                            this.hideMessagesResponse();

                        });

                    } else if (!this.isEventPublished && this.isFormValid) {
                        this.gotoNextStep();
                    }
                });
            },
            gotoNextStep: function () {
                window.location = this.next_link;
            },
            validateURL: function (){
                this.is_valid_url = false;

                if (urls_not_allowed.indexOf(this.event.url) !== -1) {
                    this.is_invalid_url = true;
                    this.is_taken_url = true;
                } else {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(this.checkURL, 500);
                }
            },
            checkURL: function () {

                let invalidCharsExpression = /[^0-9a-z]/;

                if(this.event.url && !invalidCharsExpression.test(this.event.url) && this.fields['custom_url'].valid){

                    eventsAPI.validateCustomURL(this.event.id, this.event.url).then( (response) => {
                        let event_not_exists = response.data.event_not_exists;
                        this.is_valid_url = event_not_exists;
                        this.is_invalid_url = !event_not_exists;
                        this.is_taken_url = !event_not_exists;

                    }).catch( (error) => {
                        this.is_valid_url = false;
                        this.is_invalid_url = true;
                        this.is_taken_url = false;
                    });

                }else{
                    this.is_valid_url = false;
                    this.is_invalid_url = true;
                    this.is_taken_url = false;
                }
            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            },
        },
    }
</script>

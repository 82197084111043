<template>
    <div>
        <div class="content-fields-form">
            <p></p>
            <p class="info-form">
                Por tu seguridad, necesitamos verificar tu
                identidad. Introduce a continuación el código
                de 6 dígitos que hemos enviado a {{ data.new_email }}.
            </p>
            <p></p>
            <form method="post" @submit.prevent="submitForm">
                <div v-html="data.csrf_token"></div>

                <div class="common-content-field">
                    <label class="common-label-field">INTRODUCE EL CÓDIGO</label>
                    <input type="text" name="code" v-model="code" class="common-input-text"
                           v-validate="'required|numeric|length:6'"
                           placeholder="Introduce el código" v-bind:class="{'error-input-text': errors.has('code')}">
                    <p class="message-error-field" v-if="errors.has('code')">{{ errors.first('code') }}</p>
                </div>

                <div class="common-content-field">
                    <a v-on:click="resendCode" href="javascript:void(0)" class="resend-code" v-if="!show_sending_message">Reenviar código</a>
                    <a href="javascript:void(0)" class="resend-code" v-if="show_sending_message">Enviando...</a>
                </div>

                <div class="common-container-btn content-bnt-and-link">
                    <a href="/users/edit-account/" class="link-cancel-form">CANCELAR</a>
                    <button type="submit" :disabled="!fields.code || fields.code.invalid" class="common-green-btn">
                            FINALIZAR
                    </button>
                </div>

                <div v-if="sent_form">
                    <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                    <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

    import * as usersAPI from '../../api/users'

    export default {
        name: "edit-account-confirm",
        props: ['data'],
        data: () =>  {
            return {
                code: null,
                sent_form: null,
                response_error_message: null,
                response_success_message: null,
                show_sending_message: false,
            }
        },
        methods:{
            resendCode: function () {

                this.code = null;
                this.show_sending_message =  true;

                usersAPI.sendCode(this.data.email, this.data.new_email).then( (response) => {

                    if(response.data.success){
                        this.response_error_message = '';
                        this.response_success_message = 'Un código nuevo ha sido enviado a tu correo electrónico.';
                    }else{
                        this.response_error_message = response.data.message;
                        this.response_success_message = '';
                    }

                }).catch( (error) => {
                    this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                }).finally(() => {
                    this.show_sending_message =  false;
                    this.hideMessagesResponse();
                });

                this.sent_form = true;

            },
            submitForm: function () {

                usersAPI.updateAccount(
                    this.data.first_name, this.data.last_name, this.data.email, this.data.new_email,
                    this.data.telephone, this.data.address, this.data.city,
                    this.data.current_password, this.data.new_password,
                    this.code
                ).then( (response) => {

                    if(response.data.success){

                        this.response_error_message = '';
                        this.response_success_message = response.data.message;
                        setTimeout(() => {window.location = window.location.href;}, 2000);

                    }else{

                        this.response_error_message = response.data.message;
                        this.response_success_message = '';

                    }

                }).catch( (error) => {
                    this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                }).finally(() => {
                    this.hideMessagesResponse();
                });

                this.sent_form = true;

            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            }
        }
    }


</script>
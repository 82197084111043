import { callAPIPost } from './utils'


export const createTransferRequest = (event_id, amount) => {
    return callAPIPost('/api/transfers/create-transfer-request', { event_id, amount } )
};

export const deleteTransferRequest = (event_id, transfer_id) => {
    return callAPIPost('/api/transfers/delete-transfer-request', { event_id, transfer_id } )
};

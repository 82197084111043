var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show_form,
            expression: "show_form"
          }
        ],
        staticClass: "have-account-question"
      },
      [
        _vm._v("\n        ¿Ya tienes una cuenta con nosotros?\n        "),
        _c(
          "a",
          { staticClass: "log-in-link", attrs: { href: _vm.link_login } },
          [_vm._v("INICIA SESIÓN")]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content-fields-form" }, [
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_form,
              expression: "show_form"
            }
          ],
          attrs: { method: "post" }
        },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.csrf_token) } }),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("label", { staticClass: "label-field" }, [_vm._v("NOMBRE")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.first_name,
                  expression: "user.first_name"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:2|max:50|alpha_spaces",
                  expression: "'required|min:2|max:50|alpha_spaces'"
                }
              ],
              staticClass: "input-text-form-tgl",
              class: { "error-input-text": _vm.errors.has("first_name") },
              attrs: {
                type: "text",
                name: "first_name",
                autocomplete: "given-name",
                placeholder: "Ingresa tu nombre"
              },
              domProps: { value: _vm.user.first_name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "first_name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("first_name")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("first_name")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("label", { staticClass: "label-field" }, [_vm._v("APELLIDOS")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.last_name,
                  expression: "user.last_name"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:2|max:50|alpha_spaces",
                  expression: "'required|min:2|max:50|alpha_spaces'"
                }
              ],
              staticClass: "input-text-form-tgl",
              class: { "error-input-text": _vm.errors.has("last_name") },
              attrs: {
                type: "text",
                name: "last_name",
                autocomplete: "family-name",
                placeholder: "Ingresa tus apellidos"
              },
              domProps: { value: _vm.user.last_name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "last_name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("last_name")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("last_name")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("label", { staticClass: "label-field" }, [
              _vm._v("CORREO ELECTRÓNICO")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email|min:5|max:50",
                  expression: "'required|email|min:5|max:50'"
                }
              ],
              staticClass: "input-text-form-tgl",
              class: { "error-input-text": _vm.errors.has("email") },
              attrs: {
                type: "text",
                name: "email",
                placeholder: "Ingresa tu correo electrónico"
              },
              domProps: { value: _vm.user.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("email")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("email")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("label", { staticClass: "label-field" }, [_vm._v("CONTRASEÑA")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:8",
                  expression: "'required|min:8'"
                }
              ],
              ref: "password",
              staticClass: "input-text-form-tgl",
              class: { "error-input-text": _vm.errors.has("password") },
              attrs: {
                type: "password",
                name: "password",
                placeholder: "Ingresa tu contraseña",
                autocomplete: "new-password"
              },
              domProps: { value: _vm.user.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "password", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("password")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("password")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("label", { staticClass: "label-field" }, [
              _vm._v("CONFIRMA CONTRASEÑA")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.confirm_password,
                  expression: "user.confirm_password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|confirmed:password",
                  expression: "'required|confirmed:password'"
                }
              ],
              staticClass: "input-text-form-tgl",
              class: { "error-input-text": _vm.errors.has("confirm_password") },
              attrs: {
                type: "password",
                name: "confirm_password",
                autocomplete: "new-password",
                placeholder: "Reingresa tu contraseña"
              },
              domProps: { value: _vm.user.confirm_password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "confirm_password", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("confirm_password")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("confirm_password")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.accept_terms,
                  expression: "user.accept_terms"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required:true",
                  expression: "'required:true'"
                }
              ],
              staticClass: "checkbox-form-tgl",
              attrs: {
                type: "checkbox",
                name: "accept_terms",
                id: "accept_terms_form"
              },
              domProps: {
                checked: Array.isArray(_vm.user.accept_terms)
                  ? _vm._i(_vm.user.accept_terms, null) > -1
                  : _vm.user.accept_terms
              },
              on: {
                change: function($event) {
                  var $$a = _vm.user.accept_terms,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.user, "accept_terms", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.user,
                          "accept_terms",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.user, "accept_terms", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "label-field-checkbox",
                attrs: { for: "accept_terms_form" }
              },
              [
                _vm._v(
                  "\n                        Acepto los\n                    "
                ),
                _c(
                  "a",
                  {
                    staticClass: "link-terms",
                    attrs: { href: _vm.link_terms, target: "_blank" }
                  },
                  [_vm._v("Términos y Condiciones")]
                ),
                _vm._v(" y\n                    "),
                _c(
                  "a",
                  {
                    staticClass: "link-terms",
                    attrs: { href: _vm.link_policy, target: "_blank" }
                  },
                  [_vm._v("Política de Privacidad")]
                )
              ]
            ),
            _vm._v(" "),
            _vm.errors.has("accept_terms")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("accept_terms")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-submit" }, [
            _c(
              "button",
              {
                staticClass: "btn-submit-tgl",
                attrs: { type: "button" },
                on: { click: _vm.submitForm }
              },
              [_vm._v("\n                    REGÍSTRATE\n                ")]
            )
          ]),
          _vm._v(" "),
          _vm.sent_form
            ? _c("div", [
                _c("p", {
                  staticClass: "error-submit-form-message",
                  domProps: { innerHTML: _vm._s(_vm.response_form_message) }
                })
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show_form,
              expression: "!show_form"
            }
          ],
          staticClass: "content-success-register"
        },
        [
          _c("h5", { staticClass: "title-form-success" }, [
            _vm._v("REGISTRO EXITOSO")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info-form-success" }, [
            _vm._v(
              "\n                ¡Te has registrado correctamente! Te hemos enviado un correo para verificar tu\n                cuenta. No olvides revisar tu buzón de correos no deseados.\n            "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-submit" }, [
            _c(
              "a",
              {
                staticClass: "btn-submit-tgl btn-finish-register",
                attrs: { href: _vm.link_home }
              },
              [_vm._v("FINALIZAR")]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
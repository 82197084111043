<template>
    <div>
        <form method="post" @submit.prevent="dataCoupleSubmit">

            <h4 class="common-title-section" >DATOS DE LA PAREJA <div class="ico-heart-title"></div></h4>

            <div v-html="csrf_token"></div>

            <div>
                <div class="wrapper-check-radio">
                    <label for="1_female" class="label-check-radio">
                        <input type="radio" hidden value="1" name="member_1_is_female" id="1_female"
                               class="custom-check-radio" v-model="member_1.is_female" v-validate="'required'">
                        <span class="label-text">Novia</span>
                    </label>
                    <label for="1_male" class="label-check-radio">
                        <input type="radio" hidden value="0" name="member_1_is_female" id="1_male"
                               class="custom-check-radio" v-model="member_1.is_female" v-validate="'required'">
                        <span class="label-text">Novio</span>
                    </label>
                </div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">NOMBRE *</label>
                    <input type="text" name="member_1_first_name" class="common-input-text"
                           placeholder="Ingresa un nombre"
                           v-bind:class="{'error-input-text': errors.has('member_1_first_name')}"
                           v-validate="'required|min:2|max:50|alpha_spaces'"
                           v-model="member_1.first_name">

                    <p class="message-error-field" v-if="errors.has('member_1_first_name')">
                        {{ errors.first('member_1_first_name') }}
                    </p>

                </div>
                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">APELLIDOS *</label>
                    <input type="text" name="member_1_last_name" class="common-input-text"
                           placeholder="Ingresa un apellido"
                           v-bind:class="{'error-input-text': errors.has('member_1_last_name')}"
                           v-validate="'required|min:2|max:50|alpha_spaces'"
                           v-model="member_1.last_name">

                    <p class="message-error-field" v-if="errors.has('member_1_last_name')">
                        {{ errors.first('member_1_last_name') }}
                    </p>
                </div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">FECHA DE NACIMIENTO *</label>
                    <datepicker name="member_1_birthdate" class="common-input-text"
                           placeholder="DD-MM-AAAA"
                           :open-date="member_1.birthdate ? member_1.birthdate : getOpenDate()"
                           v-bind:class="{'error-input-text': errors.has('member_1_birthdate')}"
                           v-validate="'required'"
                           v-model="member_1.birthdate" id="birthdate1"
                           :language="es" :disabledDates="disabledDates" @selected="changeBirthDate1"
                           :initialView="`${member_1.birthdate ? 'day' : 'year'}`"
                    ></datepicker>

                    <p class="message-error-field" v-if="errors.has('member_1_birthdate')">
                        {{ errors.first('member_1_birthdate') }}
                    </p>
                </div>
                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">CORREO ELECTRÓNICO *</label>
                    <input type="email" name="member_1_email" class="common-input-text"
                           placeholder="Ingresa un correo electrónico"
                           v-bind:class="{'error-input-text': errors.has('member_1_email')}"
                           v-validate="'required|email|min:5'"
                           v-model="member_1.email">

                    <p class="message-error-field" v-if="errors.has('member_1_email')">
                        {{ errors.first('member_1_email') }}
                    </p>
                </div>

                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">¿CÓMO TE ENCONTRAMOS EN FACEBOOK?</label>
                    <input type="text" name="member_1_facebook" class="common-input-text"
                           v-bind:class="{'error-input-text': errors.has('member_1_facebook')}"
                           v-validate="'min:2|max:60|alpha_spaces'"
                           v-model="member_1.account_facebook">

                    <p class="message-error-field" v-if="errors.has('member_1_facebook')">
                        {{ errors.first('member_1_facebook') }}
                    </p>

                </div>

                <div class="common-content-field container-checkbox">
                    <input type="checkbox" name="1_receive_email" class="checkbox-form-tgl" id="1_receive_email"
                           v-validate="{is_not: member_1.email}"
                           v-model="member_1.receive_email">
                    <label class="label-field-checkbox" for="1_receive_email">
                        <span class="text-label-checkbox text-label-data-couple w-auto">Quiero recibir un correo electrónico cada vez que regalen algo.</span>
                    </label>
                </div>
            </div>

            <div class="next-content">
                <div class="wrapper-check-radio">
                    <label for="2_female" class="label-check-radio">
                        <input type="radio" hidden value="1" name="member_2_is_female" id="2_female"
                               class="custom-check-radio" v-model="member_2.is_female" v-validate="'required'">
                        <span class="label-text">Novia</span>
                    </label>
                    <label for="2_male" class="label-check-radio">
                        <input type="radio" hidden value="0" name="member_2_is_female" id="2_male"
                               class="custom-check-radio" v-model="member_2.is_female" v-validate="'required'">
                        <span class="label-text">Novio</span>
                    </label>
                </div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">NOMBRE *</label>
                    <input type="text" name="member_2_first_name" class="common-input-text"
                           placeholder="Ingresa un nombre"
                           v-bind:class="{'error-input-text': errors.has('member_2_first_name')}"
                           v-validate="'required|min:2|max:50|alpha_spaces'"
                           v-model="member_2.first_name">

                    <p class="message-error-field" v-if="errors.has('member_2_first_name')">
                        {{ errors.first('member_2_first_name') }}
                    </p>

                </div>
                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">APELLIDOS *</label>
                    <input type="text" name="member_2_last_name" class="common-input-text"
                           placeholder="Ingresa un apellido"
                           v-bind:class="{'error-input-text': errors.has('member_2_last_name')}"
                           v-validate="'required|min:2|max:50|alpha_spaces'"
                           v-model="member_2.last_name">

                    <p class="message-error-field" v-if="errors.has('member_2_last_name')">
                        {{ errors.first('member_2_last_name') }}
                    </p>
                </div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">FECHA DE NACIMIENTO *</label>
                    <datepicker name="member_2_birthdate" class="common-input-text"
                           placeholder="DD-MM-AAAA"
                           :open-date="member_2.birthdate ? member_2.birthdate : getOpenDate()"
                           v-bind:class="{'error-input-text': errors.has('member_2_birthdate')}"
                           v-validate="'required'"
                           v-model="member_2.birthdate" id="birthdate2"
                           :language="es" :disabledDates="disabledDates" @selected="changeBirthDate2"
                           :initialView="`${member_2.birthdate ? 'day' : 'year'}`"
                    ></datepicker>

                    <p class="message-error-field" v-if="errors.has('member_2_birthdate')">
                        {{ errors.first('member_2_birthdate') }}
                    </p>
                </div>
                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">CORREO ELECTRÓNICO *</label>
                    <input type="email" name="member_2_email" class="common-input-text"
                           placeholder="Ingresa un correo electrónico"
                           v-bind:class="{'error-input-text': errors.has('member_2_email')}"
                           v-validate="'required|email|min:5'"
                           v-model="member_2.email">

                    <p class="message-error-field" v-if="errors.has('member_2_email')">
                        {{ errors.first('member_2_email') }}
                    </p>
                </div>

                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">¿CÓMO TE ENCONTRAMOS EN FACEBOOK?</label>
                    <input type="text" name="member_2_facebook" class="common-input-text"
                           v-bind:class="{'error-input-text': errors.has('member_2_facebook')}"
                           v-validate="'min:2|max:60|alpha_spaces'"
                           v-model="member_2.account_facebook">

                    <p class="message-error-field" v-if="errors.has('member_2_facebook')">
                        {{ errors.first('member_2_facebook') }}
                    </p>

                </div>

                <div class="common-content-field container-checkbox">
                    <input type="checkbox" name="2_receive_email" class="checkbox-form-tgl" id="2_receive_email"
                           v-validate="{is_not: member_2.email}"
                           v-model="member_2.receive_email">
                    <label class="label-field-checkbox" for="2_receive_email">
                        <span class="text-label-checkbox text-label-data-couple w-auto">Quiero recibir un correo electrónico cada vez que regalen algo.</span>
                    </label>
                </div>
            </div>

            <div class="common-container-btn text-right">
                <button type="submit" class="common-green-btn" :disabled="(isEventPublished && !isFormChanged)">
                    {{ isEventPublished ? 'ACTUALIZAR' : 'SIGUIENTE' }}
                    <span class="arrow-into-btn" v-if="!isEventPublished"></span>
                </button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>

        </form>

    </div>
</template>

<script>

    import * as eventsAPI from '../../api/events'
    import * as Utils from '../../utils'
    import * as moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';
    import * as EventUtils from './utils'


    export default {
        name: "data-couple",
        components: {
            Datepicker
        },
        props: ['csrf_token', 'data_member_1', 'data_member_2', 'event_id', 'next_url',
                'event_status', 'event_type_id', 'event_type'],
        data: () =>  {
            return {
                member_1: {
                    id: null,
                    first_name: null,
                    last_name: null,
                    birthdate: null,
                    email: null,
                    receive_email: true,
                    is_female: 1,
                    account_facebook: null
                },
                member_2: {
                    id: null,
                    first_name: null,
                    last_name: null,
                    birthdate: null,
                    email: null,
                    receive_email: false,
                    is_female: 1,
                    account_facebook: null
                },
                response_error_message: '',
                response_success_message: '',
                es: es,
                disabledDates: {
                    from: moment().subtract(1, "days").toDate(),
                }
            }
        },
        computed: {
            isFormChanged: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed);
            },
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            },
            isEventPublished: function() {
                return this.event_status >= EventUtils.event_status.PUBLISHED;
            }
        },
        methods:{
            getOpenDate: function () {
                let openDate = moment();
                openDate = openDate.subtract(25, "years");
                openDate = openDate.dayOfYear(1);
                return openDate.toDate();
            },
            changeBirthDate1: function () {
                this.fields.member_1_birthdate.changed = true;
            },
            changeBirthDate2: function () {
                this.fields.member_2_birthdate.changed = true;
            },
            dataCoupleSubmit: function () {
                this.$validator.validate().then(result => {
                    if (result && this.isFormChanged && this.isFormValid) {
                        const data = {
                            event_id: this.event_id,
                            member_1: {
                                id: this.member_1.id ?  this.member_1.id : null,
                                first_name: this.member_1.first_name,
                                last_name: this.member_1.last_name,
                                birthdate: Utils.changeFormatDate(this.member_1.birthdate),
                                email: this.member_1.email,
                                receive_email: this.member_1.receive_email,
                                is_female: + this.member_1.is_female,
                                account_facebook: this.member_1.account_facebook,
                                is_active: true
                            },
                            member_2 : {
                                id: this.member_2.id ? this.member_2.id : null,
                                first_name: this.member_2.first_name,
                                last_name: this.member_2.last_name,
                                birthdate: Utils.changeFormatDate(this.member_2.birthdate),
                                email: this.member_2.email,
                                receive_email: this.member_2.receive_email,
                                is_female: + this.member_2.is_female,
                                account_facebook: this.member_2.account_facebook,
                                is_active: true
                            }
                        };
                        const data_json = {
                            data: JSON.stringify(data)
                        };

                        eventsAPI.dataCouple(data_json).then( (response) => {

                            if(response.data.success){
                                this.response_error_message = '';
                                this.response_success_message = response.data.message;

                                setTimeout(() => {
                                    if (this.isEventPublished) {
                                        window.location.reload(false);
                                    } else {
                                        this.gotoNextStep();
                                    }
                                }, 1000);

                            }else{
                                this.response_error_message = response.data.message;
                                this.response_success_message = '';
                            }

                            this.hideMessagesResponse();

                        }).catch( (error) => {
                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();
                        });
                    } else if (!this.isEventPublished && this.isFormValid) {
                        this.gotoNextStep();
                    }
                });
            },

            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            },

            gotoNextStep: function() {
                window.location = this.next_url;
            }
        },
        created() {
            if(this.data_member_1 && this.data_member_2){
                const data_member_1 = JSON.parse(this.data_member_1);
                const data_member_2 = JSON.parse(this.data_member_2);

                this.member_1 = {
                    id: data_member_1.id,
                    first_name: data_member_1.first_name,
                    last_name: data_member_1.last_name,
                    email: data_member_1.email,
                    receive_email: data_member_1.receive_email,
                    is_female: + data_member_1.is_female,
                    account_facebook: data_member_1.account_facebook
                };

                this.member_2 = {
                    id: data_member_2.id,
                    first_name: data_member_2.first_name,
                    last_name: data_member_2.last_name,
                    email: data_member_2.email,
                    receive_email: data_member_2.receive_email,
                    is_female: + data_member_2.is_female,
                    account_facebook: data_member_2.account_facebook
                };

                if(data_member_1.birthdate && data_member_2.birthdate &&
                    data_member_1.birthdate !== "None" && data_member_2.birthdate !== "None"){
                    this.member_1.birthdate = moment(data_member_1.birthdate).toDate();
                    this.member_2.birthdate = moment(data_member_2.birthdate).toDate();
                }else{
                    this.member_1.birthdate = '';
                    this.member_2.birthdate = '';
                }

            }

        },
        mounted() {
            this.$nextTick(() => {
                if (this.data_member_1 && this.data_member_2){
                    this.$validator.validateAll();
                }
            });
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.google && _vm.map
        ? _c("GoogleMapAutocomplete", {
            attrs: {
              google: _vm.google,
              map: _vm.map,
              location: _vm.location,
              show: _vm.show
            },
            on: {
              "add-marker": _vm.addMarker,
              "change-autocomplete": _vm.changeAutoComplete,
              "validate-autocomplete": _vm.validateAutoComplete
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "common-content-field wrapper-google-maps",
          staticStyle: { position: "relative" }
        },
        [
          !_vm.show
            ? _c("div", { staticClass: "google-map-deactivated" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { ref: "googleMap", staticClass: "google-map" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div :class="['dropdown', 'tgl-dropdown', dropdownClass ? dropdownClass : '']">
        <button :class="['common-select', buttonClass ? buttonClass : '']"
                type="button" :disabled="disabled"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            {{ selectedValue }}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a :class="['dropdown-item', value === (valuePropName ? option[valuePropName] : option) ? 'active' : '']"
               href="javascript:void(0)"
               v-for="option in options"
               @click="updateInput(option)">{{ labelPropName ? option[labelPropName] : option }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "dropdown",
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: [String, Number],
            disabled: {
                type: Boolean,
                default: false
            },
            name: String,
            options: [Array, Object],
            valuePropName: String,
            labelPropName: String,
            dropdownClass: String,
            buttonClass: String
        },
        computed: {
            selectedValue() {
                if (this.labelPropName && this.valuePropName && this.options) {
                    if (this.options instanceof Array) {
                        const result = this.options.filter(option => option[this.valuePropName] === this.value);
                        return (result && result.length > 0) ? result[0][this.labelPropName] : ''
                    } else {
                        for(let key in this.options) {
                            if (this.options.hasOwnProperty(key) && this.options[key][this.valuePropName] === this.value) {
                                return this.options[key][this.labelPropName]
                            }
                        }
                    }
                }
                return this.value ? this.value : '';
            }
        },
        methods: {
            updateInput(selectedOption) {
                const newValue = this.valuePropName ? selectedOption[this.valuePropName] : selectedOption;
                if (newValue !== this.value) {
                    this.$emit('change', newValue);
                }
            }
        },
        $_veeValidate: {
            value () {
                return this.value;
            },
            name () {
                return this.name;
            }
        },
    }
</script>

<template>
    <div class="page-event-info-section event-info-location" v-bind:class="{ deactivated: !show }">
        <div class="row tgl-row event-info-title">
            <div class="col-md-9 tgl-col">
                <h4 class="common-title-section">INFORMACIÓN DEL LUGAR</h4>
            </div>
            <div v-if="index===0" class="col-md-3 tgl-col text-right">
                <button v-if="show" type="button" class="common-gray-btn-rounded"
                        v-on:click="hideLocation">
                    OCULTAR
                </button>
                <button v-if="!show" type="button" class="common-green-btn-rounded"
                        v-on:click="showLocation">
                    MOSTRAR
                </button>
            </div>
            <div v-if="index>0" class="col-md-3 tgl-col text-right">
                <button type="button" class="common-gray-btn-rounded"
                        :disabled="!show" v-on:click="deleteLocation">
                    ELIMINAR
                </button>
            </div>
        </div>
        <div>
            <div class="common-content-field half-content half-left">
                <label class="common-label-field">TÍTULO DEL EVENTO *</label>
                <input type="text" name="location_title" class="common-input-text"
                       placeholder="ej. Ceremonia, Recepción"
                       v-bind:class="{'error-input-text': errors.has('location_title')}"
                       v-validate="'required|max:150'"
                       v-model="location.title" :disabled="!show">

                <p class="message-error-field" v-if="errors.has('location_title')">
                    {{ errors.first('location_title') }}
                </p>
            </div>
            <div class="common-content-field half-content half-right">
                <div class="static-field-events">
                    <div v-if="!show" class="static-field-events-deactivated"></div>
                    <span class="static-field-events-label">
                        ÍCONO DEL EVENTO *
                    </span>
                    <span class="static-field-events-btn" data-toggle="modal" v-bind:data-target="'#iconListModal_' + index">
                        ÍCONO DEL EVENTO *
                    </span>
                    <span class="btn-static-field" data-toggle="modal" v-bind:data-target="'#iconListModal_' + index">ELEGIR</span>
                </div>
                <p class="message-error-field" v-if="errors.has('location_icon')">
                    {{ errors.first('location_icon') }}
                </p>
            </div>
            <div class="common-content-field half-content half-left">
                <label class="common-label-field">FECHA DEL EVENTO *</label>
                <datepicker name="location_date" class="common-input-text"
                       placeholder="DD-MM-AAAA"
                       v-bind:class="{'error-input-text': errors.has('location_date')}"
                       v-validate="'required'"
                       v-model="location.date" :disabled="!show"
                       :language="es" :disabledDates="disabledDates" @selected="changeEventDate"
                       :initialView="`${location.date ? 'day' : 'year'}`"
                ></datepicker>

                <p class="message-error-field" v-if="errors.has('location_date')">
                    {{ errors.first('location_date') }}
                </p>
            </div>
            <div class="common-content-field half-content half-right">
                <label class="common-label-field">HORA DEL EVENTO *</label>

                <div class="row tgl-row">
                    <div class="col-4 tgl-col">
                        <select name="location_hour" class="common-select" placeholder="Hr"
                                v-bind:class="{'error-input-text': errors.has('location_hour')}"
                                v-model.number="location.hour"
                                v-validate="'required'"
                                :disabled="!show">
                            <option v-for="n in 12" :value="n">
                                {{ n.toString().padStart(2, '0') }}
                            </option>
                        </select>
                    </div>

                    <div class="col-4 tgl-col">
                        <select name="location_minutes" class="common-select" placeholder="Min"
                                v-bind:class="{'error-input-text': errors.has('location_minutes')}"
                                v-model.number="location.minutes"
                                v-validate="'required'"
                                :disabled="!show">
                            <option value="0">00</option>
                            <option v-for="n in 11" :value="n*5">
                                {{ (n*5).toString().padStart(2, '0') }}
                            </option>
                        </select>
                    </div>

                    <div class="col-4 tgl-col">
                        <select name="location_am" class="common-select" placeholder="am"
                                v-bind:class="{'error-input-text': errors.has('location_am')}"
                                v-model.number="location.am"
                                v-validate="'required'"
                                :disabled="!show">
                            <option value="AM">am</option>
                            <option value="PM">pm</option>
                        </select>
                    </div>
                </div>

                <p class="message-error-field" v-if="errors.has('location_hour') ">
                    {{ errors.first('location_hour') }}
                </p>
            </div>
            <div class="common-content-field">
                <label class="common-label-field">NOMBRE DEL LUGAR</label>
                <input type="text" name="location_name" class="common-input-text"
                       placeholder="ej. Club Campestre"
                       v-bind:class="{'error-input-text': errors.has('location_name')}"
                       v-validate="'min:2|max:150'"
                       v-model="location.name" :disabled="!show">

                <p class="message-error-field" v-if="errors.has('location_name')">
                    {{ errors.first('location_name') }}
                </p>
            </div>

            <GoogleMapLoader
                :mapConfig="mapConfig"
                :apiKey="googleMapsApiKey"
                :location="location"
                :show="show"
                @change-map="changeMap"
                @validate-map="validateMap"
            />

            <p class="message-error-field" v-if="show && !mapValidated">
                Selecciona un punto válido en el mapa.
            </p>
        </div>

        <div class="modal fade icon-modal" v-bind:id="'iconListModal_' + index">
            <div class="modal-dialog modal-dialog-centered wrapper-cover-modal">
                <div class="modal-content content-cover-modal">
                    <h4 class="common-title-section">ELIGE UN ÍCONO PARA TU EVENTO</h4>
                    <button type="button" class="close close-covers-modal" aria-label="Close" data-dismiss="modal">
                        <span>×</span>
                    </button>
                    <div class="wrapper-covers row">
                        <div v-for="(icon, index2) in icon_list" class="wrapper-select-cover col-6 col-lg-4 text-center">
                            <input type="radio" name="location_icon" v-model="location.icon"
                                   hidden v-bind:value="icon.value" v-bind:id="'icon_' + index + '_' + index2"
                                   class="check-radio-cover"
                                   v-validate="'required'"
                                   :disabled="!show">
                            <label v-bind:for="'icon_' + index + '_' + index2" class="label-select-cover">
                                <div class="overlay-wrapper">
                                    <div class="cover-image-preview" v-bind:style="{ backgroundImage: 'url('+icon.url+')'}">
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="common-container-btn text-right">
                        <button type="submit" class="common-green-btn" :disabled="!location.icon" data-dismiss="modal">
                            SELECCIONAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';
    import GoogleMapLoader from '../components/GoogleMapLoader';
    import { EventBus } from '../event-bus.js';

    export default {
        name: 'event-information-location',
        components: {
            Datepicker,
            GoogleMapLoader
        },
        props: {
            index: Number,
            googleMapsApiKey: String,
            location: Object,
            show: Boolean,
            icon_list: Array
        },
        data() {
            return {
                es: es,
                disabledDates: {
                    to: moment(new Date()).toDate(),
                },
                mapChanged: false,
                mapValidated: false,
                showValueChanged: false,
                initialShow: null,
            }
        },
        mounted() {
        },
        methods: {
            changeEventDate: function () {
                this.fields.location_date.changed = true;
            },
            hideLocation: function () {
                this.location.show = false;
            },
            showLocation: function () {
                this.location.show = true;
                /* hack to avoid investigating why icon is not validated correctly*/
                if (!this.location.icon) {
                    this.fields['location_icon'].valid = false;
                }
            },
            deleteLocation: function(){
                this.$emit('delete-location', this.index);
            },
            changeMap: function (value) {
                this.mapChanged = value;
            },
            validateMap: function (value) {
                this.mapValidated = value;
            }
        },
        computed: {
            mapConfig () {
                return {
                    center: this.mapCenter,
                    zoom: 12
                }
            },
            mapCenter () {
                if (this.location.latitude && this.location.longitude) {
                    return { lat: this.location.latitude,
                             lng: this.location.longitude }
                }
                return { lat: -12.1214941, lng: -77.0463574 }
            },
            isFormChanged: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed) ||
                    this.mapChanged || this.showValueChanged;
            },
            isFormValid: function() {
                if (this.location.show) {
                    return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid) &&
                        this.mapValidated;
                }
                return true;
            },
        },
        watch: {
            show: function (value) {
                this.location.show = value
                if (value !== this.initialShow) {
                    this.showValueChanged = true;
                }
                else {
                    this.showValueChanged = false;
                }
            },
            isFormChanged: function (value) {
                this.location.changed = value;
            },
            isFormValid: function (value) {
                this.location.valid = value;
            }
        },
        created() {
            if (this.location.show) {
                this.initialShow = this.location.show;
            }
            if (this.isFormValid) {
                this.location.valid = true;
            }
            var vm =this;
            EventBus.$on('validate-all', function() {
                vm.$validator.validate().then(result => {});
            });
        },
    };

</script>

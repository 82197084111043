var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper-table-dashboard" }, [
    _c("div", { staticClass: "table-header-dashboard" }, [
      _c("div", { staticClass: "box-of-table col-date-order" }, [
        _c(
          "a",
          {
            class: [
              "label-header-dashboard",
              _vm.is_dashboard_page ? "" : "no-dashboard"
            ],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.changeOrderBy("order__payment_date")
              }
            }
          },
          [_vm._v("FECHA")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box-of-table col-name-order" }, [
        _c(
          "a",
          {
            class: [
              "label-header-dashboard",
              _vm.is_dashboard_page ? "" : "no-dashboard"
            ],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.changeOrderBy("order__user_alias")
              }
            }
          },
          [_vm._v("NOMBRE")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box-of-table col-name-order-gift" }, [
        _c(
          "a",
          {
            class: [
              "label-header-dashboard",
              _vm.is_dashboard_page ? "" : "no-dashboard"
            ],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.changeOrderBy("order_item__name")
              }
            }
          },
          [_vm._v("REGALO")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box-of-table col-num-quota" }, [
        _c(
          "a",
          {
            class: [
              "label-header-dashboard",
              _vm.is_dashboard_page ? "" : "no-dashboard"
            ],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.changeOrderBy("quota")
              }
            }
          },
          [_vm._v("CUOTAS")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box-of-table" }, [
        _c(
          "a",
          {
            class: [
              "label-header-dashboard",
              _vm.is_dashboard_page ? "" : "no-dashboard"
            ],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.changeOrderBy("price")
              }
            }
          },
          [_vm._v("MONTO")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "body-header-dashboard" },
      _vm._l(_vm.giftListSorted, function(gift) {
        return _c("div", { staticClass: "row-body-dashboard" }, [
          _c("div", { staticClass: "box-of-table col-date-order" }, [
            _c("div", { staticClass: "info-body-dashboard" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(gift.order__payment_date) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box-of-table col-name-order" }, [
            _c("div", { staticClass: "info-body-dashboard" }, [
              _vm._v(_vm._s(gift.order__user_alias))
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "box-of-table col-name-order-gift col-with-sub-title"
            },
            [
              _c("div", { staticClass: "sub-title-col" }, [_vm._v("Regalo: ")]),
              _vm._v(" "),
              gift.order_item_type_id == _vm.item_type.CONTRIB
                ? _c("div", { staticClass: "info-body-dashboard" }, [
                    _vm._v(
                      "\n                    Aporte libre\n                "
                    )
                  ])
                : _c("div", { staticClass: "info-body-dashboard" }, [
                    gift.order_item__is_donation_gift == true
                      ? _c("span", [
                          _c("strong", [_vm._v(_vm._s(gift.order_item__name))])
                        ])
                      : _c("span", [_vm._v(_vm._s(gift.order_item__name))])
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "box-of-table col-num-quota col-with-sub-title" },
            [
              _c("div", { staticClass: "sub-title-col" }, [_vm._v("Cuotas: ")]),
              _vm._v(" "),
              gift.order_item_type_id == _vm.item_type.QUOTAS
                ? _c("div", { staticClass: "info-body-dashboard" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(gift.quota) +
                        "/" +
                        _vm._s(gift.order_item__num_quota) +
                        "\n                "
                    )
                  ])
                : _c("div", { staticClass: "info-body-dashboard" }, [
                    _vm._v("-")
                  ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "box-of-table col-with-sub-title" }, [
            _c("div", { staticClass: "sub-title-col" }, [_vm._v("Monto: ")]),
            _vm._v(" "),
            _c("div", { staticClass: "info-body-dashboard" }, [
              _vm._v(
                _vm._s(_vm.event.currency.symbol) + " " + _vm._s(gift.price)
              )
            ])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
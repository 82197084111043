<template>
    <div>
        <form method="post" v-if="!hide_form_edit_account">
            <div v-html="csrf_token"></div>
            <div>
                <h4 class="common-title-section">EDITA TU USUARIO</h4>

                <div class="common-content-field">
                    <label class="common-label-field">NOMBRE</label>
                    <input type="text" name="first_name" class="common-input-text" placeholder="Ingresa tu nombre"
                           v-bind:class="{'error-input-text': errors.has('first_name')}"
                           autocomplete="given-name"
                           v-validate="'required|min:2|max:50|alpha_spaces'" v-model="user.first_name">
                    <p class="message-error-field" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</p>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">APELLIDOS</label>
                    <input type="text" name="last_name" class="common-input-text" placeholder="Ingresa tu apellido"
                           v-bind:class="{'error-input-text': errors.has('last_name')}"
                           autocomplete="family-name"
                           v-validate="'required|min:2|max:50|alpha_spaces'" v-model="user.last_name">
                    <p class="message-error-field" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</p>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">CORREO ELECTRÓNICO</label>
                    <div class="static-field" v-if="!edit_email">
                        <span class="text-static-field" v-html="user.email.new"></span>
                        <span class="btn-edit-mail" v-on:click="edit_email = true">EDITAR</span>
                    </div>
                    <input v-if="edit_email" type="email" name="email" class="common-input-text" placeholder="Ingresa tu correo electrónico"
                           v-validate="'required|email|min:5|max:50'" v-bind:class="{'error-input-text': errors.has('email')}"
                           v-model="user.email.new">
                    <p class="message-error-field" v-if="errors.has('email')">{{ errors.first('email') }}</p>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">TELÉFONO</label>
                    <input type="text" name="telephone" class="common-input-text" placeholder="Ingresa tu teléfono de contacto"
                           v-bind:class="{'error-input-text': errors.has('telephone')}"
                           v-validate="'numeric|min:5|max:15'" v-model="user.telephone">
                    <p class="message-error-field" v-if="errors.has('telephone')">{{ errors.first('telephone') }}</p>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">DIRECCIÓN</label>
                    <input type="text" name="address" class="common-input-text" placeholder="Ingresa tu dirección"
                           v-bind:class="{'error-input-text': errors.has('address')}"
                           autocomplete="street-address"
                           v-validate="'min:5|max:100'" v-model="user.address">
                    <p class="message-error-field" v-if="errors.has('address')">{{ errors.first('address') }}</p>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">CIUDAD</label>
                    <input type="text" name="city" class="common-input-text" placeholder="Ingresa tu ciudad"
                           autocomplete="address-level2"
                           v-bind:class="{'error-input-text': errors.has('city')}"
                           v-validate="'min:2|max:30'" v-model="user.city">
                    <p class="message-error-field" v-if="errors.has('city')">{{ errors.first('city') }}</p>
                </div>
            </div>
        </form>
        <form method="post" v-if="!hide_form_edit_account">
            <div>
                <h4 class="common-title-section after-content">CAMBIA TU CONTRASEÑA</h4>

                <div class="common-content-field">
                    <label class="common-label-field">CONTRASEÑA ACTUAL</label>
                    <input type="password" name="current_password" class="common-input-text"
                           autocomplete="current-password"
                           placeholder="Ingresa tu contraseña" v-model="password.current"
                           v-bind:class="{'error-input-text': errors.has('current_password')}"
                           v-validate.continues="'required_if:new_password'">
                    <p class="message-error-field" v-if="errors.has('current_password')">{{ errors.first('current_password') }}</p>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">NUEVA CONTRASEÑA</label>
                    <input type="password" name="new_password" class="common-input-text" placeholder="Ingresa tu nueva contraseña"
                           autocomplete="new-password" ref="new_password"
                           v-validate="'min:8'"
                           v-bind:class="{'error-input-text': errors.has('new_password')}"
                           v-model="password.new">
                    <p class="message-error-field" v-if="errors.has('new_password')">{{ errors.first('new_password') }}</p>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">CONFIRMA TU NUEVA CONTRASEÑA</label>
                    <input type="password" name="confirm_password" class="common-input-text" placeholder="Reingresa tu nueva contraseña"
                           autocomplete="new-password"
                           v-bind:class="{'error-input-text': errors.has('confirm_password')}"
                           v-validate.continues="'confirm_password:new_password'"
                           v-model="password.confirm">
                    <p class="message-error-field" v-if="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</p>
                </div>
            </div>
            <div class="common-container-btn content-bnt-and-link">
                <a href="/users/edit-account/" class="link-cancel-form">CANCELAR</a>
                <button type="button" class="common-green-btn"
                        v-on:click="clickBtnSubmit">GUARDAR CAMBIOS</button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>
        </form>

        <edit-account-confirm v-if="hide_form_edit_account" :data="data_form_verify_code">
        </edit-account-confirm>
    </div>
</template>

<script>

    import * as usersAPI from '../../api/users'
    import { scrollToTop } from '../../main'
    import EditAccountConfirm from './EditAccountConfirm'

    export default {
        name: "edit-account",
        props: ['csrf_token', 'user_first_name', 'user_last_name', 'user_email',
                'user_address', 'user_telephone', 'user_city'],
        data: () =>  {
            return {
                user: {
                    first_name: null,
                    last_name: null,
                    telephone: null,
                    address: null,
                    city: null,
                    email: {
                        old: null,
                        new: null
                    },
                },
                password: {
                    current: null,
                    new: null,
                    confirm: null
                },
                response_error_message: '',
                response_success_message: '',
                edit_email: false,
                hide_form_edit_account: false,
                data_form_verify_code: {}
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed)
                    && Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            clickBtnSubmit: function () {

                this.$validator.validateAll().finally(() => {
                    if (this.isFormValid) {
                        const data = {
                            first_name: this.user.first_name,
                            last_name: this.user.last_name,
                            email: this.user.email.old,
                            new_email: this.user.email.new,
                            telephone: this.user.telephone,
                            address: this.user.address,
                            city: this.user.city,
                            current_password: this.password.current,
                            new_password: this.password.new,
                            confirm_password: this.password.confirm
                        };

                        this.validateDataAccount(data);
                    }
                });

            },
            validateDataAccount: function (data) {
                usersAPI.validateDataAccount(
                    data.first_name, data.last_name, data.email, data.new_email, data.current_password, data.new_password
                ).then( (response) => {
                    if(response.data.success){

                        this.response_error_message = '';

                        if(response.data.email_changed){
                            this.sendCode(data);
                        }else{
                            this.updateDataAccount(data);
                        }

                    }else{
                        this.response_error_message = response.data.message;
                        this.response_success_message = '';
                    }

                }).catch( (error) => {
                    this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                }).finally(() => {
                    this.hideMessagesResponse();
                });
            },
            updateDataAccount: function (data) {
                usersAPI.updateAccount(
                    data.first_name, data.last_name, data.email, data.new_email,
                    data.telephone, data.address, data.city,
                    data.current_password, data.new_password
                ).then( (response) => {

                    if(response.data.success){

                        this.response_error_message = '';
                        this.response_success_message = response.data.message;

                        setTimeout(() => {window.location = window.location.href;}, 2000);
                    }else{

                        this.response_error_message = response.data.message;
                        this.response_success_message = '';
                    }

                }).catch( (error) => {
                    this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                }).finally(() => {
                    this.hideMessagesResponse();
                });
            },
            sendCode: function (data) {
                usersAPI.sendCode(data.email, data.new_email).then( (response) => {
                    if(response.data.success){

                        let appTarget = document.getElementById('app');
                        scrollToTop(appTarget);

                        this.response_error_message = '';
                        this.response_success_message = response.data.message;

                        this.hide_form_edit_account = true;
                        this.data_form_verify_code = data;

                    }else{
                        this.response_success_message = '';
                        this.response_error_message = response.data.message;
                    }

                }).catch( (error) => {
                    this.response_success_message = '';
                    this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                }).finally(() => {
                    this.hideMessagesResponse();
                });
            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            }
        },
        components: { EditAccountConfirm },
        created() {
            this.user.first_name = this.user_first_name;
            this.user.last_name =  this.user_last_name;
            this.user.telephone = this.user_telephone;
            this.user.address = this.user_address;
            this.user.city = this.user_city;
            this.user.email.old =  this.user_email;
            this.user.email.new =  this.user_email;
        },
        mounted() {
            this.$nextTick(() => {
                this.$validator.validateAll();
            });
        }
    }
</script>

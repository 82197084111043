var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "description-page" }, [
      _vm._v(
        "\n        Antes de publicar tu lista de regalos revisa que todos los datos de la cuenta sean\n        correctos.\n    "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "common-wrapper-content-page" }, [
      _c(
        "div",
        { staticClass: "common-container-content-page container-less-height" },
        [
          _c(
            "form",
            {
              attrs: { method: "post" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.dataBankSubmit($event)
                }
              }
            },
            [
              _c("div", {
                attrs: { hidden: "" },
                domProps: { innerHTML: _vm._s(_vm.csrf_token) }
              }),
              _vm._v(" "),
              _c("h4", { staticClass: "common-title-section" }, [
                _vm._v("CONFIRMACIÓN DE DATOS")
              ]),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              _c("div", [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("TITULAR DE LA CUENTA")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "simple-text width-p-left" }, [
                  _vm._v(
                    _vm._s(_vm.data_bank.holder_first_name) +
                      " " +
                      _vm._s(_vm.data_bank.holder_last_name)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("label", { staticClass: "common-label-field" }, [
                    _vm._v("NOMBRE DEL BANCO")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.data_vue.list_bank, function(bank) {
                    return bank.id === _vm.data_bank.bank_id
                      ? _c("div", { staticClass: "simple-text width-p-left" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(bank.label) +
                              "\n                    "
                          )
                        ])
                      : _vm._e()
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("TIPO DE MONEDA")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "simple-text width-p-left" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.data_vue.event.currency.symbol) +
                      " " +
                      _vm._s(_vm.data_vue.event.currency.name) +
                      "\n                    "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("NÚMERO DE CUENTA")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "simple-text width-p-left" }, [
                  _vm._v(_vm._s(_vm.data_bank.account_number))
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("CÓDIGO DE CUENTA INTERBANCARIO - CCI")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "simple-text width-p-left" }, [
                  _vm._v(_vm._s(_vm.data_bank.peruvian_cci))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "common-container-btn content-bnt-and-link flex-mobile"
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "link-cancel-form desktop",
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.backToEditData }
                    },
                    [
                      _vm._v(
                        "\n                        EDITAR LOS DATOS BANCARIOS\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "link-cancel-form mobile",
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.backToEditData }
                    },
                    [
                      _vm._v(
                        "\n                        EDITAR\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "common-green-btn",
                      attrs: {
                        type: "submit",
                        disabled:
                          _vm.isEventPublished && !_vm.$parent.form_was_changed
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.isEventPublished ? "ACTUALIZAR" : "SIGUIENTE"
                          ) +
                          "\n                        "
                      ),
                      !_vm.isEventPublished
                        ? _c("span", { staticClass: "arrow-into-btn" })
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _vm.response_error_message
                  ? _c("p", {
                      staticClass: "error-submit-form-message",
                      domProps: {
                        innerHTML: _vm._s(_vm.response_error_message)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.response_success_message
                  ? _c("p", {
                      staticClass: "success-submit-form-message",
                      domProps: {
                        innerHTML: _vm._s(_vm.response_success_message)
                      }
                    })
                  : _vm._e()
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from 'axios';
import qs from 'qs';
import * as Utils from '../utils';

function getCookie (name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }

    return cookieValue;
}

axios.interceptors.request.use(function (config) {
    Utils.startLoading();
    return config;
});

axios.interceptors.response.use(function (response) {
    Utils.stopLoading();
    return response;
}, function (error) {
    Utils.stopLoading();
    return Promise.reject(error);
});

export function callAPIPost (url, data) {
    return axios({
        method: 'POST',
        headers: {
            'X-CSRFToken': getCookie('csrftoken'),
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(data),
        url: window.location.origin + url
    });
}

export function callAPIPostIncludeFiles (url, data) {
    let formData = new FormData();

    for (const key_data in data) {
        formData.append(key_data, data[key_data]);
    }

    return axios({
        method: 'POST',
        headers: {
            'X-CSRFToken': getCookie('csrftoken'),
            'Content-Type': 'multipart/form-data'
        },
        data:  formData,
        url: window.location.origin + url
    });
}

export function callAPIGet (url) {
    return axios({
        method: 'GET',
        url: url
    });
}
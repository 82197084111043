<template>
    <div>
        <h2 class="common-title">CHECKOUT (1/2)</h2>
        <div class="common-wrapper-content-page">
            <div class="common-container-content-page">
                <div>
                    <h4 class="common-title-section">DATOS PERSONALES</h4>
                    <div class="wrapper-bg-login" v-if="!user.user_id">
                        <span class="text-to-login">¿Ya tienes una cuenta con nosotros? </span>
                        <a :href="login_link" class="link-login">INICIA SESIÓN</a>
                        <span class="text-to-login"> o continúa como invitado</span>
                    </div>
                    <div>
                        <div class="common-content-field half-content half-left">
                            <label class="common-label-field">NOMBRE *</label>
                            <input type="text" name="first_name"
                                   :class="['common-input-text', errors.has('first_name') ? 'error-input-text' : '']"
                                   @blur="updateNickname"
                                   v-validate="'required|min:2|max:50|alpha_spaces'" v-model="user.first_name">

                            <p class="message-error-field" v-if="errors.has('first_name')">
                                {{ errors.first('first_name') }}
                            </p>
                        </div>
                        <div class="common-content-field half-content half-right">
                            <label class="common-label-field">APELLIDOS *</label>
                            <input type="text" name="last_name"
                                   :class="['common-input-text', errors.has('last_name') ? 'error-input-text' : '']"
                                   v-validate="'required|min:2|max:50|alpha_spaces'" v-model="user.last_name">

                            <p class="message-error-field" v-if="errors.has('last_name')">
                                {{ errors.first('last_name') }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div class="common-content-field half-content half-left">
                            <label class="common-label-field">CORREO ELECTRÓNICO *</label>
                            <input type="email" name="email"
                                   :class="['common-input-text', errors.has('email') ? 'error-input-text' : '']"
                                   v-validate="'required|email|min:5|max:50'" v-model="user.email">

                            <p class="message-error-field" v-if="errors.has('email')">
                                {{ errors.first('email') }}
                            </p>
                        </div>
                        <div class="common-content-field half-content half-right">
                            <label class="common-label-field">DIRECCIÓN *</label>
                            <input type="text" name="address"
                                   :class="['common-input-text', errors.has('address') ? 'error-input-text' : '']"
                                   v-validate="'required|min:5|max:100'" v-model="user.address">

                            <p class="message-error-field" v-if="errors.has('address')">
                                {{ errors.first('address') }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div class="common-content-field half-content half-left">
                            <label class="common-label-field">CIUDAD *</label>
                            <input type="text" name="city"
                                   :class="['common-input-text', errors.has('city') ? 'error-input-text' : '']"
                                   v-validate="'required|min:2|max:30'" v-model="user.city">

                            <p class="message-error-field" v-if="errors.has('city')">
                                {{ errors.first('city') }}
                            </p>
                        </div>
                        <div class="common-content-field half-content half-right">
                            <label class="common-label-field">TELÉFONO *</label>
                            <input type="text" name="telephone"
                                   :class="['common-input-text', errors.has('telephone') ? 'error-input-text' : '']"
                                   v-validate="'required|numeric|min:5|max:15'" v-model="user.telephone">

                            <p class="message-error-field" v-if="errors.has('telephone')">
                                {{ errors.first('telephone') }}
                            </p>
                        </div>
                    </div>

                    <div v-if="!user.user_id">
                        <div>
                            <div class="common-content-field ">
                                <div class="container-checkbox">
                                    <input type="checkbox" id="registerUser" class="checkbox-form-tgl"
                                           name="register_user" v-validate="" v-model="user.want_register">
                                    <label class="label-field-checkbox" for="registerUser">
                                        <span class="text-label-checkbox">Quiero registrarme como usuario</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div v-if="user.want_register">
                            <div class="common-content-field"></div>
                            <div class="common-content-field half-content half-left">
                                <label class="common-label-field">CONTRASEÑA *</label>
                                <input type="password" name="password" placeholder="Ingresa tu contraseña"
                                       v-model="user.password"
                                       v-validate="`${user.want_register ? 'required|min:8' : 'min:8'}`"
                                       ref="password"
                                       :class="['common-input-text', errors.has('password') ? 'error-input-text' : '']"
                                />
                                <p class="message-error-field" v-if="errors.has('password')">
                                    {{ errors.first('password') }}
                                </p>
                            </div>

                            <div class="common-content-field half-content half-right">
                                <label class="common-label-field">CONFIRMA CONTRASEÑA *</label>
                                <input type="password" name="confirm_password" v-model="user.confirm_password"
                                       v-validate="`${user.want_register ? 'required|confirmed:password' : ''}`"
                                       placeholder="Reingresa tu contraseña"
                                       :class="['common-input-text', errors.has('confirm_password') ? 'error-input-text' : '']"
                                       >
                                <p class="message-error-field" v-if="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="next-content">
                    <h4 class="common-title-section after-content">MENSAJE PARA {{ event_title }}</h4>

                    <div class="common-content-field">
                        <label class="common-label-field">DE</label>
                        <input type="text" name="nickname"
                               :class="['common-input-text', errors.has('nickname') ? 'error-input-text' : '']"
                               placeholder="Ingresa tu nombre"
                               v-validate="'required|min:2|max:100'" v-model="user.nickname">

                        <p class="message-error-field" v-if="errors.has('nickname')">
                            {{ errors.first('nickname') }}
                        </p>

                    </div>

                    <div class="common-content-field">
                        <label class="common-label-field">AGREGA UN MENSAJE</label>
                        <textarea type="text" name="e_message"
                               placeholder="Ingresar mensaje"
                               :class="['common-input-text', errors.has('e_message') ? 'error-input-text' : '']"
                               v-validate="'required|max:400'" rows="5" v-model="user.message">
                        </textarea>

                        <p class="message-error-field" v-if="errors.has('e_message')">
                            {{ errors.first('e_message') }}
                        </p>
                    </div>

                    <div class="container-checkbox">
                        <input type="checkbox" id="publicMessage" class="checkbox-form-tgl"
                               name="public_message" v-validate="" v-model="user.is_public_message">
                        <label class="label-field-checkbox v-align-sub label-is-public-message" for="publicMessage">
                            <span class="text-label-checkbox">Mensaje público</span>
                        </label>
                        <span class="icon-help-tool"
                              v-tooltip="{ placement: 'right', title: 'Elige esta opción si quieres que tu mensaje sea público.' }">
                        </span>
                    </div>

                </div>
                <div class="common-container-btn content-bnt-and-link flex-mobile">
                    <a :href="event_link" class="link-cancel-form">
                        CANCELAR
                    </a>
                    <button class="common-green-btn" @click="goNextStep()">
                        SIGUIENTE
                        <span class="arrow-into-btn"></span>
                    </button>
                </div>


                <div>
                    <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as ordersAPI from '../../api/orders'

    export default {
        name: "checkout-data-order",
        props: ['event_link', 'user', 'login_link', 'event_title', 'list_document_type'],
        data: () =>  {
            return {
                response_error_message: null,
                document_type: {
                    'DNI': 1,
                    'CARNET': 2
                }
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        mounted() {

            if(this.user){
                if (!this.user.nickname) {
                    this.user.nickname = this.user.first_name
                }

                if(this.user.want_register) {

                    this.$nextTick(() => {
                        this.$validator.validateAll();
                    });

                }else{

                    this.$validator.fields.items.map( field => {
                        if(this.user[field.name]){
                            this.$validator.validate(field.name);
                        }
                    });

                }
            }
        },
        methods:{
            updateNickname: function() {
                if (!this.user.nickname) {
                    this.user.nickname = this.user.first_name
                }
            },
            hideThisStep: function (){
                this.$emit('update_show_first_step', false);
            },
            goNextStep: function (){
                this.$validator.validate().then(result => {
                    if (result && this.isFormValid) {

                        if(this.user.want_register && this.user.email){

                            ordersAPI.validationEmail(this.user.email).then( (response) => {

                                if(response.data.success){

                                    this.response_error_message = '';
                                    this.hideThisStep();

                                }else{
                                    this.response_error_message = response.data.message;
                                }

                                this.hideMessagesResponse();

                            }).catch( (error) => {

                                this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                                this.hideMessagesResponse();

                            });

                        }else{
                            this.hideThisStep();
                        }
                    }
                });
            },
            hideMessagesResponse: function(){
                setTimeout(() => {
                    this.response_error_message = '';
                }, 2000);
            }
        }
    }
</script>

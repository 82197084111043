const getMessage = (field) => 'El campo ' + field + ' no coincide.';
const validate = (value, { targetValue }) => {
    if (
        (targetValue === undefined || targetValue === null || targetValue === '')
        &&
        (value === undefined || value === null || value === '')
    ) {
        return true;
    }
    return String(value) === String(targetValue);
};

export default {
    getMessage,
    validate
};

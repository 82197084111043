<template>

    <div :class="['wrapper-icon-shopping-cart', count_item_in_cart > 0 ? '' : 'cart-empty']"
         :data-toggle="count_item_in_cart > 0 ? 'modal' : ''" data-target="#shoppingCartModal">
        <div class="wrapper-count-items">
            <span class="count-items">{{ count_item_in_cart }}</span>
        </div>
        <div class="icon-cart"></div>
    </div>

</template>

<script>

    export default {
        name: "shopping-cart-icon",
        props: ['count_item_in_cart']
    }
</script>

<template>
    <div>

        <bank-account-edit
                :data_account_bank="data_bank"
                :before_step="before_step"
                :data_vue="data_vue"
                :no_holder="no_holder"
                :default_holder="default_holder"
                v-if="show_data_bank_edit"
        ></bank-account-edit>

        <bank-account-confirm v-else
            :csrf_token="csrf_token"
            :data_account_bank="data_bank"
            :data_vue="data_vue"
            :next_url="next_url"
        >
        </bank-account-confirm>

    </div>
</template>

<script>

    import BankAccountEdit from './BankAccountEdit'
    import BankAccountConfirm from './BankAccountConfirm'
    import { event_status } from './utils'


    export default {
        name: "bank-account",
        props: ['csrf_token', 'data_vue', 'before_step', 'next_url'],
        components: { BankAccountEdit, BankAccountConfirm },
        data: () =>  {
            return {
                show_data_bank_edit: true,
                data_bank: {
                    bank_account_id: null,
                    couple_member_id: null,
                    holder_first_name: null,
                    holder_last_name: null,
                    document_type_id: null,
                    document_number: null,
                    bank_id: null,
                    bank_account_type_id: null,
                    account_number: null,
                    peruvian_cci: null,
                    event_id: null,
                    currency_id: null
                },
                form_was_changed: false,
                no_holder: null,
                default_holder: null
            }
        },
        created() {

            const data = this.data_vue;

            this.data_bank = {
                bank_account_id: data.bank_account ? data.bank_account.id : null,
                couple_member_id: data.bank_account ? data.bank_account.couple_member_id : data.couple_members[0].id,
                holder_first_name: data.bank_account ? data.bank_account.account_holder_name : data.couple_members[0].first_name,
                holder_last_name: data.bank_account ? data.bank_account.account_holder_lastname : data.couple_members[0].last_name,
                document_type_id: data.bank_account ? data.bank_account.document_type_id : data.list_document_type[0].id,
                document_number: data.bank_account ? data.bank_account.document_number : '',
                bank_id: data.bank_account ? data.bank_account.bank_id : data.list_bank[0].id,
                bank_account_type_id: data.bank_account ? data.bank_account.bank_account_type_id : data.list_bank_account_type[0].id,
                account_number: data.bank_account ? data.bank_account.account_number : '',
                peruvian_cci: data.bank_account ? data.bank_account.peruvian_cci : '',
                event_id: data.event.id,
                currency_id: data.event.currency.id
            };

            if(data.event.status !== event_status.NOT_PUBLISHED || this.data_bank.bank_account_id){
                this.show_data_bank_edit = false;
            }

            let no_holder = data.couple_members.filter( (member) => member.id !== this.data_bank.couple_member_id )[0];

            if (no_holder){
                this.no_holder = {
                    id: no_holder.id,
                    first_name: no_holder.first_name,
                    last_name: no_holder.last_name
                };
            }

            this.default_holder = {
                id: this.data_bank.couple_member_id,
                first_name: this.data_bank.holder_first_name,
                last_name: this.data_bank.holder_last_name
            };

        }
    }
</script>

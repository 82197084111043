var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-view-items" },
    [
      _c("shopping-cart-icon", {
        attrs: { count_item_in_cart: _vm.count_item_in_cart }
      }),
      _vm._v(" "),
      _c("shopping-cart", {
        attrs: {
          event: _vm.event,
          cart: _vm.cart,
          count_item_in_cart: _vm.count_item_in_cart,
          checkout_link: _vm.checkout_link
        },
        on: {
          update_quota_or_amount_from_cart: _vm.updateQuotaOrAmountFromCart,
          update_count_items_cart: _vm.updateCountItemsInCart,
          update_contrib_is_in_cart: _vm.updateContribIsInCart
        }
      }),
      _vm._v(" "),
      _vm.has_items
        ? _c("div", { staticClass: "wrapper-options-filter in-preview-page" }, [
            _c("div", { staticClass: "content-item-filter" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search_item,
                    expression: "search_item"
                  }
                ],
                staticClass: "input-search-menu",
                attrs: { type: "text", placeholder: "Buscar item" },
                domProps: { value: _vm.search_item },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search_item = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container-checkbox show-device" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.show_only_group,
                    expression: "show_only_group"
                  }
                ],
                staticClass: "checkbox-form-tgl",
                attrs: { type: "checkbox", id: "filterByGroup" },
                domProps: {
                  checked: Array.isArray(_vm.show_only_group)
                    ? _vm._i(_vm.show_only_group, null) > -1
                    : _vm.show_only_group
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.show_only_group,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.show_only_group = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.show_only_group = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.show_only_group = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      placement: "right",
                      title:
                        "Estos son regalos a los que puedes aportar cuotas sin pagar el monto total."
                    },
                    expression:
                      "{ placement: 'right', title: 'Estos son regalos a los que puedes aportar cuotas sin pagar el monto total.' }"
                  }
                ],
                staticClass: "icon-help-tool"
              })
            ]),
            _vm._v(" "),
            _c("hr", { staticClass: "hr-tgl show-device hr-tgl-preview" }),
            _vm._v(" "),
            _c("div", { staticClass: "content-item-filter" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-item-filter" },
              [
                _c("category-sidebar", {
                  ref: "category-sidebar",
                  attrs: {
                    data_list_category: _vm.data_vue.list_category,
                    is_no_build_list: true,
                    has_donation_items: _vm.data_vue.has_donation_items
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content-item-filter show-device" }),
            _vm._v(" "),
            _c("hr", { staticClass: "hr-tgl show-device hr-tgl-preview" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper-content-list-item in-preview-page" }, [
        _vm.has_items
          ? _c(
              "div",
              { staticClass: "content-open-filter content-filter-select" },
              [
                _c("h2", { staticClass: "common-title" }, [
                  _vm._v(_vm._s(_vm.name_category_selected))
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "filter-to-order-by show-device" }, [
                  _c("label", { staticClass: "label-order-by" }, [
                    _vm._v("ORDENAR POR")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.order_by,
                          expression: "order_by",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "common-select",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.order_by = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: _vm.orders.a_z } }, [
                        _vm._v("A - Z")
                      ]),
                      _vm._v(" "),
                      _c(
                        "option",
                        { domProps: { value: _vm.orders.price_less_higher } },
                        [_vm._v("PRECIO: MENOR A MAYOR")]
                      ),
                      _vm._v(" "),
                      _c(
                        "option",
                        { domProps: { value: _vm.orders.price_higher_less } },
                        [_vm._v("PRECIO: MAYOR A MENOR")]
                      ),
                      _vm._v(" "),
                      _c(
                        "option",
                        { domProps: { value: _vm.orders.no_bought } },
                        [_vm._v("NO REGALADOS")]
                      )
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.has_items
          ? _c(
              "div",
              { staticClass: "content-open-filter show-device mobile" },
              [
                _vm.order_by === _vm.orders.a_z
                  ? _c("h2", { staticClass: "common-title" }, [_vm._v("A - Z")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order_by === _vm.orders.price_less_higher
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("PRECIO: MENOR A MAYOR")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order_by === _vm.orders.price_higher_less
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("PRECIO: MAYOR A MENOR")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order_by === _vm.orders.no_bought
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("NO REGALADOS")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.show_only_group
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("SOLO GRUPALES")
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wrapper-list-item" },
          [
            _vm.event.is_contribution_account
              ? _c("account-contrib", {
                  key: "account_contrib",
                  ref: "account-contrib-card",
                  attrs: {
                    event: _vm.event,
                    cart: _vm.cart,
                    contrib_is_in_cart: _vm.contrib_is_in_cart
                  },
                  on: {
                    update_count_items_cart: _vm.updateCountItemsInCart,
                    update_contrib_is_in_cart: _vm.updateContribIsInCart
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.listItemsFiltered, function(item) {
              return _c("event-item", {
                key: item.id,
                attrs: { event: _vm.event, item: item, cart: _vm.cart },
                on: { update_count_items_cart: _vm.updateCountItemsInCart }
              })
            }),
            _vm._v(" "),
            !_vm.has_items && !_vm.event.is_contribution_account
              ? _c("span", { staticClass: "simple-text" }, [
                  _vm._v(
                    "\n                Esta lista no posee regalos en este momento.\n            "
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "label-field-checkbox label-in-list-item",
        attrs: { for: "filterByGroup" }
      },
      [
        _c("span", { staticClass: "text-label-checkbox w-auto" }, [
          _vm._v(
            "\n                    Solo regalos grupales\n                "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "tab-open-filter show-device mobile",
        attrs: { id: "openSideFilter" }
      },
      [
        _vm._v("\n                FILTROS\n                "),
        _c("span", { staticClass: "arrow-open-filter" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
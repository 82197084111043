<template>
    <div>
        <form method="post" @submit.prevent="coverDesignSubmit">
            <div v-html="csrf_token"></div>
            <h4 class="common-title-section">DATOS DE LA LISTA</h4>
            <p></p>

            <div>
                <div class="common-content-field">
                    <label class="common-label-field">NOMBRE DE LA LISTA *</label>
                    <input type="text" name="event_title" class="common-input-text"
                           placeholder="Ingresa el nombre de la lista"
                           v-bind:class="{'error-input-text': errors.has('event_title')}"
                           v-validate="'required|min:2|max:100'"
                           v-model="e_title">

                    <p class="message-error-field" v-if="errors.has('event_title')">
                        {{ errors.first('event_title') }}
                    </p>
                </div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">FECHA DEL EVENTO *</label>

                    <datepicker name="event_date" class="common-input-text"
                           placeholder="DD-MM-AAAA"
                           v-bind:class="{'error-input-text': errors.has('event_date')}"
                           v-validate="'required'"
                           v-model="e_date" id="eventDate"
                           :language="es" :disabledDates="disabledDates" @selected="changeEventDate"
                           :initialView="`${e_date ? 'day' : 'year'}`"
                    ></datepicker>

                    <p class="message-error-field" v-if="errors.has('event_date')">
                        {{ errors.first('event_date') }}
                    </p>
                </div>
                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">CIUDAD *</label>
                    <input type="text" name="event_location" class="common-input-text"
                           placeholder="Ingresa una ciudad"
                           v-bind:class="{'error-input-text': errors.has('event_location')}"
                           v-validate="'required|min:2|max:30'"
                           v-model="e_location">

                    <p class="message-error-field" v-if="errors.has('event_location')">
                        {{ errors.first('event_location') }}
                    </p>
                </div>

                <div class="common-content-field">
                    <div class="static-field-events">
                        <span class="static-field-events-label">
                            ELIGE TU FOTO DE PORTADA
                        </span>
                        <span class="static-field-events-btn" data-toggle="modal" data-target="#coverListModal">
                            ELIGE TU FOTO DE PORTADA
                        </span>
                        <span class="icon-help-tool"
                              v-tooltip="{ placement: 'right',
                                           title: 'Ingresa a nuestra librería y elige la foto de portada que más te guste.' }"></span>
                        <span class="btn-static-field" data-toggle="modal" data-target="#coverListModal">ELEGIR</span>
                    </div>
                    <p class="message-error-field" v-if="errors.has('item_cover')">
                        {{ errors.first('item_cover') }}
                    </p>
                </div>

                <div class="common-content-field">
                    <div class="static-field-events">

                        <span class="static-field-events-label">
                            FOTO DE PERFIL
                        </span>
                        <label class="static-field-events-btn" for="profilePicture">
                            SUBE TU FOTO DE PERFIL
                        </label>
                        <span class="icon-help-tool"
                              v-tooltip="{ placement: 'right',
                                           title: 'Recuerda que esta foto será circular y se verá mejor si se les ve ' +
                                             'de cerca. Te recomendamos cortar tu foto cuadrada antes de subirla.' }"></span>
                        <label class="btn-static-field" for="profilePicture">SUBIR</label>

                        <input type="file" id="profilePicture" hidden
                        name="e_profile_picture" v-validate="'ext:jpeg,jpg,png|size:5000'"
                        ref="profile_file" v-on:change="updateProfileFile" v-if="profile_picture"/>

                        <input type="file" id="profilePicture" hidden
                        name="e_profile_picture" v-validate="'required|ext:jpeg,jpg,png|size:5000'"
                        ref="profile_file" v-on:change="updateProfileFile" v-if="!profile_picture"/>
                    </div>

                    <p class="message-error-field" v-if="errors.has('e_profile_picture')">
                        {{ errors.first('e_profile_picture') }}
                    </p>
                </div>
                <p></p>
                <div class="common-content-field">
                    <label class="common-label-field">VISTA PREVIA</label>
                    <div class="wrapper-preview-profile">
                        <div class="preview-cover" v-bind:style="{ backgroundImage: 'url('+cover_url+')'}"></div>
                        <div class="preview-profile" v-bind:style="{ backgroundImage: 'url('+profile_picture_url+')'}"></div>
                    </div>
                </div>

                <div class="common-content-field">
                    <label class="common-label-field">AGREGA UN MENSAJE PARA TUS INVITADOS</label>
                    <textarea type="text" name="e_message" class="common-input-text"
                           placeholder="Ingresar mensaje"
                           v-bind:class="{'error-input-text': errors.has('e_message')}"
                           v-validate="'max:900'"
                           v-model="event_message"
                           rows="5">
                    </textarea>

                    <p class="message-error-field" v-if="errors.has('e_message')">
                        {{ errors.first('e_message') }}
                    </p>
                </div>

            </div>

            <div class="common-container-btn content-bnt-and-link flex-mobile">
                <a v-bind:href="before_step" class="link-cancel-form desktop">REGRESAR AL PASO ANTERIOR</a>
                <a v-bind:href="before_step" class="link-cancel-form mobile">REGRESAR</a>
                <button type="submit" class="common-green-btn" :disabled="(isEventPublished && !isFormChanged)">
                    {{ isEventPublished ? 'ACTUALIZAR' : 'SIGUIENTE' }}
                    <span class="arrow-into-btn" v-if="!isEventPublished"></span>
                </button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>
        </form>
        <div>
            <div class="modal fade" id="coverListModal">
                <div class="modal-dialog modal-dialog-centered wrapper-cover-modal">
                    <div class="modal-content content-cover-modal">
                        <h4 class="common-title-section">SELECCIONA TU FOTO DE PORTADA</h4>
                        <button type="button" class="close close-covers-modal" aria-label="Close" data-dismiss="modal">
                            <span>×</span>
                        </button>
                        <div class="wrapper-covers">
                            <div v-for="objCover in covers_list" class="wrapper-select-cover">
                                <input type="radio" name="item_cover" v-model="new_cover_id"
                                       hidden v-bind:value="objCover.id" v-bind:id="objCover.id"
                                       class="check-radio-cover"
                                       v-validate="'required'">
                                <label v-bind:for="objCover.id" v-bind:title="objCover.label" class="label-select-cover">
                                    <div class="overlay-wrapper">
                                        <div class="cover-image-preview" v-bind:style="{ backgroundImage: 'url('+objCover.path+')'}">
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="common-container-btn text-right">
                            <button type="submit" class="common-green-btn" :disabled="!new_cover_id" v-on:click="updateCoverId"
                            data-dismiss="modal">
                                SELECCIONAR
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import 'bootstrap/js/dist/modal'
    import * as eventsAPI from '../../api/events'
    import * as Utils from '../../utils'
    import * as moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';
    import * as EventUtils from './utils'


    export default {
        name: "cover-design",
        components: {
            Datepicker
        },
        props: ['csrf_token', 'data_vue', 'event_id', 'next_url', 'before_step', 'event_status'],
        data: () =>  {
            return {
                e_title: null,
                e_date: null,
                e_location: null,
                cover_id: null,
                cover_url: '',
                profile_picture: null,
                profile_picture_url: '',
                event_message: null,
                response_error_message: '',
                response_success_message: '',
                profile_file: '',
                covers_list: [],
                new_cover_id: null,
                es: es,
                disabledDates: {
                    to: moment(new Date()).toDate(),
                }
            }
        },
        computed: {
            isFormChanged: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed);
            },
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            },
            isEventPublished: function() {
                return this.event_status >= EventUtils.event_status.PUBLISHED;
            },
        },
        methods:{
            changeEventDate: function () {
                this.fields.event_date.changed = true;
            },

            coverDesignSubmit: function () {
                this.$validator.validate().then(result => {
                    if (result && this.isFormChanged && this.isFormValid) {
                        const data = {
                            event_id: this.event_id,
                            title: this.e_title,
                            event_date: Utils.changeFormatDate(this.e_date),
                            location: this.e_location,
                            cover_image_id: this.cover_id,
                            profile_file: this.profile_file,
                            event_message: this.event_message
                        };

                        eventsAPI.storeCoverDesign(data).then((response) => {

                            if (response.data.success) {
                                this.response_error_message = '';
                                this.response_success_message = response.data.message;

                                setTimeout(() => {
                                    if (this.isEventPublished) {
                                        window.location.reload(false);
                                    } else {
                                        this.gotoNextStep();
                                    }
                                }, 1000);

                            } else {
                                this.response_error_message = response.data.message;
                                this.response_success_message = '';
                            }

                            this.hideMessagesResponse();

                        }).catch((error) => {
                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();
                        });
                    } else if (!this.isEventPublished && this.isFormValid) {
                        this.gotoNextStep();
                    }
                });
            },

            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            },

            gotoNextStep: function() {
                window.location = this.next_url;
            },

            updateProfileFile() {
                this.profile_file = this.$refs.profile_file.files[0];

                if(this.profile_file && !this.errors.has('e_profile_picture')){
                    try {
                        Utils.getOrientation(this.profile_file, (orientation) => {
                            if (orientation > 1 && orientation < 9) {
                                Utils.resetOrientation(URL.createObjectURL(this.profile_file), orientation, (result) => {
                                    this.profile_picture_url = result;
                                })
                            }
                            this.profile_picture_url = URL.createObjectURL(this.profile_file);
                        });
                    } catch (e) {
                        this.profile_picture_url = URL.createObjectURL(this.profile_file);
                    }
                }

            },
            setCoverUrl(cover_id) {
                const cover = this.covers_list.filter(cover => parseInt(cover['id']) === parseInt(cover_id))[0];
                this.cover_url = (cover) ? cover.path : '';
            },
            updateCoverId() {
                this.cover_id = this.new_cover_id;
                this.setCoverUrl(this.cover_id);
            },
            getTomorrowDate() {
                const date = new Date();
                const tomorrow = (date.getUTCDate()+1) +'-'+ (date.getMonth() +1 ) +'-'+ date.getFullYear();
                return tomorrow;
            }
        },
        created() {
            if(this.data_vue){
                const data_vue = JSON.parse(this.data_vue);

                if(typeof data_vue == "object"){

                    const media_url = data_vue.MEDIA_URL;
                    this.e_title = data_vue.title;

                    if(data_vue.event_date && data_vue.event_date !== "None"){
                        this.e_date = moment(data_vue.event_date).toDate();
                    }else{
                        this.e_date = '';
                    }

                    this.e_location = data_vue.location ? data_vue.location : null;
                    this.cover_id = data_vue.cover_image_id ? data_vue.cover_image_id : null;
                    this.profile_picture = data_vue.profile_picture ? data_vue.profile_picture : null;
                    this.profile_picture_url = data_vue.profile_picture ? media_url + data_vue.profile_picture : '';

                    if(data_vue.event_message && data_vue.event_message !== "null"){
                        this.event_message = data_vue.event_message;
                    }else{
                        this.event_message = '';
                    }

                    this.new_cover_id = this.cover_id;

                    this.covers_list = data_vue.covers_list.map((coverObj) => {
                        coverObj.path = media_url + coverObj.path;
                        return coverObj;
                    });
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                const data_vue = JSON.parse(this.data_vue);
                if (data_vue.title && data_vue.event_date && data_vue.location && data_vue.cover_image_id
                    && data_vue.profile_picture){
                    this.$validator.validateAll();
                    this.setCoverUrl(this.cover_id);
                }
            });
        }
    }
</script>

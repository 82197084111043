var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.coverDesignSubmit($event)
          }
        }
      },
      [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.csrf_token) } }),
        _vm._v(" "),
        _c("h4", { staticClass: "common-title-section" }, [
          _vm._v("DATOS DE LA LISTA")
        ]),
        _vm._v(" "),
        _c("p"),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [
              _vm._v("NOMBRE DE LA LISTA *")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:2|max:100",
                  expression: "'required|min:2|max:100'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.e_title,
                  expression: "e_title"
                }
              ],
              staticClass: "common-input-text",
              class: { "error-input-text": _vm.errors.has("event_title") },
              attrs: {
                type: "text",
                name: "event_title",
                placeholder: "Ingresa el nombre de la lista"
              },
              domProps: { value: _vm.e_title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.e_title = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("event_title")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("event_title")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("FECHA DEL EVENTO *")
              ]),
              _vm._v(" "),
              _c("datepicker", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "common-input-text",
                class: { "error-input-text": _vm.errors.has("event_date") },
                attrs: {
                  name: "event_date",
                  placeholder: "DD-MM-AAAA",
                  id: "eventDate",
                  language: _vm.es,
                  disabledDates: _vm.disabledDates,
                  initialView: "" + (_vm.e_date ? "day" : "year")
                },
                on: { selected: _vm.changeEventDate },
                model: {
                  value: _vm.e_date,
                  callback: function($$v) {
                    _vm.e_date = $$v
                  },
                  expression: "e_date"
                }
              }),
              _vm._v(" "),
              _vm.errors.has("event_date")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("event_date")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("CIUDAD *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:30",
                    expression: "'required|min:2|max:30'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.e_location,
                    expression: "e_location"
                  }
                ],
                staticClass: "common-input-text",
                class: { "error-input-text": _vm.errors.has("event_location") },
                attrs: {
                  type: "text",
                  name: "event_location",
                  placeholder: "Ingresa una ciudad"
                },
                domProps: { value: _vm.e_location },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.e_location = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("event_location")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("event_location")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("div", { staticClass: "static-field-events" }, [
              _c("span", { staticClass: "static-field-events-label" }, [
                _vm._v(
                  "\n                        ELIGE TU FOTO DE PORTADA\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "static-field-events-btn",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#coverListModal"
                  }
                },
                [
                  _vm._v(
                    "\n                        ELIGE TU FOTO DE PORTADA\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      placement: "right",
                      title:
                        "Ingresa a nuestra librería y elige la foto de portada que más te guste."
                    },
                    expression:
                      "{ placement: 'right',\n                                       title: 'Ingresa a nuestra librería y elige la foto de portada que más te guste.' }"
                  }
                ],
                staticClass: "icon-help-tool"
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "btn-static-field",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#coverListModal"
                  }
                },
                [_vm._v("ELEGIR")]
              )
            ]),
            _vm._v(" "),
            _vm.errors.has("item_cover")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("item_cover")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("div", { staticClass: "static-field-events" }, [
              _c("span", { staticClass: "static-field-events-label" }, [
                _vm._v(
                  "\n                        FOTO DE PERFIL\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "static-field-events-btn",
                  attrs: { for: "profilePicture" }
                },
                [
                  _vm._v(
                    "\n                        SUBE TU FOTO DE PERFIL\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      placement: "right",
                      title:
                        "Recuerda que esta foto será circular y se verá mejor si se les ve " +
                        "de cerca. Te recomendamos cortar tu foto cuadrada antes de subirla."
                    },
                    expression:
                      "{ placement: 'right',\n                                       title: 'Recuerda que esta foto será circular y se verá mejor si se les ve ' +\n                                         'de cerca. Te recomendamos cortar tu foto cuadrada antes de subirla.' }"
                  }
                ],
                staticClass: "icon-help-tool"
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "btn-static-field",
                  attrs: { for: "profilePicture" }
                },
                [_vm._v("SUBIR")]
              ),
              _vm._v(" "),
              _vm.profile_picture
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "ext:jpeg,jpg,png|size:5000",
                        expression: "'ext:jpeg,jpg,png|size:5000'"
                      }
                    ],
                    ref: "profile_file",
                    attrs: {
                      type: "file",
                      id: "profilePicture",
                      hidden: "",
                      name: "e_profile_picture"
                    },
                    on: { change: _vm.updateProfileFile }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.profile_picture
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|ext:jpeg,jpg,png|size:5000",
                        expression: "'required|ext:jpeg,jpg,png|size:5000'"
                      }
                    ],
                    ref: "profile_file",
                    attrs: {
                      type: "file",
                      id: "profilePicture",
                      hidden: "",
                      name: "e_profile_picture"
                    },
                    on: { change: _vm.updateProfileFile }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.errors.has("e_profile_picture")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("e_profile_picture")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [
              _vm._v("VISTA PREVIA")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "wrapper-preview-profile" }, [
              _c("div", {
                staticClass: "preview-cover",
                style: { backgroundImage: "url(" + _vm.cover_url + ")" }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "preview-profile",
                style: {
                  backgroundImage: "url(" + _vm.profile_picture_url + ")"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [
              _vm._v("AGREGA UN MENSAJE PARA TUS INVITADOS")
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "max:900",
                  expression: "'max:900'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.event_message,
                  expression: "event_message"
                }
              ],
              staticClass: "common-input-text",
              class: { "error-input-text": _vm.errors.has("e_message") },
              attrs: {
                type: "text",
                name: "e_message",
                placeholder: "Ingresar mensaje",
                rows: "5"
              },
              domProps: { value: _vm.event_message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.event_message = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("e_message")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("e_message")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "common-container-btn content-bnt-and-link flex-mobile"
          },
          [
            _c(
              "a",
              {
                staticClass: "link-cancel-form desktop",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR AL PASO ANTERIOR")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-cancel-form mobile",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "common-green-btn",
                attrs: {
                  type: "submit",
                  disabled: _vm.isEventPublished && !_vm.isFormChanged
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.isEventPublished ? "ACTUALIZAR" : "SIGUIENTE") +
                    "\n                "
                ),
                !_vm.isEventPublished
                  ? _c("span", { staticClass: "arrow-into-btn" })
                  : _vm._e()
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.response_error_message
            ? _c("p", {
                staticClass: "error-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_error_message) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.response_success_message
            ? _c("p", {
                staticClass: "success-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_success_message) }
              })
            : _vm._e()
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        { staticClass: "modal fade", attrs: { id: "coverListModal" } },
        [
          _c(
            "div",
            {
              staticClass:
                "modal-dialog modal-dialog-centered wrapper-cover-modal"
            },
            [
              _c("div", { staticClass: "modal-content content-cover-modal" }, [
                _c("h4", { staticClass: "common-title-section" }, [
                  _vm._v("SELECCIONA TU FOTO DE PORTADA")
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "wrapper-covers" },
                  _vm._l(_vm.covers_list, function(objCover) {
                    return _c("div", { staticClass: "wrapper-select-cover" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.new_cover_id,
                            expression: "new_cover_id"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "check-radio-cover",
                        attrs: {
                          type: "radio",
                          name: "item_cover",
                          hidden: "",
                          id: objCover.id
                        },
                        domProps: {
                          value: objCover.id,
                          checked: _vm._q(_vm.new_cover_id, objCover.id)
                        },
                        on: {
                          change: function($event) {
                            _vm.new_cover_id = objCover.id
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "label-select-cover",
                          attrs: { for: objCover.id, title: objCover.label }
                        },
                        [
                          _c("div", { staticClass: "overlay-wrapper" }, [
                            _c("div", {
                              staticClass: "cover-image-preview",
                              style: {
                                backgroundImage: "url(" + objCover.path + ")"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "common-container-btn text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "common-green-btn",
                      attrs: {
                        type: "submit",
                        disabled: !_vm.new_cover_id,
                        "data-dismiss": "modal"
                      },
                      on: { click: _vm.updateCoverId }
                    },
                    [
                      _vm._v(
                        "\n                            SELECCIONAR\n                        "
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close close-covers-modal",
        attrs: {
          type: "button",
          "aria-label": "Close",
          "data-dismiss": "modal"
        }
      },
      [_c("span", [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
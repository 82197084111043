import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/collapse';
import "@babel/polyfill";

/* TinyMCE needs to be imported before Vue starts so that it works for tinymce-vue */
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/themes/mobile/theme';
import 'tinymce/plugins/lists';

import '../style/main.styl';
import '../style/home.styl';
import '../style/static_pages.styl';

import './app-vue/main-vue';
import * as Utils from './utils'

const openSearchTarget = document.getElementById('searchList');
const wrapperSearchBox = document.getElementById('wrapperSearchBox');
const inputSearchMenu = document.getElementById('inputSearchMenu');
const btnSearchMenu = document.getElementById('btnSearchMenu');
const headerNavbarTgl = document.getElementById('headerNavbarTgl');
const openSideBarMenuTarget = document.getElementById("openSideBarMenu");
const closeSideBarMenuTarget = document.getElementById("closeSideBarMenu");
const sideNavMenu = document.getElementById("sideNavMenu");
const iconCloseSearchBox = document.getElementById('clearInputMobile');
const clearInputMobileTarget = document.getElementById('clearInputMobile');
const vueCarouselTarget = document.getElementsByClassName('VueCarousel-inner');
const wrapperBodyTarget = document.getElementById('wrapper_body');


const openSearchBox = (e) => {
    e.preventDefault();
    headerNavbarTgl.classList.add('z-index-0');
    wrapperSearchBox.classList.add('active');
    inputSearchMenu.focus();
};

const closeSearchBox = (e) => {
    e.preventDefault();
    headerNavbarTgl.classList.remove('z-index-0');
    wrapperSearchBox.classList.remove('active');
};

if(wrapperSearchBox){
    wrapperSearchBox.addEventListener('click', (e) => {
        e.preventDefault();
        closeSearchBox(e);
    });
    inputSearchMenu.addEventListener('click', (e) => {
        e.stopPropagation();
    });

    btnSearchMenu.addEventListener('click', (e) => {
        e.stopPropagation();
    });
}

const sideBarEvents = {
    open: (sideTarget, addClass) => {
        sideTarget.classList.add(addClass);
        wrapperBodyTarget.classList.add('sidebar-is-open');
    },
    close: (sideTarget, addClass) => {
        sideTarget.classList.remove(addClass);
        wrapperBodyTarget.classList.remove('sidebar-is-open');
    },
    eventToOpen: (eventTarget, sideTarget, addClass) => {
        eventTarget.addEventListener('click', (e) => {
            e.preventDefault();
            sideBarEvents.open(sideTarget, addClass)
        });
    },
    eventToClose: (eventTarget, sideTarget, addClass) => {
        eventTarget.addEventListener('click', (e) => {
            e.preventDefault();
            sideBarEvents.close(sideTarget, addClass)
        });
    }
};

if (openSearchTarget || inputSearchMenu || iconCloseSearchBox){
    openSearchTarget.addEventListener('click', openSearchBox);
}

if(clearInputMobileTarget){
    clearInputMobileTarget.addEventListener('click', (e) => {
        document.getElementById('inputSearchMobile').value = '';
    });
}

if (openSideBarMenuTarget || closeSideBarMenuTarget){
    sideBarEvents.eventToOpen(openSideBarMenuTarget, sideNavMenu, 'open-sidebar');
    sideBarEvents.eventToClose(closeSideBarMenuTarget, sideNavMenu, 'open-sidebar');
}

const openFilterTarget = document.getElementById('openSideFilter');
const closeFilterTarget = document.getElementById('closeSideFilter');
const sideFilterTarget = document.getElementById('sidebarFilterCategory');

if (sideFilterTarget){
    sideBarEvents.eventToOpen(openFilterTarget, sideFilterTarget, 'open-sidebar-filters');
    sideBarEvents.eventToClose(closeFilterTarget, sideFilterTarget, 'open-sidebar-filters');
}

const itemsTermTarget = document.getElementsByClassName('item-term');
const backTopTarget = document.getElementsByClassName('back-to-top');


if(itemsTermTarget){
    for (let i = 0; i < itemsTermTarget.length; i++) {
        let elem = itemsTermTarget[i];
        elem.addEventListener('click', (e) => {
            e.preventDefault();
            let dataArticle = e.target.dataset.article;
            let articleContent = document.getElementById(dataArticle);

            scrollToTop(articleContent);
        });
    }
}

if (backTopTarget){
    for (let i = 0; i < backTopTarget.length; i++) {
        let elem = backTopTarget[i];
        elem.addEventListener('click', (e) => {
            e.preventDefault();
            let firstTermContent = document.getElementById('article_1');
            scrollToTop(firstTermContent);
        });
    }
}

if(vueCarouselTarget){
    for (let i = 0; i < vueCarouselTarget.length; i++) {
        let contentTestimonials = vueCarouselTarget[i];
        const testimonialsCount = contentTestimonials.childElementCount;

        if (testimonialsCount < 3){
            contentTestimonials.classList.add('vc-inner-center-content');
        }
    }
}

var fixedBottomBar = document.getElementById('fixedBottomBar');
var colContentItems = document.getElementById('colContentItems');

if(fixedBottomBar && colContentItems){
    const changePositionBar = () => {
        let footer = document.getElementsByTagName('footer')[0];

        if( $(fixedBottomBar).offset().top + $(fixedBottomBar).height() >= $(footer).offset().top ){
            fixedBottomBar.classList.add('bar-absolute');
        }

        if ($(document).scrollTop() + window.innerHeight < $(footer).offset().top){
            fixedBottomBar.classList.remove('bar-absolute');
        }

    };

    $(document).scroll(function () {
		changePositionBar();
	});
}


export function scrollToTop (element) {
    $('html, body').animate({
        scrollTop: $(element).offset().top - 120
    }, 200);
}


const btnSharePage = document.getElementById('btn_share_page');

if(btnSharePage){
    btnSharePage.addEventListener('click', (e) => {
        e.preventDefault();
        Utils.sharePage(btnSharePage);
    });
}


const shoppingCartModal = document.getElementById('shoppingCartModal');

if(shoppingCartModal){


    let previousScrollY = 0;

    $(shoppingCartModal).on('show.bs.modal', function (e) {
        previousScrollY = window.scrollY;
        $('html').addClass('modal-open').css({
            marginTop: -previousScrollY,
            overflow: 'hidden',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            position: 'fixed'
        });
    });

    $(shoppingCartModal).on('hide.bs.modal', function (e) {
        $('html').removeClass('modal-open').css({
            marginTop: 0,
            overflow: 'visible',
            left: 'auto',
            right: 'auto',
            top: 'auto',
            bottom: 'auto',
            position: 'static'
        });
        window.scrollTo(0, previousScrollY);
    });
}
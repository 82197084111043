import * as moment from 'moment';


export function changeFormatDate($inputDate, $formatDate) {
    let format_date = 'YYYY-MM-DD';
    if($formatDate !== null && typeof $formatDate == "string") format_date = $formatDate;

    return moment($inputDate).format(format_date);
}


export function sharePage(target){
    var $this = target;
    var dataUrl = $this.dataset['linkShare'];

    var urlFbShare = 'https://www.facebook.com/sharer.php?u=';
    var urlSharePage = urlFbShare + dataUrl;
    var nameWindow = "Share The Gift List";
    var styleWindow = "width=400, height=400, scrollbars=NO";

    window.open(urlSharePage, nameWindow, styleWindow);
}

export function startLoading() {
    const loading = document.getElementById('main_loading');
    loading.classList.add('active');
}

export function stopLoading() {
    const loading = document.getElementById('main_loading');
    loading.classList.remove('active');
}

// from http://stackoverflow.com/a/32490603
export function getOrientation(file, callback) {
    let reader = new FileReader();

    reader.onload = function(event) {
        let view = new DataView(event.target.result);

        if (view.getUint16(0, false) !== 0xFFD8) {
            return callback(-2);
        }

        let length = view.byteLength, offset = 2;

        while (offset < length) {
            let marker = view.getUint16(offset, false);
            offset += 2;

            if (marker === 0xFFE1) {
                if (view.getUint32(offset += 2, false) !== 0x45786966) {
                    return callback(-1);
                }
                let little = view.getUint16(offset += 6, false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                let tags = view.getUint16(offset, little);
                offset += 2;

                for (let i = 0; i < tags; i++) {
                    if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            }
            else if ((marker & 0xFF00) !== 0xFF00) {
                break;
            }
            else {
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
}

export function resetOrientation(srcBase64, srcOrientation, callback) {
	let img = new Image();

	img.onload = function() {
        let width = img.width, height = img.height;
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height , width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: break;
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        callback(canvas.toDataURL());
    };

    img.src = srcBase64;
}

export function dataURLtoFile(dataURI, filename) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new File([ia], filename, {type:mimeString});
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper-item wrapper-item-added",
      on: { mouseleave: _vm.onMouseLeave }
    },
    [
      _c(
        "div",
        {
          staticClass: "wrapper-image-card",
          style: { backgroundImage: "url(" + _vm.item.image + ")" }
        },
        [
          _vm.item.is_donation_gift
            ? _c("div", { staticClass: "wrapper-item-is-donation" }, [
                _c("div", { staticClass: "img-solidary" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.is_in_cart
            ? _c("div", { staticClass: "wrapper-bg-added added-to-cart" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isItemAvailable
            ? _c("div", { staticClass: "wrapper-bg-bought" })
            : _c("div", { staticClass: "wrapper-buttons-card text-center" }, [
                _c(
                  "form",
                  {
                    attrs: { method: "post" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submitItem($event)
                      }
                    }
                  },
                  [
                    _vm.item.num_quota > 1
                      ? _c("dropdown", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "min_value:0",
                              expression: "'min_value:0'"
                            }
                          ],
                          attrs: {
                            "dropdown-class": "select-quota-dropdown",
                            "button-class": "select-quota",
                            name: "quota",
                            options: _vm.itemQuotaOptions,
                            "value-prop-name": "id",
                            "label-prop-name": "label"
                          },
                          model: {
                            value: _vm.quota,
                            callback: function($$v) {
                              _vm.quota = _vm._n($$v)
                            },
                            expression: "quota"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        class: [
                          "common-green-btn",
                          _vm.item.is_in_cart && _vm.item.num_quota > 1
                            ? "btn-update-quota"
                            : ""
                        ],
                        attrs: {
                          type: "submit",
                          disabled:
                            _vm.item.num_quota > 1 &&
                            !(_vm.fields["quota"] && _vm.fields["quota"].valid)
                        }
                      },
                      [
                        _vm.item.is_in_cart && _vm.item.num_quota > 1
                          ? _c("span", [_vm._v("ACTUALIZAR")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.item.is_in_cart && _vm.item.num_quota === 1
                          ? _c("span", [_vm._v("ELIMINAR")])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.item.is_in_cart
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.event.gift_word
                                    ? _vm.event.gift_word
                                    : "REGALAR"
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "wrapper-info-card",
            "wrapper-info-height",
            !_vm.isItemAvailable ? "wrapper-info-card-bought" : ""
          ]
        },
        [
          _vm.item.is_donation_gift
            ? _c("h5", { staticClass: "title-card with-tooltip" }, [
                _c("span", { staticClass: "title-span-card" }, [
                  _vm._v(_vm._s(_vm.item.name))
                ]),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        placement: "right",
                        title:
                          "El valor de este regalo se donará a esta organizacion."
                      },
                      expression:
                        "{placement: 'right', title: 'El valor de este regalo se donará a esta organizacion.' }"
                    }
                  ],
                  staticClass: "icon-help-tool"
                })
              ])
            : _c("h5", { staticClass: "title-card" }, [
                _vm._v(_vm._s(_vm.item.name))
              ]),
          _vm._v(" "),
          _vm.item.description
            ? _c("div", { staticClass: "description-item" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.item.description) + "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.num_quota > 1
            ? _c("div", { staticClass: "price-item" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.event.currency.symbol) +
                    _vm._s(_vm.item.price_quota) +
                    " por " +
                    _vm._s(
                      _vm.event.quota_word ? _vm.event.quota_word : "cuota"
                    ) +
                    "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.num_quota > 1
            ? _c("div", { staticClass: "total-price" }, [
                _vm._v(
                  "PRECIO TOTAL: " +
                    _vm._s(_vm.event.currency.symbol) +
                    _vm._s(_vm.item.price)
                )
              ])
            : _c("div", { staticClass: "price-item" }, [
                _vm._v(
                  _vm._s(_vm.event.currency.symbol) + _vm._s(_vm.item.price)
                )
              ]),
          _vm._v(" "),
          _vm.item.num_quota > 1
            ? _c("div", { staticClass: "wrapper-progress-bar" }, [
                _c("div", { staticClass: "container-progress-bar" }, [
                  _c("div", {
                    staticClass: "progress-bar-count",
                    style: { width: _vm.progressBarWidth + "%" }
                  })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "info-progress-bar" }, [
                  _vm._v(_vm._s(_vm.availableQuotaString))
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
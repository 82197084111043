var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.createComplaintSubmit($event)
          }
        }
      },
      [
        _c("div", {
          attrs: { hidden: "" },
          domProps: { innerHTML: _vm._s(_vm.csrf_token) }
        }),
        _vm._v(" "),
        _c("h4", { staticClass: "common-title-section" }, [
          _vm._v("IDENTIFICACIÓN DEL CONSUMIDOR RECLAMANTE")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("NOMBRE *")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:2|max:200",
                expression: "'required|min:2|max:200'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("first_name") ? "error-input-text" : ""
            ],
            attrs: {
              type: "text",
              name: "first_name",
              placeholder: "Ingresa tu nombre completo"
            },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("first_name")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("first_name")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("DOMICILIO *")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:2|max:200",
                expression: "'required|min:2|max:200'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.address,
                expression: "address"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("address") ? "error-input-text" : ""
            ],
            attrs: {
              type: "text",
              name: "address",
              placeholder:
                "Ingresa tu dirección incluyendo distrito, provincia y departamento"
            },
            domProps: { value: _vm.address },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.address = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("address")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("address")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("TIPO DE DOCUMENTO")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.document_type_id,
                      expression: "document_type_id",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "common-select",
                  attrs: { name: "document_type" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.document_type_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.data_vue.list_document_type, function(doc_type) {
                  return _c("option", { domProps: { value: doc_type.id } }, [
                    _vm._v(_vm._s(doc_type.label) + "\n                    ")
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.errors.has("document_type")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("document_type")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("NRO. DE DOCUMENTO *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|numeric|min:8|max:9",
                    expression: "'required|numeric|min:8|max:9'"
                  },
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.document_number,
                    expression: "document_number",
                    modifiers: { number: true }
                  }
                ],
                class: [
                  "common-input-text",
                  _vm.errors.has("dni_number") ? "error-input-text" : ""
                ],
                attrs: {
                  type: "text",
                  name: "dni_number",
                  placeholder: "ej. 12345678"
                },
                domProps: { value: _vm.document_number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.document_number = _vm._n($event.target.value)
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("dni_number")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("dni_number")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("TELÉFONO *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|numeric|min:5|max:15",
                    expression: "'required|numeric|min:5|max:15'"
                  },
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.telephone,
                    expression: "telephone",
                    modifiers: { number: true }
                  }
                ],
                class: [
                  "common-input-text",
                  _vm.errors.has("telephone") ? "error-input-text" : ""
                ],
                attrs: {
                  type: "text",
                  name: "telephone",
                  placeholder: "Ingresa tu teléfono"
                },
                domProps: { value: _vm.telephone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.telephone = _vm._n($event.target.value)
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("telephone")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("telephone")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("EMAIL *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email|min:5|max:50",
                    expression: "'required|email|min:5|max:50'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                class: [
                  "common-input-text",
                  _vm.errors.has("email") ? "error-input-text" : ""
                ],
                attrs: {
                  type: "text",
                  name: "email",
                  placeholder: "Ingresa tu correo electrónico"
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("email")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("email")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "next-content" }),
        _vm._v(" "),
        _c("h4", { staticClass: "common-title-section" }, [
          _vm._v("IDENTIFICACIÓN DEL BIEN CONTRATADO")
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "wrapper-check-radio" }, [
            _c(
              "label",
              {
                staticClass: "label-check-radio mr-0",
                attrs: { for: "contracted_service_1" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.contracted_service_type_id,
                      expression: "contracted_service_type_id",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    name: "contracted_service",
                    id: "contracted_service_1"
                  },
                  domProps: {
                    value: _vm.data_vue.contracted_service_type.PRODUCT,
                    checked: _vm._q(
                      _vm.contracted_service_type_id,
                      _vm._n(_vm.data_vue.contracted_service_type.PRODUCT)
                    )
                  },
                  on: {
                    change: function($event) {
                      _vm.contracted_service_type_id = _vm._n(
                        _vm.data_vue.contracted_service_type.PRODUCT
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Producto")])
              ]
            ),
            _vm._v("\n                       \n                "),
            _c(
              "label",
              {
                staticClass: "label-check-radio mr-0",
                attrs: { for: "contracted_service_2" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.contracted_service_type_id,
                      expression: "contracted_service_type_id",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    name: "contracted_service",
                    id: "contracted_service_2"
                  },
                  domProps: {
                    value: _vm.data_vue.contracted_service_type.SERVICE,
                    checked: _vm._q(
                      _vm.contracted_service_type_id,
                      _vm._n(_vm.data_vue.contracted_service_type.SERVICE)
                    )
                  },
                  on: {
                    change: function($event) {
                      _vm.contracted_service_type_id = _vm._n(
                        _vm.data_vue.contracted_service_type.SERVICE
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Servicio")])
              ]
            )
          ]),
          _vm._v(" "),
          _vm.errors.has("contracted_service")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("contracted_service")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("MONTO RECLAMADO")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "numeric",
                    expression: "'numeric'"
                  },
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.reclaimed_amount,
                    expression: "reclaimed_amount",
                    modifiers: { number: true }
                  }
                ],
                class: [
                  "common-input-text",
                  "currency-symbol",
                  "soles",
                  _vm.errors.has("reclaimed_amount") ? "error-input-text" : ""
                ],
                attrs: { type: "text", name: "reclaimed_amount" },
                domProps: { value: _vm.reclaimed_amount },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.reclaimed_amount = _vm._n($event.target.value)
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("reclaimed_amount")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("reclaimed_amount")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("DESCRIPCIÓN *")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:2|max:200",
                expression: "'required|min:2|max:200'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.claim_description,
                expression: "claim_description"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("claim_description") ? "error-input-text" : ""
            ],
            attrs: { type: "text", name: "claim_description" },
            domProps: { value: _vm.claim_description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.claim_description = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("claim_description")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("claim_description")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "next-content" }),
        _vm._v(" "),
        _c("h4", { staticClass: "common-title-section" }, [
          _vm._v("DETALLE DE RECLAMACIÓN Y PEDIDO DEL CONSUMIDOR")
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "wrapper-check-radio" }, [
            _c(
              "label",
              {
                staticClass: "label-check-radio mr-0",
                attrs: { for: "claim_type_1" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.claim_type_id,
                      expression: "claim_type_id",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    name: "claim_type",
                    id: "claim_type_1"
                  },
                  domProps: {
                    value: _vm.data_vue.claim_type.CLAIM,
                    checked: _vm._q(
                      _vm.claim_type_id,
                      _vm._n(_vm.data_vue.claim_type.CLAIM)
                    )
                  },
                  on: {
                    change: function($event) {
                      _vm.claim_type_id = _vm._n(_vm.data_vue.claim_type.CLAIM)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Reclamo")])
              ]
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    placement: "right",
                    title:
                      "Disconformidad relacionada a los productos o servicios."
                  },
                  expression:
                    "{ placement: 'right',\n                      title: 'Disconformidad relacionada a los productos o servicios.' }"
                }
              ],
              staticClass: "icon-help-tool"
            }),
            _vm._v("\n                   \n                "),
            _c(
              "label",
              {
                staticClass: "label-check-radio mr-0",
                attrs: { for: "claim_type_2" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.claim_type_id,
                      expression: "claim_type_id",
                      modifiers: { number: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    name: "claim_type",
                    id: "claim_type_2"
                  },
                  domProps: {
                    value: _vm.data_vue.claim_type.COMPLAINT,
                    checked: _vm._q(
                      _vm.claim_type_id,
                      _vm._n(_vm.data_vue.claim_type.COMPLAINT)
                    )
                  },
                  on: {
                    change: function($event) {
                      _vm.claim_type_id = _vm._n(
                        _vm.data_vue.claim_type.COMPLAINT
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Queja")])
              ]
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    placement: "right",
                    title:
                      "Disconformidad no relacionada a los productos o servicios; o, malestar o descontento respecto a la atención al público."
                  },
                  expression:
                    "{ placement: 'right',\n                      title: 'Disconformidad no relacionada a los productos o servicios; o, malestar o descontento respecto a la atención al público.' }"
                }
              ],
              staticClass: "icon-help-tool"
            })
          ]),
          _vm._v(" "),
          _vm.errors.has("claim_type")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("claim_type")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("DETALLE")
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "min:2|max:400",
                expression: "'min:2|max:400'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.claim_detail,
                expression: "claim_detail"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("claim_detail") ? "error-input-text" : ""
            ],
            attrs: { type: "text", name: "claim_detail", rows: "5" },
            domProps: { value: _vm.claim_detail },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.claim_detail = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("claim_detail")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("claim_detail")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("PEDIDO")
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "min:2|max:400",
                expression: "'min:2|max:400'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.claim_order,
                expression: "claim_order"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("claim_order") ? "error-input-text" : ""
            ],
            attrs: { type: "text", name: "claim_order", rows: "5" },
            domProps: { value: _vm.claim_order },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.claim_order = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("claim_order")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("claim_order")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", [
          _vm.response_error_message
            ? _c("p", {
                staticClass: "error-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_error_message) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.response_success_message
            ? _c("p", {
                staticClass: "success-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_success_message) }
              })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-container-btn text-right" }, [
      _c(
        "button",
        { staticClass: "common-green-btn", attrs: { type: "submit" } },
        [_vm._v("ENVIAR")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>

    <div>


        <shopping-cart-icon :count_item_in_cart="count_item_in_cart" />

        <shopping-cart
            :event="event"
            :cart="cart"
            :count_item_in_cart="count_item_in_cart"
            :is_checkout_page="is_checkout_page"
            :event_link="event_link"
            @update_count_items_cart="updateCountItemsInCart"
            @force_update_checkout_component="forceUpdateCheckoutComponent"
        />

        <checkout-data-order
                v-if="show_first_step"
                :event_link="event_link"
                :event_title="event.title"
                :user="user"
                :login_link="login_link"
                @update_show_first_step="updateShowFirstStep"
                :list_document_type="list_document_type"

        />


        <checkout-pay
                v-else
                :csrf_token="csrf_token"
                :event="event"
                :list_items="list_items"
                :cart="cart"
                @update_count_items_cart="updateCountItemsInCart"
                :key="'checkout_pay'"
                :event_link="event_link"
                :user="user"
                @update_show_first_step="updateShowFirstStep"
                :payment_method_id="payment_method_id"
                @update_payment_method="updatePaymentMethod"
                :public_key_culqi="public_key_culqi"
                :public_key_mp="public_key_mp"
                :link_successful="link_successful"
        />

    </div>


</template>

<script>

    import ShoppingCart from '../shopping_cart/ShoppingCart'
    import ShoppingCartIcon from '../shopping_cart/ShoppingCartIcon'
    import CheckoutDataOrder from './CheckoutDataOrder'
    import CheckoutPay from './CheckoutPay'
    import * as EventUtils from '../events/utils'
    import * as OrderUtils from './utils'

    export default {
        name: "checkout",
        props: ['csrf_token', 'data_vue', 'shopping_cart', 'event_link',
                'user_object', 'login_link', 'public_key_culqi', 'public_key_mp',
                'link_deposit_payment', 'link_card_payment', 'list_document_type'],
        data: () =>  {
            return {
                response_error_message: null,
                response_success_message: null,
                event: {},
                cart: {
                    items: {},
                    total: 0
                },
                contrib_is_in_cart: false,
                count_item_in_cart: 0,
                list_items: [],
                is_checkout_page: true,
                show_first_step: true,
                user: {
                    user_id: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    address: null,
                    telephone: null,
                    city: null,
                    nickname: null,
                    message: null,
                    is_public_message: true,
                    want_register: false,
                    password: null,
                    document_type_id: null,
                    document_number: null,
                    country_issuance: OrderUtils.countryIssuance.DEFAULT
                },
                payment_method_id: null,
                link_successful: {}
            }
        },
        watch: {
            'count_item_in_cart': function (){
                if(!this.count_item_in_cart){
                    window.location = this.event_link;
                }
            }
        },
        methods:{

            updatePaymentMethod: function(value){
                this.payment_method_id = value;
            },
            updateCountItemsInCart: function(value){
                this.count_item_in_cart = value;
            },
            forceUpdateCheckoutComponent: function(){

                for (let i = 0; i < this.$children.length; i++) {
                    let component = this.$children[i];

                    if(component.$vnode.key === 'checkout_pay'){
                        component.$forceUpdate();
                        break;
                    }
                }
            },
            updateShowFirstStep: function(value){
                this.show_first_step = value;
            }
        },

        created() {
            this.list_items = EventUtils.loadEventItems(this.data_vue);
            this.event = this.data_vue.event;

            EventUtils.loadItemsToCart(this.cart, this.shopping_cart, this.list_items);
            this.count_item_in_cart = Object.keys(this.cart.items).length;


            for (let i = 0; i < this.list_items.length; i++) {
                let item = this.list_items[i];

                item.is_in_cart = item.id in this.cart.items;

                if (item.is_in_cart) {
                    this.cart.items[item.id].item = item;
                }

            }

            let user = this.user_object;

            if(user){
                this.user.user_id = user.user_id;
                this.user.first_name = user.first_name;
                this.user.last_name = user.last_name;
                this.user.email = user.email;
                this.user.address = user.address;
                this.user.telephone = user.telephone;
                this.user.city = user.city;
            }

            this.user.document_type_id = this.list_document_type[0]['id']

            this.link_successful = {
                deposit: this.link_deposit_payment,
                card: this.link_card_payment
            };

        },
        mounted() {},
        components: { ShoppingCartIcon, CheckoutDataOrder, CheckoutPay, ShoppingCart },
    }
</script>


<template>
    <div>
        <div class="have-account-question" v-show="show_form">
            ¿Ya tienes una cuenta con nosotros?
            <a v-bind:href="link_login" class="log-in-link">INICIA SESIÓN</a>
        </div>
        <div class="content-fields-form">
            <form method="post" v-show="show_form">
                <div v-html="csrf_token"></div>

                <div class="content-field">
                    <label class="label-field">NOMBRE</label>
                    <input type="text" name="first_name" v-model="user.first_name" class="input-text-form-tgl"
                           autocomplete="given-name" v-validate="'required|min:2|max:50|alpha_spaces'"
                           placeholder="Ingresa tu nombre" v-bind:class="{'error-input-text': errors.has('first_name')}">
                    <p class="text-message-error" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</p>
                </div>

                <div class="content-field">
                    <label class="label-field">APELLIDOS</label>
                    <input type="text" name="last_name" v-model="user.last_name" class="input-text-form-tgl"
                           autocomplete="family-name" v-validate="'required|min:2|max:50|alpha_spaces'"
                           placeholder="Ingresa tus apellidos" v-bind:class="{'error-input-text': errors.has('last_name')}">
                    <p class="text-message-error" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</p>
                </div>

                <div class="content-field">
                    <label class="label-field">CORREO ELECTRÓNICO</label>
                    <input type="text" name="email" v-model="user.email" class="input-text-form-tgl"
                           placeholder="Ingresa tu correo electrónico" v-validate="'required|email|min:5|max:50'"
                           v-bind:class="{'error-input-text': errors.has('email')}">
                    <p class="text-message-error" v-if="errors.has('email')">{{ errors.first('email') }}</p>
                </div>

                <div class="content-field">
                    <label class="label-field">CONTRASEÑA</label>
                    <input type="password" name="password" v-model="user.password" placeholder="Ingresa tu contraseña"
                           autocomplete="new-password" v-validate="'required|min:8'" ref="password"
                           class="input-text-form-tgl" v-bind:class="{'error-input-text': errors.has('password')}"/>
                    <p class="text-message-error" v-if="errors.has('password')">{{ errors.first('password') }}</p>
                </div>

                <div class="content-field">
                    <label class="label-field">CONFIRMA CONTRASEÑA</label>
                    <input type="password" name="confirm_password" v-model="user.confirm_password"
                           autocomplete="new-password" v-validate="'required|confirmed:password'"
                           class="input-text-form-tgl" placeholder="Reingresa tu contraseña"
                           v-bind:class="{'error-input-text': errors.has('confirm_password')}">
                    <p class="text-message-error" v-if="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</p>
                </div>

                <div class="content-field">
                    <input type="checkbox" name="accept_terms" v-model="user.accept_terms" class="checkbox-form-tgl"
                           id="accept_terms_form" v-validate="'required:true'">
                    <label class="label-field-checkbox" for="accept_terms_form">
                            Acepto los
                        <a v-bind:href="link_terms" target="_blank" class="link-terms">Términos y Condiciones</a> y
                        <a v-bind:href="link_policy" target="_blank" class="link-terms">Política de Privacidad</a>
                    </label>
                    <p class="text-message-error" v-if="errors.has('accept_terms')">{{ errors.first('accept_terms') }}</p>
                </div>

                <div class="content-submit">
                    <button type="button" class="btn-submit-tgl" v-on:click="submitForm">
                        REGÍSTRATE
                    </button>
                </div>

                <div v-if="sent_form">
                    <p v-html="response_form_message" class="error-submit-form-message"></p>
                </div>

            </form>
            <div class="content-success-register" v-show="!show_form">
                <h5 class="title-form-success">REGISTRO EXITOSO</h5>
                <p class="info-form-success">
                    ¡Te has registrado correctamente! Te hemos enviado un correo para verificar tu
                    cuenta. No olvides revisar tu buzón de correos no deseados.
                </p>
                <div class="content-submit">
                    <a v-bind:href="link_home" class="btn-submit-tgl btn-finish-register">FINALIZAR</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as usersAPI from '../../api/users'

    export default {
        name: "register-user",
        props: ['csrf_token', 'link_home', 'link_terms', 'link_policy', 'link_login'],
        data: () =>  {
            return {
                user: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    password: null,
                    confirm_password: null,
                    accept_terms: false
                },
                sent_form: null,
                success_form: null,
                response_form_message: '',
                show_form: true
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            submitForm: function () {

                this.$validator.validateAll().finally(() => {
                    if (this.isFormValid) {

                        usersAPI.registerUser(
                            this.user.first_name, this.user.last_name, this.user.email, this.user.password
                        ).then((response) => {
                            this.success_form = response.data.success;
                            this.response_form_message = response.data.message;

                            if (response.data.success) {
                                this.show_form = false;
                            }

                        }).catch((error) => {
                            this.success_form = false;
                            this.response_form_message = 'Ocurrió un error, intente de nuevo.';
                        }).finally(() => {
                            this.hideMessagesResponse();
                        });

                        this.sent_form = true;
                    }
                });

            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_form_message = '';
                }.bind(this), 2000)
            }
        }
    }

</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.currencyAndCommissionSubmit($event)
          }
        }
      },
      [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.csrf_token) } }),
        _vm._v(" "),
        _c("div", [
          _c("h4", { staticClass: "common-title-section" }, [
            _vm._v("TIPO DE MONEDA")
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("p", { staticClass: "description-page not-padding" }, [
            _vm._v(
              "\n                Elige el tipo de moneda en la que quieres hacer tu lista y recibir el dinero de tus\n                regalos. Recuerda que deberás proporcionar una cuenta en la misma moneda y que\n                esta información no podrá ser modificada una vez que tu lista haya sido publicada.\n            "
            )
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("div", { staticClass: "wrapper-check-radio" }, [
              _c(
                "label",
                {
                  staticClass: "label-check-radio complete-width",
                  attrs: { for: "currency_1" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currency,
                        expression: "currency"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "custom-check-radio",
                    attrs: {
                      type: "radio",
                      hidden: "",
                      value: "1",
                      name: "currency",
                      id: "currency_1",
                      disabled: this.v_published
                    },
                    domProps: { checked: _vm._q(_vm.currency, "1") },
                    on: {
                      change: function($event) {
                        _vm.currency = "1"
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label-text" }, [
                    _vm._v("S/. - Soles")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "label-check-radio complete-width",
                  attrs: { for: "currency_2" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currency,
                        expression: "currency"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "custom-check-radio",
                    attrs: {
                      type: "radio",
                      hidden: "",
                      value: "2",
                      name: "currency",
                      id: "currency_2",
                      disabled: this.v_published
                    },
                    domProps: { checked: _vm._q(_vm.currency, "2") },
                    on: {
                      change: function($event) {
                        _vm.currency = "2"
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label-text" }, [
                    _vm._v("$ - Dólares Americanos")
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errors.has("currency")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.errors.first("currency")) +
                        "\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "next-content" }, [
          _c("h4", { staticClass: "common-title-section" }, [
            _vm._v("COBRO DE COMISIÓN")
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("p", { staticClass: "description-page not-padding" }, [
            _vm._v(
              "\n                Elige la opción “Nuestros Invitados” y recibe el 100% de tus regalos.\n                Si eliges la opción “Nosotros / yo”, la comisión variable de The Gift List (" +
                _vm._s(_vm.cv_tgl) +
                " %)\n                se descontará de tu monto recaudado. Recuerda que esta información no podrá ser\n                modificada una vez que hayas publicado tu lista.\n            "
            )
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("div", { staticClass: "wrapper-check-radio" }, [
              _c(
                "label",
                {
                  staticClass: "label-check-radio complete-width",
                  attrs: { for: "assume_0" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.assume_commission,
                        expression: "assume_commission"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "custom-check-radio",
                    attrs: {
                      type: "radio",
                      hidden: "",
                      value: "1",
                      name: "assume",
                      id: "assume_0",
                      disabled: this.v_published
                    },
                    domProps: { checked: _vm._q(_vm.assume_commission, "1") },
                    on: {
                      change: function($event) {
                        _vm.assume_commission = "1"
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label-text" }, [
                    _vm._v("Nuestros invitados")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "label-check-radio complete-width",
                  attrs: { for: "assume_1" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.assume_commission,
                        expression: "assume_commission"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "custom-check-radio",
                    attrs: {
                      type: "radio",
                      hidden: "",
                      value: "0",
                      name: "assume",
                      id: "assume_1",
                      disabled: this.v_published
                    },
                    domProps: { checked: _vm._q(_vm.assume_commission, "0") },
                    on: {
                      change: function($event) {
                        _vm.assume_commission = "0"
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label-text" }, [
                    _vm._v("Nosotros / yo")
                  ])
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "common-container-btn content-bnt-and-link flex-mobile"
          },
          [
            _c(
              "a",
              {
                staticClass: "link-cancel-form desktop",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR AL PASO ANTERIOR")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-cancel-form mobile",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "common-green-btn",
                attrs: {
                  type: "submit",
                  disabled: _vm.isEventPublished && !_vm.isFormChanged
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.isEventPublished ? "ACTUALIZAR" : "SIGUIENTE") +
                    "\n                "
                ),
                !_vm.isEventPublished
                  ? _c("span", { staticClass: "arrow-into-btn" })
                  : _vm._e()
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.response_error_message
            ? _c("p", {
                staticClass: "error-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_error_message) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.response_success_message
            ? _c("p", {
                staticClass: "success-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_success_message) }
              })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
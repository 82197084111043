export default {
    first_name: 'nombre',
    last_name: 'apellidos',
    telephone: 'teléfono',
    email: 'correo electrónico',
    address: 'dirección',
    city: 'ciudad',
    password: 'contraseña',
    current_password: 'contraseña actual',
    new_password: 'nueva contraseña',
    confirm_password: 'confirmación de nueva contraseña',
    code: 'código',
    accept_terms: 'términos y condiciones',
    member_1_first_name: 'nombre',
    member_2_first_name: 'nombre',
    member_1_last_name: 'apellidos',
    member_2_last_name: 'apellidos',
    member_1_birthdate: 'fecha de nacimiento',
    member_2_birthdate: 'fecha de nacimiento',
    member_1_email: 'correo electrónico',
    member_2_email: 'correo electrónico',
    member_1_facebook: 'facebook',
    member_2_facebook: 'facebook',
    event_title: 'nombre de la lista',
    event_date: 'fecha de la boda',
    event_location: 'ciudad',
    e_message: 'mensaje',
    e_profile_picture: 'foto de perfil',
    item_cover: 'foto de portada',
    item_name: 'nombre',
    item_price: 'precio',
    item_description: 'descripción',
    item_img_file: 'imagen del regalo',
    select_parent_category: 'categoría',
    select_sub_category: 'subcategoría',
    dni_number: 'número de documento',
    account_number: 'número de la cuenta',
    peruvian_cci: 'código interbancario',
    custom_url: 'URL',
    nickname: 'nombre',
    amount_transfer: 'monto a transferir',
    amount: 'aporte libre',
    reclaimed_amount : 'monto reclamado',
    claim_description: 'descripción',
    claim_type: 'tipo de reclamo',
    claim_order: 'pedido',
    claim_detail: 'detalle',
    document_type: 'tipo de documento',
    subject: 'asunto',
    message: 'mensaje',
    contracted_service: 'bien contratado',
    location_title: 'título',
    location_date: 'fecha del evento',
    location_hour: 'hora',
    location_minutes: 'minutos',
    location_name: 'nombre del lugar',
    location_icon: 'ícono',
    currency: 'tipo de moneda',
    event_type_id: 'tipo de lista',
    country_issuance: 'país de emisión',
    docNumber: 'número de documento',
    cardholderName: 'nombre y apellido'
};

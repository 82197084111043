var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "wrapper-icon-shopping-cart",
        _vm.count_item_in_cart > 0 ? "" : "cart-empty"
      ],
      attrs: {
        "data-toggle": _vm.count_item_in_cart > 0 ? "modal" : "",
        "data-target": "#shoppingCartModal"
      }
    },
    [
      _c("div", { staticClass: "wrapper-count-items" }, [
        _c("span", { staticClass: "count-items" }, [
          _vm._v(_vm._s(_vm.count_item_in_cart))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "icon-cart" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="content-fields-form">

        <form method="post" @submit.prevent="contactUsSubmit">
            <div v-html="csrf_token" hidden></div>

            <h4 class="common-title-section mb-3">CONTÁCTANOS</h4>
            <p class="description-page not-padding mb-4">Ingresa tus datos y te contactaremos en breve.</p>

            <div class="common-content-field">
                <label class="common-label-field">NOMBRE *</label>
                <input type="text" name="first_name"
                       :class="['common-input-text', errors.has('first_name') ? 'error-input-text' : '' ]"
                       placeholder="Ingresa tus nombres y apellidos"
                       v-validate="'required|min:2|max:200'"
                       v-model="name" >

                <p class="message-error-field" v-if="errors.has('first_name')">
                    {{ errors.first('first_name') }}
                </p>
            </div>

            <div>
                <div class="common-content-field half-content half-left">
                    <label class="common-label-field">TELÉFONO *</label>

                    <input type="text" name="telephone"
                           :class="['common-input-text', errors.has('telephone') ? 'error-input-text' : '' ]"
                           placeholder="Ingresa tu teléfono"
                           v-validate="'required|numeric|min:5|max:15'" v-model.number="telephone">

                    <p class="message-error-field" v-if="errors.has('telephone')">
                        {{ errors.first('telephone') }}
                    </p>
                </div>

                <div class="common-content-field half-content half-right">
                    <label class="common-label-field">CORREO ELECTRÓNICO *</label>

                    <input type="text" name="email"
                           :class="['common-input-text', errors.has('email') ? 'error-input-text' : '' ]"
                           placeholder="Ingresa tu correo electrónico"
                           v-validate="'required|email|min:5|max:50'" v-model="email">

                    <p class="message-error-field" v-if="errors.has('email')">
                        {{ errors.first('email') }}
                    </p>
                </div>
            </div>

            <div class="common-content-field">
                <label class="common-label-field">ASUNTO *</label>
                <input type="text" name="subject"
                       :class="['common-input-text', errors.has('subject') ? 'error-input-text' : '' ]"
                       v-validate="'required|min:2|max:200'"
                       v-model="subject" >

                <p class="message-error-field" v-if="errors.has('subject')">
                    {{ errors.first('subject') }}
                </p>
            </div>

            <div class="common-content-field">
                <label class="common-label-field">MENSAJE *</label>
                <textarea type="text" name="message"
                          :class="['common-input-text', errors.has('message') ? 'error-input-text' : '']"
                          v-validate="'required|min:2|max:1000'" v-model="message"
                          rows="5">
                </textarea>

                <p class="message-error-field" v-if="errors.has('message')">
                    {{ errors.first('message') }}
                </p>
            </div>


            <div class="container-btn-and-social-network">
                <div class="content-social-network">
                    <div class="content-network">
                        <div class="img-network d-inline-block n-email"></div>
                        <a :href="'mailto:'+ info_tgl.email_contact" class="d-inline-block name-network">
                            {{ info_tgl.email_contact }}
                        </a>
                    </div>
                    <div class="content-network">
                        <div class="img-network d-inline-block n-fb"></div>
                        <a :href="info_tgl.social_network.FACEBOOK_URL" class="d-inline-block name-network">
                            {{ info_tgl.social_network.FACEBOOK_USERNAME }}
                        </a>
                    </div>
                    <div class="content-network">
                        <div class="img-network d-inline-block n-instg"></div>
                        <a :href="info_tgl.social_network.INSTAGRAM_URL" class="d-inline-block name-network">
                            {{ info_tgl.social_network.INSTAGRAM_USERNAME }}
                        </a>
                    </div>
                </div>
                <button type="submit" class="common-green-btn">ENVIAR</button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>

        </form>

    </div>
</template>

<script>

    import * as contactUsAPI from '../../api/contact-us'

    export default {
        name: "contact-us-page",
        props: ['csrf_token', 'next_url', 'info_tgl'],
        data: () => {
            return {
                response_error_message: null,
                response_success_message: null,
                name: null,
                telephone: null,
                email: null,
                subject: null,
                message: null
            }
        },
        created() {},
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            contactUsSubmit: function() {

                this.$validator.validateAll().finally(() => {
                    if (this.isFormValid) {

                        let data = {
                            name: this.name ? this.name : '',
                            telephone: this.telephone ? this.telephone : '',
                            email: this.email ? this.email : '',
                            subject: this.subject ? this.subject : '',
                            message: this.message ? this.message : ''
                        };

                        let data_json = {data: JSON.stringify(data)};


                        contactUsAPI.sendEmilContactUs(data_json).then((response) => {

                            let is_success = response.data.success;

                            this.response_error_message = is_success ? '' : response.data.message;
                            this.response_success_message = is_success ? response.data.message : '';

                            this.hideMessagesResponse();

                            setTimeout(() => {
                                window.location = this.next_url
                            }, 1000);

                        }).catch((error) => {

                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();

                        });
                    }
                });

            },
            hideMessagesResponse: function(){
                setTimeout(() => {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }, 2000);
            },
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show_first_step
        ? _c("div", [
            _c("div", { staticClass: "content-fields-form" }, [
              _c("p"),
              _vm._v(" "),
              _c("p", { staticClass: "info-form" }, [
                _vm._v(
                  "\n                Para ayudarte a restablecer tu contraseña,\n                por favor escribe el correo electrónico\n                vinculado a tu cuenta en The Gift List.\n            "
                )
              ]),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              _c(
                "form",
                {
                  attrs: { method: "post" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitFirstStep($event)
                    }
                  }
                },
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.csrf_token) }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "content-field" }, [
                    _c("label", { staticClass: "label-field" }, [
                      _vm._v("CORREO ELECTRÓNICO")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.email,
                          expression: "user.email"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email|min:5|max:50",
                          expression: "'required|email|min:5|max:50'"
                        }
                      ],
                      staticClass: "input-text-form-tgl",
                      class: { "error-input-text": _vm.errors.has("email") },
                      attrs: {
                        type: "text",
                        name: "email",
                        placeholder: "Ingresa tu correo electrónico"
                      },
                      domProps: { value: _vm.user.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.user, "email", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("email")
                      ? _c("p", { staticClass: "text-message-error" }, [
                          _vm._v(_vm._s(_vm.errors.first("email")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content-submit content-bnt-recover-pass" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link-cancel-form",
                          attrs: { href: _vm.link_login }
                        },
                        [_vm._v("CANCELAR")]
                      ),
                      _vm._v(" "),
                      _vm._m(0)
                    ]
                  ),
                  _vm._v(" "),
                  _vm.sent_form
                    ? _c("div", [
                        _c("p", {
                          staticClass: "error-submit-form-message",
                          domProps: {
                            innerHTML: _vm._s(_vm.response_form_message)
                          }
                        })
                      ])
                    : _vm._e()
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.show_second_step
        ? _c("recovery-p-enter-code", {
            attrs: { data_second_step: _vm.data_second_step }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "btn-submit-tgl", attrs: { type: "submit" } },
      [
        _vm._v(
          "\n                        SIGUIENTE    \n                        "
        ),
        _c("span", { staticClass: "arrow-btn-form" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
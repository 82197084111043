var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("shopping-cart-icon", {
        attrs: { count_item_in_cart: _vm.count_item_in_cart }
      }),
      _vm._v(" "),
      _c("shopping-cart", {
        attrs: {
          event: _vm.event,
          cart: _vm.cart,
          count_item_in_cart: _vm.count_item_in_cart,
          is_checkout_page: _vm.is_checkout_page,
          event_link: _vm.event_link
        },
        on: {
          update_count_items_cart: _vm.updateCountItemsInCart,
          force_update_checkout_component: _vm.forceUpdateCheckoutComponent
        }
      }),
      _vm._v(" "),
      _vm.show_first_step
        ? _c("checkout-data-order", {
            attrs: {
              event_link: _vm.event_link,
              event_title: _vm.event.title,
              user: _vm.user,
              login_link: _vm.login_link,
              list_document_type: _vm.list_document_type
            },
            on: { update_show_first_step: _vm.updateShowFirstStep }
          })
        : _c("checkout-pay", {
            key: "checkout_pay",
            attrs: {
              csrf_token: _vm.csrf_token,
              event: _vm.event,
              list_items: _vm.list_items,
              cart: _vm.cart,
              event_link: _vm.event_link,
              user: _vm.user,
              payment_method_id: _vm.payment_method_id,
              public_key_culqi: _vm.public_key_culqi,
              public_key_mp: _vm.public_key_mp,
              link_successful: _vm.link_successful
            },
            on: {
              update_count_items_cart: _vm.updateCountItemsInCart,
              update_show_first_step: _vm.updateShowFirstStep,
              update_payment_method: _vm.updatePaymentMethod
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
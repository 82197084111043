var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-item wrapper-add-contribution wrapper-item-added" },
    [
      _c("div", { staticClass: "wrapper-image-card" }, [
        _vm.contrib_is_in_cart
          ? _c("div", { staticClass: "wrapper-bg-added added-to-cart" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper-info-card wrapper-info-height" }, [
        _c("h5", { staticClass: "title-card" }, [_vm._v("Aporte Libre")]),
        _vm._v(" "),
        _c("div", { staticClass: "description-item" }, [
          _vm._v(
            _vm._s(
              _vm.event.contrib_text
                ? _vm.event.contrib_text
                : "Ingresa el monto que quieres regalar"
            )
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "amount-contribution-label" }, [
          _vm._v("MONTO A APORTAR")
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { method: "post" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.addItemToCart($event)
              }
            }
          },
          [
            _c("div", { staticClass: "container-btn-input-contribution" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.amount,
                    expression: "amount",
                    modifiers: { number: true }
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min_value:10",
                    expression: "'required|min_value:10'"
                  }
                ],
                class: [
                  "common-input-text",
                  "currency-symbol",
                  _vm.event.currency.id === 1 ? "soles" : "dolar",
                  _vm.errors.has("amount") ? "error-input-text" : ""
                ],
                attrs: { type: "text", name: "amount" },
                domProps: { value: _vm.amount },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.amount = _vm._n($event.target.value)
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: [
                    "common-green-btn",
                    _vm.contrib_is_in_cart ? "btn-update-quota" : ""
                  ],
                  attrs: {
                    type: "submit",
                    disabled: !(
                      _vm.fields["amount"] && _vm.fields["amount"].valid
                    )
                  }
                },
                [
                  _vm.contrib_is_in_cart
                    ? _c("span", [_vm._v("ACTUALIZAR")])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.event.gift_word
                              ? _vm.event.gift_word
                              : "REGALAR"
                          )
                        )
                      ])
                ]
              )
            ]),
            _vm._v(" "),
            _vm.errors.has("amount")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.errors.first("amount")) +
                      "\n            "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.contrib_is_in_cart
          ? _c("div", { staticClass: "wrapper-add-another-one" }, [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "simple-link add-new-card-link",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.deleteItemFromCart }
                  },
                  [_vm._v("\n                    ELIMINAR\n                ")]
                )
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="wrapper-table-dashboard table-gift-in-list">
        <div class="table-header-dashboard">
            <div class="box-of-table col-name-gift">
                <a href="javascript:void(0)" @click="changeOrderBy('name')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">NOMBRE</a>
            </div>
            <div class="box-of-table col-parent-cat">
                <a href="javascript:void(0)" @click="changeOrderBy('category__parent__label')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">CATEGORÍA</a>
            </div>
            <div class="box-of-table col-cat-name">
                <a href="javascript:void(0)" @click="changeOrderBy('category__label')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">SUBCATEGORÍA</a>
            </div>
            <div class="box-of-table col-num-quota">
                <a href="javascript:void(0)" @click="changeOrderBy('num_quota')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">CUOTAS</a>
            </div>
            <div class="box-of-table col-price">
                <a href="javascript:void(0)" @click="changeOrderBy('price')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">PRECIO TOTAL</a>
            </div>
            <div class="box-of-table col-state">
                <a href="javascript:void(0)" @click="changeOrderBy('status')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">ESTADO</a>
            </div>
        </div>

        <div class="body-header-dashboard">
            <div class="row-body-dashboard" v-for="gift in giftListSorted">
                <div class="box-of-table col-name-gift col-with-sub-title">
                     <div class="sub-title-col">Nombre: </div>
                    <div class="info-body-dashboard is-strong">{{ gift.name }}</div>
                </div>
                <div class="box-of-table col-parent-cat col-with-sub-title">
                    <div class="sub-title-col">Categoría: </div>
                    <div class="info-body-dashboard">{{ gift.category__parent__label }}</div>
                </div>

                <div class="box-of-table col-cat-name col-with-sub-title">
                    <div class="sub-title-col">Subcategoría: </div>
                    <div class="info-body-dashboard">{{ gift.category__label }}</div>
                </div>

                <div class="box-of-table col-num-quota col-with-sub-title">
                    <div class="sub-title-col">Cuotas: </div>
                    <div class="info-body-dashboard" v-if="gift.num_quota > 1">{{ gift.num_quota }}</div>
                    <div class="info-body-dashboard"v-else>Cuota única</div>
                </div>

                <div class="box-of-table col-price col-with-sub-title">
                    <div class="sub-title-col">Precio total: </div>
                    <div class="info-body-dashboard">{{ event.currency.symbol }} {{ gift.price }}</div>
                </div>
                <div class="box-of-table col-state col-with-sub-title">
                    <div class="sub-title-col">Estado: </div>
                    <div class="info-body-dashboard">{{gift.status}}</div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import { sortTableDashboard } from './utils'

    export default {
        name: "gifts-in-list-table",
        props:['data_vue', 'is_dashboard_page'],
        data: () =>  {
            return {
                gifts_in_list: null,
                event: null,
                order_by: null,
                sort_asc: false
            }
        },
        created(){
            this.gifts_in_list = this.data_vue.gifts_in_list;
            this.event = this.data_vue.event;
        },
        computed: {
            giftListSorted() {
                return sortTableDashboard(this.gifts_in_list, this.order_by, this.sort_asc);
            }
        },
        methods:{
            changeOrderBy: function (value){
                if(!this.is_dashboard_page){
                    this.sort_asc = !this.sort_asc;
                    this.order_by = value;
                }
            }
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "wrapper-content-image rounded-circle" }, [
      _c("img", {
        staticClass: "content-image rounded-circle",
        attrs: { alt: _vm.event_title, src: _vm.event_image_url }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content-overlay rounded-circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "content-lock" })
    ]),
    _vm._v(" "),
    _c("h2", { staticClass: "title-event" }, [
      _vm._v("\n        " + _vm._s(_vm.event_title) + "\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "date-event" }, [_vm._v(_vm._s(_vm.event_date))]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.tokenSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("CONTRASEÑA")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.token,
                expression: "token"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "common-input-text",
            class: { "error-input-text": _vm.errors.has("password") },
            attrs: {
              type: "password",
              name: "password",
              autofocus: "",
              placeholder: "Ingresa la contraseña"
            },
            domProps: { value: _vm.token },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.token = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("password")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(_vm._s(_vm.errors.first("password")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "common-container-btn content-bnt-and-link flex-mobile"
          },
          [
            _c(
              "a",
              {
                staticClass: "link-cancel-form",
                attrs: { href: "javascript:history.back();" }
              },
              [_vm._v("CANCELAR")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "common-green-btn",
                attrs: { type: "submit", disabled: !_vm.isFormValid }
              },
              [_vm._v("\n                ACCEDER\n            ")]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.response_error_message
            ? _c("p", {
                staticClass: "error-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_error_message) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.response_success_message
            ? _c("p", {
                staticClass: "success-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_success_message) }
              })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { callAPIPost } from './utils'


export const checkoutAmounts = (event_id, sub_total) => {
    return callAPIPost('/api/checkout/checkout-amounts', { data: JSON.stringify({
            event_id, sub_total
        }) } )
};

export const checkout = (data) => {
    return callAPIPost('/api/checkout/checkout-request', data )
};

export const validationEmail = (email) => {
    return callAPIPost('/api/checkout/validation-email', { email } )
};

<template>
    <div class="wrapper-view-items">

        <shopping-cart-icon :count_item_in_cart="count_item_in_cart" />

        <shopping-cart
            :event="event"
            :cart="cart"
            :count_item_in_cart="count_item_in_cart"
            @update_quota_or_amount_from_cart="updateQuotaOrAmountFromCart"
            @update_count_items_cart="updateCountItemsInCart"
            @update_contrib_is_in_cart="updateContribIsInCart"
            :checkout_link="checkout_link"
        />

        <div v-if="has_items" class="wrapper-options-filter in-preview-page">
            <div class="content-item-filter">
                <input type="text" class="input-search-menu" placeholder="Buscar item" v-model="search_item">
            </div>

            <div class="container-checkbox show-device">
                <input type="checkbox" id="filterByGroup" class="checkbox-form-tgl" v-model="show_only_group">
                <label class="label-field-checkbox label-in-list-item" for="filterByGroup">
                    <span class="text-label-checkbox w-auto">
                        Solo regalos grupales
                    </span>
                </label>
                <span class="icon-help-tool"
                    v-tooltip="{ placement: 'right', title: 'Estos son regalos a los que puedes aportar cuotas sin pagar el monto total.' }">
                </span>
            </div>

            <hr class="hr-tgl show-device hr-tgl-preview">
            <div class="content-item-filter"></div>
            <div class="content-item-filter">
                <category-sidebar
                    ref="category-sidebar"
                    :data_list_category="data_vue.list_category"
                    :is_no_build_list="true"
                    :has_donation_items="data_vue.has_donation_items"
                   >
                </category-sidebar>
            </div>
            <div class="content-item-filter show-device"></div>
            <hr class="hr-tgl show-device hr-tgl-preview">
        </div>
        <div class="wrapper-content-list-item in-preview-page">
            <div v-if="has_items" class="content-open-filter content-filter-select">
                <h2 class="common-title">{{ name_category_selected }}</h2>
                <span class="tab-open-filter show-device mobile" id="openSideFilter">
                    FILTROS
                    <span class="arrow-open-filter"></span>
                </span>
                <div class="filter-to-order-by show-device">
                    <label class="label-order-by">ORDENAR POR</label>
                    <select class="common-select" v-model.number="order_by">
                        <option :value="orders.a_z">A - Z</option>
                        <option :value="orders.price_less_higher">PRECIO: MENOR A MAYOR</option>
                        <option :value="orders.price_higher_less">PRECIO: MAYOR A MENOR</option>
                        <option :value="orders.no_bought">NO REGALADOS</option>
                    </select>
                </div>
            </div>
            <div v-if="has_items" class="content-open-filter show-device mobile">
                <h2 class="common-title" v-if="order_by === orders.a_z">A - Z</h2>
                <h2 class="common-title" v-if="order_by === orders.price_less_higher">PRECIO: MENOR A MAYOR</h2>
                <h2 class="common-title" v-if="order_by === orders.price_higher_less">PRECIO: MAYOR A MENOR</h2>
                <h2 class="common-title" v-if="order_by === orders.no_bought">NO REGALADOS</h2>
                <h2 class="common-title" v-if="show_only_group">SOLO GRUPALES</h2>
            </div>

            <div class="wrapper-list-item">
                <account-contrib v-if="event.is_contribution_account"
                        ref="account-contrib-card"
                        :event="event"
                        :cart="cart"
                        :contrib_is_in_cart="contrib_is_in_cart"
                        :key="'account_contrib'"
                        @update_count_items_cart="updateCountItemsInCart"
                        @update_contrib_is_in_cart="updateContribIsInCart"
                />
                <event-item
                        v-for="item in listItemsFiltered"
                        :key="item.id"
                        :event="event"
                        :item="item"
                        :cart="cart"
                        @update_count_items_cart="updateCountItemsInCart"
                />

                <span class="simple-text" v-if="(!has_items && !event.is_contribution_account)">
                    Esta lista no posee regalos en este momento.
                </span>
            </div>
        </div>
    </div>
</template>

<script>

    import 'bootstrap/js/dist/modal'
    import CategorySidebar from '../../events/CategorySidebar'
    import EventItem from './EventItem'
    import AccountContrib from './AccountContrib'
    import ShoppingCart from './../../shopping_cart/ShoppingCart'
    import * as EventUtils from '../utils'
    import ShoppingCartIcon from '../../shopping_cart/ShoppingCartIcon'

    export default {
        name: "event-list",
        props: ['csrf_token', 'data_vue', 'shopping_cart', 'checkout_link' ],

        data: () =>  {
            return {
                list_items: [],
                event: {
                    id: null,
                    is_contribution_account: null,
                    status: null,
                    currency: {},
                    gift_word: null,
                    quota_word: null
                },
                cart: {
                    items: {},
                    total: 0
                },
                category_selected: 0,
                name_category_selected: EventUtils.ALL_CATEGORY_NAME,
                search_item: '',
                order_by: EventUtils.ORDER_BY_INITIAL,
                orders: EventUtils.ORDER_BY_OPTIONS,
                show_only_group: false,
                contrib_is_in_cart: false,
                count_item_in_cart: 0,
                has_items: false
            }
        },
        watch: {
            'show_only_group': function(){
                if(this.show_only_group){
                    this.setOrderBy(this.orders.only_group);
                }else{
                    this.setOrderBy(this.order_by);
                }
            },
        },
        methods:{
            updateCategoryFromCategory: function (category_id, name_category) {
                this.category_selected = category_id;
                this.name_category_selected = name_category;
            },
            updateOrderByFromCategory: function(order_by){
                if(order_by === this.orders.only_group){
                    this.show_only_group = true;
                }else{
                    this.show_only_group = false;
                    this.order_by = order_by;
                }
            },
            setOrderBy: function(order_by){
                this.$refs['category-sidebar'].updateOrderByFromParent(order_by);
            },
            updateQuotaOrAmountFromCart: function (value, component_key, is_account_contrib){
                let components = this.$children;
                for(let i = 0; i <= components.length; i++){
                    let component = components[i];
                    if(component.$vnode.key !== undefined && component.$vnode.key === component_key){
                        if(is_account_contrib){
                            component.amount = value;
                        }else{
                            component.quota = value;
                        }
                        break;
                    }
                }

            },
            updateCountItemsInCart: function(value){
                this.count_item_in_cart = value;
                if (value > 0) {
                    $('#shoppingCartModal').modal('show');
                }
            },
            updateContribIsInCart: function(value){
                this.contrib_is_in_cart = value;

                if(this.contrib_is_in_cart === false){
                    this.$refs['account-contrib-card'].clearValidatorField();
                }

            }
        },
        created() {
            if(this.data_vue){
                this.list_items = EventUtils.loadEventItems(this.data_vue);
                this.event = this.data_vue.event;
            }
            if(this.shopping_cart){
                EventUtils.loadItemsToCart(this.cart, this.shopping_cart, this.list_items);
                this.count_item_in_cart = Object.keys(this.cart.items).length;
            }

            ( this.list_items.length > 0 ) ? this.has_items = true : this.has_items = false
        },
        computed: {
            listItemsFiltered() {
                const list_items = EventUtils.filterEventItems(
                    this.list_items, this.search_item, this.category_selected, this.show_only_group
                );
                return EventUtils.sortEventItems(list_items, this.order_by, this.data_vue.list_category);
            }
        },
        components: { CategorySidebar, EventItem, AccountContrib, ShoppingCartIcon, ShoppingCart },
    }
</script>

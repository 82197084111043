<template>
    <div>
        <GoogleMapAutocomplete v-if="google && map"
                               :google="google"
                               :map="map"
                               :location="location"
                               :show="show"
                               @add-marker="addMarker"
                               @change-autocomplete="changeAutoComplete"
                               @validate-autocomplete="validateAutoComplete"
        />
        <div class="common-content-field wrapper-google-maps" style="position: relative">
            <div v-if="!show" class="google-map-deactivated"></div>
            <div class="google-map" ref="googleMap"></div>
        </div>
    </div>
</template>

<script>

import loadGoogleMapsApi  from 'load-google-maps-api'
import GoogleMapAutocomplete from './GoogleMapAutocomplete';

export default {
    props: {
        mapConfig: Object,
        apiKey: String,
        location: Object,
        show: Boolean
    },
    components: {
        GoogleMapAutocomplete
    },
    data() {
        return {
            google: null,
            map: null,
            markersObj: [],
            autoCompleteChanged: false,
            autoCompleteValidated: false,
            latitudeChanged: false,
            longitudeChanged: false,
            initialLatitude: null,
            initialLongitude: null,
        }
    },
    async mounted() {
        const googleMapApi = await loadGoogleMapsApi({
            key: this.apiKey,
            libraries: ['places'],
            language: 'es'
        });
        this.google = googleMapApi;
        this.initializeMap();
    },
    methods: {
        initializeMap() {
            const self = this;

            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.Map(
                mapContainer, this.mapConfig
            );

            this.map.addListener('click', function (event) {
                self.addMarker(event.latLng, true);
            });

            if (this.location.latitude && this.location.longitude) {
                this.addMarker({
                    lat: this.location.latitude,
                    lng: this.location.longitude
                })
            }
        },
        addMarker: function (e, fromMap) {
            this.cleanMarkers();
            this.markersObj.push(new this.google.Marker({
                position: e,
                map: this.map,
            }));
            this.map.setCenter(e);
            this.map.setZoom(16);
            this.location.latitude = e.lat;
            this.location.longitude = e.lng;
            if (fromMap) {
                this.location.latitude = e.lat();
                this.location.longitude = e.lng();
            }
        },
        cleanMarkers: function () {
            this.setMapOnAll(null)
        },
        setMapOnAll: function(map) {
            const self = this;
            for (let i = 0; i < self.markersObj.length; i++) {
                self.markersObj[i].setMap(map);
            }
        },
        changeAutoComplete: function (value) {
            this.autoCompleteChanged = value;
        },
        validateAutoComplete: function (value) {
            this.autoCompleteValidated = value;
        }
    },
    computed: {
        latitude: function() {
            return this.location.latitude;
        },
        longitude: function() {
            return this.location.longitude;
        },
        isFormChanged: function() {
            return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed) ||
                this.autoCompleteChanged || this.latitudeChanged || this.longitudeChanged;
        },
        isFormValid: function() {
            return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid) &&
                this.autoCompleteValidated && (this.location.latitude != null) && (this.location.longitude != null);
        },
    },
    watch: {
        latitude: function (value) {
            if (value !== this.initialLatitude) {
                this.latitudeChanged = true;
            }
            else {
                this.latitudeChanged = false;
            }
        },
        longitude: function (value) {
            if (value !== this.initialLongitude) {
                this.longitudeChanged = true;
            }
            else {
                this.longitudeChanged = false;
            }
        },
        isFormChanged: function (value) {
            this.$emit('change-map', value);
        },
        isFormValid: function (value) {
            this.$emit('validate-map', value);
        }
    },
    created() {
        if (this.location.latitude) {
            this.initialLatitude = this.location.latitude;
        }
        if (this.location.longitude) {
            this.initialLongitude = this.location.longitude;
        }
    }
}
</script>

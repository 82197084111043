<template>
    <div class="wrapper-fixed-bottom-bar" id="fixedBottomBar">
        <div class="container-fluid">
            <div class="container">
                <div class="content-elements">
                    <a v-bind:href="before_step" class="link-cancel-form link-desktop">REGRESAR AL PASO ANTERIOR</a>
                    <a v-bind:href="before_step" class="link-cancel-form link-mobile">REGRESAR</a>

                    <div class="info-items-added">
                        Número de regalos agregados: <span class="count-item-added">({{ items_added_count }})</span>
                        <br>
                        <strong>Total: {{ event.currency.symbol }} {{ total_amount_items_added }}</strong>
                    </div>

                    <button @click="activateNextStep" class="common-green-btn"
                            v-if="event.status_step === 4">
                        SIGUIENTE
                        <span class="arrow-into-btn"></span>
                    </button>
                    <a v-bind:href="next_link" class="common-green-btn"
                       v-if="event.status_step > 4">
                        SIGUIENTE
                        <span class="arrow-into-btn"></span>
                    </a>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as eventsAPI from '../../api/events'
    import * as EventUtils from './utils'

    export default {
        name: "fixed-bottom-bar",
        props: ['before_step', 'total_amount_items_added', 'items_added_count', 'event', 'next_link'],
        methods:{
            activateNextStep: function(){

                eventsAPI.storeList(this.event.id).then( (response) => {

                    let response_success = response.data.success;

                    if(response_success){
                        window.location = this.next_link;
                    }

                })
            }
        },
        created() {
        },

    }
</script>

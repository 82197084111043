<template>

    <div class="wrapper-table-dashboard">
        <div class="table-header-dashboard">
            <div class="box-of-table col-date-order">
                <a href="javascript:void(0)" @click="changeOrderBy('order__payment_date')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">FECHA</a>
            </div>
            <div class="box-of-table col-name-order">
                <a href="javascript:void(0)" @click="changeOrderBy('order__user_alias')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">NOMBRE</a>
            </div>
            <div class="box-of-table col-name-order-gift">
                <a href="javascript:void(0)" @click="changeOrderBy('order_item__name')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">REGALO</a>
            </div>
            <div class="box-of-table col-num-quota">
                <a href="javascript:void(0)" @click="changeOrderBy('quota')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">CUOTAS</a>
            </div>
            <div class="box-of-table">
                <a href="javascript:void(0)" @click="changeOrderBy('price')"
                   :class="['label-header-dashboard', is_dashboard_page ? '' : 'no-dashboard']">MONTO</a>
            </div>
        </div>

        <div class="body-header-dashboard">
            <div class="row-body-dashboard" v-for="gift in giftListSorted">
                <div class="box-of-table col-date-order">
                    <div class="info-body-dashboard">
                        {{ gift.order__payment_date }}
                    </div>
                </div>
                <div class="box-of-table col-name-order">
                    <div class="info-body-dashboard">{{ gift.order__user_alias }}</div>
                </div>
                <div class="box-of-table col-name-order-gift col-with-sub-title">
                    <div class="sub-title-col">Regalo: </div>
                    <div class="info-body-dashboard" v-if="gift.order_item_type_id == item_type.CONTRIB">
                        Aporte libre
                    </div>
                    <div class="info-body-dashboard" v-else>
                        <span v-if="gift.order_item__is_donation_gift == true">
                            <strong>{{ gift.order_item__name }}</strong>
                        </span>
                        <span v-else>{{ gift.order_item__name }}</span>
                    </div>
                </div>
                <div class="box-of-table col-num-quota col-with-sub-title">
                    <div class="sub-title-col">Cuotas: </div>
                    <div class="info-body-dashboard" v-if="gift.order_item_type_id == item_type.QUOTAS">
                        {{ gift.quota }}/{{ gift.order_item__num_quota }}
                    </div>
                    <div class="info-body-dashboard" v-else>-</div>
                </div>
                <div class="box-of-table col-with-sub-title">
                    <div class="sub-title-col">Monto: </div>
                    <div class="info-body-dashboard">{{ event.currency.symbol }} {{ gift.price }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { sortTableDashboard } from './utils'

    export default {
        name: "received-gifts-table",
        props:['data_vue', 'is_dashboard_page'],
        data: () =>  {
            return {
                list_gifts: null,
                item_type: null,
                event: null,
                order_by: null,
                sort_asc: false
            }
        },
        created(){
            this.list_gifts = this.data_vue.list_received_gifts;
            this.item_type = this.data_vue.item_type;
            this.event = this.data_vue.event;
        },
        computed: {
            giftListSorted() {
                return sortTableDashboard(this.list_gifts, this.order_by, this.sort_asc);
            }
        },
        methods:{
            changeOrderBy: function (value){
                if(!this.is_dashboard_page){
                    this.sort_asc = !this.sort_asc;
                    this.order_by = value;
                }
            }
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.publishSubmit($event)
          }
        }
      },
      [
        _c("div", {
          attrs: { hidden: "" },
          domProps: { innerHTML: _vm._s(_vm.csrf_token) }
        }),
        _vm._v(" "),
        _c("h4", { staticClass: "common-title-section" }, [
          _vm._v("URL PERSONALIZADA")
        ]),
        _vm._v(" "),
        _c("p"),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("p"),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "common-content-field" }, [
            _c("div", { staticClass: "content-label-left" }, [
              _c("span", { staticClass: "label-custom-url" }, [
                _vm._v("www.thegiftlist.pe/")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:50",
                    expression: "'required|min:2|max:50'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.event.url,
                    expression: "event.url"
                  }
                ],
                class: [
                  "common-input-text",
                  "input-custom-url",
                  _vm.errors.has("custom_url") || _vm.is_invalid_url
                    ? "error-input-text invalid"
                    : "",
                  _vm.is_valid_url ? "valid" : ""
                ],
                attrs: {
                  type: "text",
                  name: "custom_url",
                  placeholder: "ej. michiycarlos"
                },
                domProps: { value: _vm.event.url },
                on: {
                  keyup: _vm.validateURL,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.event, "url", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.errors.has("custom_url")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("custom_url")) +
                      "\n                "
                  )
                ])
              : _vm.is_invalid_url
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm.is_taken_url
                    ? _c("span", [
                        _vm._v(
                          "La URL personalizada que has ingresado ya está en uso. Prueba con otra opción."
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "No se permiten espacios, mayúculas, caracteres especiales ni la letra ñ en la URL personalizada."
                        )
                      ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "common-content-field" }, [
              _c("div", { staticClass: "container-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.event.is_private,
                      expression: "event.is_private"
                    },
                    { name: "validate", rawName: "v-validate" }
                  ],
                  staticClass: "checkbox-form-tgl",
                  attrs: {
                    type: "checkbox",
                    id: "isPrivateEvent",
                    name: "is_private"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.event.is_private)
                      ? _vm._i(_vm.event.is_private, null) > -1
                      : _vm.event.is_private
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.event.is_private,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.event, "is_private", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.event,
                              "is_private",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.event, "is_private", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        placement: "right",
                        title:
                          "Elige esta opción si quieres que tus invitados necesiten una clave para ingresar a tu lista."
                      },
                      expression:
                        "{ placement: 'right', title: 'Elige esta opción si quieres que tus invitados necesiten una clave para ingresar a tu lista.' }"
                    }
                  ],
                  staticClass: "icon-help-tool"
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.event.is_private
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "common-content-field half-content half-left"
                  },
                  [
                    _c("label", { staticClass: "common-label-field" }, [
                      _vm._v("CONTRASEÑA")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value:
                            "" +
                            (_vm.event.is_private ? "required|min:2" : "min:2"),
                          expression:
                            "`${event.is_private ? 'required|min:2' : 'min:2'}`"
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.event.token,
                          expression: "event.token"
                        }
                      ],
                      class: [
                        "common-input-text",
                        _vm.errors.has("password") ? "error-input-text" : ""
                      ],
                      attrs: {
                        type: "text",
                        name: "password",
                        placeholder: "Ingresa una contraseña"
                      },
                      domProps: { value: _vm.event.token },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.event, "token", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("password")
                      ? _c("p", { staticClass: "message-error-field" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.errors.first("password")) +
                              "\n                    "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "common-content-field" }, [
              _c("div", { staticClass: "container-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.event.is_permission_share,
                      expression: "event.is_permission_share"
                    },
                    { name: "validate", rawName: "v-validate" }
                  ],
                  staticClass: "checkbox-form-tgl",
                  attrs: {
                    type: "checkbox",
                    id: "isPermissionShare",
                    name: "is_permission_share"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.event.is_permission_share)
                      ? _vm._i(_vm.event.is_permission_share, null) > -1
                      : _vm.event.is_permission_share
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.event.is_permission_share,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.event,
                              "is_permission_share",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.event,
                              "is_permission_share",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.event, "is_permission_share", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "label-field-checkbox ",
                    attrs: { for: "isPermissionShare" }
                  },
                  [
                    _c("span", { staticClass: "text-label-checkbox w-auto" }, [
                      _vm._v(
                        "\n                                Autorizo a The Gift List a publicar mi foto en redes sociales.\n                                 "
                      ),
                      _c("span", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              placement: "right",
                              title:
                                "Elige esta opción si nos autorizas a compartir tu lista y tu foto de pefil en las redes sociales de The Gift List."
                            },
                            expression:
                              "{ placement: 'right', title: 'Elige esta opción si nos autorizas a compartir tu lista y tu foto de pefil en las redes sociales de The Gift List.' }"
                          }
                        ],
                        staticClass: "icon-help-tool"
                      })
                    ])
                  ]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "common-container-btn content-bnt-and-link flex-mobile"
          },
          [
            _c(
              "a",
              {
                staticClass: "link-cancel-form desktop",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR AL PASO ANTERIOR")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-cancel-form mobile",
                attrs: { href: _vm.before_step }
              },
              [_vm._v("REGRESAR")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "common-green-btn",
                attrs: {
                  type: "submit",
                  disabled: _vm.isEventPublished && !_vm.isFormChanged
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.isEventPublished ? "ACTUALIZAR" : "PUBLICAR") +
                    "\n            "
                )
              ]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _vm.response_error_message
        ? _c("p", {
            staticClass: "error-submit-form-message",
            domProps: { innerHTML: _vm._s(_vm.response_error_message) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.response_success_message
        ? _c("p", {
            staticClass: "success-submit-form-message",
            domProps: { innerHTML: _vm._s(_vm.response_success_message) }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "description-page not-padding" }, [
      _vm._v(
        "\n            Personaliza el URL de tu página. Con esta información será más fácil que tus\n            invitados encuentren tu lista.\n            "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n            La URL personalizada solo permite letras minúsculas (sin tildes) y números, con\n            excepción de la letra ñ.\n        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label-field-checkbox", attrs: { for: "isPrivateEvent" } },
      [
        _c("span", { staticClass: "text-label-checkbox w-auto" }, [
          _vm._v(
            "\n                            Es una lista privada.\n                        "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
import { callAPIPost } from './utils'


export const addOrUpdateItem = (event_id, item_id, quota, price) => {
    return callAPIPost('/api/shopping-cart/add-item', { event_id, item_id, quota, price })
};

export const deleteItem = (event_id, item_id) => {
    return callAPIPost('/api/shopping-cart/delete-item', { event_id, item_id })
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-view-items" },
    [
      _vm.has_items
        ? _c("div", { staticClass: "wrapper-options-filter in-preview-page" }, [
            _c("div", { staticClass: "content-item-filter" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search_item,
                    expression: "search_item"
                  }
                ],
                staticClass: "input-search-menu",
                attrs: { type: "text", placeholder: "Buscar item" },
                domProps: { value: _vm.search_item },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search_item = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container-checkbox show-device" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.show_only_group,
                    expression: "show_only_group"
                  }
                ],
                staticClass: "checkbox-form-tgl",
                attrs: { type: "checkbox", id: "filterByGroup" },
                domProps: {
                  checked: Array.isArray(_vm.show_only_group)
                    ? _vm._i(_vm.show_only_group, null) > -1
                    : _vm.show_only_group
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.show_only_group,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.show_only_group = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.show_only_group = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.show_only_group = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      placement: "right",
                      title:
                        "Estos son regalos a los que puedes aportar cuotas sin pagar el monto total."
                    },
                    expression:
                      "{ placement: 'right', title: 'Estos son regalos a los que puedes aportar cuotas sin pagar el monto total.' }"
                  }
                ],
                staticClass: "icon-help-tool"
              })
            ]),
            _vm._v(" "),
            _c("hr", { staticClass: "hr-tgl show-device hr-tgl-preview" }),
            _vm._v(" "),
            _c("div", { staticClass: "content-item-filter" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-item-filter" },
              [
                _c("category-sidebar", {
                  ref: "category-sidebar",
                  attrs: {
                    data_list_category: _vm.data_vue.list_category,
                    is_no_build_list: true,
                    has_donation_items: _vm.data_vue.has_donation_items
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content-item-filter show-device" }),
            _vm._v(" "),
            _c("hr", { staticClass: "hr-tgl show-device hr-tgl-preview" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper-content-list-item in-preview-page" }, [
        _vm.has_items
          ? _c(
              "div",
              { staticClass: "content-open-filter content-filter-select" },
              [
                _c("h2", { staticClass: "common-title" }, [
                  _vm._v(_vm._s(_vm.name_category_selected))
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "filter-to-order-by show-device" }, [
                  _c("label", { staticClass: "label-order-by" }, [
                    _vm._v("ORDENAR POR")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.order_by,
                          expression: "order_by"
                        }
                      ],
                      staticClass: "common-select",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.order_by = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: _vm.orders.a_z } }, [
                        _vm._v("A - Z")
                      ]),
                      _vm._v(" "),
                      _c(
                        "option",
                        { domProps: { value: _vm.orders.price_less_higher } },
                        [_vm._v("PRECIO: MENOR A MAYOR")]
                      ),
                      _vm._v(" "),
                      _c(
                        "option",
                        { domProps: { value: _vm.orders.price_higher_less } },
                        [_vm._v("PRECIO: MAYOR A MENOR")]
                      ),
                      _vm._v(" "),
                      _c(
                        "option",
                        { domProps: { value: _vm.orders.no_bought } },
                        [_vm._v("NO REGALADOS")]
                      )
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.has_items
          ? _c(
              "div",
              { staticClass: "content-open-filter show-device mobile" },
              [
                _vm.order_by === _vm.orders.a_z
                  ? _c("h2", { staticClass: "common-title" }, [_vm._v("A - Z")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order_by === _vm.orders.price_less_higher
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("PRECIO: MENOR A MAYOR")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order_by === _vm.orders.price_higher_less
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("PRECIO: MAYOR A MENOR")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order_by === _vm.orders.no_bought
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("NO REGALADOS")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.show_only_group
                  ? _c("h2", { staticClass: "common-title" }, [
                      _vm._v("SOLO GRUPALES")
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wrapper-list-item" },
          [
            _vm.event.is_contribution_account
              ? _c(
                  "div",
                  { staticClass: "wrapper-item wrapper-add-contribution" },
                  [
                    _c("div", { staticClass: "wrapper-image-card" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "wrapper-info-card" }, [
                      _c("h5", { staticClass: "title-card" }, [
                        _vm._v("Aporte Libre")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-item" }, [
                        _vm._v("Ingresa el monto que quieres regalar")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "amount-contribution-label" }, [
                        _vm._v("MONTO A APORTAR")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "container-btn-input-contribution" },
                        [
                          _c("input", {
                            class: [
                              "common-input-text",
                              "currency-symbol",
                              _vm.event.currency.id == 1 ? "soles" : "dolar"
                            ],
                            attrs: { type: "text" }
                          }),
                          _vm._v(" "),
                          _c("button", { staticClass: "common-green-btn" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.event.gift_word
                                    ? _vm.event.gift_word
                                    : "REGALAR"
                                ) +
                                "\n                        "
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.listItemsFiltered, function(item) {
              return _c(
                "div",
                { staticClass: "wrapper-item wrapper-item-added" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wrapper-image-card",
                      style: { backgroundImage: "url(" + item.image + ")" }
                    },
                    [
                      item.is_donation_gift
                        ? _c(
                            "div",
                            { staticClass: "wrapper-item-is-donation" },
                            [_c("div", { staticClass: "img-solidary" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isItemAvailable(item)
                        ? _c("div", { staticClass: "wrapper-bg-bought" })
                        : _c(
                            "div",
                            { staticClass: "wrapper-buttons-card text-center" },
                            [
                              !_vm.isItemPurchased(item)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "delete-item-link",
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(item.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            ELIMINAR\n                        "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "common-green-btn",
                                  attrs: {
                                    "data-toggle": "modal",
                                    "data-target": "#storeItemModal"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.loadUpdateDataModal(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            EDITAR\n                        "
                                  )
                                ]
                              )
                            ]
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [
                        "wrapper-info-card",
                        !_vm.isItemAvailable(item)
                          ? "wrapper-info-card-bought"
                          : ""
                      ]
                    },
                    [
                      item.is_donation_gift
                        ? _c("h5", { staticClass: "title-card with-tooltip" }, [
                            _c("span", { staticClass: "title-span-card" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    placement: "right",
                                    title:
                                      "El valor de este regalo se donará a esta organizacion."
                                  },
                                  expression:
                                    "{placement: 'right', title: 'El valor de este regalo se donará a esta organizacion.' }"
                                }
                              ],
                              staticClass: "icon-help-tool"
                            })
                          ])
                        : _c("h5", { staticClass: "title-card" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                      _vm._v(" "),
                      item.description
                        ? _c("div", { staticClass: "description-item" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.description) +
                                "\n                    "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.num_quota > 1
                        ? _c("div", { staticClass: "price-item" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.event.currency.symbol) +
                                _vm._s(item.price_quota) +
                                " por cuota\n                    "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.num_quota > 1
                        ? _c("div", { staticClass: "total-price" }, [
                            _vm._v(
                              "PRECIO TOTAL: " +
                                _vm._s(_vm.event.currency.symbol) +
                                _vm._s(item.price)
                            )
                          ])
                        : _c("div", { staticClass: "price-item" }, [
                            _vm._v(
                              _vm._s(_vm.event.currency.symbol) +
                                _vm._s(item.price)
                            )
                          ]),
                      _vm._v(" "),
                      item.num_quota > 1
                        ? _c("div", { staticClass: "wrapper-progress-bar" }, [
                            _c(
                              "div",
                              { staticClass: "container-progress-bar" },
                              [
                                _c("div", {
                                  staticClass: "progress-bar-count",
                                  style: {
                                    width: _vm.progressBarWidth(item) + "%"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "info-progress-bar" }, [
                              _vm._v(_vm._s(_vm.availableQuotaString(item)))
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            _vm._v(" "),
            !_vm.has_items && !_vm.event.is_contribution_account
              ? _c("span", { staticClass: "simple-text" }, [
                  _vm._v(
                    "\n                Esta lista no posee regalos en este momento.\n            "
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("store-item-modal", {
        ref: "store-item-modal",
        attrs: {
          data_list_category: _vm.data_vue.list_category,
          currency: _vm.event.currency,
          csrf_token: _vm.csrf_token,
          event_id: _vm.event.id
        },
        on: { "item-updated": _vm.onItemUpdate }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "label-field-checkbox label-in-list-item",
        attrs: { for: "filterByGroup" }
      },
      [
        _c("span", { staticClass: "text-label-checkbox w-auto" }, [
          _vm._v(
            "\n                    Solo regalos grupales\n                "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "tab-open-filter show-device mobile",
        attrs: { id: "openSideFilter" }
      },
      [
        _vm._v("\n                FILTROS\n                "),
        _c("span", { staticClass: "arrow-open-filter" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
function hasFieldValue(value) {
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }
  if (value === undefined || value === null) {
    return false;
  }
  return !!String(value).trim().length;
}

const getMessage = (field) => 'El campo ' + field + ' es obligatorio.';
const validate = (value, { targetValue }) => {
  const targetHasValue = hasFieldValue(targetValue);
  if (targetHasValue) {
    return hasFieldValue(value);
  }
  return true;
};

export default {
  getMessage,
  validate
};

export const ORDER_BY_OPTIONS = {
    'a_z': 1,
    'price_less_higher': 2,
    'price_higher_less': 3,
    'no_bought': 4,
    'only_group': 5,
};

export const event_status = {
    NOT_PUBLISHED: 0,
    PUBLISHED: 1,
    HIDDEN: 2,
    CLOSED: 3,
    DELETED: 4
};

export const urls_not_allowed = [
    'api',
    'admin',
    'users',
    'pages',
    'events',
    'search',
    'libro',
    'contact'
];

export const ORDER_BY_INITIAL = 1;

export const ALL_CATEGORY_NAME = 'TODAS LAS CATEGORÍAS';
export const DONATION_ITEMS_CAT_NAME = 'REGALO SOLIDARIO';

export const loadEventItems = (data_vue) => {
    return data_vue.list_items.map((item) => {
        item.image = data_vue.MEDIA_URL + item.image;
        if (item.original_item) {
            item.original_item.image = data_vue.MEDIA_URL + item.original_item.image;
        }
        /* we need to add this property here so that is works in a reactive way when used from EventItem.vue */
        item.is_in_cart = false;
        return item;
    });
};

export const isItemAvailable = (item) => {
    return item.available_quota > 0;
};

export const isItemPurchased = (item) => {
    return !!item.id && (item.num_quota > item.available_quota || item.is_added_to_cart);
};

export const progressBarWidth = (item) => {
    let purchased_quotas = item.purchased_quota + item.reserved_quota;
    if(purchased_quotas >= item.num_quota){
        return 100;
    }
    return (purchased_quotas * 100) / item.num_quota;
};

export const availableQuotaString = (item, quota_word) => {

    let text = '';

    if(item.available_quota === 1){
        text = 'FALTA 1 ' + (quota_word ? quota_word.toUpperCase() : 'CUOTA')
    }else if(item.available_quota > 1){
        text = 'FALTAN ' + item.available_quota + ' ' + (quota_word ? quota_word.toUpperCase() : 'CUOTA') + 'S'
    }else{
        text = 'REGALADO'
    }

    return text;
};

export const sortEventItems = (list_items, order_by, list_category) => {
    const itemOrderCompare = (a, b) => {

        const getItemPrice = item => item.num_quota > 1 ? item.price_quota : item.price;

        const getIndexInCategoryList = id => {
            let i = 0;
            for (let key in list_category) {
                if (list_category.hasOwnProperty(key) && id === list_category[key].id) {
                    break;
                }
                i++;
            }
            return i;
        };

        const getNameForSorting = item => {
            let prefix = '';
            if (item.donation_company__name) {
                prefix = item.donation_company__name.toLowerCase();
            } else if (item.company_item__name) {
                prefix = item.company_item__name.toLowerCase();
            }
            return prefix + item.name.toLowerCase()
        }

        switch (order_by) {
            case ORDER_BY_OPTIONS.price_less_higher:
                return getItemPrice(a) - getItemPrice(b);
            case ORDER_BY_OPTIONS.price_higher_less:
                return getItemPrice(b) - getItemPrice(a);
            case ORDER_BY_OPTIONS.no_bought:
                let a_is_available = isItemAvailable(a);
                let b_is_available = isItemAvailable(b);

                if(a_is_available === b_is_available) return 0;
                if(b_is_available && !a_is_available) return 1;
                return -1;
            default:
                if (list_category && a.category_id__parent_id && b.category_id__parent_id) {
                    if (a.category_id__parent_id !== b.category_id__parent_id) {
                        return getIndexInCategoryList(a.category_id__parent_id) - getIndexInCategoryList(b.category_id__parent_id);
                    }
                }

                return getNameForSorting(a).localeCompare(getNameForSorting(b));
        }
    };

    return list_items.sort( itemOrderCompare );
};

export const filterEventItems = (list_items, search_text, selected_category, show_group_only, show_only_added) => {
    return list_items.filter(item => {

        if (!show_only_added || item.is_item_added) {
            if (item.name.toLowerCase().includes(search_text.toLowerCase())){
                if( selected_category === 0 ||
                    selected_category === item.category_id ||
                    selected_category === item.category_id__parent_id ||
                    (selected_category === -1 && item.is_donation_gift) ) {

                    if(show_group_only){
                        return item.num_quota > 1;
                    }
                    return true;
                }

                return false;
            }

            return false;
        }

        return false;
    });
};

export const updateEventItemInList = (item_list, updated_item, media_url) => {
    for(let item of item_list) {
        if (item.id === updated_item.id) {
            item.category_id = updated_item.category_id;
            item.category_id__parent_id = updated_item.category_id__parent_id;
            item.name = updated_item.name;
            item.description = updated_item.description;
            item.price = updated_item.price;
            item.image = media_url + updated_item.image;
            item.price_quota = updated_item.price_quota;
            item.num_quota = updated_item.num_quota;
            item.available_quota = updated_item.available_quota;
            item.reserved_quota = updated_item.reserved_quota;
            item.purchased_quota = updated_item.purchased_quota;

            break;
        }
    }
};

export const removeEventItemFromList = (item_list, event_item_id) => {
    let index_to_remove = null;
    let removed_item = null;
    for(const [index, item] of item_list.entries()) {
        if (item.id === event_item_id && item.is_item_added) {
            index_to_remove = index;
            removed_item = item;
            break;
        }
    }
    if (index_to_remove !== null) {
        item_list.splice(index_to_remove, 1);
    }
    if (removed_item.original_item) {
        let original_item_in_list = false;
        for(const [index, item] of item_list.entries()) {
            if ((item.is_item_added && item.item_id === removed_item.item_id) ||
                (!item.is_item_added && item.id === removed_item.item_id)) {
                original_item_in_list = true;
                break;
            }
        }
        if(!original_item_in_list) {
            item_list.push(removed_item.original_item);
        }
    }
    return removed_item;
};

export const removeItemFromList = (item_list, item_id, is_donation_gift) => {
    let index_to_remove = null;
    let removed_item = null;
    for(const [index, item] of item_list.entries()) {
        if (((is_donation_gift && item.is_donation && item.id === item_id) ||
            (!is_donation_gift && item.id === item_id)) && !item.is_item_added) {
            index_to_remove = index;
            removed_item = item;
            break;
        }
    }
    if (index_to_remove !== null) {
        item_list.splice(index_to_remove, 1);
    }
    return removed_item;
};

export const roundPrice = (price) => {
    return Math.round((price) * 100) / 100
};

export const loadItemsToCart = (cart, shopping_cart, list_items) => {
    for (const item_id in shopping_cart) {
        let item = null;
        if (item_id !== '0') {
            item = list_items.filter(item => item.id == item_id).pop();
            item.is_in_cart = true;
        }
        cart.items[item_id] = {
            quota: shopping_cart[item_id].quota,
            price: roundPrice(shopping_cart[item_id].price),
            item: item ? item : null
        };
        cart.total += shopping_cart[item_id].price;
    }

    cart.total = roundPrice(cart.total);
};

export const addorUpdateCartItem = (cart, item, quota, price) => {
    let item_id = item ? item.id : 0;
    if (item) {
        item.is_in_cart = true;
    } else {
        quota = 1;
    }

    if (cart.items[item_id]) {
        cart.total -= cart.items[item_id].price;
    }
    cart.items[item_id] = {
        quota: quota,
        price: price,
        item: item ? item : null
    };
    cart.total += price;
    cart.total = roundPrice(cart.total);
};

export const deleteCartItem = (cart, item) => {
    let item_id = item ? item.id : 0;
    if (item) {
        item.is_in_cart = false;
    }

    if (cart.items[item_id]) {
        cart.total -= cart.items[item_id].price;

        if(cart.total > 0){
            cart.total = roundPrice(cart.total);
        }else{
             cart.total = 0;
        }

        delete cart.items[item_id];
    }
};

export const sortTableDashboard = (list_gifts, order_by, sort_asc) => {

    if(order_by === null){
        return list_gifts;
    }

    let valueA, valueB = null;
    let property_with_number = ['price', 'quota', 'num_quota'];

    let key_quota_e_item = order_by === 'quota' ? 'order_item__num_quota' : '';

    const getItemName = (name) => {
        return name == null ? 'aporte libre' : name;
    };

    const sortRowTable = (a, b) => {

        if(property_with_number.indexOf(order_by) > -1){

            let a_value = a[order_by];
            let b_value = b[order_by];

            if(key_quota_e_item){
                a_value = a[key_quota_e_item] > 1 ? a[order_by] :  0;
                b_value = b[key_quota_e_item] > 1 ? b[order_by] :  0;
            }

            return sort_asc ? a_value - b_value : b_value - a_value;

        }else if(order_by === 'order__payment_date'){
            valueA = new Date(a[order_by]);
            valueB = new Date(b[order_by]);

            return sort_asc ? valueA - valueB : valueB - valueA;
        }

        if (order_by === 'order_item__name'){
            valueA = getItemName(a[order_by]);
            valueB = getItemName(b[order_by]);
        } else {
            valueA = a[order_by].toLowerCase();
            valueB = b[order_by].toLowerCase();
        }

        return sort_asc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);

    };

    return list_gifts.sort( sortRowTable );
};
<template>
    <div>
        <form method="post" @submit.prevent="checkoutSubmit">
            <div v-html="csrf_token" hidden></div>
            <h2 class="common-title">CHECKOUT (2/2)</h2>
            <div class="common-wrapper-content-page">
                <div class="common-container-content-page">

                    <h4 class="common-title-section">RESUMEN DE COMPRA</h4>

                    <div>

                        <div class="wrapper-shopping-cart is-checkout-page">
                            <div class="wrapper-cart-modal">
                                <div class="content-cart-modal">
                                    <div :class="['wrapper-items-in-cart', event.guest_assume_commission ? '' : 'is-checkout-page']">

                                        <item-cart
                                                v-for="(item, i_key in cart.items"
                                                :key="(parseInt(i_key) === 0) ? 'account_contrib' : parseInt(i_key)"
                                                :event="event"
                                                :item_cart="item"
                                                :cart="cart"
                                                :is_checkout_page="is_checkout_page"
                                                @update_count_items="updateCountItems"
                                                @update_checkout_component="updateCheckoutComponent"
                                        />

                                        <div class="wrapper-footer-cart is-subtotal">
                                            <div class="wrapper-total-cart">
                                                <div class="total-text">SUBTOTAL</div>
                                                <div class="total-price-cart">
                                                    {{ event.currency.symbol }}{{ cart.total }}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="accordion" id="accordionCheckout">
                                        <div class="card" v-if="event.currency.id == CURRENCY_SOLES_ID">
                                            <label for="creditCardOneOption" id="headingCreditCardOne"
                                                   class="card-header accordion-checkout-header" data-toggle="collapse"
                                                   data-target="#collapseCreditCardOne" aria-expanded="false"
                                                   aria-controls="collapseCreditCardOne">
                                                <div class="container-checkout-option">
                                                    <div class="container-radio-option">
                                                        <input class="form-check-input" type="radio" name="exampleRadios"
                                                               id="creditCardOneOption" value="3" v-model="payment_method" v-validate="'required'">
                                                        <div class="form-check-label">
                                                            TARJETA DE CRÉDITO/DÉBITO (EMITIDA EN PERÚ)
                                                        </div>
                                                    </div>
                                                    <div class="payment-method-img mp-logo"></div>
                                                </div>
                                            </label>
                                            <div id="collapseCreditCardOne" class="collapse" aria-labelledby="headingCreditCardOne"
                                                 data-parent="#accordionCheckout">
                                                <div class="card-body accordion-checkout-body">
                                                    <div class="commission-text">
                                                        COMISIÓN DEL MEDIO DE PAGO: {{ event.currency.symbol }}{{ plus_by_peruvian_card }}
                                                    </div>
                                                    <div class="total-amount-text">TOTAL A PAGAR: {{ event.currency.symbol }}{{ amountChargedGuest }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <label for="creditCardTwoOption" class="card-header accordion-checkout-header"
                                                   id="headingCreditCardTwo" data-toggle="collapse" aria-expanded="false"
                                                   aria-controls="collapseCreditCardTwo" data-target="#collapseCreditCardTwo">
                                                <div class="container-checkout-option">
                                                    <div class="container-radio-option">
                                                        <input class="form-check-input" type="radio" name="exampleRadios"
                                                               id="creditCardTwoOption" value="2" v-model="payment_method" v-validate="'required'">
                                                        <div class="form-check-label"
                                                             v-text="event.currency.id == CURRENCY_SOLES_ID ?
                                                             'TARJETA DE CRÉDITO/DÉBITO (EMITIDA EN EL EXTRANJERO)' :
                                                             'TARJETA DE CRÉDITO/DÉBITO'"></div>
                                                    </div>
                                                    <div class="payment-method-img culqi-logo"></div>
                                                </div>
                                            </label>
                                            <div id="collapseCreditCardTwo" class="collapse" aria-labelledby="headingCreditCardTwo"
                                                 data-parent="#accordionCheckout">
                                                <div class="card-body accordion-checkout-body">
                                                    <div class="commission-text">
                                                        COMISIÓN DEL MEDIO DE PAGO: {{ event.currency.symbol }}{{ plus_by_card }}
                                                    </div>
                                                    <div class="total-amount-text">
                                                        TOTAL A PAGAR: {{ event.currency.symbol }}{{ amountChargedGuest }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <label for="depositOption" class="card-header accordion-checkout-header"
                                                   id="headingDepositOption" data-toggle="collapse" aria-expanded="false"
                                                   aria-controls="collapseDepositOption" data-target="#collapseDepositOption">
                                                <div class="container-checkout-option">
                                                    <div class="container-radio-option">
                                                        <input class="form-check-input" type="radio" name="exampleRadios"
                                                               id="depositOption" value="1" v-model="payment_method" v-validate="'required'">
                                                        <div class="form-check-label">TRANSFERENCIA BANCARIA</div>
                                                    </div>
                                                    <div class="payment-method-img deposit-logo"
                                                         :class="{'deposit-soles': event.currency.id == CURRENCY_SOLES_ID}"></div>
                                                </div>
                                            </label>
                                            <div id="collapseDepositOption" class="collapse" aria-labelledby="headingDepositOption"
                                                 data-parent="#accordionCheckout">
                                                <div class="card-body accordion-checkout-body">
                                                    <div class="commission-text">
                                                        COMISIÓN DEL MEDIO DE PAGO: {{ event.currency.symbol }}{{ plus_by_deposit }}
                                                    </div>
                                                    <div class="total-amount-text">TOTAL A PAGAR: {{ event.currency.symbol }}{{ amountChargedGuest }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="common-container-btn content-bnt-and-link flex-mobile">
                        <div>
                            <a href="javascript:void(0)" class="link-cancel-form desktop" @click="hideThisStep">
                                REGRESAR AL PASO ANTERIOR
                            </a>
                            <a href="javascript:void(0)" class="link-cancel-form mobile" @click="hideThisStep">
                                REGRESAR
                            </a>
                        </div>
                        <button v-if="event.currency.id == CURRENCY_SOLES_ID && payment_method == PAYMENT_METHOD.PERUVIAN_CARD"
                                class="common-green-btn" type="button" data-toggle="modal" data-target="#mercadoPagoModal" :disabled="!isFormValid">
                            PAGAR
                            <span class="arrow-into-btn"></span>
                        </button>
                        <button v-else class="common-green-btn" type="submit" :disabled="!isFormValid">
                            PAGAR
                            <span class="arrow-into-btn"></span>
                        </button>
                    </div>

                    <div>
                        <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                        <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
                    </div>
                </div>
            </div>
        </form>

        <mercado-pago
                v-if="event.currency.id == CURRENCY_SOLES_ID && payment_method == PAYMENT_METHOD.PERUVIAN_CARD"
                :transaction-amount="amountChargedGuest"
                :public-key="public_key_mp"
                @tokencreated="checkoutSubmit"
        />
    </div>
</template>

<script>

    import ItemCart from '../shopping_cart/ItemCart'
    import MercadoPago from './MercadoPago'
    import * as ordersAPI from '../../api/orders'
    import * as OrderUtils from './utils'

    export default {
        name: "checkout-pay",
        props: ['csrf_token', 'cart', 'event', 'event_link', 'user',
                'payment_method_id', 'public_key_culqi', 'public_key_mp', 'link_successful'],
        data: () =>  {
            return {
                response_error_message: null,
                response_success_message: null,
                is_checkout_page: true,
                payment_method: null,
                who_pay: OrderUtils.assume_commission.COUPLE,
                plus_by_card: 0,
                plus_by_peruvian_card: 0,
                plus_by_deposit: 0,
                total_by_card: 0,
                total_by_peruvian_card: 0,
                total_by_deposit: 0,
                CURRENCY_SOLES_ID: OrderUtils.currency.SOLES,
                PAYMENT_METHOD: OrderUtils.payment_method,
            }
        },
        methods:{
            updateCountItems: function (value){
                this.$emit('update_count_items_cart', value);
            },
            updateCheckoutComponent: function(){
                this.$forceUpdate();
            },
            hideThisStep: function (){
                this.$emit('update_show_first_step', true);
            },


            checkoutSubmit: function (mercadoPagoData){

                const data = {
                    user: {
                        user_id: this.user.user_id,
                        first_name: this.user.first_name,
                        last_name: this.user.last_name,
                        email: this.user.email,
                        address: this.user.address,
                        telephone: this.user.telephone,
                        city: this.user.city,
                        want_register: this.user.want_register,
                        password: this.user.password,
                        document_number: this.user.document_number,
                        document_type_id: this.user.document_type_id,
                        country_issuance: this.user.country_issuance
                    },
                    order: {
                        user_alias: this.user.nickname,
                        message: this.user.message,
                        is_public_message: this.user.is_public_message,
                        subtotal: this.cart.total,
                        payment_method_id: parseInt(this.payment_method)
                    },
                    event_id: this.event.id,
                    list_item: [],
                    data_culqi: {},
                    data_mercadopago: {}
                };

                OrderUtils.dataItemToRequest(this.cart.items, data.list_item);

                this.response_error_message = '';

                if(parseInt(this.payment_method) !== OrderUtils.payment_method.DEPOSIT){

                    if( parseInt(this.event.currency.id) === this.CURRENCY_SOLES_ID &&
                        parseInt(this.payment_method) === OrderUtils.payment_method.PERUVIAN_CARD){

                        data.data_mercadopago = mercadoPagoData;
                        this.createOrderAPI(data)

                    } else {
                        let amount_culqi = parseInt(parseFloat(this.amountChargedGuest*100).toFixed(2));

                        Culqi.settings({
                            title: 'TheGiftList',
                            currency: this.event.currency.short_code,
                            description: 'Listas de Bodas y Showers',
                            amount: amount_culqi
                        });

                        window.culqi = () => {
                            if (Culqi.token) {

                                data.data_culqi = {
                                    amount: amount_culqi,
                                    currency_code: this.event.currency.short_code,
                                    country_code: 'PE',
                                    source_id: Culqi.token.id
                                };

                                this.createOrderAPI(data);

                            } else {
                                this.response_error_message = 'Ocurrió un error al procesar el pago, por favor intente de nuevo.';
                            }
                        };

                        Culqi.open();
                    }
                }else{
                    this.createOrderAPI(data);
                }

            },
            hideMessagesResponse: function(){
                setTimeout(() => {
                    this.response_success_message = '';
                }, 2000);
            },
            createOrderAPI: function (data){

                let data_json= { data:JSON.stringify(data) };

                ordersAPI.checkout(data_json).then( (response) => {

                    if(response.data.success){

                        this.response_success_message = response.data.message;

                        if(data.order.payment_method_id === OrderUtils.payment_method.DEPOSIT){
                            let url = this.link_successful.deposit + response.data.order_id + '/';
                            window.location = url
                        }else{
                            window.location = this.link_successful.card
                        }

                    }else{

                        this.response_error_message = response.data.message;
                        this.response_success_message = '';

                    }

                    this.hideMessagesResponse();

                }).catch( (error) => {
                    this.response_error_message = 'Ocurrió un error, por favor intente de nuevo.';
                    this.hideMessagesResponse();

                });
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid) && this.amountChargedGuest > 0.1;
            },
            amountChargedGuest: function () {
                if(parseInt(this.event.currency.id) === this.CURRENCY_SOLES_ID &&
                   parseInt(this.payment_method) === OrderUtils.payment_method.PERUVIAN_CARD){

                    return this.total_by_peruvian_card
                }else if(parseInt(this.payment_method) === OrderUtils.payment_method.CARD){
                    return this.total_by_card
                }else{
                    return this.total_by_deposit
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$validator.validateAll();
            });

            Culqi.publicKey = this.public_key_culqi;

        },
        created() {
            this.payment_method = this.payment_method_id;
            if(this.event.guest_assume_commission) this.who_pay = OrderUtils.assume_commission.GUEST;
            ordersAPI.checkoutAmounts(this.event.id, this.cart.total).then(response => {
                if(response.data.success){
                    this.plus_by_card = response.data.plus_by_card
                    this.plus_by_peruvian_card = response.data.plus_by_peruvian_card
                    this.plus_by_deposit = response.data.plus_by_deposit
                    this.total_by_card = response.data.total_by_card
                    this.total_by_peruvian_card = response.data.total_by_peruvian_card
                    this.total_by_deposit = response.data.total_by_deposit
                } else {
                      this.response_error_message = 'Error inesperado al cargar los montos, por favor refresque la página.'
                }
            }).catch(error => {
                this.response_error_message = 'Error inesperado al cargar los montos, por favor refresque la página.'
            })
        },
        watch: {
            'payment_method': function (){
                if(this.payment_method){
                    this.$emit('update_payment_method', this.payment_method);
                }
            }
        },
        components: { ItemCart, MercadoPago }
    }
</script>

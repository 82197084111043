var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-fields-form" }, [
      _c("p"),
      _vm._v(" "),
      _vm.show_form
        ? _c(
            "form",
            {
              attrs: { method: "post" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitForm($event)
                }
              }
            },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.data_third_step.csrf_token) }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "content-field" }, [
                _c("label", { staticClass: "label-field" }, [
                  _vm._v("NUEVA CONTRASEÑA")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.password,
                      expression: "user.password"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:8",
                      expression: "'required|min:8'"
                    }
                  ],
                  ref: "password",
                  staticClass: "input-text-form-tgl",
                  class: { "error-input-text": _vm.errors.has("password") },
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: "Ingresa tu contraseña"
                  },
                  domProps: { value: _vm.user.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "password", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("password")
                  ? _c("p", { staticClass: "text-message-error" }, [
                      _vm._v(_vm._s(_vm.errors.first("password")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content-field" }, [
                _c("label", { staticClass: "label-field" }, [
                  _vm._v("CONFIRMA NUEVA CONTRASEÑA")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.confirm_password,
                      expression: "user.confirm_password"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|confirmed:password",
                      expression: "'required|confirmed:password'"
                    }
                  ],
                  staticClass: "input-text-form-tgl",
                  class: {
                    "error-input-text": _vm.errors.has("confirm_password")
                  },
                  attrs: {
                    type: "password",
                    name: "confirm_password",
                    placeholder: "Reingresa tu contraseña"
                  },
                  domProps: { value: _vm.user.confirm_password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.user,
                        "confirm_password",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("confirm_password")
                  ? _c("p", { staticClass: "text-message-error" }, [
                      _vm._v(_vm._s(_vm.errors.first("confirm_password")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content-submit content-bnt-recover-pass" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "link-cancel-form",
                      attrs: { href: _vm.data_third_step.link_login }
                    },
                    [_vm._v("CANCELAR")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-submit-tgl",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("\n                    GUARDAR\n                ")]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.sent_form
                ? _c("div", [
                    _c("p", {
                      staticClass: "error-submit-form-message",
                      domProps: { innerHTML: _vm._s(_vm.response_form_message) }
                    })
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show_form,
              expression: "!show_form"
            }
          ],
          staticClass: "content-success-register"
        },
        [
          _c("h5", { staticClass: "title-form-success" }, [
            _vm._v("CAMBIO DE CONTRASEÑA EXITOSO")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info-form-success" }, [
            _vm._v(
              "\n                ¡Has cambiado tu contraseña correctamente!\n            "
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-submit" }, [
      _c(
        "a",
        {
          staticClass: "btn-submit-tgl btn-finish-register",
          attrs: { href: "/" }
        },
        [_vm._v("FINALIZAR")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-fields-form" }, [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.contactUsSubmit($event)
          }
        }
      },
      [
        _c("div", {
          attrs: { hidden: "" },
          domProps: { innerHTML: _vm._s(_vm.csrf_token) }
        }),
        _vm._v(" "),
        _c("h4", { staticClass: "common-title-section mb-3" }, [
          _vm._v("CONTÁCTANOS")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "description-page not-padding mb-4" }, [
          _vm._v("Ingresa tus datos y te contactaremos en breve.")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("NOMBRE *")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:2|max:200",
                expression: "'required|min:2|max:200'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("first_name") ? "error-input-text" : ""
            ],
            attrs: {
              type: "text",
              name: "first_name",
              placeholder: "Ingresa tus nombres y apellidos"
            },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("first_name")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("first_name")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("TELÉFONO *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|numeric|min:5|max:15",
                    expression: "'required|numeric|min:5|max:15'"
                  },
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.telephone,
                    expression: "telephone",
                    modifiers: { number: true }
                  }
                ],
                class: [
                  "common-input-text",
                  _vm.errors.has("telephone") ? "error-input-text" : ""
                ],
                attrs: {
                  type: "text",
                  name: "telephone",
                  placeholder: "Ingresa tu teléfono"
                },
                domProps: { value: _vm.telephone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.telephone = _vm._n($event.target.value)
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("telephone")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("telephone")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("CORREO ELECTRÓNICO *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email|min:5|max:50",
                    expression: "'required|email|min:5|max:50'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                class: [
                  "common-input-text",
                  _vm.errors.has("email") ? "error-input-text" : ""
                ],
                attrs: {
                  type: "text",
                  name: "email",
                  placeholder: "Ingresa tu correo electrónico"
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("email")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("email")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("ASUNTO *")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:2|max:200",
                expression: "'required|min:2|max:200'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.subject,
                expression: "subject"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("subject") ? "error-input-text" : ""
            ],
            attrs: { type: "text", name: "subject" },
            domProps: { value: _vm.subject },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.subject = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("subject")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("subject")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-content-field" }, [
          _c("label", { staticClass: "common-label-field" }, [
            _vm._v("MENSAJE *")
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:2|max:1000",
                expression: "'required|min:2|max:1000'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message"
              }
            ],
            class: [
              "common-input-text",
              _vm.errors.has("message") ? "error-input-text" : ""
            ],
            attrs: { type: "text", name: "message", rows: "5" },
            domProps: { value: _vm.message },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.message = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.has("message")
            ? _c("p", { staticClass: "message-error-field" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.first("message")) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-btn-and-social-network" }, [
          _c("div", { staticClass: "content-social-network" }, [
            _c("div", { staticClass: "content-network" }, [
              _c("div", { staticClass: "img-network d-inline-block n-email" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "d-inline-block name-network",
                  attrs: { href: "mailto:" + _vm.info_tgl.email_contact }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.info_tgl.email_contact) +
                      "\n                    "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content-network" }, [
              _c("div", { staticClass: "img-network d-inline-block n-fb" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "d-inline-block name-network",
                  attrs: { href: _vm.info_tgl.social_network.FACEBOOK_URL }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.info_tgl.social_network.FACEBOOK_USERNAME) +
                      "\n                    "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content-network" }, [
              _c("div", { staticClass: "img-network d-inline-block n-instg" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "d-inline-block name-network",
                  attrs: { href: _vm.info_tgl.social_network.INSTAGRAM_URL }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.info_tgl.social_network.INSTAGRAM_USERNAME) +
                      "\n                    "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "common-green-btn", attrs: { type: "submit" } },
            [_vm._v("ENVIAR")]
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.response_error_message
            ? _c("p", {
                staticClass: "error-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_error_message) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.response_success_message
            ? _c("p", {
                staticClass: "success-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_success_message) }
              })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
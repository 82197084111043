var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("form", { attrs: { method: "post" } }, [
      _c("div", {
        attrs: { hidden: "" },
        domProps: { innerHTML: _vm._s(_vm.csrf_token) }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "common-container-content-page container-height-auto" },
        [
          _c("div", { staticClass: "wrapper-info-amount-event" }, [
            _c("div", { staticClass: "info-amount info-money-available " }, [
              _c("div", [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("TOTAL DISPONIBLE")
                ]),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        placement: "top",
                        title:
                          "Luego de 7 días de recibir un regalo podrás ver el monto sumado aquí y podrás solicitar la transferencia a tu cuenta."
                      },
                      expression:
                        "{ placement: 'top', title: 'Luego de 7 días de recibir un regalo podrás ver el monto sumado aquí y podrás solicitar la transferencia a tu cuenta.' }"
                    }
                  ],
                  staticClass: "icon-help-tool"
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "event-amount" }, [
                _vm._v(
                  _vm._s(_vm.event.currency.symbol) +
                    " " +
                    _vm._s(_vm.event.money_available)
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "info-amount info-money-total hide-in-mobile" },
              [
                _c("div", [
                  _c("label", { staticClass: "common-label-field" }, [
                    _vm._v("TOTAL REGALADO")
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          placement: "top",
                          title:
                            "Al recibir un regalo podrás ver el monto sumado aquí."
                        },
                        expression:
                          "{ placement: 'top', title: 'Al recibir un regalo podrás ver el monto sumado aquí.' }"
                      }
                    ],
                    staticClass: "icon-help-tool"
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "event-amount" }, [
                  _vm._v(
                    _vm._s(_vm.event.currency.symbol) +
                      " " +
                      _vm._s(_vm.event.total)
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-amount hide-in-mobile" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "event-amount" }, [
                _vm._v(
                  _vm._s(_vm.event.currency.symbol) +
                    " " +
                    _vm._s(_vm.event.money_transferred)
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "wrapper-event-amount-mobile" }, [
              _c("div", { staticClass: "info-amount info-money-total" }, [
                _c("div", [
                  _c("label", { staticClass: "common-label-field" }, [
                    _vm._v("TOTAL REGALADO")
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          placement: "top",
                          title:
                            "Al recibir un regalo podrás ver el monto sumado aquí."
                        },
                        expression:
                          "{ placement: 'top', title: 'Al recibir un regalo podrás ver el monto sumado aquí.' }"
                      }
                    ],
                    staticClass: "icon-help-tool"
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "event-amount" }, [
                  _vm._v(
                    _vm._s(_vm.event.currency.symbol) +
                      " " +
                      _vm._s(_vm.event.total)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info-amount info-money-transfer" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "event-amount" }, [
                  _vm._v(
                    _vm._s(_vm.event.currency.symbol) +
                      " " +
                      _vm._s(_vm.event.money_transferred)
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h4", { staticClass: "common-title-section" }, [
            _vm._v("TRANSFERIR MONTO")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [
              _vm._v("MONTO A TRANSFERIR")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.amount_to_transfer,
                  expression: "amount_to_transfer"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    decimal: 2,
                    min_value: 1,
                    max_value: _vm.event.money_available
                  },
                  expression:
                    "{required: true, decimal: 2, min_value: 1, max_value: event.money_available}"
                }
              ],
              staticClass: "common-input-text",
              class: { "error-input-text": _vm.errors.has("amount_transfer") },
              attrs: {
                type: "text",
                name: "amount_transfer",
                readonly: _vm.event.money_available == 0 ? true : false,
                placeholder:
                  _vm.windowSize > 768
                    ? "Ingresa un monto igual o menor al total disponible"
                    : "Igual o menor al total disponible"
              },
              domProps: { value: _vm.amount_to_transfer },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.amount_to_transfer = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("amount_transfer")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("amount_transfer")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "common-container-btn text-center container-btn-transfer"
            },
            [
              _c(
                "button",
                {
                  staticClass: "common-green-btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.transferRequestSubmit($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    SOLICITAR TRANSFERENCIA\n                "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _vm.response_error_message
              ? _c("p", {
                  staticClass: "error-submit-form-message",
                  domProps: { innerHTML: _vm._s(_vm.response_error_message) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.response_success_message
              ? _c("p", {
                  staticClass: "success-submit-form-message",
                  domProps: { innerHTML: _vm._s(_vm.response_success_message) }
                })
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "section-history-transfers" }, [
        _c(
          "h2",
          { staticClass: "common-title title-no-padding history-title" },
          [_vm._v("HISTORIAL DE TRANSFERENCIAS")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "wrapper-table-tgl" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-tgl-transfers" }, [
              _vm._m(2),
              _vm._v(" "),
              _vm.list_transfers.length
                ? _c(
                    "tbody",
                    _vm._l(_vm.list_transfers, function(transfer, index) {
                      return _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "simple-text",
                            attrs: { scope: "row" }
                          },
                          [_vm._v(_vm._s(transfer.id))]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "simple-text" }, [
                          _vm._v(
                            _vm._s(transfer.transfer_request_status__label)
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "simple-text" }, [
                          _vm._v(_vm._s(transfer.created_at))
                        ]),
                        _vm._v(" "),
                        transfer.transfer_request_status_id !=
                        _vm.transfer_status.SOLICITADA
                          ? _c("td", { staticClass: "simple-text" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(transfer.updated_at) +
                                  "\n                                "
                              )
                            ])
                          : _c("td", { staticClass: "simple-text" }),
                        _vm._v(" "),
                        _c("td", { staticClass: "simple-text" }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.event.currency.symbol) +
                              " " +
                              _vm._s(transfer.commission) +
                              "\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "simple-text" }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.event.currency.symbol) +
                              " " +
                              _vm._s(transfer.total) +
                              "\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          transfer.transfer_request_status_id ==
                          _vm.transfer_status.SOLICITADA
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn-action-transfer simple-link",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.deleteTransferRequest(
                                        transfer.id,
                                        index
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                        CANCELAR\n                                    "
                                  )
                                ]
                              )
                            : _c("span", [_vm._v("-")])
                        ])
                      ])
                    }),
                    0
                  )
                : _c("tbody", [_vm._m(3)])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wrapper-history-mobile" }, [
          _vm.list_transfers.length
            ? _c(
                "div",
                _vm._l(_vm.list_transfers, function(transfer, index) {
                  return _c("div", { staticClass: "wrapper-row-transfer" }, [
                    _c("div", { staticClass: "row-transfer" }, [
                      _c("span", { staticClass: "label-row" }, [_vm._v("ID#")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info-row simple-text" }, [
                        _vm._v(_vm._s(transfer.id))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row-transfer" }, [
                      _c("span", { staticClass: "label-row" }, [
                        _vm._v("Estado")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info-row simple-text" }, [
                        _vm._v(_vm._s(transfer.transfer_request_status__label))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row-transfer" }, [
                      _c("span", { staticClass: "label-row" }, [
                        _vm._v("F. Proceso")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info-row simple-text" }, [
                        _vm._v(_vm._s(transfer.created_at))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row-transfer" }, [
                      _c("span", { staticClass: "label-row" }, [
                        _vm._v("F. Operación")
                      ]),
                      _vm._v(" "),
                      transfer.transfer_request_status_id !=
                      _vm.transfer_status.SOLICITADA
                        ? _c("span", { staticClass: "info-row simple-text" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(transfer.updated_at) +
                                "\n                            "
                            )
                          ])
                        : _c("span", { staticClass: "info-row simple-text" })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row-transfer" }, [
                      _c("span", { staticClass: "label-row" }, [
                        _vm._v("Cargo")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info-row simple-text" }, [
                        _vm._v(
                          _vm._s(_vm.event.currency.symbol) +
                            " " +
                            _vm._s(transfer.commission)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row-transfer" }, [
                      _c("span", { staticClass: "label-row" }, [
                        _vm._v("Abono")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info-row simple-text" }, [
                        _vm._v(
                          _vm._s(_vm.event.currency.symbol) +
                            " " +
                            _vm._s(transfer.total)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row-transfer" }, [
                      _c("span", { staticClass: "label-row" }, [
                        _vm._v("Acciones")
                      ]),
                      _vm._v(" "),
                      transfer.transfer_request_status_id ==
                      _vm.transfer_status.SOLICITADA
                        ? _c(
                            "a",
                            {
                              staticClass: "btn-action-transfer simple-link",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.deleteTransferRequest(
                                    transfer.id,
                                    index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                CANCELAR\n                            "
                              )
                            ]
                          )
                        : _c("span", { staticClass: "info-row simple-text" }, [
                            _vm._v("-")
                          ])
                    ])
                  ])
                }),
                0
              )
            : _c("div", { staticClass: "simple-text history-title" }, [
                _vm._v("Lo sentimos. No se encontraron transferencias.")
              ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { staticClass: "common-label-field" }, [
        _vm._v("TOTAL TRANSFERIDO")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { staticClass: "common-label-field" }, [
        _vm._v("TOTAL TRANSFERIDO")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "title-head-table", attrs: { scope: "col" } }, [
          _vm._v("ID #")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "title-head-table", attrs: { scope: "col" } }, [
          _vm._v("ESTADO")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "title-head-table", attrs: { scope: "col" } }, [
          _vm._v("F. PROCESO")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "title-head-table", attrs: { scope: "col" } }, [
          _vm._v("F. OPERACIÓN")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "title-head-table", attrs: { scope: "col" } }, [
          _vm._v("CARGO")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "title-head-table", attrs: { scope: "col" } }, [
          _vm._v("ABONO")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "title-head-table", attrs: { scope: "col" } }, [
          _vm._v("ACCIONES")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "7" } }, [
        _vm._v("Lo sentimos. No se encontraron transferencias.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
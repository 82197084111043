<template>
    <div class="wrapper-view-items">
        <div v-if="has_items" class="wrapper-options-filter in-preview-page">
            <div class="content-item-filter">
                <input type="text" class="input-search-menu" placeholder="Buscar item" v-model="search_item">
            </div>

            <div class="container-checkbox show-device">
                <input type="checkbox" id="filterByGroup" class="checkbox-form-tgl" v-model="show_only_group">
                <label class="label-field-checkbox label-in-list-item" for="filterByGroup">
                    <span class="text-label-checkbox w-auto">
                        Solo regalos grupales
                    </span>
                </label>
                <span class="icon-help-tool"
                    v-tooltip="{ placement: 'right', title: 'Estos son regalos a los que puedes aportar cuotas sin pagar el monto total.' }">
                </span>
            </div>

            <hr class="hr-tgl show-device hr-tgl-preview">
            <div class="content-item-filter"></div>
            <div class="content-item-filter">
                <category-sidebar
                    ref="category-sidebar"
                    :data_list_category="data_vue.list_category"
                    :is_no_build_list="true"
                    :has_donation_items="data_vue.has_donation_items"
                   >
                </category-sidebar>
            </div>
            <div class="content-item-filter show-device"></div>
            <hr class="hr-tgl show-device hr-tgl-preview">
        </div>
        <div class="wrapper-content-list-item in-preview-page">
            <div v-if="has_items" class="content-open-filter content-filter-select">
                <h2 class="common-title">{{ name_category_selected }}</h2>
                <span class="tab-open-filter show-device mobile" id="openSideFilter">
                    FILTROS
                    <span class="arrow-open-filter"></span>
                </span>
                <div class="filter-to-order-by show-device">
                    <label class="label-order-by">ORDENAR POR</label>
                    <select class="common-select" v-model="order_by">
                        <option :value="orders.a_z">A - Z</option>
                        <option :value="orders.price_less_higher">PRECIO: MENOR A MAYOR</option>
                        <option :value="orders.price_higher_less">PRECIO: MAYOR A MENOR</option>
                        <option :value="orders.no_bought">NO REGALADOS</option>
                    </select>
                </div>
            </div>
            <div v-if="has_items" class="content-open-filter show-device mobile">
                <h2 class="common-title" v-if="order_by === orders.a_z">A - Z</h2>
                <h2 class="common-title" v-if="order_by === orders.price_less_higher">PRECIO: MENOR A MAYOR</h2>
                <h2 class="common-title" v-if="order_by === orders.price_higher_less">PRECIO: MAYOR A MENOR</h2>
                <h2 class="common-title" v-if="order_by === orders.no_bought">NO REGALADOS</h2>
                <h2 class="common-title" v-if="show_only_group">SOLO GRUPALES</h2>
            </div>

            <div class="wrapper-list-item">
                <div class="wrapper-item wrapper-add-contribution" v-if="event.is_contribution_account">
                    <div class="wrapper-image-card"></div>
                    <div class="wrapper-info-card">
                        <h5 class="title-card">Aporte Libre</h5>
                        <div class="description-item">Ingresa el monto que quieres regalar</div>
                        <div class="amount-contribution-label">MONTO A APORTAR</div>

                        <div class="container-btn-input-contribution">
                            <input type="text"
                                   :class="['common-input-text', 'currency-symbol', event.currency.id == 1 ? 'soles' : 'dolar']">

                            <button class="common-green-btn">
                                {{ event.gift_word ? event.gift_word : 'REGALAR' }}
                            </button>
                        </div>
                    </div>
                </div>

                <div v-for="item in listItemsFiltered" class="wrapper-item wrapper-item-added">

                    <div class="wrapper-image-card" :style="{ backgroundImage: 'url('+item.image+')'}">
                        <div class="wrapper-item-is-donation" v-if="item.is_donation_gift">
                            <div class="img-solidary"></div>
                        </div>

                        <div class="wrapper-bg-bought" v-if="!isItemAvailable(item)"></div>
                        <div class="wrapper-buttons-card text-center" v-else>
                            <a href="javascript:void(0)" class="delete-item-link"
                               v-if="!isItemPurchased(item)" @click="deleteItem(item.id)">
                                ELIMINAR
                            </a>
                            <button class="common-green-btn" data-toggle="modal"
                                    data-target="#storeItemModal" @click="loadUpdateDataModal(item)">
                                EDITAR
                            </button>
                        </div>
                    </div>

                    <div :class="['wrapper-info-card', !isItemAvailable(item) ? 'wrapper-info-card-bought' : '']">

                        <h5 v-if="item.is_donation_gift" class="title-card with-tooltip">
                            <span class="title-span-card">{{ item.name }}</span>
                            <div class="icon-help-tool"
                                  v-tooltip="{placement: 'right', title: 'El valor de este regalo se donará a esta organizacion.' }">
                            </div>
                        </h5>
                        <h5 v-else class="title-card">{{ item.name }}</h5>

                        <div class="description-item" v-if="item.description">
                            {{ item.description }}
                        </div>

                        <div class="price-item" v-if="item.num_quota > 1">
                            {{ event.currency.symbol }}{{ item.price_quota }} por cuota
                        </div>

                        <div class="total-price" v-if="item.num_quota > 1">PRECIO TOTAL: {{ event.currency.symbol }}{{ item.price }}</div>
                        <div class="price-item" v-else>{{ event.currency.symbol }}{{ item.price }}</div>

                        <div class="wrapper-progress-bar" v-if="item.num_quota > 1">
                            <div class="container-progress-bar">
                                <div class="progress-bar-count" :style="{ width: progressBarWidth(item)+'%'}"></div>
                            </div>
                            <span class="info-progress-bar">{{ availableQuotaString(item) }}</span>
                        </div>
                    </div>
                </div>

                <span class="simple-text" v-if="(!has_items && !event.is_contribution_account)">
                    Esta lista no posee regalos en este momento.
                </span>

            </div>
        </div>
        <store-item-modal
            ref="store-item-modal"
            :data_list_category="data_vue.list_category"
            :currency="event.currency"
            :csrf_token="csrf_token"
            :event_id="event.id"
            @item-updated="onItemUpdate"
        >
        </store-item-modal>
    </div>
</template>

<script>

    import 'bootstrap/js/dist/modal'
    import * as eventsAPI from '../../api/events'
    import CategorySidebar from './CategorySidebar'
    import StoreItemModal from './StoreItemModal'
    import * as EventUtils from './utils'

    export default {
        name: "preview-list",
        props: ['csrf_token', 'data_vue' ],

        data: () =>  {
            return {
                list_items: [],
                event: {
                    id: null,
                    is_contribution_account: null,
                    status: null,
                    currency: {},
                    gift_word: null,
                    quota_word: null
                },
                category_selected: 0,
                name_category_selected: EventUtils.ALL_CATEGORY_NAME,
                search_item: '',
                order_by: EventUtils.ORDER_BY_INITIAL,
                orders: EventUtils.ORDER_BY_OPTIONS,
                show_only_group: false,
                has_items: false
            }
        },
        watch: {
            'show_only_group': function(){
                if(this.show_only_group){
                    this.setOrderBy(this.orders.only_group);
                }else{
                    this.setOrderBy(this.orders.a_z);
                }
            },
        },
        methods:{
            loadUpdateDataModal: function (obj_item) {
                this.$refs['store-item-modal'].loadUpdateDataModal(obj_item);
            },
            onItemUpdate: function(obj_item) {
                EventUtils.updateEventItemInList(this.list_items, obj_item, this.data_vue.MEDIA_URL);
            },
            updateCategoryFromCategory: function (category_id, name_category) {
                this.category_selected = category_id;
                this.name_category_selected = name_category;
            },
            updateOrderByFromCategory: function(order_by){
                if(order_by === this.orders.only_group){
                    this.show_only_group = true;
                }else{
                    this.show_only_group = false;
                    this.order_by = order_by;
                }
            },
            setOrderBy: function(order_by){
                this.$refs['category-sidebar'].updateOrderByFromParent(order_by);
            },
            availableQuotaString: function(item) {
                return EventUtils.availableQuotaString(item, this.event.quota_word);
            },
            progressBarWidth: function(item) {
                return EventUtils.progressBarWidth(item);
            },
            isItemAvailable: (item) => EventUtils.isItemAvailable(item),
            isItemPurchased: (item) => EventUtils.isItemPurchased(item),
            deleteItem: function(item_id){

                eventsAPI.deleteEventItem({
                    id: item_id,
                    event_id: this.event.id
                }).then( (response) => {

                    if(response.data.success){
                        EventUtils.removeEventItemFromList(this.list_items, item_id);
                    }

                });

            }
        },
        created() {
            if(this.data_vue){
                this.list_items = EventUtils.loadEventItems(this.data_vue);
                this.event = this.data_vue.event;
            }


            ( this.list_items.length > 0 ) ? this.has_items = true : this.has_items = false
        },
        computed: {
            listItemsFiltered() {
                const list_items = EventUtils.filterEventItems(
                    this.list_items, this.search_item, this.category_selected, this.show_only_group
                );
                return EventUtils.sortEventItems(list_items, this.order_by, this.data_vue.list_category);
            }
        },
        components: { CategorySidebar, StoreItemModal },
    }
</script>

import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import TooltipDirective from './directives/TooltipDirective';
import * as Validation from './validation';

import Dropdown from './components/Dropdown';

import RegisterVue from './users/Register';
import LoginVue from './users/Login';
import RecoveryPassword from './users/RecoveryPassword';
import EditAccount from './users/EditAccount';

import ContactUs from './contact_us/ContactUs';

import './events-vue';
import './orders-vue';
import './transfers-vue';
import './complaints_book-vue';

Vue.use(VueCarousel);
Validation.init();

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);

Vue.component('dropdown', Dropdown);

Vue.directive('tooltip', TooltipDirective);
Vue.component('register-user', RegisterVue);
Vue.component('login-user', LoginVue);
Vue.component('recovery-password', RecoveryPassword);
Vue.component('edit-account', EditAccount);

Vue.component('contact-us-page', ContactUs);


export default new Vue({
    delimiters: ['<%', '%>'],
    el: '#app',
    data: {
        show_error_event_item: false
    }
});

<template>
    <div>

        <div class="modal fade wrapper-shopping-cart" id="shoppingCartModal">
            <div class="modal-dialog modal-dialog-centered wrapper-cart-modal">
                <div class="modal-content content-cart-modal">
                    <h4 class="common-title-section">
                        CARRITO DE COMPRAS
                        <span v-if="count_item_in_cart === 1">(1 ARTÍCULO)</span>
                        <span v-else>({{ count_item_in_cart }} ARTÍCULOS)</span>
                    </h4>

                    <div class="wrapper-items-in-cart">

                        <item-cart
                                v-for="(item, i_key) in cart.items"
                                :key="(parseInt(i_key) === 0) ? 'account_contrib' : parseInt(i_key)"
                                :event="event"
                                :item_cart="item"
                                :cart="cart"
                                @update_quota_or_amount="updateQuotaOrAmount"
                                @update_count_items="updateCountItems"
                                @update_contrib="updateContrib"
                                :is_checkout_page="is_checkout_page"
                                @update_checkout_component="updateCheckoutComponent"
                        />

                    </div>
                    <div class="wrapper-footer-cart">
                        <div class="wrapper-total-cart">
                            <div class="total-text">TOTAL</div>
                            <div class="total-price-cart">{{ event.currency.symbol }}{{ cart.total }}</div>
                        </div>

                        <div class="common-container-btn content-bnt-and-link flex-mobile">
                            <div>
                                <div v-if="is_checkout_page">
                                    <a :href="event_link" class="link-cancel-form desktop">
                                        SEGUIR REGALANDO
                                    </a>
                                    <a :href="event_link" class="link-cancel-form mobile">REGRESAR</a>
                                </div>
                                <div v-else>
                                    <a href="javascript:void(0)" class="link-cancel-form desktop" data-dismiss="modal">
                                        SEGUIR REGALANDO
                                    </a>
                                    <a href="javascript:void(0)" class="link-cancel-form mobile" data-dismiss="modal">REGRESAR</a>
                                </div>
                            </div>
                            <div v-if="is_checkout_page">
                                <a href="javascript:void(0)" data-dismiss="modal" class="common-green-btn">
                                    CHECKOUT
                                    <span class="arrow-into-btn"></span>
                                </a>
                            </div>
                            <div v-else>
                                <a :href="checkout_link" class="common-green-btn" v-if="count_item_in_cart > 0">
                                    CHECKOUT
                                    <span class="arrow-into-btn"></span>
                                </a>
                                <button disabled class="common-green-btn" v-else>
                                    CHECKOUT
                                    <span class="arrow-into-btn"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import ItemCart from './ItemCart'

    export default {
        name: "shopping-cart",
        props: ['event', 'cart', 'count_item_in_cart', 'checkout_link', 'is_checkout_page', 'event_link'],
        components: { ItemCart },
        methods:{
            updateQuotaOrAmount: function (value, component_key, is_account_contrib){
                this.$emit('update_quota_or_amount_from_cart', value, component_key, is_account_contrib);
            },
            updateCountItems: function (value){
                this.$emit('update_count_items_cart', value);
                if (value === 0) {
                    $('#shoppingCartModal').modal('hide');
                }
            },
            updateContrib: function (value){
                this.$emit('update_contrib_is_in_cart', value);
            },
            updateCheckoutComponent: function(){
                this.$emit('force_update_checkout_component', '');
            }

        }
    }
</script>

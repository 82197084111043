<template>
    <div>
        <form method="post" @submit.prevent="currencyAndCommissionSubmit">
            <div v-html="csrf_token"></div>
            <div>
                <h4 class="common-title-section">TIPO DE MONEDA</h4>
                <p></p>
                <p class="description-page not-padding">
                    Elige el tipo de moneda en la que quieres hacer tu lista y recibir el dinero de tus
                    regalos. Recuerda que deberás proporcionar una cuenta en la misma moneda y que
                    esta información no podrá ser modificada una vez que tu lista haya sido publicada.
                </p>
                <p></p>

                <div class="common-content-field">
                    <div class="wrapper-check-radio">
                        <label for="currency_1" class="label-check-radio complete-width">
                            <input type="radio" hidden value="1" name="currency" id="currency_1"
                                   class="custom-check-radio" v-model="currency" v-validate="'required'"
                                   v-bind:disabled="this.v_published">
                            <span class="label-text">S/. - Soles</span>
                        </label>
                        <label for="currency_2" class="label-check-radio complete-width">
                            <input type="radio" hidden value="2" name="currency" id="currency_2"
                                   class="custom-check-radio" v-model="currency" v-validate="'required'"
                                   v-bind:disabled="this.v_published">
                            <span class="label-text">$ - Dólares Americanos</span>
                        </label>
                        <p class="message-error-field" v-if="errors.has('currency')">
                            {{ errors.first('currency') }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="next-content">
                <h4 class="common-title-section">COBRO DE COMISIÓN</h4>
                <p></p>
                <p class="description-page not-padding">
                    Elige la opción “Nuestros Invitados” y recibe el 100% de tus regalos.
                    Si eliges la opción “Nosotros / yo”, la comisión variable de The Gift List ({{ cv_tgl }} %)
                    se descontará de tu monto recaudado. Recuerda que esta información no podrá ser
                    modificada una vez que hayas publicado tu lista.
                </p>
                <p></p>

                <div class="common-content-field">
                    <div class="wrapper-check-radio">
                        <label for="assume_0" class="label-check-radio complete-width">
                            <input type="radio" hidden value="1" name="assume" id="assume_0"
                                   class="custom-check-radio" v-model="assume_commission" v-validate="'required'"
                                   v-bind:disabled="this.v_published">
                            <span class="label-text">Nuestros invitados</span>
                        </label>
                        <label for="assume_1" class="label-check-radio complete-width">
                            <input type="radio" hidden value="0" name="assume" id="assume_1"
                                   class="custom-check-radio" v-model="assume_commission" v-validate="'required'"
                                   v-bind:disabled="this.v_published">
                            <span class="label-text">Nosotros / yo</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="common-container-btn content-bnt-and-link flex-mobile">
                <a v-bind:href="before_step" class="link-cancel-form desktop">REGRESAR AL PASO ANTERIOR</a>
                <a v-bind:href="before_step" class="link-cancel-form mobile">REGRESAR</a>
                <button type="submit" class="common-green-btn" :disabled="(isEventPublished && !isFormChanged)">
                    {{ isEventPublished ? 'ACTUALIZAR' : 'SIGUIENTE' }}
                    <span class="arrow-into-btn" v-if="!isEventPublished"></span>
                </button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>
        </form>
    </div>
</template>

<script>

    import * as eventsAPI from '../../api/events'
    import * as EventUtils from './utils'

    export default {
        name: "currency-and-commission",
        props: ['csrf_token', 'data_vue', 'published', 'event_id', 'next_url', 'before_step', 'event_status', 'cv_tgl'],
        data: () =>  {
            return {
                currency: null,
                assume_commission: 1,
                response_error_message: '',
                response_success_message: '',
                v_published: false
            }
        },
        computed: {
            isFormChanged: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed);
            },
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            },
            isEventPublished: function() {
                return this.event_status >= EventUtils.event_status.PUBLISHED;
            },
        },
        methods:{
            currencyAndCommissionSubmit: function () {
                this.$validator.validate().then(result => {
                    if (result && this.isFormChanged && this.isFormValid) {
                        const data = {
                            event_id: this.event_id,
                            currency_id: this.currency,
                            guest_assume_commission: this.assume_commission,
                        };

                        eventsAPI.storeDataEventDetail(data).then((response) => {

                            if (response.data.success) {
                                this.response_error_message = '';
                                this.response_success_message = response.data.message;

                                setTimeout(() => {
                                    if (this.isEventPublished) {
                                        window.location.reload(false);
                                    } else {
                                        this.gotoNextStep();
                                    }
                                }, 1000);

                            } else {
                                this.response_error_message = response.data.message;
                                this.response_success_message = '';
                            }

                            this.hideMessagesResponse();

                        }).catch((error) => {
                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();
                        });
                    } else if (!this.isEventPublished && this.isFormValid) {
                        this.gotoNextStep();
                    }
                });
            },

            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            },

            gotoNextStep: function() {
                window.location = this.next_url;
            }
        },
        created() {
            if(this.data_vue){
                const data_vue = JSON.parse(this.data_vue);

                if(data_vue.event_currency && data_vue.guest_assume_commission !== null){

                    this.currency = + data_vue.event_currency;
                    this.assume_commission = + data_vue.guest_assume_commission;

                }
            }

            if (this.published){
                this.v_published = (+ this.published >= EventUtils.event_status.PUBLISHED);
            }

        },
        mounted() {
            this.$nextTick(() => {

                const data_vue = JSON.parse(this.data_vue);

                if (data_vue.event_currency && data_vue.guest_assume_commission !== null){
                    this.$validator.validateAll();
                }
            });
        }
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show_data_bank_edit
        ? _c("bank-account-edit", {
            attrs: {
              data_account_bank: _vm.data_bank,
              before_step: _vm.before_step,
              data_vue: _vm.data_vue,
              no_holder: _vm.no_holder,
              default_holder: _vm.default_holder
            }
          })
        : _c("bank-account-confirm", {
            attrs: {
              csrf_token: _vm.csrf_token,
              data_account_bank: _vm.data_bank,
              data_vue: _vm.data_vue,
              next_url: _vm.next_url
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
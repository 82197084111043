var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "have-account-question" }, [
      _vm._v("\n        ¿Aún no tienes una cuenta con nosotros?\n        "),
      _c(
        "a",
        { staticClass: "log-in-link", attrs: { href: _vm.link_register_user } },
        [_vm._v("REGÍSTRATE")]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-fields-form" }, [
      _c(
        "form",
        {
          attrs: { method: "post" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.csrf_token) } }),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("label", { staticClass: "label-field" }, [
              _vm._v("CORREO ELECTRÓNICO")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                }
              ],
              staticClass: "input-text-form-tgl",
              class: { "error-input-text": _vm.errors.has("email") },
              attrs: {
                type: "text",
                name: "email",
                autofocus: "",
                autocomplete: "username",
                placeholder: "Ingresa tu correo electrónico"
              },
              domProps: { value: _vm.user.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("email")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("email")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("label", { staticClass: "label-field" }, [_vm._v("CONTRASEÑA")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "input-text-form-tgl",
              class: { "error-input-text": _vm.errors.has("password") },
              attrs: {
                type: "password",
                name: "password",
                autocomplete: "current-password",
                placeholder: "Ingresa tu contraseña"
              },
              domProps: { value: _vm.user.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "password", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("password")
              ? _c("p", { staticClass: "text-message-error" }, [
                  _vm._v(_vm._s(_vm.errors.first("password")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.remember_me,
                  expression: "user.remember_me"
                }
              ],
              staticClass: "checkbox-form-tgl",
              attrs: {
                type: "checkbox",
                name: "accept_terms",
                id: "remember_checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.user.remember_me)
                  ? _vm._i(_vm.user.remember_me, null) > -1
                  : _vm.user.remember_me
              },
              on: {
                change: function($event) {
                  var $$a = _vm.user.remember_me,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.user, "remember_me", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.user,
                          "remember_me",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.user, "remember_me", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "label-field-checkbox",
                attrs: { for: "remember_checkbox" }
              },
              [_vm._v("\n                        Recuérdame\n                ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "forgot-pass-link",
                attrs: { href: _vm.recover_pass_link }
              },
              [_vm._v("¿Olvidaste tu contraseña?")]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "text-message-error",
              domProps: { innerHTML: _vm._s(_vm.user.remember_me.message) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-submit" }, [
            _c(
              "button",
              {
                staticClass: "btn-submit-tgl",
                attrs: { type: "submit", disabled: !_vm.isFormValid }
              },
              [
                _vm._v(
                  "\n                        INICIAR SESIÓN\n                "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.sent_form
            ? _c("div", [
                _c("p", {
                  staticClass: "error-submit-form-message",
                  domProps: { innerHTML: _vm._s(_vm.response_form_message) }
                })
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="wrapper-sidebar-category" id="sidebarFilterCategory">
        <div class="wrapper-content-sidebar">
            <div class="bar-back-category show-device mobile">
                <a href="javascript:void(0)" class="link-back-category" id="closeSideFilter">
                    <span class="arrow-back-category"></span>
                    REGRESAR
                </a>
            </div>
            <div class="wrapper-categories">
                <div class="header-category show-device mobile">
                    <span>CATEGORIAS</span>
                </div>
                <div>

                    <div>
                        <div class="content-item-category">
                            <input type="radio" hidden id="allCategories" name="category" value="0"
                                   class="input-category input-radio-right" v-model.number="cat_selected">
                            <label for="allCategories" class="label-category">
                                <span class="name-category">{{ all_cat_name }}</span>
                                <span class="arrow-category"></span>
                            </label>
                        </div>
                    </div>

                    <div v-if="has_donation_items || !is_no_build_list">
                        <div class="content-item-category">
                            <input type="radio" hidden id="donationItems" name="category" value="-1"
                                   class="input-category input-radio-right" v-model.number="cat_selected">
                            <label for="donationItems" class="label-category">
                                <span class="name-category">{{ donation_items_cat_name }}</span>
                                <span class="arrow-category"></span>
                            </label>
                        </div>
                    </div>

                    <div v-for="(parent_cat, key, index) in list_category">

                        <div class="content-item-category">
                            <input type="radio" hidden :id="parent_cat.label+parent_cat.id" name="category"
                                   class="input-category input-radio-right" v-model.number="cat_selected" :value="parent_cat.id">
                            <label :for="parent_cat.label+parent_cat.id" class="label-category">
                                <span class="name-category">{{ parent_cat.label }}</span>
                                <span class="arrow-category"></span>
                            </label>
                        </div>

                        <div class="content-item-category category-child" v-for="sub_cat in parent_cat.sub_cats">
                            <input type="radio" hidden :id="sub_cat.label+sub_cat.id" name="category"
                                   class="input-category input-radio-right" v-model.number="cat_selected" :value="sub_cat.id">
                            <label :for="sub_cat.label+sub_cat.id" class="label-category sub-label-category">
                                <span class="name-category">{{ sub_cat.label }}</span>
                                <span class="arrow-category"></span>
                            </label>
                        </div>
                    </div>
                    <div class="content-item-category last-category"></div>
                </div>
                <div v-if="is_no_build_list">
                    <div class="header-category show-device mobile">
                        <span>ORDENAR POR</span>
                    </div>
                    <div class="show-device mobile">
                        <div class="content-item-category">
                            <input type="radio" hidden id="order_by1" name="order_by"
                                   class="input-category input-radio-right"
                                   v-model.number="order_by" value="1">
                                <label for="order_by1" class="label-category">A - Z</label>
                        </div>
                        <div class="content-item-category">
                            <input type="radio" hidden id="order_by2" name="order_by"
                                   class="input-category input-radio-right"
                                   v-model.number="order_by" value="2">
                                <label for="order_by2" class="label-category">PRECIO: MENOR A MAYOR</label>
                        </div>
                        <div class="content-item-category">
                            <input type="radio" hidden id="order_by3" name="order_by"
                                   class="input-category input-radio-right"
                                   v-model.number="order_by" value="3">
                                <label for="order_by3" class="label-category">PRECIO: MAYOR A MENOR</label>
                        </div>
                        <div class="content-item-category">
                            <input type="radio" hidden id="order_by4" name="order_by"
                                   class="input-category input-radio-right"
                                   v-model.number="order_by" value="4">
                                <label for="order_by4" class="label-category">NO REGALADOS</label>
                        </div>
                        <div class="content-item-category">
                            <input type="radio" hidden id="order_by5" name="order_by"
                                   class="input-category input-radio-right"
                                   v-model.number="order_by" value="5">
                                <label for="order_by5" class="label-category">SOLO GRUPALES</label>
                        </div>
                        <div class="content-item-category last-category"></div>
                        <div class="content-item-category last-category"></div>
                    </div>
                </div>
                <div v-else class="content-item-category last-category"></div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as EventUtils from './utils'

    export default {
        name: "category-sidebar",
        props: ['data_list_category', 'is_no_build_list', 'has_donation_items'],
        data: () =>  {
            return {
                list_category: [],
                cat_selected: 0,
                order_by: 1,
                all_cat_name: EventUtils.ALL_CATEGORY_NAME,
                donation_items_cat_name: EventUtils.DONATION_ITEMS_CAT_NAME
            }
        },
        watch: {
            'cat_selected': function(){
                this.onCategoryChange();
            },
            'order_by': function(){
                this.onOrderByChange();
            }
        },
        methods:{
            onCategoryChange: function () {
                const category_selected = this.cat_selected;

                let name_category = EventUtils.ALL_CATEGORY_NAME;

                if (category_selected === -1){
                    name_category = EventUtils.DONATION_ITEMS_CAT_NAME;
                }

                for (const name_cat in this.list_category){

                    const cat_parent = this.list_category[name_cat];

                    if(cat_parent['id'] === category_selected){
                        name_category = cat_parent['label'];
                        break;
                    }else{
                        const sub_cats = cat_parent['sub_cats'];

                        for (let i=0; i < sub_cats.length; i++){
                            let sub_cat = sub_cats[i];

                            if(sub_cat['id'] === category_selected){
                                name_category = cat_parent['label'] + '/' +sub_cat['label'];
                                break;
                            }
                        }
                    }
                }


                this.$parent.updateCategoryFromCategory(this.cat_selected, name_category);
            },
            onOrderByChange: function () {
                this.$parent.updateOrderByFromCategory(this.order_by);
            },
            updateOrderByFromParent: function(order_by){
                this.order_by = order_by;
            },
        },
        created() {
            this.list_category = this.data_list_category;
        },
    }
</script>

<template>
    <div>
        <div class="wrapper-content-image rounded-circle">
            <img class="content-image rounded-circle" :alt="event_title" :src="event_image_url">
            <div class="content-overlay rounded-circle"></div>
            <div class="content-lock"></div>
        </div>
        <h2 class="title-event">
            {{ event_title }}
        </h2>
        <div class="date-event">{{ event_date }}</div>
        <form method="post" @submit.prevent="tokenSubmit">
            <div class="common-content-field">
                <label class="common-label-field">CONTRASEÑA</label>
                <input type="password" name="password" v-model="token"
                       autofocus v-validate="'required'"
                       class="common-input-text" placeholder="Ingresa la contraseña"
                       v-bind:class="{'error-input-text': errors.has('password')}">
                <p class="message-error-field" v-if="errors.has('password')">{{ errors.first('password') }}</p>
            </div>

            <div class="common-container-btn content-bnt-and-link flex-mobile">
                <a href="javascript:history.back();" class="link-cancel-form">CANCELAR</a>
                <button type="submit" class="common-green-btn" :disabled="!isFormValid">
                    ACCEDER
                </button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>
        </form>
    </div>
</template>

<script>

    import * as eventsAPI from '../../../api/events'

    export default {
        name: "Password",
        props: ['event_id', 'event_url', 'event_date', 'event_title', 'event_image_url'],
        data: () => {
            return {
                token: null,
                response_error_message: '',
                response_success_message: '',
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed)
                    && Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            tokenSubmit: function () {

                if (this.isFormValid) {
                    eventsAPI.verifyToken(
                        this.event_id, this.token
                    ).then( (response) => {

                        if(response.data.success){
                            this.response_error_message = '';
                            this.response_success_message = response.data.message;

                            setTimeout(() => {
                                window.location = `${window.location.origin}/${this.event_url}/`
                            }, 1000);

                        }else{
                            this.response_error_message = response.data.message;
                            this.response_success_message = '';
                        }

                        this.hideMessagesResponse();

                    }).catch( (error) => {
                        this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                        this.hideMessagesResponse();
                    });
                }
            },

            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            }
        }
    }
</script>

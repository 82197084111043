var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show_second_step
        ? _c("div", [
            _c("div", { staticClass: "content-fields-form" }, [
              _c("p"),
              _vm._v(" "),
              _c("p", { staticClass: "info-form" }, [
                _vm._v(
                  "\n                Por tu seguridad, necesitamos verificar tu\n                identidad. Introduce a continuación el código\n                de 6 dígitos que hemos enviado a " +
                    _vm._s(_vm.data_second_step.user_email) +
                    ".\n            "
                )
              ]),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              _c(
                "form",
                {
                  attrs: { method: "post" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.data_second_step.csrf_token)
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "content-field" }, [
                    _c("label", { staticClass: "label-field" }, [
                      _vm._v("INTRODUCE EL CÓDIGO")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.code,
                          expression: "code"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric|length:6",
                          expression: "'required|numeric|length:6'"
                        }
                      ],
                      staticClass: "input-text-form-tgl",
                      class: { "error-input-text": _vm.errors.has("code") },
                      attrs: {
                        type: "text",
                        name: "code",
                        placeholder: "Introduce el código"
                      },
                      domProps: { value: _vm.code },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.code = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("code")
                      ? _c("p", { staticClass: "text-message-error" }, [
                          _vm._v(_vm._s(_vm.errors.first("code")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content-field" }, [
                    !_vm.show_sending_message
                      ? _c(
                          "a",
                          {
                            staticClass: "resend-code",
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.resendCode }
                          },
                          [_vm._v("Reenviar código")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.show_sending_message
                      ? _c(
                          "a",
                          {
                            staticClass: "resend-code",
                            attrs: { href: "javascript:void(0)" }
                          },
                          [_vm._v("Enviando...")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content-submit content-bnt-recover-pass" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link-cancel-form",
                          attrs: { href: _vm.data_second_step.link_login }
                        },
                        [_vm._v("CANCELAR")]
                      ),
                      _vm._v(" "),
                      _vm._m(0)
                    ]
                  ),
                  _vm._v(" "),
                  _vm.sent_form
                    ? _c("div", [
                        _c("p", {
                          staticClass: "error-submit-form-message",
                          domProps: {
                            innerHTML: _vm._s(_vm.response_error_form_message)
                          }
                        }),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "success-submit-form-message",
                          domProps: {
                            innerHTML: _vm._s(_vm.response_success_form_message)
                          }
                        })
                      ])
                    : _vm._e()
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.show_third_step
        ? _c("recovery-p-new-password", {
            attrs: { data_third_step: _vm.data_third_step }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "btn-submit-tgl", attrs: { type: "submit" } },
      [
        _vm._v(
          "\n                        SIGUIENTE    \n                        "
        ),
        _c("span", { staticClass: "arrow-btn-form" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <div class="modal fade wrapper-shopping-cart" data-backdrop="static" id="mercadoPagoModal">
            <div class="modal-dialog modal-dialog-centered wrapper-cart-modal">
                <div class="modal-content content-cart-modal">

                    <div class="common-container-btn content-bnt-and-link title">
                        <div>
                            <div class="mercadopago-logo"></div>
                        </div>
                        <div>
                            <div class="amount">S/ {{ (+transactionAmount).toFixed(2) }}</div>
                        </div>
                    </div>
                    
                    <p class="message-error-field" v-if="!!errorMessage">
                        {{ errorMessage }}
                    </p>
                    <p class="message-error-field" v-if="verifyData">
                        Error al procesar el pago, por favor revise los datos e intente nuevamente.
                    </p>
                    <form method="post" id="mercadoPagoPay">
                        <input type="hidden" name="description" :value="description"/>
                        <input type="hidden" name="transaction_amount" :value="transactionAmount"/>
                        <input type="hidden" name="payment_method_id" :value="paymentMethodId"/>

                        <div class="common-content-field">
                            <div class="common-content-field">
                                <label class="common-label-field" for="cardholderName">
                                    Nombre y Apellido del titular (igual que en la tarjeta)
                                </label>
                                <input type="text" id="cardholderName" data-checkout="cardholderName" data-vv-name="cardholderName"
                                       :class="['common-input-text', errors.has('cardholderName') ? 'error-input-text' : '']"
                                       v-validate="'required'"
                                       v-model="cardholderName"
                                />
                                <p class="message-error-field" v-if="errors.has('cardholderName')">
                                    {{ errors.first('cardholderName') }}
                                </p>
                            </div>
                        </div>

                        <div class="common-content-field">
                            <div class="common-content-field">
                                <label class="common-label-field" for="cardNumber">Número de tarjeta</label>
                                <div class="input-group">
                                    <input type="number" id="cardNumber" data-vv-name="cardNumber" data-checkout="cardNumber"
                                           :class="['form-control', 'common-input-text', errors.has('cardNumber') ? 'error-input-text' : '']"
                                           maxlength="16"
                                           v-validate="'required|numeric'"
                                           v-model="cardNumber"
                                           onselectstart="return false"
                                           onpaste="return false" onCopy="return false" onCut="return false"
                                           onDrag="return false" onDrop="return false" autocomplete=off
                                           @keyup="guessPaymentMethod" @change="guessPaymentMethod"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ paymentMethodId }}</span>
                                    </div>
                                </div>
                                <p class="message-error-field" v-if="errors.has('cardNumber')">
                                    El número de tarjeta es requerido y de máximo 16 dígitos
                                </p>
                            </div>
                        </div>

                        <div>
                            <div class="common-content-field half-content half-left">
                                <label for="cardExpirationMonth" class="common-label-field">Fecha de vencimiento</label>
                                <div class="common-content-field">
                                    <input type="number" data-vv-name="cardExpirationMonth" id="cardExpirationMonth" data-checkout="cardExpirationMonth"
                                           min="01" max="12" maxlength="2" placeholder="MM"
                                           :class="['common-input-text', 'due-date', errors.has('cardExpirationMonth') ? 'error-input-text' : '']"
                                           v-validate="'required|integer|digits:2'" v-model="cardExpirationMonth"
                                           onselectstart="return false" onpaste="return false" onCopy="return false"
                                           onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off>
                                    <span class="divider-due-date">/</span>
                                    <input type="number" data-vv-name="cardExpirationYear" id="cardExpirationYear" data-checkout="cardExpirationYear"
                                           min="20" max="99" maxlength="2" placeholder="AA"
                                           :class="['common-input-text', 'due-date', errors.has('cardExpirationYear') ? 'error-input-text' : '']"
                                           v-validate="'required|integer|digits:2'" v-model="cardExpirationYear">
                                    <p class="message-error-field" v-if="errors.has('cardExpirationMonth')">
                                        El mes es requerido y de 2 dígitos
                                    </p>
                                    <p class="message-error-field" v-if="errors.has('cardExpirationYear')">
                                        El año es requerido y de 2 dígitos
                                    </p>
                                </div>
                            </div>
                            <div class="common-content-field half-content half-right">
                                <label for="securityCode" class="common-label-field">Código de seguridad</label>
                                <input type="number" data-vv-name="securityCode" id="securityCode" data-checkout="securityCode"
                                       placeholder="CVV / CVC" maxlength="4"
                                       :class="['common-input-text', errors.has('securityCode') ? 'error-input-text' : '']"
                                       v-validate="'required|numeric'" v-model="securityCode"
                                       onselectstart="return false" onpaste="return false" onCopy="return false"
                                       onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off/>
                                <p class="message-error-field" v-if="errors.has('securityCode')">
                                    El código es requerido y de máximo 4 dígitos
                                </p>
                            </div>
                        </div>

                        <div>
                            <div class="common-content-field half-content half-left">
                                <label class="common-label-field">Tipo de documento</label>
                                <dropdown v-model="docType" name="docType"
                                          v-validate="'required'"
                                          :options="docTypeList" value-prop-name="id" label-prop-name="name">
                                </dropdown>
                                <input type="hidden" data-checkout="docType" :value="docType" />

                                <p class="message-error-field" v-if="errors.has('docType')">
                                    Seleccione el tipo de documento
                                </p>
                            </div>
                            <div class="common-content-field half-content half-right">
                                <label class="common-label-field" for="docNumber">Número de documento</label>
                                <input type="text" id="docNumber" data-checkout="docNumber" data-vv-name="docNumber"
                                       v-validate="{required: true, max: docNumberMax, min: docNumberMin}"
                                       v-model="docNumber"
                                       :class="['common-input-text', errors.has('docNumber') ? 'error-input-text' : '']"
                                />

                                <p class="message-error-field" v-if="errors.has('docNumber')">
                                    {{ errors.first('docNumber') }}
                                </p>
                            </div>
                        </div>

                        <div>
                            <div class="common-content-field">
                                <label class="common-label-field" for="email">Correo electrónico</label>
                                <input type="email" id="email" name="email"
                                       v-validate="'required'"
                                       v-model="email"
                                       :class="['common-input-text', errors.has('email') ? 'error-input-text' : '']"
                                />

                                <p class="message-error-field" v-if="errors.has('email')">
                                    {{ errors.first('email') }}
                                </p>
                            </div>
                        </div>
                    </form>

                    <div class="common-container-btn content-bnt-and-link flex-mobile">
                        <div>
                            <a href="javascript:void(0)" class="link-cancel-form" data-dismiss="modal">
                                CANCELAR
                            </a>
                        </div>
                        <div>
                            <div class="pci-logo"></div>
                        </div>
                        <div>
                            <a v-if="showPayButton" href="javascript:void(0)" @click.once="pay" class="common-green-btn">
                                PAGAR
                            </a>
                            <a v-else href="javascript:void(0)" class="common-green-btn">
                                PAGAR
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MercadoPago',
        props: {
            transactionAmount: Number,
            publicKey: String
        },
        data: () =>  {
            return {
                showPayButton: true,
                description: 'Regalo TGL',
                cardBin: null,
                cardNumber: null,
                cardholderName: null,
                cardExpirationMonth: null,
                cardExpirationYear: null,
                securityCode: null,
                paymentMethodId: null,
                docType: null,
                docTypeList: [],
                installments: 1,
                docNumber: null,
                email: null,
                errorMessage: null,
                verifyData: false,
                docNumberMin: 5,
                docNumberMax: 20
            }
        },
        watch: {
            docType: function () {
                this.docTypeList.forEach(type => {
                    if (type.id === this.docType) {
                        this.docNumberMin = type.min_length
                        this.docNumberMax = type.max_length
                    }
                })
            }
        },
        methods: {
            setUnexpectedError() {
                this.errorMessage = 'Error inesperado al conectarse con el proveedor de pago, por favor cierre la ventana e intente nuevamente en un minuto.'
            },
            init() {
                this.showPayButton = true
                this.cardBin = null
                this.cardNumber = null
                this.cardholderName = null
                this.cardExpirationMonth = null
                this.cardExpirationYear = null
                this.securityCode = null
                this.paymentMethodId = null
                this.docType = null
                this.docTypeList = {}
                this.docNumber = null
                this.email = null
                this.errorMessage = null
                this.verifyData = false
                this.docNumberMin = 5
                this.docNumberMax = 20

                this.$validator.fields.items.map( field => {
                    this.$validator.reset(field);
                    this.$validator.errors.remove(field.name, field.scope);
                });

                window.Mercadopago.setPublishableKey(this.publicKey)
                window.Mercadopago.getIdentificationTypes((status, response) => {
                    if (status === 200) {
                        this.docTypeList = response
                    } else {
                        this.setUnexpectedError()
                    }
                })
            },
            guessPaymentMethod() {
                if (this.cardNumber && this.cardNumber.length >= 6) {
                    const bin = this.cardNumber.substring(0,6)
                    if (bin !== this.cardBin) {
                        this.cardBin = bin
                        window.Mercadopago.getPaymentMethod({
                            "bin": bin
                        }, this.setPaymentMethod)
                    }
                } else {
                    this.cardBin = null
                    this.paymentMethodId = null
                }
            },
            setPaymentMethod(status, response) {
                if (status === 200) {
                    this.paymentMethodId = response[0].id
                } else {
                    this.setUnexpectedError()
                }
            },
            pay() {
                this.showPayButton = false
                if (!this.errorMessage) {
                    this.verifyData = false
                    this.$validator.validate().then(result => {
                        if (result && this.isFormValid) {
                            // limpiar sesion, luego de "payment" rechazado
                            window.Mercadopago.clearSession()
                            const $form = document.querySelector('#mercadoPagoPay')
                            window.Mercadopago.createToken($form, this.sdkResponseHandler)
                        } else {
                            this.showPayButton = true
                        }
                    })
                }
            },
            sdkResponseHandler(status, response) {
                if (status !== 200 && status !== 201) {
                    this.verifyData = true
                    this.showPayButton = true
                }else{
                    const mpData = {
                        transaction_amount: this.transactionAmount,
                        token: response.id,
                        description: this.description,
                        installments: this.installments,
                        payment_method_id: this.paymentMethodId,
                        email: this.email
                    }
                    this.$emit('tokencreated', mpData)
                    $('#mercadoPagoModal').modal('hide')
                }
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        mounted() {
            $('#mercadoPagoModal').on('show.bs.modal', this.init)
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.dataCoupleSubmit($event)
          }
        }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.csrf_token) } }),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "wrapper-check-radio" }, [
            _c(
              "label",
              { staticClass: "label-check-radio", attrs: { for: "1_female" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.member_1.is_female,
                      expression: "member_1.is_female"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    value: "1",
                    name: "member_1_is_female",
                    id: "1_female"
                  },
                  domProps: { checked: _vm._q(_vm.member_1.is_female, "1") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.member_1, "is_female", "1")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Novia")])
              ]
            ),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "label-check-radio", attrs: { for: "1_male" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.member_1.is_female,
                      expression: "member_1.is_female"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    value: "0",
                    name: "member_1_is_female",
                    id: "1_male"
                  },
                  domProps: { checked: _vm._q(_vm.member_1.is_female, "0") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.member_1, "is_female", "0")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Novio")])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("NOMBRE *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:50|alpha_spaces",
                    expression: "'required|min:2|max:50|alpha_spaces'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_1.first_name,
                    expression: "member_1.first_name"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_1_first_name")
                },
                attrs: {
                  type: "text",
                  name: "member_1_first_name",
                  placeholder: "Ingresa un nombre"
                },
                domProps: { value: _vm.member_1.first_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.member_1, "first_name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_1_first_name")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_1_first_name")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("APELLIDOS *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:50|alpha_spaces",
                    expression: "'required|min:2|max:50|alpha_spaces'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_1.last_name,
                    expression: "member_1.last_name"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_1_last_name")
                },
                attrs: {
                  type: "text",
                  name: "member_1_last_name",
                  placeholder: "Ingresa un apellido"
                },
                domProps: { value: _vm.member_1.last_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.member_1, "last_name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_1_last_name")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_1_last_name")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("FECHA DE NACIMIENTO *")
              ]),
              _vm._v(" "),
              _c("datepicker", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_1_birthdate")
                },
                attrs: {
                  name: "member_1_birthdate",
                  placeholder: "DD-MM-AAAA",
                  "open-date": _vm.member_1.birthdate
                    ? _vm.member_1.birthdate
                    : _vm.getOpenDate(),
                  id: "birthdate1",
                  language: _vm.es,
                  disabledDates: _vm.disabledDates,
                  initialView: "" + (_vm.member_1.birthdate ? "day" : "year")
                },
                on: { selected: _vm.changeBirthDate1 },
                model: {
                  value: _vm.member_1.birthdate,
                  callback: function($$v) {
                    _vm.$set(_vm.member_1, "birthdate", $$v)
                  },
                  expression: "member_1.birthdate"
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_1_birthdate")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_1_birthdate")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("CORREO ELECTRÓNICO *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email|min:5",
                    expression: "'required|email|min:5'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_1.email,
                    expression: "member_1.email"
                  }
                ],
                staticClass: "common-input-text",
                class: { "error-input-text": _vm.errors.has("member_1_email") },
                attrs: {
                  type: "email",
                  name: "member_1_email",
                  placeholder: "Ingresa un correo electrónico"
                },
                domProps: { value: _vm.member_1.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.member_1, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_1_email")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_1_email")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("¿CÓMO TE ENCONTRAMOS EN FACEBOOK?")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min:2|max:60|alpha_spaces",
                    expression: "'min:2|max:60|alpha_spaces'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_1.account_facebook,
                    expression: "member_1.account_facebook"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_1_facebook")
                },
                attrs: { type: "text", name: "member_1_facebook" },
                domProps: { value: _vm.member_1.account_facebook },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.member_1,
                      "account_facebook",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_1_facebook")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_1_facebook")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field container-checkbox" },
            [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { is_not: _vm.member_1.email },
                    expression: "{is_not: member_1.email}"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_1.receive_email,
                    expression: "member_1.receive_email"
                  }
                ],
                staticClass: "checkbox-form-tgl",
                attrs: {
                  type: "checkbox",
                  name: "1_receive_email",
                  id: "1_receive_email"
                },
                domProps: {
                  checked: Array.isArray(_vm.member_1.receive_email)
                    ? _vm._i(_vm.member_1.receive_email, null) > -1
                    : _vm.member_1.receive_email
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.member_1.receive_email,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.member_1,
                            "receive_email",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.member_1,
                            "receive_email",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.member_1, "receive_email", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(1)
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "next-content" }, [
          _c("div", { staticClass: "wrapper-check-radio" }, [
            _c(
              "label",
              { staticClass: "label-check-radio", attrs: { for: "2_female" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.member_2.is_female,
                      expression: "member_2.is_female"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    value: "1",
                    name: "member_2_is_female",
                    id: "2_female"
                  },
                  domProps: { checked: _vm._q(_vm.member_2.is_female, "1") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.member_2, "is_female", "1")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Novia")])
              ]
            ),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "label-check-radio", attrs: { for: "2_male" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.member_2.is_female,
                      expression: "member_2.is_female"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "custom-check-radio",
                  attrs: {
                    type: "radio",
                    hidden: "",
                    value: "0",
                    name: "member_2_is_female",
                    id: "2_male"
                  },
                  domProps: { checked: _vm._q(_vm.member_2.is_female, "0") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.member_2, "is_female", "0")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "label-text" }, [_vm._v("Novio")])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("NOMBRE *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:50|alpha_spaces",
                    expression: "'required|min:2|max:50|alpha_spaces'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_2.first_name,
                    expression: "member_2.first_name"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_2_first_name")
                },
                attrs: {
                  type: "text",
                  name: "member_2_first_name",
                  placeholder: "Ingresa un nombre"
                },
                domProps: { value: _vm.member_2.first_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.member_2, "first_name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_2_first_name")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_2_first_name")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("APELLIDOS *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:2|max:50|alpha_spaces",
                    expression: "'required|min:2|max:50|alpha_spaces'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_2.last_name,
                    expression: "member_2.last_name"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_2_last_name")
                },
                attrs: {
                  type: "text",
                  name: "member_2_last_name",
                  placeholder: "Ingresa un apellido"
                },
                domProps: { value: _vm.member_2.last_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.member_2, "last_name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_2_last_name")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_2_last_name")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("FECHA DE NACIMIENTO *")
              ]),
              _vm._v(" "),
              _c("datepicker", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_2_birthdate")
                },
                attrs: {
                  name: "member_2_birthdate",
                  placeholder: "DD-MM-AAAA",
                  "open-date": _vm.member_2.birthdate
                    ? _vm.member_2.birthdate
                    : _vm.getOpenDate(),
                  id: "birthdate2",
                  language: _vm.es,
                  disabledDates: _vm.disabledDates,
                  initialView: "" + (_vm.member_2.birthdate ? "day" : "year")
                },
                on: { selected: _vm.changeBirthDate2 },
                model: {
                  value: _vm.member_2.birthdate,
                  callback: function($$v) {
                    _vm.$set(_vm.member_2, "birthdate", $$v)
                  },
                  expression: "member_2.birthdate"
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_2_birthdate")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_2_birthdate")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-right" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("CORREO ELECTRÓNICO *")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email|min:5",
                    expression: "'required|email|min:5'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_2.email,
                    expression: "member_2.email"
                  }
                ],
                staticClass: "common-input-text",
                class: { "error-input-text": _vm.errors.has("member_2_email") },
                attrs: {
                  type: "email",
                  name: "member_2_email",
                  placeholder: "Ingresa un correo electrónico"
                },
                domProps: { value: _vm.member_2.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.member_2, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_2_email")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_2_email")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field half-content half-left" },
            [
              _c("label", { staticClass: "common-label-field" }, [
                _vm._v("¿CÓMO TE ENCONTRAMOS EN FACEBOOK?")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min:2|max:60|alpha_spaces",
                    expression: "'min:2|max:60|alpha_spaces'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_2.account_facebook,
                    expression: "member_2.account_facebook"
                  }
                ],
                staticClass: "common-input-text",
                class: {
                  "error-input-text": _vm.errors.has("member_2_facebook")
                },
                attrs: { type: "text", name: "member_2_facebook" },
                domProps: { value: _vm.member_2.account_facebook },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.member_2,
                      "account_facebook",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("member_2_facebook")
                ? _c("p", { staticClass: "message-error-field" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.errors.first("member_2_facebook")) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field container-checkbox" },
            [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { is_not: _vm.member_2.email },
                    expression: "{is_not: member_2.email}"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member_2.receive_email,
                    expression: "member_2.receive_email"
                  }
                ],
                staticClass: "checkbox-form-tgl",
                attrs: {
                  type: "checkbox",
                  name: "2_receive_email",
                  id: "2_receive_email"
                },
                domProps: {
                  checked: Array.isArray(_vm.member_2.receive_email)
                    ? _vm._i(_vm.member_2.receive_email, null) > -1
                    : _vm.member_2.receive_email
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.member_2.receive_email,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.member_2,
                            "receive_email",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.member_2,
                            "receive_email",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.member_2, "receive_email", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(2)
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "common-container-btn text-right" }, [
          _c(
            "button",
            {
              staticClass: "common-green-btn",
              attrs: {
                type: "submit",
                disabled: _vm.isEventPublished && !_vm.isFormChanged
              }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.isEventPublished ? "ACTUALIZAR" : "SIGUIENTE") +
                  "\n                "
              ),
              !_vm.isEventPublished
                ? _c("span", { staticClass: "arrow-into-btn" })
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.response_error_message
            ? _c("p", {
                staticClass: "error-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_error_message) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.response_success_message
            ? _c("p", {
                staticClass: "success-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_success_message) }
              })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "common-title-section" }, [
      _vm._v("DATOS DE LA PAREJA "),
      _c("div", { staticClass: "ico-heart-title" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "label-field-checkbox",
        attrs: { for: "1_receive_email" }
      },
      [
        _c(
          "span",
          { staticClass: "text-label-checkbox text-label-data-couple w-auto" },
          [
            _vm._v(
              "Quiero recibir un correo electrónico cada vez que regalen algo."
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "label-field-checkbox",
        attrs: { for: "2_receive_email" }
      },
      [
        _c(
          "span",
          { staticClass: "text-label-checkbox text-label-data-couple w-auto" },
          [
            _vm._v(
              "Quiero recibir un correo electrónico cada vez que regalen algo."
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="modal fade" id="storeItemModal">
        <div class="modal-dialog modal-dialog-centered wrapper-store-item-modal">
            <div class="modal-content content-store-item-modal">
                <form method="post" @submit.prevent="storeItemSubmit">
                    <div v-html="csrf_token" hidden></div>
                    <div class="wrapper-item wrapper-item-normal">

                        <div hidden >
                            <input type="file" id="imageItemFile" hidden
                                name="item_img_file" v-validate="'ext:jpeg,jpg,png|size:5000'"
                                ref="item_image_file" v-on:change="updateItemImage" v-if="item.image"/>

                            <input type="file" id="imageItemFile" hidden
                                name="item_img_file" v-validate="'required|ext:jpeg,jpg,png|size:5000'"
                                ref="item_image_file" v-on:change="updateItemImage" v-if="!item.image"/>
                        </div>

                        <div class="wrapper-image-card wrapper-select-image" v-if="is_custom_item"
                            :style="{ backgroundImage: 'url('+item.image_url+')'}" @dragover.prevent @drop="onDrop">
                            <label for="imageItemFile"
                                   v-bind:class="['select-image-label', item.image_url ? '' : 'no-has-image']"
                                   >
                            </label>
                        </div>

                        <div class="wrapper-image-card" :style="{ backgroundImage: 'url('+item.image_url+')'}" v-else>

                            <div class="wrapper-buttons-card text-center" v-if="!item.is_donation_gift && !item.fixed_gift">
                                <label for="imageItemFile" class="change-image-label">Cambiar imagen</label>
                            </div>

                            <div class="wrapper-info-is-donation" v-if="item.is_donation_gift">
                                <div class="img-solidary"></div>
                                <div class="info-soldy-gift">
                                    Al agregar este regalo estarás donando ese dinero.
                                    The Gift List transferirá el dinero de este regalo directamente a la
                                    organización que elegiste.
                                </div>
                            </div>

                        </div>

                        <div class="wrapper-info-card">
                            <p class="message-error-field" v-if="!item_image_drop_valid && errors.has('item_img_file')">
                                {{ errors.first('item_img_file') }}
                            </p>

                            <div class="common-content-field" v-if="item.fixed_gift && !item.is_donation_gift">
                                Regalo fijo: al utilizar este regalo estarás ayudando a promocionar un emprendimiento social.
                                Por eso no podrás modificar el precio ni la descripción. No lo estarás comprando ni estarás
                                aportando al emprendimiento, solo estarás ayudando a dar a conocer sus productos!
                            </div>

                            <div class="common-content-field">
                                <label class="common-label-field">NOMBRE DEL REGALO*</label>
                                <input type="text" name="item_name" class="common-input-text" placeholder="Ingresa un nombre"
                                       v-validate="'required|min:2|max:45'"
                                       v-bind:class="{'error-input-text': errors.has('item_name')}"
                                       v-model="item.name" :disabled="item.is_donation_gift || item.fixed_gift">
                                <p class="message-error-field" v-if="errors.has('item_name')">{{ errors.first('item_name') }}</p>
                            </div>

                            <div class="common-content-field half-content half-left">
                                <label class="common-label-field">PRECIO*</label>
                                <input type="text" name="item_price" class="common-input-text currency-symbol "
                                       v-bind:class="{'soles': currency.id === 1, 'dolar': currency.id === 2, 'error-input-text': errors.has('item_price')}"
                                       placeholder="Ingresa un precio"
                                       v-validate="'required|numeric|min_value:10'"
                                       v-model.number="item.price"
                                       :disabled="is_purchased || item.fixed_gift"
                                       key="item_price"
                                >

                                <p class="message-error-field" v-if="errors.has('item_price')">{{ errors.first('item_price') }}</p>

                            </div>
                            <div class="common-content-field half-content half-right">
                                <div class="container-checkbox checkbox-quota">
                                    <input type="checkbox" id="itemInQuota" class="checkbox-form-tgl"
                                           @change="item.num_quota = item.has_quota ? 2 : 1"
                                           v-model="item.has_quota"
                                           v-if="!is_purchased"
                                    >
                                    <label class="label-field-checkbox" for="itemInQuota" v-if="!is_purchased">
                                        <span class="text-label-checkbox">
                                            En cuotas
                                        </span>
                                    </label>
                                    <div class="icon-help-tool icon-tooltip-modal icon-alone" v-show="is_purchased"
                                          v-tooltip="{placement: 'right', title: 'El regalo ya ha sido comprado, no se puede modificar el precio ni las cuotas.' }">
                                    </div>
                                    <div class="icon-help-tool icon-tooltip-modal" v-show="!is_purchased"
                                          v-tooltip="{placement: 'right', title: 'Elige esta opción si quieres que el precio total de este regalo se divida en cuotas. De esta manera tus invitados podrán regalarte una o más cuotas.' }">
                                    </div>
                                </div>
                            </div>

                            <div v-if="item.has_quota">
                                <div class="common-content-field half-content half-left">
                                    <label class="common-label-field">NÚMERO DE CUOTAS</label>
                                    <dropdown v-model.number="item.num_quota"
                                              :options="getQuotaOptions()"
                                              :disabled="is_purchased">
                                    </dropdown>
                                </div>
                                <div class="common-content-field half-content half-right">
                                    <label class="common-label-field">VALOR DE CADA CUOTA</label>
                                    <span class="price-quota">{{ currency.symbol }} {{ item.price_quota }}</span>
                                </div>
                            </div>

                            <div class="common-content-field">
                                <label class="common-label-field">DESCRIPCIÓN</label>
                                <textarea type="text" name="item_description" class="common-input-text"
                                       placeholder="Ingresar una descripción"
                                       v-bind:class="{'error-input-text': errors.has('item_description')}"
                                       v-validate="{max: item.fixed_gift || item.is_donation_gift ? 300 : 200}"
                                       rows="3" v-model="item.description" :disabled="item.fixed_gift">
                                </textarea>

                                <p class="message-error-field" v-if="errors.has('item_description')">{{ errors.first('item_description') }}</p>
                            </div>

                            <div v-if="!item.is_donation_gift">
                                <div class="common-content-field half-content half-left">
                                    <label class="common-label-field">CATEGORÍA DEL REGALO</label>
                                    <dropdown v-model.number="item.parent_category_id"
                                              name="select_parent_category"
                                              @change="onChangeCategory"
                                              v-validate="'required'"
                                              :options="list_category" value-prop-name="id" label-prop-name="label">
                                    </dropdown>
                                </div>
                                <div class="common-content-field half-content half-right">
                                    <label class="common-label-field">SUBCATEGORÍA DEL REGALO</label>
                                    <dropdown v-model.number="item.category_id"
                                              name="select_sub_category"
                                              v-validate="'required'"
                                              :options="list_sub_category" value-prop-name="id" label-prop-name="label">
                                    </dropdown>
                                </div>
                            </div>

                            <p class="message-error-field" v-if="errors.has('select_parent_category')">
                                {{ errors.first('select_parent_category') }}
                            </p>
                            <p class="message-error-field" v-if="errors.has('select_sub_category')">
                                {{ errors.first('select_sub_category') }}
                            </p>

                            <div class="common-container-btn content-bnt-and-link">
                                <a href="/users/edit-account/" class="link-cancel-form" data-dismiss="modal">CANCELAR</a>
                                <button type="submit" class="common-green-btn">
                                    <span v-if="this.item.id">GUARDAR</span>
                                    <span v-else>AGREGAR ÍTEM</span>
                                </button>
                            </div>

                            <div>
                                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

    import * as eventsAPI from '../../api/events'
    import * as EventUtils from './utils'

    export default {
        name: "store-item-modal",
        props: ['data_list_category', 'currency', 'csrf_token', 'event_id'],
        data: () =>  {
            return {
                item: {
                    id: null,
                    name: '',
                    price: '',
                    has_quota: false,
                    available_quota: 1,
                    num_quota: 1,
                    price_quota: 0,
                    description: '',
                    category_id: null,
                    parent_category_id: null,
                    image: '',
                    image_url: '',
                    item_id: null,
                    original_item: null,
                    is_donation_gift: null,
                    company_item_id: null,
                    fixed_gift: false,
                },
                item_image_file: '',
                item_image_drop_valid: false,
                list_category: null,
                list_sub_category: null,
                response_error_message: null,
                response_success_message: null,
                is_custom_item: false,
                is_purchased: false
            }
        },

        watch: {
            'item.num_quota': function(){
                this.changePriceQuota()
            },
            'item.price': function(){
                this.changePriceQuota()
            }
        },
        computed: {
            isButtonDisabled: function() {
                return !!this.response_error_message || !!this.response_success_message || !this.isFormValid;
            },
            isFormValid: function() {
                return Object.keys(this.fields).every(key => {
                    if (this.is_custom_item && key === 'item_img_file') {
                        return this.item_image_drop_valid || (this.fields[key] && this.fields[key].valid)
                    }
                    return this.fields[key] && this.fields[key].valid
                });
            }
        },
        methods:{
            loadCreateDataModal: function (obj_item=null) {

                this.item_image_file = '';
                this.item_image_drop_valid = false;
                this.is_custom_item = !obj_item;
                this.is_purchased = false;

                this.item = {
                    id: null,
                    name: '',
                    price: null,
                    has_quota: false,
                    available_quota: 1,
                    num_quota: 1,
                    price_quota: 0,
                    description: '',
                    category_id: null,
                    parent_category_id: null,
                    image: '',
                    image_url: '',
                    item_id: null,
                    original_item: null,
                    fixed_gift: false
                };

                if(!this.is_custom_item){
                    let is_donation_gift = obj_item.is_donation_gift ? obj_item.is_donation_gift : false;

                    this.item.name = obj_item.name;
                    this.item.category_id = obj_item.category_id;
                    this.item.parent_category_id = obj_item.category_id__parent_id;
                    this.item.image = obj_item.image;
                    this.item.image_url = obj_item.image;
                    this.item.item_id = !is_donation_gift || obj_item.fixed_gift ? obj_item.id : null;
                    this.item.original_item = obj_item;
                    this.item.is_donation_gift = is_donation_gift;
                    this.item.company_item_id = is_donation_gift ? (obj_item.fixed_gift ? obj_item.donation_company__id : obj_item.id) : null;
                    this.item.fixed_gift = obj_item.fixed_gift ? obj_item.fixed_gift : false;

                    if (this.item.is_donation_gift) {
                        this.item.description = obj_item.description ? obj_item.description : '';
                    }

                    if (this.item.fixed_gift) {
                        this.item.description = obj_item.description ? obj_item.description : '';
                        if (this.currency.id == 1) {
                            this.item.price = obj_item.price_soles;
                        } else if (this.currency.id == 2) {
                            this.item.price = obj_item.price_dollars;
                        }
                    }
                }

                this.loadSubCategory();

                this.$validator.fields.items.map( field => {
                    this.$validator.reset(field);
                    this.$validator.errors.remove(field.name, field.scope);
                });

            },
            getQuotaOptions: function() {
                return [2,3,4,5,6,7,8,9,10,20,50,100]
            },
            loadUpdateDataModal: function (obj_item=null) {

                this.item_image_file = '';
                this.item_image_drop_valid = false;

                if(obj_item){
                    const has_quota = obj_item.num_quota > 1;
                    let is_donation_gift = obj_item.is_donation_gift ? obj_item.is_donation_gift : false;

                    this.item = {
                        id: obj_item.id,
                        name: obj_item.name,
                        price: obj_item.price ? parseFloat(obj_item.price) : null,
                        has_quota: has_quota,
                        available_quota: obj_item.available_quota,
                        num_quota: has_quota ? obj_item.num_quota : 1,
                        price_quota: has_quota ? obj_item.price_quota : 0,
                        description: obj_item.description ? obj_item.description : '',
                        category_id: obj_item.category_id,
                        parent_category_id: obj_item.category_id__parent_id,
                        image: obj_item.image,
                        image_url: obj_item.image,
                        item_id: obj_item.item_id ? obj_item.item_id : null,
                        is_donation_gift: obj_item.is_donation_gift ? obj_item.is_donation_gift : false,
                        company_item_id: (is_donation_gift) ? obj_item.company_item_id : null,
                        is_added_to_cart: obj_item.is_added_to_cart,
                        fixed_gift: obj_item.fixed_gift ? obj_item.fixed_gift : false,
                    };
                    this.is_custom_item = false;
                    this.is_purchased = EventUtils.isItemPurchased(this.item);
                }

                this.loadSubCategory();

                this.$validator.fields.items.map( field => {
                    this.$validator.reset(field);
                    this.$validator.errors.remove(field.name, field.scope);
                });

            },
            onChangeCategory: function() {
                this.item.category_id = null;
                this.loadSubCategory();
            },
            loadSubCategory: function(){
                for (const key in this.list_category){
                    const cat_parent = this.list_category[key];
                    if(cat_parent.id === this.item.parent_category_id){
                        this.list_sub_category = cat_parent.sub_cats;
                        break;
                    }
                }
            },
            changePriceQuota: function(){

                let price_quota = +parseFloat(this.item.price/this.item.num_quota).toFixed(2);
                this.item.price_quota = !isNaN(price_quota) ? price_quota : 0;

            },
            storeItemSubmit: function(){

                this.$validator.validateAll().finally(() => {
                    if (!this.response_error_message && !this.response_success_message && this.isFormValid) {
                        const data = {
                            id: this.item.id,
                            item_id: this.item.item_id,
                            category_id: this.item.category_id,
                            name: this.item.name,
                            description: this.item.description,
                            price: this.item.price,
                            price_quota: this.item.has_quota ? this.item.price_quota : 0,
                            num_quota: this.item.has_quota ? this.item.num_quota : 1,
                            event_id: this.event_id,
                            image: this.item_image_file,
                            is_donation_gift: this.item.is_donation_gift,
                            company_item_id: this.item.company_item_id
                        };

                        eventsAPI.storeEventItem(data).then((response) => {

                            if (response.data.success) {

                                this.response_error_message = '';
                                this.response_success_message = response.data.message;

                                if (this.item.id) {
                                    this.$emit('item-updated', response.data.event_item);
                                } else {
                                    let event_item = response.data.event_item;
                                    event_item.original_item = this.item.original_item;
                                    this.$emit('item-added', event_item);
                                }

                            } else {

                                this.response_error_message = response.data.message;
                                this.response_success_message = '';

                            }

                            this.hideMessagesResponse();

                        }).catch((error) => {

                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();
                        });
                    }
                });

            },
            updateItemImage() {
                const file = this.$refs.item_image_file.files[0];

                if(file){
                    this.item_image_file = file;
                    this.item.image_url = URL.createObjectURL(this.item_image_file);
                    this.item_image_drop_valid = false;
				} else if (this.item_image_file && this.item.image_url) {
                    this.item_image_drop_valid = true;
                }

            },
            hideMessagesResponse: function(){
                setTimeout(() => {
                    if (this.response_success_message) {
                        $('#storeItemModal').modal('hide');
                    }
                    this.response_error_message = '';
                    this.response_success_message = '';
                }, 2000);
            },
            onDrop: function(e) {

                e.stopPropagation();
                e.preventDefault();

                let file = e.dataTransfer.files[0];

                let files_allowed = ['jpeg', 'jpg', 'png'];
                let file_name = file.name.toLowerCase();
                let extension = file_name.slice((file_name.lastIndexOf('.')+1), file_name.length);


                let correct_size = 5000*1024;
                let is_file_allowed = files_allowed.indexOf(extension);

                if(is_file_allowed !== -1 && file.size <= correct_size){
                    this.item_image_drop_valid = true;
                    this.item_image_file = file;
                    this.item.image_url = URL.createObjectURL(file);

                }
            }
        },
        created() {
            if(this.data_list_category){
                this.list_category = this.data_list_category;
            }

        }
    }
</script>

import { callAPIPost } from './utils'

export const sendCode = (email, email_to) => {
    return callAPIPost('/api/users/send-code', { email, email_to })
};

export const checkCode = (code, email) => {
    return callAPIPost('/api/users/check-code', { code, email })
};

export const registerUser = (first_name, last_name, email, password) => {
    return callAPIPost('/api/users/register-user', { first_name, last_name, email, password })
};

export const recoveryPasswordUpdatePassword = (email, password, security_code) => {
    return callAPIPost(
        '/api/users/update-password',
        { email, password, security_code }
        )
};

export const validateDataAccount = (
    first_name, last_name, email, new_email, current_password, new_password
) => {
    return callAPIPost(
        '/api/users/validate-data-account',
        { first_name, last_name, email, new_email, current_password, new_password }
        )
};

export const updateAccount = (
    first_name, last_name, email, new_email, telephone, address, city, current_password, new_password, security_code
) => {
    return callAPIPost(
        '/api/users/edit-account',
        {
            first_name, last_name, email, new_email,
            telephone, address, city,
            current_password, new_password, security_code
        })
};

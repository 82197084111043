var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.csrf_token) } }),
        _vm._v(" "),
        _vm._l(_vm.locations, function(location, index) {
          return _c("EventInformationLocation", {
            key: index,
            attrs: {
              index: index,
              location: location,
              googleMapsApiKey: _vm.googleMapsApiKey,
              show: _vm.show_locations,
              icon_list: _vm.icon_list
            },
            on: { "delete-location": _vm.deleteLocation }
          })
        }),
        _vm._v(" "),
        _vm.locations.length < _vm.max_locations_number
          ? _c(
              "div",
              {
                staticClass: "page-event-info-section",
                class: { deactivated: !_vm.show_locations }
              },
              [
                _c(
                  "div",
                  { staticClass: "row tgl-row event-info-add-location" },
                  [
                    _c("div", { staticClass: "col-12 tgl-col" }, [
                      _c(
                        "button",
                        {
                          staticClass: "common-green-btn add-location-btn",
                          attrs: {
                            type: "button",
                            disabled: !_vm.show_locations
                          },
                          on: { click: _vm.addLocation }
                        },
                        [
                          _vm._v(
                            "\n                        + AGREGAR OTRA LOCACIÓN (ej. Ceremonia, Recepción)\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "page-event-info-section" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [
              _vm._v("HASHTAG")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "alpha_num|max:140",
                  expression: "'alpha_num|max:140'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.hashtag,
                  expression: "hashtag"
                }
              ],
              staticClass: "common-input-text",
              class: { "error-input-text": _vm.errors.has("hashtag") },
              attrs: { type: "text", name: "hashtag", placeholder: "#" },
              domProps: { value: _vm.hashtag },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.hashtag = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("hashtag")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("hashtag")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [
              _vm._v("DRESS CODE")
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "max:400",
                  expression: "'max:400'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.dress_code,
                  expression: "dress_code"
                }
              ],
              staticClass: "common-input-text",
              class: { "error-input-text": _vm.errors.has("dress_code") },
              attrs: {
                type: "text",
                id: "dress_code",
                name: "dress_code",
                rows: "3"
              },
              domProps: { value: _vm.dress_code },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.dress_code = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("dress_code")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("dress_code")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "common-content-field" },
            [
              _c("editor", {
                attrs: {
                  init: {
                    menubar: false,
                    plugins: ["lists"],
                    toolbar:
                      "formatselect | bold italic strikethrough forecolor | alignleft aligncenter alignright alignjustify | numlist bullist"
                  }
                },
                model: {
                  value: _vm.additional_info,
                  callback: function($$v) {
                    _vm.additional_info = $$v
                  },
                  expression: "additional_info"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-event-info-section" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", [
            _vm.response_error_message
              ? _c("p", {
                  staticClass: "error-submit-form-message",
                  domProps: { innerHTML: _vm._s(_vm.response_error_message) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.response_success_message
              ? _c("p", {
                  staticClass: "success-submit-form-message",
                  domProps: { innerHTML: _vm._s(_vm.response_success_message) }
                })
              : _vm._e()
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row tgl-row event-info-title" }, [
      _c("div", { staticClass: "col-md-12 tgl-col" }, [
        _c("h4", { staticClass: "common-title-section" }, [
          _vm._v("INFORMACIÓN ADICIONAL")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-container-btn text-right" }, [
      _c(
        "button",
        { staticClass: "common-green-btn", attrs: { type: "submit" } },
        [_vm._v("\n                    GUARDAR\n                ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import { callAPIPost, callAPIPostIncludeFiles, callAPIGet } from './utils'


export const createEvent = (event_type_id, title, prom_code) => {
    return callAPIPost('/api/events/create-event', { event_type_id, title, prom_code })
};

export const dataCouple = (data) => {
    return callAPIPost('/api/events/store-data-couple', data, true)
};

export const storeDataEventDetail = (data) => {
    return callAPIPost('/api/events/store-data-event-detail', data)
};

export const storeCoverDesign = (data) => {
    return callAPIPostIncludeFiles('/api/events/store-cover-design', data)
};

// {id, item_id, event_id, category_id, name, description, price, image, price_quota, num_quota,
// is_donation_gift, company_item_id}
export const storeEventItem = ({id, item_id, event_id, category_id, name, description, price, image,
    price_quota, num_quota, is_donation_gift, company_item_id}) => {
    return callAPIPostIncludeFiles('/api/events/store-event-item', {
        id: id ? id: '',
        item_id: item_id ? item_id : '',
        event_id, name, description, price, image, is_donation_gift,
        category_id: category_id ? category_id : '',
        company_item_id: company_item_id ? company_item_id : '',
        price_quota: price_quota ? price_quota : 0,
        num_quota: num_quota ? num_quota : 1
    })
};

export const changeAccountContribution = (event_id, enable) => {
    return callAPIPost('/api/events/change-account-contribution', { event_id, enable })
};

export const changeCurrency = (event_id, currency_id) => {
    return callAPIPost('/api/events/change-currency', { event_id, currency_id })
};

export const storeList = (event_id) => {
    return callAPIPost('/api/events/store-list', { event_id })
};

export const verifyToken = (event_id, token) => {
    return callAPIPost('/api/events/verify-token', { event_id, token })
};

export const deleteEventItem = (data) => {
    return callAPIPost('/api/events/delete-event-item', data)
};
export const storeBankAccount = (data) => {
    return callAPIPost('/api/events/store-account-bank', data)
};

export const validateCustomURL = (event_id, url) => {
    return callAPIPost('/api/events/validate-custom-url', { event_id, url })
};

export const storeDataPublish = (data) => {
    return callAPIPost('/api/events/store-data-publish', data)
};

export const getDedicatories = () => {
    return callAPIGet(window.location.pathname + 'dedicatories')
};

export const updateDedicatory = (event_id, order_id) => {
    return callAPIPost('/api/events/update-dedicatory', { event_id, order_id })
};

export const updateShowDedicatoryPage = (event_id, show_dedicatories_page) => {
    return callAPIPost('/api/events/update-show-dedicatory-page', { event_id, show_dedicatories_page })
};

export const saveEventInformation = (data) => {
    return callAPIPost('/api/events/save-event-information', data, true)
};

export const updateShowEventInfoPage = (event_id, show_event_info_page) => {
    return callAPIPost('/api/events/update-show-event-info-page', { event_id, show_event_info_page })
};
export const getEventInfo = () => {
    return callAPIGet(window.location.pathname + 'event-info-page')
};
import 'bootstrap/js/dist/tooltip';


export default function TooltipDirective(el, binding) {
    $(el).tooltip({
        title: binding.value.title,
        placement: binding.value.placement,
        trigger: 'hover',
    });
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "common-content-field" }, [
    _c("label", { staticClass: "common-label-field" }, [_vm._v("DIRECCIÓN *")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "validate",
          rawName: "v-validate",
          value: "required|max:150",
          expression: "'required|max:150'"
        },
        {
          name: "model",
          rawName: "v-model",
          value: _vm.location.address,
          expression: "location.address"
        }
      ],
      ref: "autocomplete",
      staticClass: "common-input-text",
      class: { "error-input-text": _vm.errors.has("address") },
      attrs: {
        type: "text",
        name: "address",
        placeholder: "",
        disabled: !_vm.show
      },
      domProps: { value: _vm.location.address },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.location, "address", $event.target.value)
        }
      }
    }),
    _vm._v(" "),
    _vm.errors.has("address")
      ? _c("p", { staticClass: "message-error-field" }, [
          _vm._v("\n        " + _vm._s(_vm.errors.first("address")) + "\n    ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
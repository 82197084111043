import { callAPIPost } from './utils'

export const login = (email, password, remember_me, redirect_to) => {
    return callAPIPost('/api/users/login', {
        email,
        password,
        remember_me: remember_me ? 'true' : '',
        redirect_to
    })
};

<template>
    <div>
        <div class="content-fields-form">
            <p></p>
            <form method="post" v-if="show_form" @submit.prevent="submitForm">
                <div v-html="data_third_step.csrf_token"></div>

                <div class="content-field">
                    <label class="label-field">NUEVA CONTRASEÑA</label>

                    <input type="password" name="password" v-model="user.password"
                           class="input-text-form-tgl" placeholder="Ingresa tu contraseña"
                           v-validate="'required|min:8'" ref="password"
                           v-bind:class="{'error-input-text': errors.has('password')}">
                    <p class="text-message-error" v-if="errors.has('password')">{{ errors.first('password') }}</p>
                </div>

                <div class="content-field">
                    <label class="label-field">CONFIRMA NUEVA CONTRASEÑA</label>
                    <input type="password" name="confirm_password" v-model="user.confirm_password"
                           class="input-text-form-tgl" placeholder="Reingresa tu contraseña"
                           v-validate="'required|confirmed:password'"
                           v-bind:class="{'error-input-text': errors.has('confirm_password')}">
                    <p class="text-message-error" v-if="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</p>
                </div>

                <div class="content-submit content-bnt-recover-pass">
                    <a v-bind:href="data_third_step.link_login" class="link-cancel-form">CANCELAR</a>
                    <button type="submit" class="btn-submit-tgl">
                        GUARDAR
                    </button>
                </div>

                <div v-if="sent_form">
                    <p v-html="response_form_message" class="error-submit-form-message"></p>
                </div>

            </form>
            <div class="content-success-register" v-show="!show_form">
                <h5 class="title-form-success">CAMBIO DE CONTRASEÑA EXITOSO</h5>
                <p class="info-form-success">
                    ¡Has cambiado tu contraseña correctamente!
                </p>
                <div class="content-submit">
                    <a href="/" class="btn-submit-tgl btn-finish-register">FINALIZAR</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as usersAPI from '../../api/users'

    export default {
        name: "recovery-p-new-password",
        props: ['data_third_step'],
        data: () =>  {
            return {
                user: {
                    password: null,
                    confirm_password: null
                },
                sent_form: null,
                response_form_message: '',
                show_form: true
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            submitForm: function () {

                this.$validator.validateAll().finally(() => {
                    if (this.isFormValid) {

                        usersAPI.recoveryPasswordUpdatePassword(
                            this.data_third_step.user_email,
                            this.user.password,
                            this.data_third_step.security_code).then((response) => {

                            this.response_form_message = response.data.message;

                            if (response.data.success) {

                                this.show_form = false;

                            }

                        }).catch((error) => {
                            this.response_form_message = 'Ocurrió un error, intente de nuevo.';
                        });

                        this.sent_form = true;
                    }
                });

            }
        }
    }


</script>
<template>
    <div>
        <p class="description-page">
            Ingresa los datos de la cuenta bancaria dónde recibirás las transferencias de los
            regalos. Cada vez que recibas un regalo, podrás ver el monto actualizado en tu
            pagina de administrador. Puedes solicitar la transferencia a tu cuenta en el
            momento que prefieras.
        </p>
        <div class="common-wrapper-content-page">
            <div class="common-container-content-page">
                <h4 class="common-title-section">INFORMACIÓN DE LA CUENTA</h4>
                <p></p>
                <p v-if="data_vue.event.event_type_id == data_vue.event_type.BABY_SHOWER.id"
                   class="description-page not-padding">
                    La cuenta bancaria debe estar a nombre de la mamá / del papá.
                </p>

                <p v-if="data_vue.event.event_type_id == data_vue.event_type.BIRTHDAY.id"
                   class="description-page not-padding">
                    La cuenta bancaria debe estar a nombre del cumpleañero / cumpleañera.
                </p>

                <p class="description-page not-padding"
                    v-if="data_vue.event.event_type_id == data_vue.event_type.WEDDING.id || data_vue.event.event_type_id == data_vue.event_type.SHOWER.id">
                    La cuenta bancaria debe estar a nombre del novio / de la novia.
                </p>
                <p></p>

                <div class="common-content-field" v-if="data_vue.event.event_type_id != data_vue.event_type.BIRTHDAY.id">
                    <div class="wrapper-check-radio">
                        <label v-for="member in data_vue.couple_members" :for="'member_'+member.id" class="label-check-radio">
                            <input type="radio" hidden :value="member.id" name="member_id" :id="'member_'+member.id"
                                   class="custom-check-radio" v-model.number="data_bank.couple_member_id" v-validate="'required'">
                            <span class="label-text">Usar cuenta de {{ member.first_name }}</span>
                        </label>
                    </div>
                </div>
                <div>
                    <div class="common-content-field half-content half-left">
                        <label class="common-label-field">NOMBRE *</label>

                        <input type="text" name="first_name" placeholder="Ingresa un nombre"
                               :class="['common-input-text', errors.has('first_name') ? 'error-input-text' : '']"
                               v-validate="'required|min:2|max:50|alpha_spaces'" v-model="data_bank.holder_first_name">

                        <p class="message-error-field" v-if="errors.has('first_name')">
                            {{ errors.first('first_name') }}
                        </p>

                    </div>
                    <div class="common-content-field half-content half-right">
                        <label class="common-label-field">APELLIDOS *</label>

                        <input type="text" name="last_name" placeholder="Ingresa un apellido"
                               :class="['common-input-text', errors.has('last_name') ? 'error-input-text' : '']"
                               v-validate="'required|min:2|max:50|alpha_spaces'" v-model="data_bank.holder_last_name">

                        <p class="message-error-field" v-if="errors.has('last_name')">
                            {{ errors.first('last_name') }}
                        </p>
                    </div>
                </div>
                <div>
                    <div class="common-content-field half-content half-left">
                        <label class="common-label-field">TIPO DE DOCUMENTO DE IDENTIDAD</label>

                        <select name="document_type" class="common-select" v-model.number="data_bank.document_type_id" v-validate="'required'">
                            <option v-for="doc_type in data_vue.list_document_type" :value="doc_type.id">{{ doc_type.label }}</option>
                        </select>

                    </div>
                    <div class="common-content-field half-content half-right">
                        <label class="common-label-field">NÚMERO DE DOCUMENTO DE IDENTIDAD *</label>
                        <input type="text" name="dni_number" placeholder="Ingresa el número"
                               :class="['common-input-text', errors.has('dni_number') ? 'error-input-text' : '']"
                               v-validate="'required|numeric|min:8|max:9'" v-model="data_bank.document_number">

                        <p class="message-error-field" v-if="errors.has('dni_number')">
                            {{ errors.first('dni_number') }}
                        </p>
                    </div>
                </div>
                <div class="next-content">
                    <div>
                        <div class="common-content-field half-content half-left">
                            <label class="common-label-field">BANCO *</label>

                            <select name="bank" class="common-select" v-model.number="data_bank.bank_id" v-validate="'required'">
                                <option v-for="bank in data_vue.list_bank" :value="bank.id">{{ bank.label }}</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="common-content-field half-content half-left">
                            <label class="common-label-field">TIPO DE CUENTA</label>

                            <select name="account_type" class="common-select" v-model.number="data_bank.bank_account_type_id" v-validate="'required'">
                                <option v-for="bank_type in data_vue.list_bank_account_type" :value="bank_type.id">{{ bank_type.label }}</option>
                            </select>
                        </div>

                        <div class="common-content-field half-content half-right">
                            <label class="common-label-field label-field-with-tooltip">
                                TIPO DE MONEDA
                                <span class="icon-help-tool"
                                      v-tooltip="{ placement: 'right',
                                      title: 'La moneda de la cuenta debe ser la misma que la de tu lista.' }"
                                ></span>
                            </label>
                            <input type="text" name="dni_number" class="common-input-text input-readonly"
                                   v-model="data_vue.event.currency.name" readonly>
                        </div>
                    </div>
                    <div>
                        <div class="common-content-field half-content half-left">
                            <label class="common-label-field">NÚMERO DE CUENTA *</label>
                            <input type="text" name="account_number" placeholder="Ingresa el número"
                                   :class="['common-input-text', errors.has('account_number') ? 'error-input-text' : '']"
                                   v-validate="'required|numeric|min:10|max:25'" v-model="data_bank.account_number">

                            <p class="message-error-field" v-if="errors.has('account_number')">
                                {{ errors.first('account_number') }}
                            </p>
                        </div>
                        <div class="common-content-field half-content half-right">
                            <label class="common-label-field">CÓDIGO DE CUENTA INTERBANCARIO *</label>
                            <input type="text" name="peruvian_cci" placeholder="Ingresa el número"
                                   :class="['common-input-text', errors.has('peruvian_cci') ? 'error-input-text' : '']"
                                   v-validate="'required|numeric|min:20|max:20'" v-model="data_bank.peruvian_cci">

                            <p class="message-error-field" v-if="errors.has('peruvian_cci')">
                                {{ errors.first('peruvian_cci') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="common-container-btn content-bnt-and-link flex-mobile">
                    <a :href="before_step" class="link-cancel-form desktop">REGRESAR AL PASO ANTERIOR</a>
                    <a :href="before_step" class="link-cancel-form mobile">REGRESAR</a>
                    <button class="common-green-btn" @click="showDataBankConfirm">
                        SIGUIENTE
                        <span class="arrow-into-btn"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "bank-account-edit",
        props: ['data_account_bank', 'before_step', 'data_vue', 'no_holder', 'default_holder'],
        data: () =>  {
            return {
                data_bank: {},
            }
        },
        created(){
            this.data_bank = this.data_account_bank;
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            },
            isFormChanged: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed);
            }
        },
        watch: {
            'data_bank.couple_member_id': function (){

                if( this.no_holder && this.data_bank.couple_member_id === this.no_holder.id){
                    this.data_bank.holder_first_name = this.no_holder.first_name;

                    this.data_bank.holder_last_name = this.no_holder.last_name;
                }else{
                    this.data_bank.holder_first_name = this.default_holder.first_name;
                    this.data_bank.holder_last_name = this.default_holder.last_name;
                }
            },
            'data_bank.holder_first_name': function (){
                this.changeDataDefaultHolder();
            },
            'data_bank.holder_last_name': function (){
                this.changeDataDefaultHolder();
            }
        },
        methods:{
            showDataBankConfirm: function () {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.$parent.show_data_bank_edit = false;
                        if (this.isFormChanged){
                            this.$parent.form_was_changed = true;
                        }
                        this.$scrollToTop();
                    }
                });
            },
            changeDataDefaultHolder: function (){
                if( this.no_holder && this.data_bank.couple_member_id === this.no_holder.id){

                    this.no_holder.first_name = this.data_bank.holder_first_name;
                    this.no_holder.last_name = this.data_bank.holder_last_name;

                }else{
                    this.default_holder.first_name = this.data_bank.holder_first_name;
                    this.default_holder.last_name = this.data_bank.holder_last_name;
                }
            }
        },
    }
</script>

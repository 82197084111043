<template>
    <div class="wrapper-item wrapper-add-contribution wrapper-item-added">
        <div class="wrapper-image-card">
            <div class="wrapper-bg-added added-to-cart" v-if="contrib_is_in_cart"></div>
        </div>
        <div class="wrapper-info-card wrapper-info-height">
            <h5 class="title-card">Aporte Libre</h5>
            <div class="description-item">{{ event.contrib_text ? event.contrib_text : 'Ingresa el monto que quieres regalar' }}</div>
            <div class="amount-contribution-label">MONTO A APORTAR</div>

            <form method="post" @submit.prevent="addItemToCart">
                <div class="container-btn-input-contribution">
                    <input type="text" name="amount"
                           v-model.number="amount" v-validate="'required|min_value:10'"
                           :class="['common-input-text', 'currency-symbol', event.currency.id === 1 ? 'soles' : 'dolar', errors.has('amount') ? 'error-input-text' : '']">

                    <button type="submit" :class="['common-green-btn', (contrib_is_in_cart) ? 'btn-update-quota' : '']"
                            :disabled="!(fields['amount'] && fields['amount'].valid)">
                        <span v-if="contrib_is_in_cart">ACTUALIZAR</span>
                        <span v-else>{{ event.gift_word ? event.gift_word : 'REGALAR' }}</span>
                    </button>
                </div>
                <p class="message-error-field" v-if="errors.has('amount')">
                    {{ errors.first('amount') }}
                </p>
            </form>

            <div class="wrapper-add-another-one" v-if="contrib_is_in_cart">
                <div class="text-center">
                    <a href="javascript:void(0)" class="simple-link add-new-card-link"
                        @click="deleteItemFromCart">
                        ELIMINAR
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as EventUtils from '../utils'
    import * as shoppingCartAPI from '../../../api/shopping-cart'

    export default {
        name: "account-contrib",
        props: ['cart', 'event', 'contrib_is_in_cart'],

        data: () =>  {
            return {
                amount: null
            }
        },
        watch: {
            'amount': function(){
                if(!this.contrib_is_in_cart && (this.amount === null || this.amount === '')){
                    this.clearValidatorField();
                }
            }
        },
        methods:{
            addItemToCart: function() {

                const quota = 1;
                const price = EventUtils.roundPrice(this.amount);

                shoppingCartAPI.addOrUpdateItem(
                    this.event.id, 0, quota, price
                ).then((response) => {

                    if (response.data.success) {
                        EventUtils.addorUpdateCartItem(this.cart, null, quota, price);
                        this.$emit('update_contrib_is_in_cart', true);
                        this.$emit('update_count_items_cart', Object.keys(this.cart.items).length);
                    } else {
                        this.$root.show_error_event_item = true;
                    }

                    this.hideMessagesResponse();

                }).catch((error) => {
                    this.$root.show_error_event_item = true;
                    this.hideMessagesResponse();
                });

            },
            deleteItemFromCart: function() {

                shoppingCartAPI.deleteItem(
                    this.event.id, 0
                ).then( (response) => {

                    if (response.data.success) {
                        EventUtils.deleteCartItem(this.cart, null);
                        this.amount = null;
                        this.$emit('update_contrib_is_in_cart', false);
                        this.$emit('update_count_items_cart', Object.keys(this.cart.items).length);
                    } else {
                        this.$root.show_error_event_item = true;
                    }

                    this.hideMessagesResponse();

                }).catch( (error) => {
                    this.$root.show_error_event_item = true;
                    this.hideMessagesResponse();
                });
            },
            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.$root.show_error_event_item = false;
                }.bind(this), 2000)
            },
            clearValidatorField: function(){
                this.amount = null;

                this.$validator.fields.items.map( field => {
                    this.$validator.reset(field);
                    this.$validator.errors.remove(field.name, field.scope);
                })
            }
        },
        created() {
            const has_account = 0 in this.cart.items;
            if (has_account) {
                this.amount = this.cart.items[0].price;
                this.$emit('update_contrib_is_in_cart', true);
            }
        }
    }
</script>

<template>
    <div>
        <p class="description-page">
            Antes de publicar tu lista de regalos revisa que todos los datos de la cuenta sean
            correctos.
        </p>
        <div class="common-wrapper-content-page">
            <div class="common-container-content-page container-less-height">
                <form method="post" @submit.prevent="dataBankSubmit">
                    <div v-html="csrf_token" hidden></div>
                    <h4 class="common-title-section">CONFIRMACIÓN DE DATOS</h4>
                    <p></p>

                    <div>
                        <label class="common-label-field">TITULAR DE LA CUENTA</label>
                        <div class="simple-text width-p-left">{{ data_bank.holder_first_name }} {{ data_bank.holder_last_name }}</div>
                    </div>
                    <br>

                    <div>
                        <label class="common-label-field">NOMBRE DEL BANCO</label>
                        <div v-for="bank in data_vue.list_bank"
                             class="simple-text width-p-left"
                             v-if="bank.id === data_bank.bank_id">
                            {{ bank.label }}
                        </div>
                    </div>
                    <br>

                    <div>
                        <label class="common-label-field">TIPO DE MONEDA</label>
                        <div class="simple-text width-p-left">
                            {{ data_vue.event.currency.symbol }} {{ data_vue.event.currency.name }}
                        </div>
                    </div>
                    <br>

                    <div>
                        <label class="common-label-field">NÚMERO DE CUENTA</label>
                        <div class="simple-text width-p-left">{{ data_bank.account_number }}</div>
                    </div>
                    <br>

                    <div>
                        <label class="common-label-field">CÓDIGO DE CUENTA INTERBANCARIO - CCI</label>
                        <div class="simple-text width-p-left">{{ data_bank.peruvian_cci }}</div>
                    </div>

                    <div class="common-container-btn content-bnt-and-link flex-mobile">
                        <a href="javascript:void(0)" class="link-cancel-form desktop" @click="backToEditData">
                            EDITAR LOS DATOS BANCARIOS
                        </a>
                        <a href="javascript:void(0)" class="link-cancel-form mobile" @click="backToEditData">
                            EDITAR
                        </a>
                        <button type="submit" class="common-green-btn" :disabled="isEventPublished && !$parent.form_was_changed">
                            {{ isEventPublished ? 'ACTUALIZAR' : 'SIGUIENTE' }}
                            <span class="arrow-into-btn" v-if="!isEventPublished"></span>
                        </button>
                    </div>
                    <div>
                        <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                        <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

    import * as eventsAPI from '../../api/events'
    import * as EventUtils from './utils'


    export default {
        name: "bank-account-confirm",
        props: ['csrf_token', 'data_account_bank', 'data_vue', 'next_url'],
        data: () =>  {
            return {
                data_bank: {},
                response_error_message: null,
                response_success_message: null
            }
        },

        created(){
            this.data_bank = this.data_account_bank;
        },
        computed: {
            isEventPublished: function() {
                return parseInt(this.data_vue.event.status) >= EventUtils.event_status.PUBLISHED;
            },
        },
        methods:{
            backToEditData: function (){
                this.$parent.show_data_bank_edit = true;
            },
            dataBankSubmit: function () {

                if(this.$parent.form_was_changed){

                    eventsAPI.storeBankAccount(this.data_bank).then( (response) => {

                        let is_success = response.data.success;

                        this.response_error_message = is_success ? '' : response.data.message;
                        this.response_success_message = is_success ? response.data.message: '';

                        if(is_success) setTimeout(() => {
                            if (this.isEventPublished) {
                                window.location.reload(false);
                            } else {
                                this.gotoNextStep();
                            }
                        }, 1000);

                    }).catch( (error) => {
                        this.response_error_message = 'Ocurrió un error, intente de nuevo.';
                    });
                }else{
                    this.gotoNextStep();
                }

            },
            gotoNextStep: function() {
                window.location = this.next_url;
            }
        },
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "common-title" }, [_vm._v("CHECKOUT (1/2)")]),
    _vm._v(" "),
    _c("div", { staticClass: "common-wrapper-content-page" }, [
      _c("div", { staticClass: "common-container-content-page" }, [
        _c("div", [
          _c("h4", { staticClass: "common-title-section" }, [
            _vm._v("DATOS PERSONALES")
          ]),
          _vm._v(" "),
          !_vm.user.user_id
            ? _c("div", { staticClass: "wrapper-bg-login" }, [
                _c("span", { staticClass: "text-to-login" }, [
                  _vm._v("¿Ya tienes una cuenta con nosotros? ")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "link-login",
                    attrs: { href: _vm.login_link }
                  },
                  [_vm._v("INICIA SESIÓN")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "text-to-login" }, [
                  _vm._v(" o continúa como invitado")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "common-content-field half-content half-left" },
              [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("NOMBRE *")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:2|max:50|alpha_spaces",
                      expression: "'required|min:2|max:50|alpha_spaces'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.first_name,
                      expression: "user.first_name"
                    }
                  ],
                  class: [
                    "common-input-text",
                    _vm.errors.has("first_name") ? "error-input-text" : ""
                  ],
                  attrs: { type: "text", name: "first_name" },
                  domProps: { value: _vm.user.first_name },
                  on: {
                    blur: _vm.updateNickname,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "first_name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("first_name")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.first("first_name")) +
                          "\n                        "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "common-content-field half-content half-right" },
              [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("APELLIDOS *")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:2|max:50|alpha_spaces",
                      expression: "'required|min:2|max:50|alpha_spaces'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.last_name,
                      expression: "user.last_name"
                    }
                  ],
                  class: [
                    "common-input-text",
                    _vm.errors.has("last_name") ? "error-input-text" : ""
                  ],
                  attrs: { type: "text", name: "last_name" },
                  domProps: { value: _vm.user.last_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "last_name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("last_name")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.first("last_name")) +
                          "\n                        "
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "common-content-field half-content half-left" },
              [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("CORREO ELECTRÓNICO *")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email|min:5|max:50",
                      expression: "'required|email|min:5|max:50'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.email,
                      expression: "user.email"
                    }
                  ],
                  class: [
                    "common-input-text",
                    _vm.errors.has("email") ? "error-input-text" : ""
                  ],
                  attrs: { type: "email", name: "email" },
                  domProps: { value: _vm.user.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "email", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("email")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.first("email")) +
                          "\n                        "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "common-content-field half-content half-right" },
              [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("DIRECCIÓN *")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:5|max:100",
                      expression: "'required|min:5|max:100'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.address,
                      expression: "user.address"
                    }
                  ],
                  class: [
                    "common-input-text",
                    _vm.errors.has("address") ? "error-input-text" : ""
                  ],
                  attrs: { type: "text", name: "address" },
                  domProps: { value: _vm.user.address },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "address", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("address")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.first("address")) +
                          "\n                        "
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "common-content-field half-content half-left" },
              [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("CIUDAD *")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:2|max:30",
                      expression: "'required|min:2|max:30'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.city,
                      expression: "user.city"
                    }
                  ],
                  class: [
                    "common-input-text",
                    _vm.errors.has("city") ? "error-input-text" : ""
                  ],
                  attrs: { type: "text", name: "city" },
                  domProps: { value: _vm.user.city },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "city", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("city")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.first("city")) +
                          "\n                        "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "common-content-field half-content half-right" },
              [
                _c("label", { staticClass: "common-label-field" }, [
                  _vm._v("TELÉFONO *")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|numeric|min:5|max:15",
                      expression: "'required|numeric|min:5|max:15'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.telephone,
                      expression: "user.telephone"
                    }
                  ],
                  class: [
                    "common-input-text",
                    _vm.errors.has("telephone") ? "error-input-text" : ""
                  ],
                  attrs: { type: "text", name: "telephone" },
                  domProps: { value: _vm.user.telephone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "telephone", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("telephone")
                  ? _c("p", { staticClass: "message-error-field" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.errors.first("telephone")) +
                          "\n                        "
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          !_vm.user.user_id
            ? _c("div", [
                _c("div", [
                  _c("div", { staticClass: "common-content-field " }, [
                    _c("div", { staticClass: "container-checkbox" }, [
                      _c("input", {
                        directives: [
                          { name: "validate", rawName: "v-validate" },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.want_register,
                            expression: "user.want_register"
                          }
                        ],
                        staticClass: "checkbox-form-tgl",
                        attrs: {
                          type: "checkbox",
                          id: "registerUser",
                          name: "register_user"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.user.want_register)
                            ? _vm._i(_vm.user.want_register, null) > -1
                            : _vm.user.want_register
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.user.want_register,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.user,
                                    "want_register",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.user,
                                    "want_register",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.user, "want_register", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._m(0)
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.user.want_register
                  ? _c("div", [
                      _c("div", { staticClass: "common-content-field" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "common-content-field half-content half-left"
                        },
                        [
                          _c("label", { staticClass: "common-label-field" }, [
                            _vm._v("CONTRASEÑA *")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.password,
                                expression: "user.password"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  "" +
                                  (_vm.user.want_register
                                    ? "required|min:8"
                                    : "min:8"),
                                expression:
                                  "`${user.want_register ? 'required|min:8' : 'min:8'}`"
                              }
                            ],
                            ref: "password",
                            class: [
                              "common-input-text",
                              _vm.errors.has("password")
                                ? "error-input-text"
                                : ""
                            ],
                            attrs: {
                              type: "password",
                              name: "password",
                              placeholder: "Ingresa tu contraseña"
                            },
                            domProps: { value: _vm.user.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("password")
                            ? _c("p", { staticClass: "message-error-field" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.errors.first("password")) +
                                    "\n                            "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "common-content-field half-content half-right"
                        },
                        [
                          _c("label", { staticClass: "common-label-field" }, [
                            _vm._v("CONFIRMA CONTRASEÑA *")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.confirm_password,
                                expression: "user.confirm_password"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  "" +
                                  (_vm.user.want_register
                                    ? "required|confirmed:password"
                                    : ""),
                                expression:
                                  "`${user.want_register ? 'required|confirmed:password' : ''}`"
                              }
                            ],
                            class: [
                              "common-input-text",
                              _vm.errors.has("confirm_password")
                                ? "error-input-text"
                                : ""
                            ],
                            attrs: {
                              type: "password",
                              name: "confirm_password",
                              placeholder: "Reingresa tu contraseña"
                            },
                            domProps: { value: _vm.user.confirm_password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "confirm_password",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("confirm_password")
                            ? _c("p", { staticClass: "message-error-field" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("confirm_password"))
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "next-content" }, [
          _c("h4", { staticClass: "common-title-section after-content" }, [
            _vm._v("MENSAJE PARA " + _vm._s(_vm.event_title))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [_vm._v("DE")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:2|max:100",
                  expression: "'required|min:2|max:100'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.nickname,
                  expression: "user.nickname"
                }
              ],
              class: [
                "common-input-text",
                _vm.errors.has("nickname") ? "error-input-text" : ""
              ],
              attrs: {
                type: "text",
                name: "nickname",
                placeholder: "Ingresa tu nombre"
              },
              domProps: { value: _vm.user.nickname },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "nickname", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("nickname")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.errors.first("nickname")) +
                      "\n                    "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "common-content-field" }, [
            _c("label", { staticClass: "common-label-field" }, [
              _vm._v("AGREGA UN MENSAJE")
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:400",
                  expression: "'required|max:400'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.message,
                  expression: "user.message"
                }
              ],
              class: [
                "common-input-text",
                _vm.errors.has("e_message") ? "error-input-text" : ""
              ],
              attrs: {
                type: "text",
                name: "e_message",
                placeholder: "Ingresar mensaje",
                rows: "5"
              },
              domProps: { value: _vm.user.message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "message", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("e_message")
              ? _c("p", { staticClass: "message-error-field" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.errors.first("e_message")) +
                      "\n                    "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container-checkbox" }, [
            _c("input", {
              directives: [
                { name: "validate", rawName: "v-validate" },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.is_public_message,
                  expression: "user.is_public_message"
                }
              ],
              staticClass: "checkbox-form-tgl",
              attrs: {
                type: "checkbox",
                id: "publicMessage",
                name: "public_message"
              },
              domProps: {
                checked: Array.isArray(_vm.user.is_public_message)
                  ? _vm._i(_vm.user.is_public_message, null) > -1
                  : _vm.user.is_public_message
              },
              on: {
                change: function($event) {
                  var $$a = _vm.user.is_public_message,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.user,
                          "is_public_message",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.user,
                          "is_public_message",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.user, "is_public_message", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    placement: "right",
                    title:
                      "Elige esta opción si quieres que tu mensaje sea público."
                  },
                  expression:
                    "{ placement: 'right', title: 'Elige esta opción si quieres que tu mensaje sea público.' }"
                }
              ],
              staticClass: "icon-help-tool"
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "common-container-btn content-bnt-and-link flex-mobile"
          },
          [
            _c(
              "a",
              {
                staticClass: "link-cancel-form",
                attrs: { href: _vm.event_link }
              },
              [_vm._v("\n                    CANCELAR\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "common-green-btn",
                on: {
                  click: function($event) {
                    return _vm.goNextStep()
                  }
                }
              },
              [
                _vm._v("\n                    SIGUIENTE\n                    "),
                _c("span", { staticClass: "arrow-into-btn" })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.response_error_message
            ? _c("p", {
                staticClass: "error-submit-form-message",
                domProps: { innerHTML: _vm._s(_vm.response_error_message) }
              })
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label-field-checkbox", attrs: { for: "registerUser" } },
      [
        _c("span", { staticClass: "text-label-checkbox" }, [
          _vm._v("Quiero registrarme como usuario")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "label-field-checkbox v-align-sub label-is-public-message",
        attrs: { for: "publicMessage" }
      },
      [
        _c("span", { staticClass: "text-label-checkbox" }, [
          _vm._v("Mensaje público")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
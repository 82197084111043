<template>
    <div class="common-content-field">
        <label class="common-label-field">DIRECCIÓN *</label>
        <input type="text" name="address" ref="autocomplete" class="common-input-text"
               placeholder=""
               v-bind:class="{'error-input-text': errors.has('address')}"
               v-validate="'required|max:150'"
               :disabled="!show"
               v-model="location.address">

        <p class="message-error-field" v-if="errors.has('address')">
            {{ errors.first('address') }}
        </p>
    </div>
</template>

<script>
import { EventBus } from '../event-bus.js';

export default {
  props: {
    google: {
      type: Object | null,
      required: true
    },
    map: {
      type: Object | null,
      required: true
    },
    location: Object,
    show: Boolean
  },
  data() {
    return {
      address: null,
      latitude: null,
      longitude: null,
    }
  },
  mounted() {
    this.autocomplete = new this.google.places.Autocomplete(
      (this.$refs.autocomplete)
    );

    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    this.autocomplete.bindTo('bounds', this.map);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        window.alert("Dirección no encontrada.");
        return;
      }
      // If the place has a geometry, then present it on a map.
      this.location.address= place.formatted_address;
      this.location.latitude = place.geometry.location.lat();
      this.location.longitude = place.geometry.location.lng();
      this.setCenter(this.location.latitude, this.location.longitude);
    });
  },
  methods: {
    setCenter: function(latitude, longitude){
      const position = {
        lat: latitude,
        lng: longitude
      };
      this.addMarker(position)
    },
    addMarker: function(position){
      this.$emit('add-marker', position);
    }
  },
  computed: {
    isFormChanged: function() {
        return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed);
    },
    isFormValid: function() {
        return Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
    },
  },
  watch: {
    isFormChanged: function (value) {
      this.$emit('change-autocomplete', value);
    },
    isFormValid: function (value) {
      this.$emit('validate-autocomplete', value);
    }
  },
  created() {
    var vm =this;
    EventBus.$on('validate-all', function() {
      vm.$validator.validate().then(result => {});
    });
  },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-view-items" },
    [
      _c("div", { staticClass: "wrapper-options-filter" }, [
        _c("div", { staticClass: "content-item-filter" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search_item,
                expression: "search_item"
              }
            ],
            staticClass: "input-search-menu",
            attrs: { type: "text", placeholder: "Buscar item" },
            domProps: { value: _vm.search_item },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search_item = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "show-device content-preview" }, [
          _c("div", { staticClass: "content-item-filter" }, [
            _c(
              "a",
              {
                staticClass: "text-uppercase simple-link",
                attrs: { href: _vm.preview_link }
              },
              [_vm._v("VISTA PREVIA")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-item-filter" }, [
            !_vm.show_only_added
              ? _c(
                  "a",
                  {
                    staticClass: "text-uppercase simple-link",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.show_only_added = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "VER REGALOS AGREGADOS (" +
                        _vm._s(_vm.items_added_count) +
                        ")"
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.show_only_added
              ? _c(
                  "a",
                  {
                    staticClass: "text-uppercase simple-link",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.show_only_added = false
                      }
                    }
                  },
                  [_vm._v("VER TODOS LOS REGALOS")]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "hr-tgl show-device" }),
        _vm._v(" "),
        _c("div", { staticClass: "content-item-filter show-device" }),
        _vm._v(" "),
        _c("div", { staticClass: "container-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.event.is_contribution_account,
                expression: "event.is_contribution_account"
              }
            ],
            staticClass: "checkbox-form-tgl",
            attrs: { type: "checkbox", id: "isContributionAccount" },
            domProps: {
              checked: Array.isArray(_vm.event.is_contribution_account)
                ? _vm._i(_vm.event.is_contribution_account, null) > -1
                : _vm.event.is_contribution_account
            },
            on: {
              change: function($event) {
                var $$a = _vm.event.is_contribution_account,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.event,
                        "is_contribution_account",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.event,
                        "is_contribution_account",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.event, "is_contribution_account", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "label-field-checkbox label-in-list-item",
              attrs: { for: "isContributionAccount" },
              on: { click: _vm.changeAccountContribution }
            },
            [
              _c("span", { staticClass: "text-label-checkbox w-auto" }, [
                _vm._v("\n                    Aporte libre\n                ")
              ])
            ]
          ),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  placement: "right",
                  title:
                    "Al incluir esta opción, tus invitados tendrán la posibilidad de aportar el monto de dinero que ellos elijan."
                },
                expression:
                  "{ placement: 'right', title: 'Al incluir esta opción, tus invitados tendrán la posibilidad de aportar el monto de dinero que ellos elijan.' }"
              }
            ],
            staticClass: "icon-help-tool"
          }),
          _vm._v(" "),
          _c("div", [
            _vm.response_error_contribution
              ? _c("p", {
                  staticClass: "error-submit-form-message",
                  domProps: {
                    innerHTML: _vm._s(_vm.response_error_contribution)
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.response_success_contribution
              ? _c("p", {
                  staticClass: "success-submit-form-message",
                  domProps: {
                    innerHTML: _vm._s(_vm.response_success_contribution)
                  }
                })
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "show-device mobile content-preview" }, [
          _c("div", { staticClass: "content-item-filter" }, [
            _c(
              "a",
              {
                staticClass: "text-uppercase simple-link",
                attrs: { href: _vm.preview_link }
              },
              [_vm._v("VISTA PREVIA")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-item-filter" }, [
            !_vm.show_only_added
              ? _c(
                  "a",
                  {
                    staticClass: "text-uppercase simple-link",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.show_only_added = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "VER REGALOS AGREGADOS (" +
                        _vm._s(_vm.items_added_count) +
                        ")"
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.show_only_added
              ? _c(
                  "a",
                  {
                    staticClass: "text-uppercase simple-link",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.show_only_added = false
                      }
                    }
                  },
                  [_vm._v("VER TODOS LOS REGALOS")]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "hr-tgl show-device" }),
        _vm._v(" "),
        _c("div", { staticClass: "content-item-filter" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content-item-filter" },
          [
            _c("category-sidebar", {
              attrs: { data_list_category: _vm.data_vue.list_category }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content-item-filter show-device" }),
        _vm._v(" "),
        _c("hr", { staticClass: "hr-tgl show-device" }),
        _vm._v(" "),
        _c("div", { staticClass: "content-item-filter show-device" }),
        _vm._v(" "),
        _vm.event.status == _vm.event_status.NOT_PUBLISHED
          ? _c(
              "div",
              { staticClass: "content-item-filter content-change-currency" },
              [
                _c("div", { staticClass: "wrapper-categories" }, [
                  _c("div", { staticClass: "content-item-category" }, [
                    _c("span", { staticClass: "label-category" }, [
                      _vm._v(
                        "\n                        TIPO DE MONEDA\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            placement: "right",
                            title:
                              "Aquí puedes modificar el tipo de moneda de tu lista de regalos. Recuerda que deberás proporcionar una cuenta en la misma moneda y que esta información no podrá ser modificada una vez que tu lista haya sido publicada."
                          },
                          expression:
                            "{ placement: 'right', title: 'Aquí puedes modificar el tipo de moneda de tu lista de regalos. Recuerda que deberás proporcionar una cuenta en la misma moneda y que esta información no podrá ser modificada una vez que tu lista haya sido publicada.' }"
                        }
                      ],
                      staticClass: "icon-help-tool"
                    })
                  ]),
                  _vm._v(" "),
                  _vm.show_drop_currency
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.event.currency.id,
                              expression: "event.currency.id",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "common-select",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.event.currency,
                                "id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.list_currencies, function(currency) {
                          return _c(
                            "option",
                            { domProps: { value: currency.id } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(currency.symbol) +
                                  " " +
                                  _vm._s(currency.name) +
                                  "\n                    "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "content-item-category content-edit-currency"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "label-category sub-label-category"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.event.currency.symbol) +
                                  " - " +
                                  _vm._s(_vm.event.currency.name) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "simple-link",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  _vm.show_drop_currency = true
                                }
                              }
                            },
                            [_vm._v("EDITAR")]
                          )
                        ]
                      ),
                  _vm._v(" "),
                  _c("div", [
                    _vm.response_error_currency
                      ? _c("p", {
                          staticClass: "error-submit-form-message",
                          domProps: {
                            innerHTML: _vm._s(_vm.response_error_currency)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.response_success_currency
                      ? _c("p", {
                          staticClass: "success-submit-form-message",
                          domProps: {
                            innerHTML: _vm._s(_vm.response_success_currency)
                          }
                        })
                      : _vm._e()
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event.status == _vm.event_status.NOT_PUBLISHED
          ? _c("hr", { staticClass: "hr-tgl hr-tgl-mobile show-device" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wrapper-content-list-item in-build-list" },
        [
          _c("div", { staticClass: "content-open-filter" }, [
            _c("h2", { staticClass: "common-title" }, [
              _vm._v(_vm._s(_vm.name_category_selected))
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "wrapper-list-item" },
            [
              _c(
                "div",
                {
                  staticClass: "wrapper-item wrapper-add-item-first",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#storeItemModal"
                  },
                  on: {
                    click: function($event) {
                      return _vm.loadCreateDataModal()
                    }
                  }
                },
                [_vm._m(1)]
              ),
              _vm._v(" "),
              _vm._l(_vm.listItemsFiltered, function(item) {
                return _c(
                  "div",
                  {
                    key: _vm.generateKey(item),
                    class: [
                      "wrapper-item",
                      item.is_item_added
                        ? "wrapper-item-added"
                        : "wrapper-item-normal"
                    ]
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "wrapper-image-card",
                        style: { backgroundImage: "url(" + item.image + ")" }
                      },
                      [
                        item.is_item_added
                          ? _c("div", {
                              staticClass: "wrapper-bg-added add-to-list"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.is_item_added
                          ? _c(
                              "div",
                              {
                                staticClass: "wrapper-buttons-card text-center"
                              },
                              [
                                !_vm.isItemPurchased(item)
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "delete-item-link",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteItem(item.id)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            ELIMINAR\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "common-green-btn",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": "#storeItemModal"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadUpdateDataModal(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            EDITAR\n                        "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "wrapper-buttons-card text-center"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "common-green-btn",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": "#storeItemModal"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.loadCreateDataModal(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            AGREGAR\n                        "
                                    )
                                  ]
                                )
                              ]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          "wrapper-info-card",
                          item.is_item_added ? "" : "text-center"
                        ]
                      },
                      [
                        _c("h5", { staticClass: "title-card" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _vm._v(" "),
                        !item.is_item_added &&
                        item.is_donation_gift &&
                        item.description
                          ? _c("div", { staticClass: "description-item" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.description) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.is_item_added
                          ? _c("div", [
                              item.description
                                ? _c(
                                    "div",
                                    { staticClass: "description-item" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item.description) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.num_quota > 1
                                ? _c("div", { staticClass: "price-item" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.event.currency.symbol) +
                                        _vm._s(item.price_quota) +
                                        " por cuota\n                        "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.num_quota > 1
                                ? _c("div", { staticClass: "total-price" }, [
                                    _vm._v(
                                      "PRECIO TOTAL: " +
                                        _vm._s(_vm.event.currency.symbol) +
                                        _vm._s(item.price)
                                    )
                                  ])
                                : _c("div", { staticClass: "price-item" }, [
                                    _vm._v(
                                      _vm._s(_vm.event.currency.symbol) +
                                        _vm._s(item.price)
                                    )
                                  ]),
                              _vm._v(" "),
                              item.original_item
                                ? _c(
                                    "div",
                                    { staticClass: "wrapper-add-another-one" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "simple-link add-new-card-link",
                                              attrs: {
                                                href: "javascript:void(0)",
                                                "data-toggle": "modal",
                                                "data-target": "#storeItemModal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.loadCreateDataModal(
                                                    item.original_item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    AGREGAR OTRO\n                                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("fixed-bottom-bar", {
            attrs: {
              before_step: _vm.before_step,
              next_link: _vm.next_link,
              total_amount_items_added: _vm.total_amount_items_added,
              items_added_count: _vm.items_added_count,
              event: _vm.event
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("store-item-modal", {
        ref: "store-item-modal",
        attrs: {
          data_list_category: _vm.data_vue.list_category,
          currency: _vm.event.currency,
          csrf_token: _vm.csrf_token,
          event_id: _vm.event.id
        },
        on: { "item-added": _vm.onItemNew, "item-updated": _vm.onItemUpdate }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "tab-open-filter show-device mobile",
        attrs: { id: "openSideFilter" }
      },
      [
        _vm._v("\n                FILTROS\n                "),
        _c("span", { staticClass: "arrow-open-filter" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrapper-add-item" }, [
      _c("div", { staticClass: "content-icon-add-item" }, [
        _c("div", { staticClass: "img-add-card" }),
        _vm._v(" "),
        _c("span", { staticClass: "text-add-card" }, [
          _vm._v("CREAR UN PRODUCTO")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }